import { servicesNames } from "../contexts/serviceName";
import { TOTALS_TITLE } from "../states/contexts/DataContext";

export const calculateTotals = (data, serviceName) => {
  return data
    .filter((entry) => entry.service === serviceName)
    .reduce((acc, curr) => {
      if (!acc[curr.status_code]) {
        acc[curr.status_code] = 0;
      }
      acc[curr.status_code] += curr.count;
      return acc;
    }, {});
};

export const calculateTotalsFront = (totals, servicesToShow) => {
  const fraudId = servicesNames.DECISION_MAKER.frontName;
  const scoreId = servicesNames.USER_SCORE.frontName;

  const showFraud = servicesToShow?.find(
    (service) => service.title === fraudId
  ).show;
  const showScore = servicesToShow?.find(
    (service) => service.title === scoreId
  ).show;

  const {
    totals: { fraudTotal, scoreTotal },
    totalsByService,
  } = totals;

  const fraud = totalsByService[fraudId];
  const score = totalsByService[scoreId];

  const newTotals = [
    {
      title: TOTALS_TITLE.total,
      total: getTotal({ showFraud, showScore, fraudTotal, scoreTotal }),
    },
    {
      title: TOTALS_TITLE.correct,
      subtitle: "Response 200",
      total: getTotal({
        showFraud,
        showScore,
        fraudTotal: fraud?.find((el) => el.title === TOTALS_TITLE.correct)
          ?.total,
        scoreTotal: score?.find((el) => el.title === TOTALS_TITLE.correct)
          ?.total,
      }),
    },
    {
      title: TOTALS_TITLE.invalid,
      subtitle: "Response 400",
      total: getTotal({
        showFraud,
        showScore,
        fraudTotal: fraud?.find((el) => el.title === TOTALS_TITLE.invalid)
          ?.total,
        scoreTotal: score?.find((el) => el.title === TOTALS_TITLE.invalid)
          ?.total,
      }),
    },
    {
      title: TOTALS_TITLE.error,
      subtitle: "Response 500",
      total: getTotal({
        showFraud,
        showScore,
        fraudTotal: fraud?.find((el) => el.title === TOTALS_TITLE.error)?.total,
        scoreTotal: score?.find((el) => el.title === TOTALS_TITLE.error)?.total,
      }),
    },
  ];

  return newTotals;
};

const getTotal = ({ showFraud, showScore, fraudTotal, scoreTotal }) => {
  return showFraud && showScore
    ? fraudTotal + scoreTotal
    : showFraud
    ? fraudTotal
    : showScore
    ? scoreTotal
    : 0;
};
