import { COLORS } from "../styles/utils/colors";
import Button from "./Button";

const LinkConfigBtnContainer = ({
  cancelBtnConfig,
  saveBtnConfig,
  enableSave,
}) => {
  return (
    <div className="linkPage__configuration__menu__btn-container">
      <Button
        styles={{
          padding: "9px 129x",
          boxShadow: "none",
          color: COLORS.SHUTTLE_GRAY,
          backgroundColor: COLORS.WHITE,
          border: `1px solid ${COLORS.SHUTTLE_GRAY}`,
        }}
        text={cancelBtnConfig.text}
        handleClick={cancelBtnConfig.handleClick}
      />
      <Button
        styles={{ padding: "9px 129x", boxShadow: "none" }}
        text={saveBtnConfig.text}
        handleClick={saveBtnConfig.handleClick}
        disabled={!enableSave}
      />
    </div>
  );
};
export default LinkConfigBtnContainer;
