const DropdownSDKContainer = ({ classNames, containerData, functions }) => {
  const { options } = containerData;
  const { handleShow } = functions;

  return (
    <>
      {options.map((option) => (
        <button
          className={`${classNames.dropdownContentElement} ${
            option.show && "selectSDK__dropdown__element-selected"
          }`}
          key={option.id}
          onClick={() => handleShow(option)}
        >
          {option.show && (
            <span
              className={`icon-check-plano ${classNames.icon} color--dodger-blue`}
            ></span>
          )}
          <span className="text-2">{option.title}</span>
        </button>
      ))}
    </>
  );
};
export default DropdownSDKContainer;
