export const initialTabState = [
  {
    id: "nueva-busqueda",
    text: "Nueva Búsqueda de aplicante",
    selected: true,
    description:
      "Organiza y selecciona los datos que prefieras. Ningún campo es obligatorio",
  },
  {
    id: "por-id",
    text: "Búsqueda de aplicante por ID",
    selected: false,
    description:
      "Busca a alguien a quién ya se le haya asignado un ID dentro de tu organización.",
  },
  {
    id: "busqueda-masiva",
    text: "Búsqueda masiva",
    selected: false,
    description: "",
  },
];

export const initialValidateForm = [
  {
    name: "document_image",
    valid: true,
  },
  {
    name: "rfc",
    valid: true,
  },
  {
    name: "curp",
    valid: true,
  },
  {
    name: "ip",
    valid: true,
  },
  {
    name: "email",
    valid: true,
  },
  {
    name: "image",
    valid: true,
  },
  {
    name: "address",
    valid: true,
  },
  {
    name: "phone_number",
    valid: true,
  },
  {
    name: "device",
    valid: true,
  },
  {
    name: "external_id",
    valid: true,
  },
  {
    name: "document_image_back",
    valid: true,
  },
  {
    name: "lng",
    valid: true,
  },
  {
    name: "lat",
    valid: true,
  },
];

export const steps = [
  {
    element: ".busqueda__form ",
    intro:
      "En esta sección puedes hacer una búsqueda nueva o bien buscar por un ID previamente registrado. Ningún campo es obligatorio.",
    position: "right",
    tooltipClass: "customTooltip--busqueda-tooltip",
  },
];

export const stepsReport = [
  {
    element: ".busqueda__reporte",
    intro:
      "Los resultados muestran un semáforo, el cuál suma los resultados de los datos proporcionados y compara con nuestra base de datos. También encontrarás un detalle de los elementos buscados",
    position: "left",
    tooltipClass: "customTooltip--busquedaReport-tooltip",
  },
];
