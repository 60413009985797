import { useEffect, useState } from "react";
import { calculateTotalsFront } from "../helpers/calculateTotals";
import { useTolgee } from "@tolgee/react";
import { TOTALS_TITLE } from "../states/contexts/DataContext";

const ResumenConsultas = ({ totals, servicesToShow }) => {
  const [totalsToShow, setTotalsToShow] = useState(() =>
    calculateTotalsFront(totals)
  );
  const tolgee = useTolgee(["language"]);
  const isLangEs = tolgee.getLanguage() === "es-MX";

  useEffect(() => {
    setTotalsToShow(() => calculateTotalsFront(totals, servicesToShow));
  }, [totals, servicesToShow]);

  const translateTitle = (title) => {
    if (isLangEs) return title;

    switch (title) {
      case TOTALS_TITLE.total:
        return "Total requests";
      case TOTALS_TITLE.correct:
        return "Good";
      case TOTALS_TITLE.invalid:
        return "Invalid data";
      case TOTALS_TITLE.error:
        return "Errors";

      default:
        return title;
    }
  };

  return (
    <div className="resumenConsultas">
      {totalsToShow.map((el, i) => (
        <div
          key={`totals-${i}`}
          className={`resumenConsultas__element${i !== 0 ? "--border" : ""}`}
        >
          <div className="resumenConsultas__element__title">
            <h3 className="btn-text">{translateTitle(el.title)}</h3>
            {el.subtitle && <h4 className="text-3">{el.subtitle}</h4>}
          </div>
          <span className="heading-1">
            {Intl.NumberFormat("en-US").format(el.total)}
          </span>
        </div>
      ))}
    </div>
  );
};

export default ResumenConsultas;
