import { SDK_TYPE } from "../constants";

const generateObject = (el, percentageData) => ({
  ...el,
  data: percentageData ? [...percentageData] : null,
});

const getPercentageData = ({ el, web, android, magicLink }) => {
  switch (el.id) {
    case SDK_TYPE.WEB:
      return generateObject(el, web?.percentageData);
    case SDK_TYPE.ANDROID:
      return generateObject(el, android?.percentageData);
    case SDK_TYPE.MAGIC_LINK:
      return generateObject(el, magicLink?.percentageData);
    default:
      return null;
  }
};

export const getSDKPercentages = ({ state, web, android, magicLink }) =>
  state.map((el) => getPercentageData({ el, web, android, magicLink }));
