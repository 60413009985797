import { useEffect, useRef, useState } from "react";

const WarningsContainer = ({ warningsToShow, expandPanel }) => {
  const scrollContainerRef = useRef(null);
  const [showScrollButtons, setShowScrollButtons] = useState(false);

  const scroll = (scrollOffset) => {
    scrollContainerRef.current.scrollBy(0, scrollOffset);
  };

  const checkForScroll = () => {
    const container = scrollContainerRef.current;
    const hasOverflow = container.scrollHeight > container.clientHeight;
    setShowScrollButtons(hasOverflow);
  };

  useEffect(() => {
    checkForScroll();
    window.addEventListener("resize", checkForScroll);
    return () => window.removeEventListener("resize", checkForScroll);
  }, []);

  return (
    <div className="reporteDataAccordion__warnings">
      <div className="reporteDataAccordion__warnings__container">
        <h3 className="text-2--bold">Advertencia</h3>
        <div className="reporteDataAccordion__warnings__scroll-container">
          <div
            ref={scrollContainerRef}
            className="reporteDataAccordion__warnings--overflow"
          >
            {warningsToShow.map((warning, i) => (
              <p className="text-2" key={`${expandPanel}-warning-${i}`}>
                {warning}
              </p>
            ))}
          </div>
          {showScrollButtons && (
            <>
              <button
                className="icon-expand-menos reporteDataAccordion__warnings__btn--up"
                onClick={() => scroll(-100)}
              ></button>
              <button
                className="icon-down reporteDataAccordion__warnings__btn--down"
                onClick={() => scroll(100)}
              ></button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
export default WarningsContainer;
