import { useContext } from "react";
import Calendar from "./Calendar";
import DatesContext from "../contexts/DatesContext";
import DataContext from "../contexts/DataContext";
import ResumenConsultas from "./ResumenConsultas";
import Chart from "./Chart";
import { useTranslate } from "@tolgee/react";

const CommandPanelConsultas = ({ walkthrough }) => {
  const { dates, handleDates, updateChart, setUpdateChart } =
    useContext(DatesContext);
  const { t } = useTranslate();

  const {
    totals,
    errorTotals,
    chartLoading,
    errorChart,
    noChartData,
    getChartData,
    servicesPercentages,
    errorPercentages,
    servicesToShow,
  } = useContext(DataContext);

  return (
    <section className="dashboard__panel-section">
      <h2 className="heading-2 chart--title__text">
        {t("command_panel_consultas_title")}
      </h2>
      <Calendar
        dates={dates}
        setDates={handleDates}
        setUpdateChart={setUpdateChart}
      />
      <div className="dashboard__resumen-container">
        <ResumenConsultas totals={totals} servicesToShow={servicesToShow} />
      </div>
      <div className="layout__main__section__dashboard__component--container">
        <div className="layout__main__section__dashboard__component layout__main__section__dashboard__component--resumen border border-light rounded-2">
          <Chart
            walkthrough={false}
            dates={dates}
            setUpdateChart={setUpdateChart}
            updateChart={updateChart}
            sdkChart={false}
            chartLoading={chartLoading}
            errorChart={errorChart}
            noChartData={noChartData}
            getChartData={getChartData}
            servicesPercentages={servicesPercentages}
            errorPercentages={errorPercentages}
            servicesToShow={servicesToShow}
          />
        </div>
      </div>
    </section>
  );
};
export default CommandPanelConsultas;
