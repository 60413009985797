export const initialState = {
  showReport: false,
  report: {},
  reportToFlag: false,
  loading: false,
  error: false,
  devices: [],
  reportFromStatus: false,
  scrollLoader: false
};

export const FLAGS_NAMES = {
  NOT_FRAUD: "Not Fraud",
  NO_THREAT: "No Threat",
  FRAUD: "Fraud",
  POTENTIAL_THREAT: "Potential Threat",
  REVIEW: "Review",
};
