async function checkWebPSupport() {
  if (!window.createImageBitmap) return false;

  const webpData =
    "data:image/webp;base64,UklGRh4AAABXRUJQVlA4TBEAAAAvAAAAAAfQ//73v/+BiOh/AAA=";
  const blob = await fetch(webpData).then((r) => r.blob());
  return createImageBitmap(blob).then(
    () => true,
    () => false
  );
}

export const convertToWebp = async (file) => {
  if (!file) return;
  const webpSupported = await checkWebPSupport();

  return new Promise((resolve, reject) => {
    if (!webpSupported || file.type.includes("webp")) {
      resolve(file);
      return;
    }

    const reader = new FileReader();
    reader.onload = function (event) {
      const img = new Image();
      img.onload = function () {
        const canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        const name = file.name.replace(/\.(jpg|jpeg|png)$/i, ".webp");
        canvas.toBlob(
          (blob) => {
            const webpFile = new File([blob], name, {
              type: "image/webp",
            });
            resolve(webpFile);
          },
          "image/webp",
          0.75
        );
      };
      img.src = event.target.result;
    };
    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(file);
  });
};
