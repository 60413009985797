export const sorting = (dataToSort, order, type, key, inUsers) => {
  let newDataToShow;
  if (type === "number") {
    newDataToShow = dataToSort.sort((a, b) => {
      let newA = a[key] + 0;
      let newB = b[key] + 0;

      if (newA === 0 || newB === 0) return -1;

      if (newA > newB) return 1;

      if (newA < newB) return -1;

      return 0;
    });
  } else if (inUsers && type === "string") {
    newDataToShow = dataToSort.sort((a, b) => {
      let newA;
      let newB;

      if (key === "name") {
        newA = a["first_name"] + a["last_name"] + "";
        newB = b["first_name"] + b["last_name"] + "";
      } else {
        newA = a[key] + "";
        newB = b[key] + "";
      }

      if (newA === "" || newB === "") return -1;

      if (newA > newB) return 1;

      if (newA < newB) return -1;

      return 0;
    });
  } else if (type === "string") {
    newDataToShow = dataToSort.sort((a, b) => {
      const newA = a[key].includes("%")
        ? Number(a[key].replace("%", ""))
        : a[key] + "";
      const newB = b[key].includes("%")
        ? Number(b[key].replace("%", ""))
        : b[key] + "";

      if (newA === "" || newB === "") return -1;

      if (newA > newB) return 1;

      if (newA < newB) return -1;

      return 0;
    });
  } else if (type === "date") {
    newDataToShow = dataToSort.sort((a, b) => {
      return new Date(a[key]) - new Date(b[key]);
    });
  }

  if (order === "desc") {
    newDataToShow = dataToSort.reverse();
  }

  return newDataToShow;
};
