import { base64ToBlob } from "./base64ToBlob";

export const generateUrl = (logo) => {
  if (typeof logo === "string") {
    if (logo.includes("https") || logo.includes("http")) return logo;
    if (logo === "logo")
      return "https://trully-api-documentation.s3.amazonaws.com/trully-sdk/logo-trully.svg";

    const base64 = logo.replaceAll("data:image/png;base64,", "");

    const blob = base64ToBlob(base64);
    const url = URL.createObjectURL(blob);
    return url;
  }

  if (logo) {
    const url = URL.createObjectURL(logo);
    return url;
  }

  return "";
};
