import { useContext, useState } from "react";
import ReportContext from "../contexts/ReportContext";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { useTranslate } from "@tolgee/react";

const DocumentSubDropdown = () => {
  const {
    report: { document_image_quality },
  } = useContext(ReportContext);
  const [expanded, setExpanded] = useState(false);
  const { t } = useTranslate();

  const handleChange = (panel) => (isExpanded) => {
    if (panel === "") setExpanded(false);
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Accordion
      key="document-sub"
      expanded={expanded}
      onChange={!expanded ? handleChange("document-sub") : handleChange("")}
      className="reporteDataAccordion"
      style={{ minHeight: "none", paddingTop: "16px" }}
    >
      <AccordionSummary
        aria-controls="document-sub-content"
        id="document-sub-header"
        className="reporteDataAccordion__title--blue"
      >
        <div className="reporteDataAccordion__title__text--blue">
          <button>
            {!expanded ? (
              <div className="icon-down reporteDataAccordion__title__icon color--dodger-blue"></div>
            ) : (
              <div className="icon-expand-menos reporteDataAccordion__title__icon color--dodger-blue"></div>
            )}
          </button>
          <h3 className="text-2--bold color--dodger-blue">
            {t("doc_sub_drop_title")}
          </h3>
        </div>
      </AccordionSummary>
      <AccordionDetails
        sx={{ transition: "height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;" }}
      >
        <div className="reporteDataAccordion__sub-document">
          {document_image_quality?.quality_score && (
            <div className="reporteDataAccordion__description">
              <h4 className="text-2 color--shuttle-gray">
                {t("doc_sub_drop_1")}
              </h4>
              <span className="text-2">
                {document_image_quality?.quality_score}
              </span>
            </div>
          )}
          {document_image_quality?.scorecard?.compression && (
            <div className="reporteDataAccordion__description">
              <h4 className="text-2 color--shuttle-gray">
                {t("doc_sub_drop_2")}
              </h4>
              <span className="text-2">
                {document_image_quality?.scorecard?.compression}
              </span>
            </div>
          )}
          {document_image_quality?.scorecard?.doc2img_ratio && (
            <div className="reporteDataAccordion__description">
              <h4 className="text-2 color--shuttle-gray">
                {t("doc_sub_drop_3")}
              </h4>
              <span className="text-2">
                {document_image_quality?.scorecard?.doc2img_ratio}
              </span>
            </div>
          )}
          {document_image_quality?.scorecard?.ppi && (
            <div className="reporteDataAccordion__description">
              <h4 className="text-2 color--shuttle-gray">PPI</h4>
              <span className="text-2">
                {document_image_quality?.scorecard?.ppi}
              </span>
            </div>
          )}
          {document_image_quality?.scorecard?.sharpness && (
            <div className="reporteDataAccordion__description">
              <h4 className="text-2 color--shuttle-gray">Sharpness</h4>
              <span className="text-2">
                {document_image_quality?.scorecard?.sharpness}
              </span>
            </div>
          )}
        </div>
      </AccordionDetails>
    </Accordion>
  );
};
export default DocumentSubDropdown;
