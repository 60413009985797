import { useEffect } from "react";
import InputCheckbox from "./InputCheckbox.js";
import { useTranslate } from "@tolgee/react";

const ReCaptchaComponent = ({ validCaptcha, setValidCaptcha }) => {
  const { t } = useTranslate();

  const verify = (e) => {
    e.preventDefault();
    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute("6LcgqvolAAAAANfIjQf72TisT0fpYe3TUMSKIRv0", {
          action: "login",
        })
        .then(
          function (token) {
            setValidCaptcha(token);
        });
    });
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.id = "captcha";
    script.src =
      "https://www.google.com/recaptcha/api.js?render=6LcgqvolAAAAANfIjQf72TisT0fpYe3TUMSKIRv0";

    document.body.appendChild(script);

    return () => {};
  }, []);

  return (
    <InputCheckbox
      label={t("signin_captcha")}
      handleChecked={verify}
      checked={validCaptcha}
    />
  );
};
export default ReCaptchaComponent;
