import { Outlet, useLocation, Navigate } from "react-router-dom";
import { useWindowSize } from "../../hooks/useWindowSize";
import { useContext, useEffect, useState } from "react";
import Header from "../Header";
import NavBar from "../NavBar";
import { CSSTransition } from "react-transition-group";
import { VERSION, styles } from "../../version";
import Modal from "../Modal";
import AuthContext from "../../contexts/AuthContext";
import { PRIVATE_ROUTES } from "./routes";
import UserContext from "../../contexts/UserContext";
import ReportContext from "../../contexts/ReportContext";
import { url } from "../../contexts/domain";
import { io } from "socket.io-client";

const Layout = () => {
  const { width: windowSize } = useWindowSize();
  const [openSlide, setOpenSlide] = useState(true);
  const location = useLocation();
  const billing = location.pathname === PRIVATE_ROUTES.BILLING;
  const { user, sessionExpired } = useContext(AuthContext);
  const { showNewNotification } = useContext(UserContext);
  const { addIdentityReported } = useContext(ReportContext);
  const [socketInstance, setSocketInstance] = useState(null);

  useEffect(() => {
    if (user.id !== 0 && socketInstance === null) {
      const socket = io(url);

      const onAuth = (request_id) =>
        socket.emit("auth", { user: user.id, request_id });

      socket.on("auth", onAuth);

      const onDisconnect = () => {};

      socket.on("disconnect", onDisconnect);

      const onNotification = (notification) => {
        const newNotification = {
          ...notification,

          message: notification.reason,
        };

        addIdentityReported(
          newNotification.identity_reported,

          newNotification.identity_id
        );

        showNewNotification(newNotification);
      };

      socket.on("notification", onNotification);

      setSocketInstance(socket);

      return () => {
        socket.off("auth", onAuth);

        socket.off("disconnect", onDisconnect);

        socket.off("notification", onNotification);
      };
    }
  }, [user.id]);

  useEffect(() => {
    if (window.captcha) {
      const captcha = window.captcha.parentNode;
      if (captcha) captcha.style.display = "none";
    } else {
      const captcha = document.querySelector(".grecaptcha-badge");
      if (captcha) captcha.parentNode.style.display = "none";
    }

    window.addEventListener("load", () => {
      if (window.captcha) {
        const captcha = window.captcha.parentNode;
        if (captcha) captcha.style.display = "none";
      } else {
        const captcha = document.querySelector(".grecaptcha-badge");
        if (captcha) captcha.parentNode.style.display = "none";
      }
    });
  }, []);

  useEffect(() => {
    windowSize < 700 ? setOpenSlide(false) : setOpenSlide(true);
  }, [windowSize]);

  useEffect(() => {
    if (location.pathname !== PRIVATE_ROUTES.CONSULTAS) {
      sessionStorage.removeItem("selectedOption");
      sessionStorage.removeItem("searchedValue");
    }
  }, [location.pathname]);

  const navBarClasses =
    windowSize < 700
      ? "layout__sidebar--close border border-light rounded-1"
      : "layout__sidebar border border-light rounded-1";

  const mainClasses = windowSize < 700 ? "layout__main--close" : "layout__main";

  const headerClasses =
    windowSize < 700
      ? "layout__main__header layout__main__header--close"
      : "layout__main__header";

  const mainSectionClasses =
    windowSize < 700 ? "layout__main__section--close" : "layout__main__section";

  if (window.location.pathname === "/") {
    return <Navigate to={"/dashboard"} />;
  }

  return (
    <div className="layout">
      <CSSTransition in={openSlide} timeout={300} classNames="openNav">
        <section className={navBarClasses}>
          <NavBar open={openSlide} setOpenSlide={setOpenSlide} />
          <h4 style={styles}>{VERSION}</h4>
        </section>
      </CSSTransition>
      <section className={mainSectionClasses}>
        <Header styles={headerClasses} />
        {sessionExpired && <Modal logout={true} />}
        <main
          style={{ overflow: billing ? "visible" : "auto" }}
          className={mainClasses}
        >
          <Outlet />
        </main>
      </section>
    </div>
  );
};

export default Layout;
