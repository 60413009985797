import Button from "./Button";
import { T } from "@tolgee/react";
import { useTranslate } from '@tolgee/react';

const ProcessingBatch = ({ setShowModal, imagesMissing }) => {
  const {t} = useTranslate();
  return (
    <div className="processingBatch">
      {imagesMissing.length === 0 ? (
        <div className="processingBatch__container">
          <div className="icon-check processingBatch__icon color--shark"></div>
          <p className="text-2">
            <T keyName="processing_batch_success_message_1" />
            <br />
            <br />
            <T keyName="processing_batch_success_message_2" />
          </p>
        </div>
      ) : (
        <>
          <div className="processingBatch__error-title">
            <div className="icon-revisa processingBatch__icon color--shark"></div>
            <p className="text-2">
              <T keyName="processing_batch_error_message" />
            </p>
          </div>
          <div className="processingBatch__error-list">
            <ul>
              {imagesMissing.map((image, i) => (
                <li className="text-2" key={`imagesMissing-${i}`}>
                  {image}
                </li>
              ))}
            </ul>
          </div>
          <p className="text-2">
            <T keyName="processing_batch_info_message" />
          </p>
        </>
      )}
      <Button
        text={imagesMissing.length === 0 ? t("confirm_button") : t("acknowledge_button")}
        handleClick={setShowModal}
        styles={{ alignSelf: "flex-end" }}
      />
    </div>
  );
};
export default ProcessingBatch;
