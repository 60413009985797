import { useEffect, useState } from "react";
import {
  validateExternalID,
  validateNumbers,
  validateTrullyID,
} from "../../helpers/formValidations";
import ErrorSlideTimeOut from "../ErrorSlideTimeOut";
import TextInput from "../TextInput";
import Button from "../Button";
import { useTolgee, useTranslate } from "@tolgee/react";

export const ID_FORM_INPUT_DATA = {
  ID_TO_SEARCH: {
    NAME: "idToSearch",
    DESCRIPTION: "ID de la consulta",
  },
  ID_USER: {
    NAME: "idUser",
    DESCRIPTION: "ID del usuario",
  },
  ID_TRULLY: {
    NAME: "idTrully",
    DESCRIPTION: "Trully ID",
  },
};

const IDForm = ({ form, setForm, handleIDForm, error, restartReport }) => {
  const [allowSend, setAllowSend] = useState(false);
  const { t } = useTranslate();
  const tolgee = useTolgee(["language"]);

  useEffect(() => {
    if (Object.keys(form).length === 0) return;

    setAllowSend(() => {
      let clavesValidas = 0;
      let clavesVacias = 0;

      for (const key in form) {
        if (form[key] === "") clavesVacias++;
        else if (
          key === ID_FORM_INPUT_DATA.ID_TO_SEARCH.NAME &&
          validateNumbers(form[key])
        )
          clavesValidas++;
        else if (
          key === ID_FORM_INPUT_DATA.ID_USER.NAME &&
          validateExternalID(form[key])
        )
          clavesValidas++;
        else if (
          key === ID_FORM_INPUT_DATA.ID_TRULLY.NAME &&
          validateTrullyID(form[key])
        )
          clavesValidas++;
      }

      const totalClaves = Object.keys(form).length;
      return clavesValidas === 1 && clavesVacias === totalClaves - 1;
    });
  }, [form]);

  const translate = (description) => {
    const isLangEs = tolgee.getLanguage() === "es-MX";
    if (isLangEs) return description;

    switch (description) {
      case ID_FORM_INPUT_DATA.ID_TO_SEARCH.DESCRIPTION:
        return "Request ID";
      case ID_FORM_INPUT_DATA.ID_USER.DESCRIPTION:
        return "User ID";
      case ID_FORM_INPUT_DATA.ID_TRULLY.DESCRIPTION:
        return ID_FORM_INPUT_DATA.ID_TRULLY.DESCRIPTION;

      default:
        return description;
    }
  };

  return (
    <>
      <form
        className="busqueda__form__container--ID-form"
        onSubmit={(e) => e.preventDefault()}
      >
        <TextInput
          form={form}
          inputName={ID_FORM_INPUT_DATA.ID_TO_SEARCH.NAME}
          inputLabel="123456"
          description={translate(ID_FORM_INPUT_DATA.ID_TO_SEARCH.DESCRIPTION)}
          setValue={setForm}
          infoText={translate(ID_FORM_INPUT_DATA.ID_TO_SEARCH.DESCRIPTION)}
          validate={validateNumbers}
          validateErrorText={t("id_form_input_request_id_error")}
        />
        <TextInput
          form={form}
          inputName={ID_FORM_INPUT_DATA.ID_USER.NAME}
          inputLabel="13741T"
          description={translate(ID_FORM_INPUT_DATA.ID_USER.DESCRIPTION)}
          setValue={setForm}
          infoText={t("id_form_input_user_id_text")}
          iconStyles={{ width: "150px" }}
          validate={validateExternalID}
        />
        <TextInput
          form={form}
          inputName={ID_FORM_INPUT_DATA.ID_TRULLY.NAME}
          inputLabel="aB12cDe"
          description={translate(ID_FORM_INPUT_DATA.ID_TRULLY.DESCRIPTION)}
          setValue={setForm}
          infoText={t("id_form_input_trully_id_text")}
          iconStyles={{ width: "150px" }}
          validate={validateTrullyID}
        />
        <Button
          text={t("show_results_btn")}
          handleClick={handleIDForm}
          styles={{ maxWidth: "280px" }}
          disabled={!allowSend}
        />
      </form>
      <ErrorSlideTimeOut error={error} restartFn={restartReport} />
    </>
  );
};
export default IDForm;
