import { useContext } from "react";
import ReportContext from "../contexts/ReportContext";
import DataContext from "../contexts/DataContext";
import { servicesNames } from "../contexts/serviceName";
import Badge from "./Badge";
import { FLAGS_NAMES } from "../states/contexts/ReportContext";
import Informer from "./Informer";
import { COLORS } from "../styles/utils/colors";
import TruncatedText from "./TruncatedText";
import { formatUTCDate } from "../helpers/formatDate";

const TableRowTd = ({
  created_on,
  service,
  id,
  external_id,
  response,
  label,
  assigned_to,
  handleClick,
}) => {
  const { showColumns } = useContext(DataContext);
  const { report } = useContext(ReportContext);
  const date = formatUTCDate(created_on, "D [de] MMM YYYY");
  const time = formatUTCDate(created_on, "hh:mm:ss A");

  const serviceName =
    service === servicesNames.DECISION_MAKER.backName ||
    service === servicesNames.DECISION_MAKER_INVOKE.backName
      ? servicesNames.DECISION_MAKER.frontName
      : servicesNames.USER_SCORE.frontName;

  if (serviceName === "Sin Dato") {
    return;
  }

  return (
    <tr className="layout__main__section__consultas__table__tbody__tr">
      {showColumns?.date && (
        <DateColumn report={report} date={`${date} ${time}`} />
      )}
      {showColumns?.productName && (
        <ServiceNameColumn report={report} serviceName={serviceName} id={id} />
      )}
      {showColumns?.id && <IDColumn report={report} id={id} />}
      {showColumns?.externalId && (
        <ExternalID report={report} external_id={external_id} id={id} />
      )}
      {showColumns?.informador && (
        <AssignedToColumn report={report} assigned_to={assigned_to} id={id} />
      )}
      {showColumns?.estatus && (
        <FlagColumn
          report={report}
          id={id}
          serviceName={serviceName}
          label={label || response?.label}
          handleClick={handleClick}
        />
      )}
    </tr>
  );
};

const DateColumn = ({ date }) => (
  <td className="layout__main__section__consultas__table__tbody__td">
    <TruncatedText text={date} />
  </td>
);

const ServiceNameColumn = ({ serviceName }) => (
  <td className="layout__main__section__consultas__table__tbody__td">
    {serviceName === servicesNames.DECISION_MAKER.frontName && (
      <TruncatedText
        text={serviceName}
        icon="icon-Frame"
        iconClass="icon-heading"
      />
    )}

    {serviceName === servicesNames.USER_SCORE.frontName && (
      <TruncatedText
        text={serviceName}
        icon="icon-Frame"
        iconClass="icon-heading"
      />
    )}
  </td>
);

const IDColumn = ({ id }) => (
  <td className="layout__main__section__consultas__table__tbody__td">
    <TruncatedText text={id} />
  </td>
);

const ExternalID = ({ external_id }) => (
  <td className="layout__main__section__consultas__table__tbody__td">
    <TruncatedText text={external_id} />
  </td>
);

const AssignedToColumn = ({ assigned_to }) => (
  <td className="layout__main__section__consultas__table__tbody__td">
    <Informer assigned_to={assigned_to} />
  </td>
);

const FlagColumn = ({ id, serviceName, label, handleClick }) => {
  const getIcon = () => {
    return label === FLAGS_NAMES.FRAUD || label === FLAGS_NAMES.POTENTIAL_THREAT
      ? "icon-amenaza"
      : label === FLAGS_NAMES.NO_THREAT || label === FLAGS_NAMES.NOT_FRAUD
      ? "icon-check"
      : "icon-revisa";
  };

  const getColor = () => {
    return label === FLAGS_NAMES.FRAUD || label === FLAGS_NAMES.POTENTIAL_THREAT
      ? COLORS.COPPERFIELD
      : label === FLAGS_NAMES.NO_THREAT || label === FLAGS_NAMES.NOT_FRAUD
      ? COLORS.KEPPEL
      : COLORS.COM;
  };

  const getBgColor = () => {
    return label === FLAGS_NAMES.FRAUD || label === FLAGS_NAMES.POTENTIAL_THREAT
      ? COLORS.PEACH_SCHNAPPS
      : label === FLAGS_NAMES.NO_THREAT || label === FLAGS_NAMES.NOT_FRAUD
      ? COLORS.ICEBERG
      : COLORS.BUTTERMILK;
  };

  const getText = () => {
    return label === FLAGS_NAMES.FRAUD || label === FLAGS_NAMES.POTENTIAL_THREAT
      ? "Amenaza"
      : label === FLAGS_NAMES.NO_THREAT || label === FLAGS_NAMES.NOT_FRAUD
      ? "¡Correcto!"
      : "Revisar";
  };

  const getStyles = () => {
    return {
      container: {
        minWidth: "5.903125rem",
        justifyContent: "flex-start",
        gap: "6px",
      },
      text: {
        margin: 0,
      },
    };
  };

  return (
    <td
      className="layout__main__section__consultas__table__tbody__td"
      style={{
        display: "inline-flex",
        alignItems: "center",
        minHeight: "45px",
      }}
    >
      <Badge
        icon={getIcon()}
        color={getColor()}
        bgColor={getBgColor()}
        text={getText()}
        styles={getStyles()}
      />
      {serviceName !== "Sin Dato" && (
        <button
          className="icon-siguiente btn"
          onClick={() => {
            handleClick(id);
          }}
        ></button>
      )}
    </td>
  );
};
export default TableRowTd;
