export const showDropdown = ({
  tableData,
  anchorElement,
  handleShow,
  dropdownPosition,
  createNewData,
}) => {
  if (createNewData) {
    let changeDisplay;

    if (anchorElement)
      changeDisplay = tableData.map((anchor) =>
        anchor.id === anchorElement.id
          ? { ...anchor, show: true, dropdownPosition }
          : { ...anchor, show: false }
      );
    else
      changeDisplay = tableData.map((anchor) => ({
        ...anchor,
        show: false,
      }));

    handleShow(changeDisplay);
  } else handleShow();
};
