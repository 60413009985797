import { useContext, useEffect, useState } from "react";
import {
  createTheme,
  ThemeProvider,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  StepIcon,
} from "@mui/material";
import Button from "./Button";
import { COLORS } from "../styles/utils/colors";
import FileUploader from "./FileUploader";
import csvIcon from "../styles/icons/images/icon_add_csv.svg";
import imgIcon from "../styles/icons/images/icon_add_image.svg";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import { url } from "../contexts/domain";
import { generateRequest } from "../helpers/helpHttp";
import AuthContext from "../contexts/AuthContext";
import {
  DeleteObjectCommand,
  PutObjectCommand,
  S3Client,
} from "@aws-sdk/client-s3";
import Modal from "./Modal";
import { ImageExistsError } from "../exceptions";
import { useTolgee, useTranslate } from "@tolgee/react";

const steps = [
  {
    id: "CargaBatchStep1",
    label:
      "Sube el archivo .csv con los nombres de las imágenes y los datos que quieres procesas",
    description: [
      "Utiliza el .csv del Archivo de ejemplo para ligar las imágenes con datos de la persona",
      "El archivo .csv no debe superar los 5mb",
      "Debe contener las siguientes cabeceras: 'selfie_image_name', 'user_id' y  'curp'",
    ],
  },
  {
    id: "CargaBatchStep2",
    label: "Sube los archivos de las imágenes que quieres procesar",
    description: [
      "Evita nombres repetidos",
      "Sólo se admiten imágenes .webp, .png, .jpg o .jpeg",
      "La carga máxima para todos los archivos es 200mb ",
    ],
  },
];

const initialServerData = { id: "", key: "", secret: "", token: "" };

function CustomStepIcon(props) {
  const { completed } = props;

  if (completed) {
    return <CheckCircleOutlinedIcon />;
  }

  return <StepIcon {...props} />;
}

export default function StepperBatch() {
  const [activeStep, setActiveStep] = useState(0);
  const [csvUploaded, setCsvUploaded] = useState(false);
  const [imagesUploaded, setImagesUploaded] = useState(false);
  const [imagesUploadedNames, setImagesUploadedNames] = useState([]);
  const [csvImagesNames, setCSVImagesNames] = useState([]);
  const [serverData, setServerData] = useState(initialServerData);
  const [showModal, setShowModal] = useState(null);
  const [imagesMissing, setImagesMissing] = useState([]);
  const {
    user: { id },
  } = useContext(AuthContext);
  const { t } = useTranslate();
  const tolgee = useTolgee(["language"]);
  const isLangEs = tolgee.getLanguage() === "es-MX";

  useEffect(() => {
    if (activeStep !== 0) return;

    getServerData(`${url}/usage/batch`);
  }, [showModal, activeStep]);

  const getServerData = async (url) => {
    const data = await generateRequest.get({ url });
    const { id, secret, key, token } = data;
    setServerData(() => ({ id, secret, key, token }));
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => {
      getServerData(`${url}/usage/batch`);
      setCsvUploaded(() => false);
      setImagesUploaded(() => false);
      setImagesUploadedNames(() => []);
      setCSVImagesNames(() => []);
      return prevActiveStep - 1;
    });
  };

  const findImagesMissing = () => {
    const csvMissingNames = csvImagesNames.filter(
      (name) => !imagesUploadedNames.includes(name)
    );
    const imagesMissingNames = imagesUploadedNames.filter(
      (name) => !csvImagesNames.includes(name)
    );

    return csvMissingNames
      .concat(imagesMissingNames)
      .filter((name) => !(name instanceof File));
  };

  const startProcessingBatch = async () => {
    const imagesMissing = findImagesMissing();
    if (imagesMissing.length > 0) {
      setImagesMissing(() => imagesMissing);
      setImagesUploaded(() => false);
      setShowModal(() => true);
      return;
    }

    await generateRequest.post({
      url: `${url}/usage/batch`,
      options: {
        headers: {
          "content-type": "application/json",
        },
        body: { id: serverData.id, status: "completed" },
      },
    });

    setShowModal(() => true);
    setActiveStep(() => 0);
    setCsvUploaded(() => false);
    setImagesUploaded(() => false);
    setImagesUploadedNames(() => []);
    setCSVImagesNames(() => []);
    setServerData(() => initialServerData);
  };

  const generateDropZoneHTML = ({ icon, dropText, validationText }) => {
    return `<div class="cargaBatch__drop-zone">
      <img src=${icon} alt="icono" class="cargaBatch__drop-zone__img" />
      <p class="text-1">${dropText} o <span class="filepond--label-action">sube un archivo</span></p>
      <p class="text-2 color--shuttle-gray">${validationText}</p>
    </div>`;
  };

  const validateCSVStructure = ({ file, error, setErrorObjStr }) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = function () {
        const lines = reader.result.split("\n");
        const headers = lines[0].split(",");

        if (
          headers.length === 3 &&
          headers[0] === "selfie_image_name" &&
          headers[1] === "user_id" &&
          headers[2].trim() === "curp"
        ) {
          const nationalIdImageNames = lines
            .slice(1)
            .map((line) => {
              const columns = line.split(",");
              return columns[0];
            })
            .filter((el) => el);

          setCSVImagesNames(() => nationalIdImageNames);
          resolve(true);
        } else {
          setErrorObjStr(() => ({
            title: t("stepper_batch_csv_error_title"),
            text: t("stepper_batch_csv_error_text"),
          }));
          error("Invalid CSV");
          resolve(false);
        }
      };
      reader.readAsText(file);
    });
  };

  const validateImagesNames = ({ file, error, setErrorObjStr }) => {
    return new Promise((resolve, reject) => {
      const exists = csvImagesNames.find((name) => name === file.name);
      if (!exists) {
        setErrorObjStr(() => ({
          title: t("stepper_batch_img_error_title"),
          text: t("stepper_batch_img_error_text"),
        }));
        error("Invalid image name");
        resolve(false);
      }

      resolve(true);
    });
  };

  const serverConnection = ({
    file,
    setEnabledUpload,
    setTypeError,
    setErrorObjStr,
    setFilesInState,
    error,
    load,
    setFilesUploaded,
  }) => {
    const { key, secret, token, id: batchID } = serverData;

    const client = new S3Client({
      credentials: {
        accessKeyId: key,
        secretAccessKey: secret,
        sessionToken: token,
      },
      region: "us-east-1",
    });

    const command = new PutObjectCommand({
      Bucket: "trully-batch-processing",
      Key: `${
        process.env.REACT_APP_TRULLY_ENV === "prod" ? "prod" : "develop"
      }/${id}/${batchID}/${file.name}`,
      Body: file,
    });

    client
      .send(command)
      .then(() => {
        load(200);
        if (
          file.name.includes(".png") ||
          file.name.includes(".jpg") ||
          file.name.includes(".jpeg") ||
          file.name.includes(".webp")
        ) {
          const exists = imagesUploadedNames.find((img) => img === file.name);
          if (exists) throw new ImageExistsError();
          setImagesUploadedNames((prev) =>
            file.name ? [...prev, file.name] : [...prev]
          );
        }
        setFilesUploaded((prev) => prev + 1);
      })
      .catch((err) => {
        if (err instanceof ImageExistsError)
          setErrorObjStr(() => ({ title: err.title, text: err.message }));
        setEnabledUpload(() => false);
        setTypeError(() => true);
        setFilesInState && setFilesInState(() => []);
        error("oh no");
      });
  };

  const deleteElement = async (file) => {
    const { key, secret, token, id: batchID } = serverData;

    const client = new S3Client({
      credentials: {
        accessKeyId: key,
        secretAccessKey: secret,
        sessionToken: token,
      },
      region: "us-east-1",
    });

    const input = {
      Bucket: "trully-batch-processing",
      Key: `${
        process.env.REACT_APP_TRULLY_ENV === "prod" ? "prod" : "develop"
      }/${id}/${batchID}/${file}`,
    };

    const command = new DeleteObjectCommand(input);
    client.send(command).then(() => {
      setImagesUploadedNames((prev) => prev.filter((img) => img !== file.name));
    });
  };

  const theme = createTheme({
    components: {
      MuiStepIcon: {
        styleOverrides: {
          root: {
            width: "24px",
            height: "24px",
            border: `1px solid ${COLORS.DODGER_BLUE}`,
            borderRadius: "50px",
            color: COLORS.WHITE,
            "&.Mui-active": {
              "&.Mui-active": {
                color: COLORS.DODGER_BLUE,
                border: "none",
              },
              "& text": {
                fill: COLORS.WHITE,
              },
            },
            "&.Mui-completed": {
              "&.Mui-completed": {
                color: COLORS.WHITE,
              },
              "& text": {
                fill: COLORS.DODGER_BLUE,
              },
            },
          },
          text: {
            fill: COLORS.DODGER_BLUE,
            fontSize: "1rem",
          },
        },
      },
      MuiSvgIcon: {
        styleOverrides: {
          root: {
            color: COLORS.DODGER_BLUE,
          },
        },
      },
      MuiStepConnector: {
        styleOverrides: {
          line: {
            borderColor: COLORS.DANUBE,
            borderLeftWidth: "2px",
          },
        },
      },
      MuiStepContent: {
        styleOverrides: {
          root: {
            borderLeft: `2px solid ${COLORS.DANUBE}`,
          },
        },
      },
    },
  });

  const translateStepLabel = (label) => {
    if (isLangEs) return label;

    switch (label) {
      case steps[0].label:
        return "Upload the .csv file with the names of the images and the data you want to process";
      case steps[1].label:
        return "Upload the images files you want to process";

      default:
        return label;
    }
  };

  const translateStepDescription = (description) => {
    if (isLangEs) return description;

    switch (description) {
      case steps[0].description[0]:
        return "Use the .csv of the Example File to link the images with the person's data";
      case steps[0].description[1]:
        return "The .csv file must not exceed 5mb";
      case steps[0].description[2]:
        return "It must contain the following headers: 'selfie_image_name', 'user_id' and 'curp'";
      case steps[1].description[0]:
        return "Avoid repeated names";
      case steps[1].description[1]:
        return "Only .webp, .png, .jpg or .jpeg images are supported";
      case steps[1].description[2]:
        return "The maximum upload for all files is 200mb ";

      default:
        return description;
    }
  };

  const generateText = (text) => {
    const es = text.split("Archivo de ejemplo");
    const en = text.split("Example File");

    return es.length > 1 ? es : en;
  };

  return (
    <>
      {showModal && (
        <Modal
          startBatchProcess={true}
          setShowModal={() => {
            setImagesMissing(() => []);
            setShowModal(() => false);
          }}
          imagesMissing={imagesMissing}
        />
      )}
      <div className="cargaBatch__stepper">
        <ThemeProvider theme={theme}>
          <Stepper
            activeStep={activeStep}
            orientation="vertical"
            TransitionProps={{ unmountOnExit: false }}
          >
            {steps.map((step, index) => (
              <Step key={step.id}>
                <StepLabel StepIconComponent={CustomStepIcon}>
                  {translateStepLabel(step.label)}
                </StepLabel>
                <StepContent
                  sx={index === steps.length - 1 ? { border: "none" } : {}}
                >
                  <div className="cargaBatch__stepper__content">
                    <ul className="cargaBatch__stepper__description">
                      {step.description.map((text, i) => (
                        <li key={`${step.id}-${i}`}>
                          {generateText(translateStepDescription(text)).map(
                            (part, index, array) =>
                              index < array.length - 1 ? (
                                <span key={`text-${i}`}>
                                  {part}
                                  <strong className="text-2--bold">
                                    {isLangEs
                                      ? "Archivo de ejemplo"
                                      : "Example File"}
                                  </strong>
                                </span>
                              ) : (
                                <span key={`alt-text-${i}`}>
                                  {isLangEs
                                    ? translateStepDescription(part)
                                    : part}
                                </span>
                              )
                          )}
                        </li>
                      ))}
                    </ul>
                    {index === steps.length - 1 ? (
                      <FileUploader
                        allowMultiple={true}
                        allowReorder={false}
                        dropZoneHTML={generateDropZoneHTML({
                          icon: imgIcon,
                          dropText: t("stepper_batch_img_drop"),
                          validationText: t(
                            "stepper_batch_img_drop_validation"
                          ),
                        })}
                        allowFileSizeValidation={true}
                        maxTotalFileSize="200MB"
                        maxFileSize="9MB"
                        allowFileTypeValidation={true}
                        acceptedFileTypes={[
                          "image/png",
                          "image/jpeg",
                          "image/jpg",
                          "image/webp",
                        ]}
                        fileValidateTypeLabelExpectedTypes={t(
                          "stepper_batch_invalid_img"
                        )}
                        setEnabledUpload={setImagesUploaded}
                        imagePreviewFilterItem={() => false}
                        validateFiles={validateImagesNames}
                        serverConnection={serverConnection}
                        setImagesUploadedNames={setImagesUploadedNames}
                        deleteElement={deleteElement}
                      />
                    ) : (
                      <FileUploader
                        allowMultiple={false}
                        allowReorder={false}
                        dropZoneHTML={generateDropZoneHTML({
                          icon: csvIcon,
                          dropText: t("stepper_batch_csv_drop"),
                          validationText: t(
                            "stepper_batch_csv_drop_validation"
                          ),
                        })}
                        allowFileSizeValidation={true}
                        maxFileSize="5MB"
                        allowFileTypeValidation={true}
                        acceptedFileTypes={["text/csv"]}
                        fileValidateTypeLabelExpectedTypes={t(
                          "stepper_batch_invalid_csv"
                        )}
                        setEnabledUpload={setCsvUploaded}
                        validateFiles={validateCSVStructure}
                        setFilesInState={setCSVImagesNames}
                        serverConnection={serverConnection}
                        deleteElement={deleteElement}
                      />
                    )}
                    <div className="cargaBatch__stepper__btn-container">
                      {step.id !== "CargaBatchStep1" && (
                        <Button
                          handleClick={handleBack}
                          text={t("go_back_btn")}
                          styles={{
                            color: COLORS.SHARK,
                            backgroundColor: COLORS.WHITE,
                            border: `1px solid ${COLORS.CATSKILL_WHITE}`,
                          }}
                          btnClass="icon-anterior"
                        />
                      )}
                      {index === steps.length - 1 ? (
                        <Button
                          disabled={!imagesUploaded}
                          handleClick={startProcessingBatch}
                          text={t("stepper_batch_start_btn")}
                          btnClass="icon-siguiente cargaBatch__stepper__btn"
                        />
                      ) : (
                        <Button
                          disabled={!csvUploaded}
                          handleClick={handleNext}
                          text={t("continue_btn")}
                          btnClass="icon-siguiente cargaBatch__stepper__btn"
                        />
                      )}
                    </div>
                  </div>
                </StepContent>
              </Step>
            ))}
          </Stepper>
        </ThemeProvider>
      </div>
    </>
  );
}
