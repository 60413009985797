import CheckBoxOutlineBlankRoundedIcon from "@mui/icons-material/CheckBoxOutlineBlankRounded";
import CheckBoxRoundedIcon from "@mui/icons-material/CheckBoxRounded";
import CheckBox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useContext, useState } from "react";
import UserContext from "../contexts/UserContext";
import { useTolgee, useTranslate } from "@tolgee/react";
import { initialFilters } from "../states/contexts/AuthContext";

const translate = (text, isLangEs) => {
  if (isLangEs) return text;

  switch (text) {
    case initialFilters[0].label:
      return "Front INE / IFE or Passport";
    case initialFilters[4].label:
      return "Email address";
    case initialFilters[6].label:
      return "Address";
    case initialFilters[7].label:
      return "Phone Number";
    case initialFilters[8].label:
      return "Latitude, Longitude";
    case initialFilters[9].label:
      return "Back INE / IFE";

    default:
      return text;
  }
};

const SelectInputs = ({ setOpenForm, filters }) => {
  const { filtersToShow } = useContext(UserContext);
  const [form, setForm] = useState(filters);
  const [dragging, setDragging] = useState(null);
  const { t } = useTranslate();
  const tolgee = useTolgee(["language"]);
  const isLangEs = tolgee.getLanguage() === "es-MX";

  const handleDragStart = (event, index) => {
    setDragging(index);
    event.dataTransfer.effectAllowed = "move";
  };

  const handleDragOver = (event, index) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
    const newList = [...form];
    const draggingItem = newList[dragging];
    newList.splice(dragging, 1);
    newList.splice(index, 0, draggingItem);
    setForm(newList);
    filtersToShow(newList);
    setDragging(index);
  };

  const handleDragEnd = () => {
    setDragging(null);
  };

  const handleChange = ({ target: { name, checked } }) => {
    const newForm = form.map((el) =>
      el.name === name ? { ...el, show: checked } : el
    );

    setForm(newForm);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    filtersToShow(form);
    setOpenForm(false);
  };

  return (
    <form onSubmit={handleSubmit} className="selectRows selectInputs">
      {form.map((el, i) => (
        <button
          className="selectRows--inputs"
          key={`filtros-${i}`}
          draggable
          onDragStart={(event) => handleDragStart(event, i)}
          onDragOver={(event) => handleDragOver(event, i)}
          onDragEnd={handleDragEnd}
          style={{
            opacity: dragging === i ? 0.5 : 1,
            cursor: "move",
          }}
        >
          <FormControlLabel
            control={
              <CheckBox
                name={el.name}
                checked={el.show}
                icon={<CheckBoxOutlineBlankRoundedIcon />}
                checkedIcon={<CheckBoxRoundedIcon />}
                onChange={handleChange}
                inputProps={{ "aria-label": "secondary checkbox" }}
              />
            }
            label={translate(el.label, isLangEs)}
          />
          <span className="icon-arrastrar"></span>
        </button>
      ))}

      <button
        type="submit"
        className="text-2 color--dodger-blue selectRows--btn"
      >
        {t("save_preferences_btn")}
      </button>
    </form>
  );
};
export default SelectInputs;
