import { useState } from "react";
import datos from "../styles/images/Datos.svg";
import id from "../styles/images/ID.svg";
import selfie from "../styles/images/Selfie.svg";
import ImageFilterCanvas from "./ImageFilterCanvas";

const DescriptionIcon = ({ icon, text, primaryTextColor, primaryColor }) => {
  return (
    <div className="initPreview__info-container__description-container__description">
      <ImageFilterCanvas
        imageUrl={icon}
        color={primaryColor}
        canvasWidth={48}
      />
      <span
        className="initPreview__text apply-font-text"
        style={{ color: primaryTextColor }}
      >
        {text}
      </span>
    </div>
  );
};

const MagicLinkInitPreview = ({
  logo,
  primaryColor,
  secondaryColor,
  primaryTextColor,
}) => {
  const [accepted, setAccepted] = useState(false);
  return (
    <div className="linkPreview__component-container">
      <div className="initPreview__info-container">
        {logo && <img src={logo} alt="logo" className="linkPreview__logo" />}
        <div className="initPreview__info-container__header">
          <h2
            style={{ color: primaryTextColor, textAlign: "center" }}
            className="initPreview__title apply-font-heading1"
          >
            Vamos a verificar tu identidad
          </h2>
          <h4
            style={{ color: primaryTextColor, textAlign: "center" }}
            className="initPreview__text apply-font-heading2"
          >
            Para este proceso necesitas:
          </h4>
        </div>
        <div className="initPreview__info-container__description-container">
          <DescriptionIcon
            icon={datos}
            text="Datos personales"
            primaryTextColor={primaryTextColor}
            primaryColor={primaryColor}
          />
          <DescriptionIcon
            icon={id}
            text="INE o Pasaporte vigente"
            primaryTextColor={primaryTextColor}
            primaryColor={primaryColor}
          />
          <DescriptionIcon
            icon={selfie}
            text="Video Selfie"
            primaryTextColor={primaryTextColor}
            primaryColor={primaryColor}
          />
        </div>
      </div>
      <div className="initPreview__btn-container">
        <div className="initPreview__btn-container__input-container">
          <input
            type="checkbox"
            name="accept"
            id="accept"
            checked={accepted}
            onChange={() => setAccepted((prev) => !prev)}
            className="initPreview__btn-container__input"
          />
          <p
            style={{ color: primaryTextColor }}
            className="initPreview__btn-container__disclaimer apply-font-text"
          >
            Aceptar los{" "}
            <a
              href="https://www.trully.ai/es/terminos-y-condiciones"
              target="_blank"
              rel="noreferrer"
              style={{ color: primaryColor }}
            >
              Términos y Condiciones
            </a>{" "}
            y la{" "}
            <a
              href="https://www.trully.ai/es/aviso-de-privacidad-para-nuestros-candidatos"
              target="_blank"
              rel="noreferrer"
              style={{ color: primaryColor }}
            >
              Política de Privacidad
            </a>{" "}
            del servicio
          </p>
        </div>
        <button
          className="initPreview__btn-container__btn apply-font-heading2"
          style={
            accepted
              ? {
                  backgroundColor: primaryColor,
                }
              : {
                  backgroundColor: secondaryColor,
                }
          }
        >
          Continuar
        </button>
      </div>
    </div>
  );
};
export default MagicLinkInitPreview;
