import { useContext, useEffect, useState } from "react";
import { useFormValidation } from "../hooks/useFormValidation";
import InputPass from "./InputPass";
import { validatePass, validateRepeatPass } from "../helpers/formValidations";
import SmallLoader from "./SmallLoader";
import UserContext from "../contexts/UserContext";
import Button from "./Button";
import { COLORS } from "../styles/utils/colors";
import { T } from "@tolgee/react";
import { useTranslate } from '@tolgee/react';

const initialState = {
  password: "",
  new_password1: "",
  new_password2: "",
};

const initialValidateForm = [
  {
    name: "password",
    valid: true,
  },
  {
    name: "new_password1",
    valid: true,
  },
  {
    name: "new_password2",
    valid: true,
  },
];

const ChangePassForm = ({ setShowModal }) => {
  const [allowSend, setAllowSend] = useState(false);
  const [form, setForm] = useState(initialState);
  const { validateForm, setValidateForm, handleFormValidation } =
    useFormValidation(initialValidateForm);
  const { changePassLoading, changePass } = useContext(UserContext);
  const { t } = useTranslate();

  useEffect(() => {
    const password = form.password;
    const new_password1 = form.new_password1;
    const new_password2 = form.new_password2;
    const invalidForm = validateForm.find((el) => !el.valid);

    if (!password || !new_password1 || !new_password2 || invalidForm) {
      setAllowSend(false);
    } else {
      setAllowSend(true);
    }
  }, [validateForm, form.password, form.new_password1, form.new_password2]);

  const handleSubmit = (e) => {
    e.preventDefault();

    changePass(validateForm, form, setShowModal);

    setValidateForm(initialValidateForm);
  };

  const handleCancel = () => {
    setForm(initialState);
    setValidateForm(initialValidateForm);
    setShowModal(false);
  };

  return (
    <div className="changePassForm">
      <h2 className="heading-2"><T keyName="change_pass_text" /></h2>
      <form onSubmit={handleSubmit} className="changePassForm__form">
        <InputPass
          form={form}
          inputName="password"
          description={t("input_current_pass")}
          setValue={setForm}
          validate={validatePass}
          infoText={t("input_pass_info")}
          validateErrorText={t("input_pass_error")}
          iconStyles={{ width: "280px" }}
          handleFormValidation={handleFormValidation}
        />
        <InputPass
          form={form}
          inputName="new_password1"
          description={t("input_new_pass")}
          setValue={setForm}
          validate={validatePass}
          infoText={t("input_pass_info")}
          validateErrorText={t("input_pass_error")}
          iconStyles={{ width: "280px" }}
          handleFormValidation={handleFormValidation}
        />
        <InputPass
          form={form}
          inputName="new_password2"
          description={t("input_confirm_pass")}
          setValue={setForm}
          validate={validateRepeatPass}
          handleFormValidation={handleFormValidation}
        />
        <div className="changePassForm__form__buttons ">
          {changePassLoading && (
            <div className="changePassForm__loader-container">
              <SmallLoader />
            </div>
          )}
          <Button
            type="submit"
            handleClick={() => {}}
            text={t("change_pass_text")}
            disabled={!allowSend}
            styles={{
              padding: "8px 12px",
            }}
          />
          <Button
            text={t("cancel_button")}
            handleClick={handleCancel}
            styles={{
              color: COLORS.SHARK,
              backgroundColor: COLORS.WHITE,
              border: `1px solid ${COLORS.CATSKILL_WHITE}`,
              padding: "8px 12px",
            }}
          />
        </div>
      </form>
    </div>
  );
};
export default ChangePassForm;
