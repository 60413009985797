export const TYPES = {
  GET_LINKS_LIST: "GET_LINKS_LIST",
  GET_LINKS_LOADER: "GET_LINKS_LOADER",
  GET_LINKS_ERROR: "GET_LINKS_ERROR",
  DATA_TO_SHOW: "DATA_TO_SHOW",
  NO_LINKS_TO_SHOW: "NO_LINKS_TO_SHOW",
  NEW_HEADERS_TO_SHOW: "NEW_HEADERS_TO_SHOW",
  SCROLL_LOADER: "SCROLL_LOADER",
  SET_ACTIVE_LINK: "SET_ACTIVE_LINK",
  UPDATE_LINK: "UPDATE_LINK",
  SET_MAGIC_LINK_URL: "SET_MAGIC_LINK_URL",
};
