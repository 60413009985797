import moment from "moment";
import { TYPES } from "../actions/billingActions";
import { servicesNames } from "../contexts/serviceName";

export const NO_INVOICE = "Todavía no se ha emitido ninguna factura";

export const billingReducer = (state, action) => {
  switch (action.type) {
    case TYPES.GET_LAST_INVOICES:
      return handleGetLastInvoices(state, action.payload);

    case TYPES.SET_TABLE_DATA:
      return handleSetTableData(state, action.payload);

    case TYPES.CHANGE_HEADERS:
      return handleChangeHeaders(state, action.payload);

    case TYPES.INVOICES_LOADING:
      return handleInvoicesLoading(state);

    case TYPES.INVOICES_ERROR:
      return handleInvoicesError(state, action.payload);

    case TYPES.SCROLL_LOADER:
      return handleScrollLoader(state);

    case TYPES.NO_INVOICE_DATA:
      return handleNoInvoiceData(state);

    case TYPES.LOAD_DATA:
      return handleLoadData(state, action.payload);

    case TYPES.GET_CONTRACT_DATA:
      return handleGetContractData(state, action.payload);

    case TYPES.GET_TOTALS:
      return handleGetTotals(state, action.payload);

    case TYPES.ERROR_TOTALS:
      return handleErrorTotals(state, action.payload);

    default:
      return state;
  }
};

const handleGetLastInvoices = (state, { data, requestStartOn }) => ({
  ...state,
  invoiceLoading: false,
  errorInvoice: false,
  scrollLoader: false,
  lastInvoices: data,
  requestStartOn,
});

const handleSetTableData = (state, { data }) => ({
  ...state,
  invoiceLoading: false,
  errorInvoice: false,
  scrollLoader: false,
  dataToShow: data,
});

const handleChangeHeaders = (state, headers) => ({
  ...state,
  headers,
});

const handleInvoicesLoading = (state) => ({
  ...state,
  invoiceLoading: true,
  errorInvoice: false,
  scrollLoader: false,
});

const handleInvoicesError = (state, errorInvoice) => ({
  ...state,
  invoiceLoading: false,
  errorInvoice,
  scrollLoader: false,
});

const handleScrollLoader = (state) => ({
  ...state,
  invoiceLoading: false,
  errorInvoice: false,
  scrollLoader: true,
});

const handleNoInvoiceData = (state) => {
  if (state.requestStartOn !== 0) {
    return {
      ...state,
      errorInvoice: false,
      invoiceLoading: false,
      scrollLoader: false,
    };
  }

  return {
    ...state,
    errorInvoice: { errText: NO_INVOICE },
    invoiceLoading: false,
    scrollLoader: false,
    requestStartOn: 0,
    dataToShow: [],
  };
};

const handleLoadData = (state, { data, requestStartOn }) => ({
  ...state,
  invoiceLoading: false,
  errorInvoice: false,
  scrollLoader: false,
  dataToShow: [...state.dataToShow, ...data],
  requestStartOn,
});

const handleGetContractData = (
  state,
  { company_name, contact_info, contracts }
) => {
  let include_services = null;
  let active = false;
  let id = "-";
  if (contracts.length > 0) {
    include_services = contracts[0].hired_products
      .map((product) => mapProductName(product))
      .toString();
    const fechaActual = moment();
    active = contracts && moment(contracts[0].end_date) > fechaActual;
    id = contracts[0].id;
  }

  const contactInfo = {
    name: `${contact_info?.first_name} ${contact_info?.last_name}`,
    email: contact_info?.email,
  };

  const contract = {
    company_name,
    contract_number: id || "-",
    include_services: include_services || "-",
    contactInfo,
    active,
    available_totals: {
      spent: "unlimited",
      calls: "unlimited",
    },
  };

  return {
    ...state,
    contract,
  };
};

const mapProductName = (product) => {
  switch (product) {
    case servicesNames.DECISION_MAKER.backName:
      return servicesNames.DECISION_MAKER.frontName;
    case servicesNames.DECISION_MAKER_INVOKE.backName:
      return servicesNames.DECISION_MAKER_INVOKE.frontName;
    case servicesNames.USER_SCORE.backName:
      return servicesNames.USER_SCORE.frontName;
    default:
      return "Sin Dato";
  }
};

const handleGetTotals = (state, { total_calls, total_spent, usage }) => {
  const newTotals = usage
    ? calculateNewTotals(usage, state.totals.usage)
    : state.usage;
  return {
    ...state,
    totalsError: false,
    totals: {
      total_calls,
      total_spent,
      usage: newTotals,
    },
  };
};

const calculateNewTotals = (usage, currentUsage) => {
  const decisionMaker = {
    good:
      usage[servicesNames.DECISION_MAKER.backName].calls.field_200 +
      usage[servicesNames.DECISION_MAKER_INVOKE.backName]?.calls.field_200,
    bad:
      usage[servicesNames.DECISION_MAKER.backName].calls.field_400 +
      usage[servicesNames.DECISION_MAKER.backName].calls.field_500 +
      usage[servicesNames.DECISION_MAKER_INVOKE.backName]?.calls.field_400 +
      usage[servicesNames.DECISION_MAKER_INVOKE.backName]?.calls.field_500,
    spent:
      usage[servicesNames.DECISION_MAKER.backName].spent +
      usage[servicesNames.DECISION_MAKER_INVOKE.backName]?.spent,
    hired:
      usage[servicesNames.DECISION_MAKER.backName].hired +
      usage[servicesNames.DECISION_MAKER_INVOKE.backName]?.hired,
    total_calls:
      usage[servicesNames.DECISION_MAKER.backName].total_calls +
      usage[servicesNames.DECISION_MAKER_INVOKE.backName]?.total_calls,
  };

  const userScore = {
    good: usage[servicesNames.USER_SCORE.backName]?.calls.field_200,
    bad:
      usage[servicesNames.USER_SCORE.backName]?.calls.field_400 +
      usage[servicesNames.USER_SCORE.backName]?.calls.field_500,
    spent: usage[servicesNames.USER_SCORE.backName]?.spent,
    hired: usage[servicesNames.USER_SCORE.backName]?.hired,
    total_calls: usage[servicesNames.USER_SCORE.backName]?.total_calls,
  };

  return currentUsage.map((el) => {
    if (el.id === "billing-element-userScore")
      return { ...el, petitions: userScore };
    if (el.id === "billing-element-decisionMaker")
      return { ...el, petitions: decisionMaker };
    return el;
  });
};

const handleErrorTotals = (state, totalsError) => ({
  ...state,
  totalsError,
});
