import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "../Loader";
import UserContext from "../../contexts/UserContext";
import AuthContext from "../../contexts/AuthContext";
import { ROLES } from "../../reducers/authReducer";
import { PRIVATE_ROUTES } from "./routes";
import RulesAccordion from "./RulesAccordion";
import { getRuleIcon } from "../../helpers/getRuleIcon";
import { T, useTolgee } from "@tolgee/react";
import RulesAccordionItem from "../RulesAccordionItem";
import { useState } from "react";

const NoCode = () => {
  const {
    loadingRules,
    errorRules,
    getRules,
    rulesToShow,
    rulesToHire,
  } = useContext(UserContext);
  const {
    user: { role },
  } = useContext(AuthContext);
  const navigate = useNavigate();
  const [rulesSorted, setRulesSorted] = useState([]);
  const tolgee = useTolgee(["language"]);
  const isLangEs = tolgee.getLanguage() === "es-MX";

  useEffect(() => {
    if (role === ROLES.ANALISTA || role === ROLES.OLD.USER) {
      navigate(PRIVATE_ROUTES.DASHBOARD);
      return;
    }

    if (rulesToShow?.length < 1) {
      getRules();
    }
  }, []);

  useEffect(() => {
    const groupedData = rulesToShow.reduce((acc, item) => {
      const groupId = item.group_rule_id;
      if (!acc[groupId]) {
        acc[groupId] = [];
      }
      acc[groupId].push(item);
      return acc;
    }, {});
    
    const orderedArray = Object.keys(groupedData).sort((a, b) => a - b).map(key => ({
      group_rule_id: parseInt(key),
      items: groupedData[key].sort((a,b) => a.hierarchy_group_rule - b.hierarchy_group_rule),
    }));

    setRulesSorted(orderedArray);
  }, [rulesToShow]);

  return (
    <section className="noCode__page">
      <div className="noCode__title">
        <h2 style={{ display: "flex" }} className="heading-1 color--gulf-blue">
          <T keyName="nav_bar_rules" />
        </h2>
        <p className="text-2">
          <T keyName="no_code_rules_dm_text_1" />{" "}
          <span className="text-2--bold color--dodger-blue">
            Decision Maker
          </span>{" "}
          <T keyName="no_code_rules_dm_text_2" />
        </p>
      </div>
      {loadingRules && <Loader />}
      {!loadingRules && !errorRules && (
        <>
          <div className="noCode__accordion__container">
            {rulesSorted.map(item =>
              <RulesAccordionItem ruleGroup={item} />
            )}
          </div>
          <div className="noCode border border-light rounded-2">
            <table className="noCode__table--no-hire">
              <thead>
                <tr>
                  <th colSpan={4} style={{ textAlign: "left" }}>
                    <div className="noCode__table-title">
                      <h3 className="heading-2">
                        <T keyName="no_code_rules_not_bought_header" />
                      </h3>
                      <p className="text-2">
                        <T keyName="no_code_rules_not_bought_message" />
                      </p>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {rulesToHire?.map((ruleToHire, i) => {
                  const { description, products, rules, description_en, products_en } = ruleToHire;

                  return (
                    <RulesAccordion
                      key={`rulesToHire.${i}`}
                      expandPanel={products}
                      icon={getRuleIcon(products)}
                      rules={rules}
                      title={ isLangEs ? products : products_en}
                      description={isLangEs ? description : description_en}
                    />
                  );
                })}
              </tbody>
            </table>
          </div>
        </>
      )}
    </section>
  );
};
export default NoCode;
