import { useContext } from "react";
import UserContext from "../contexts/UserContext";
import SmallLoader from "./SmallLoader";
import Button from "./Button";
import { COLORS } from "../styles/utils/colors";
import { useTranslate } from '@tolgee/react';

const ModalButtons = ({ handleConfirm, setShowModal }) => {
  const { deleteUserLoading } = useContext(UserContext);
  const { t } = useTranslate();
  return (
    <>
      {deleteUserLoading && <SmallLoader />}
      <Button
        text={t("confirm_button")}
        handleClick={handleConfirm}
        styles={{ padding: "9px 12px", marginLeft: "10px" }}
      />
      <Button
        handleClick={() => setShowModal(false)}
        text={t("cancel_button")}
        styles={{
          color: COLORS.SHARK,
          backgroundColor: COLORS.WHITE,
          border: `1px solid ${COLORS.CATSKILL_WHITE}`,
          padding: "9px 12px",
        }}
      />
    </>
  );
};
export default ModalButtons;
