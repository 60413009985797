import { servicesNames } from "../../contexts/serviceName";
import { history } from "./AuthContext";

export const SEARCH_TYPES = {
  ID: "request_id",
  USER_ID: "external_id",
  TRULLY_ID: "identity_id",
  LINK: "magic_link",
  SDK: "SDK",
};

const initialHeaders = [
  {
    id: "date",
    title: "Fecha",
    dataType: "date",
    icon: true,
    dataKey: "created_on",
    sortedBy: "desc",
  },
  {
    id: "productName",
    title: "Producto Usado",
    dataType: "string",
    dataKey: "service",
    sortedBy: "desc",
  },
  {
    id: "id",
    title: "ID",
    icon: false,
    dataType: "number",
    dataKey: "id",
    sortedBy: "desc",
  },
  {
    id: "externalId",
    title: "ID de usuario",
    icon: false,
    dataType: "number",
    dataKey: "externalId",
    sortedBy: "desc",
  },
  {
    id: "informador",
    title: "Informante",
    icon: false,
    dataType: "string",
    dataKey: "informador",
    sortedBy: "desc",
  },
  {
    id: "estatus",
    title: "Estatus",
    icon: false,
    dataType: "string",
    dataKey: "response_status",
    sortedBy: "desc",
  },
];

export const initialChart = {
  total: 134,
  dataChart: [
    {
      nameX: "10/10",
      total: 32,
      [servicesNames.USER_SCORE.frontName]: 1,
      [servicesNames.DECISION_MAKER.frontName]: 31,
    },
    {
      nameX: "11/10",
      total: 1,
      [servicesNames.DECISION_MAKER.frontName]: 1,
    },
    {
      nameX: "12/10",
      total: 19,
      [servicesNames.DECISION_MAKER.frontName]: 19,
    },
    {
      nameX: "13/10",
      total: 28,
      [servicesNames.USER_SCORE.frontName]: 21,
      [servicesNames.DECISION_MAKER.frontName]: 7,
    },
    {
      nameX: "17/10",
      total: 54,
      [servicesNames.USER_SCORE.frontName]: 53,
      [servicesNames.DECISION_MAKER.frontName]: 1,
    },
  ],
};

export const initialRequests = [
  {
    created_on: "Tue, 10 Oct 2023 18:47:27 GMT",
    external_id: null,
    id: 859492,
    label: "No Threat",
    path: "/predict",
    response_status: 200,
    service: "trully-decision-maker",
  },
  {
    created_on: "Tue, 10 Oct 2023 18:44:51 GMT",
    external_id: null,
    id: 859487,
    label: "No Threat",
    path: "/predict",
    response_status: 200,
    service: "trully-decision-maker",
  },
  {
    created_on: "Tue, 10 Oct 2023 18:39:05 GMT",
    external_id: null,
    id: 859475,
    label: "Threat",
    path: "/predict",
    response_status: 200,
    service: "trully-decision-maker",
  },
  {
    created_on: "Tue, 10 Oct 2023 18:29:59 GMT",
    external_id: null,
    id: 859473,
    label: "No Threat",
    path: "/predict",
    response_status: 200,
    service: "trully-decision-maker",
  },
  {
    created_on: "Mon, 09 Oct 2023 22:13:49 GMT",
    external_id: null,
    id: 859415,
    label: "Review",
    path: "/predict",
    response_status: 200,
    service: "trully-decision-maker",
  },
  {
    created_on: "Mon, 09 Oct 2023 22:11:30 GMT",
    external_id: null,
    id: 859408,
    label: "Review",
    path: "/predict",
    response_status: 200,
    service: "trully-decision-maker",
  },
  {
    created_on: "Tue, 17 Oct 2023 07:04:55 GMT",
    external_id: "hola",
    id: 879798,
    label: null,
    path: "/enrich",
    response_status: 200,
    service: "trully-data-enrichment",
  },
  {
    created_on: "Tue, 17 Oct 2023 07:02:39 GMT",
    external_id: "hola",
    id: 879789,
    label: null,
    path: "/enrich",
    response_status: 200,
    service: "trully-data-enrichment",
  },
  {
    created_on: "Tue, 17 Oct 2023 07:02:41 GMT",
    external_id: "hola",
    id: 879786,
    label: null,
    path: "/enrich",
    response_status: 200,
    service: "trully-data-enrichment",
  },
];

export const TOTALS_TITLE = {
  total: "Consultas totales",
  correct: "Exitosas",
  invalid: "Datos Inválidos",
  error: "Errores",
};

export const initialTotals = {
  totals: {
    title: TOTALS_TITLE.total,
    total: 0,
    fraudTotal: 0,
    scoreTotal: 0,
  },
  totalsByService: {
    [servicesNames.DECISION_MAKER.frontName]: [
      { title: TOTALS_TITLE.correct, subtitle: "Response 200", total: 0 },
      { title: TOTALS_TITLE.invalid, subtitle: "Response 400", total: 0 },
      { title: TOTALS_TITLE.error, subtitle: "Response 500", total: 0 },
    ],
    [servicesNames.USER_SCORE.frontName]: [
      { title: TOTALS_TITLE.correct, subtitle: "Response 200", total: 0 },
      { title: TOTALS_TITLE.invalid, subtitle: "Response 400", total: 0 },
      { title: TOTALS_TITLE.error, subtitle: "Response 500", total: 0 },
    ],
  },
};

export const defaultState = {
  lastRequests: [],
  dataToShow: [],
  headers: initialHeaders,
  showColumns: {
    ...history,
  },
  totals: initialTotals,
  chart: {
    total: 0,
    dataChart: [],
  },
  requestStartOn: 0,
  serviceSearched: "",
  requestsLoading: false,
  chartLoading: false,
  errorRequest: false,
  errorTotals: false,
  errorChart: false,
  noChartData: false,
  scrollLoader: false,
  requestsFromLink: false,
  linkToken: "",
  servicesToShow: [
    {
      id: servicesNames.DECISION_MAKER.backName,
      title: servicesNames.DECISION_MAKER.frontName,
      show: true,
    },
    {
      id: servicesNames.USER_SCORE.backName,
      title: servicesNames.USER_SCORE.frontName,
      show: true,
    },
  ],
  servicesPercentages: [
    {
      id: servicesNames.DECISION_MAKER.backName,
      title: servicesNames.DECISION_MAKER.frontName,
      totalUsers: 0,
      percentage: 0,
    },
    {
      id: servicesNames.USER_SCORE.backName,
      title: servicesNames.USER_SCORE.frontName,
      totalUsers: 0,
      percentage: 0,
    },
  ],
  errorPercentages: false,
  rules: [
    {
      last_date: "2024-02-02 21:11:51",
      percentage: "18.67%",
      rule: "El usuario ha estado usando información personal diferente, lo que puede sugerir de robo de identidad",
      total: 512,
    },
    {
      last_date: "2024-02-02 21:11:51",
      percentage: "18.38%",
      rule: "El rostro del usuario fue encontrado en la base de datos de la empresa con otro external_id",
      total: 504,
    },
  ],
  rulesTotals: [
    { title: "Total", total: 3600 },
    {
      title: "Aceptados",
      total: 1200,
      icon: "icon-check color--keppel",
    },
    { title: "Revisar", total: 1200, icon: "icon-revisa color--com" },
    {
      title: "Rechazados",
      total: 1200,
      icon: "icon-amenaza color--copperfield",
    },
  ],
  rulesLoading: true,
  noRulesData: false,
};
