import { useEffect, useState, useContext } from "react";
import SmallLoader from "./SmallLoader";
import TextInput from "./TextInput";
import { useFormValidation } from "../hooks/useFormValidation";
import { validateEmails } from "../helpers/formValidations";
import DropDownAccounts from "./DropDownAccounts";
import { ACCOUNT_TYPES } from "../reducers/authReducer";
import IconInfo from "./IconInfo";
import UserContext from "../contexts/UserContext";
import Button from "./Button";
import { COLORS } from "../styles/utils/colors";
import { showDropdown } from "../helpers/showDropdown";
import { T } from "@tolgee/react";
import { useTranslate } from '@tolgee/react';


const initialForm = {
  emails: "",
  account_type: 1,
};

const initialValidateForm = [
  {
    name: "emails",
    valid: false,
  },
];

const AddUserForm = ({ handleConfirm, setShowModal }) => {
  const [form, setForm] = useState(initialForm);
  const { setUserToAdd, addUserLoading, userToAdd } = useContext(UserContext);
  const { validateForm, setValidateForm, handleFormValidation } =
    useFormValidation(initialValidateForm);
  const [dropdownPosition, setDropdownPosition] = useState(0);
  const [showAccountTypes, setShowAccountTypes] = useState(false);
  const { t } = useTranslate();

  const account_type = ACCOUNT_TYPES.find(
    (role) => role.id === form.account_type
  )?.role;

  useEffect(() => {
    if (form.emails.length > 1) {
      const invalid = validateForm.find((el) => !el.valid);
      if (!invalid) setUserToAdd(form);
    }
  }, [form.emails, form.account_type]);

  const handleShow = (e) => {
    const divPositionY = e.target.offsetParent.offsetTop;
    const divPositionX = e.target.offsetParent.offsetLeft;

    const dropdownPosition = {
      y: divPositionY + 15,
      x: divPositionX - 15,
    };

    showDropdown({
      createNewData: false,
      handleShow: () => {
        setDropdownPosition(() => dropdownPosition);
        setShowAccountTypes((prev) => !prev);
      },
    });
  };

  const selectRole = (roleID) => {
    setForm({
      ...form,
      account_type: roleID,
    });
    setShowAccountTypes((prev) => !prev);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const handleClick = () => {
    handleConfirm(userToAdd, setShowModal);
    setValidateForm(initialValidateForm);
  };

  return (
    <div className="addUserModal">
      <h3 className="heading-2"><T keyName="add_user_header" /></h3>
      <form className="addUserModal__form" onSubmit={handleSubmit}>
        <TextInput
          form={form}
          inputName="emails"
          inputLabel={t("input_email_description")}
          setValue={setForm}
          validate={validateEmails}
          handleFormValidation={handleFormValidation}
        />
        <div className="addUserModal__form--info">
          <span className="text-2 color--shuttle-gray"><T keyName="account_type_title" /></span>
          <IconInfo
            text={t("account_type_icon_text")}
            styles={{ width: "150px" }}
          />
        </div>
        <div className="team__accounts-container team__accounts-container--reference addUserModal__form--dropdown text-2">
          <button
            type="button"
            className="team__accounts-container--account-btn"
            onClick={handleShow}
          >
            {account_type}{" "}
            {showAccountTypes ? (
              <div className="icon-expand-menos"></div>
            ) : (
              <div className="icon-down"></div>
            )}
          </button>
          <DropDownAccounts
            showAccountTypes={showAccountTypes}
            account_type={account_type}
            dropdownPosition={dropdownPosition}
            addUser={true}
            handleClick={selectRole}
          />
        </div>
      </form>
      <div className="addUserModal__buttons-container">
        {addUserLoading && <SmallLoader />}
        <Button
          disabled={!validateEmails(form.emails)}
          type="submit"
          handleClick={handleClick}
          text={t("add_user_button")}
        />
        <Button
          disabled={addUserLoading}
          handleClick={() => setShowModal(false)}
          text={t("cancel_button")}
          styles={{
            color: COLORS.SHARK,
            backgroundColor: COLORS.WHITE,
            border: `1px solid ${COLORS.CATSKILL_WHITE}`,
            padding: "9px 12px",
          }}
        />
      </div>
    </div>
  );
};
export default AddUserForm;
