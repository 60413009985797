import {
  initialFilters,
  initialHistory,
  initialNotifications,
  initialWalkthrough,
} from "../states/contexts/AuthContext";

export const getSavedData = ({
  filters = null,
  history = null,
  walkthrough = null,
  notifications = null,
}) => {
  const getMetadataFromSessionStorage = () => {
    const metadata = sessionStorage.getItem("metadata");
    return metadata ? JSON.parse(metadata) : null;
  };

  const getDefaultData = () => ({
    filters: initialFilters,
    history: initialHistory,
    walkthrough: initialWalkthrough,
    notifications: initialNotifications,
  });

  const mergeData = (original, saved) => ({
    ...original,
    ...saved,
  });

  const mergeFilters = (filters, savedFilters) => {
    return filters.map((filter) => ({
      ...filter,
      show: savedFilters[filter.name],
    }));
  };

  const metadata = getMetadataFromSessionStorage();

  if (!metadata) {
    return getDefaultData();
  }

  const {
    metadata: {
      filters: savedFilters,
      history: savedHistory,
      walkthrough: savedWalkthrough,
    },
    notifications: savedNotifications,
  } = metadata;

  const newFilters =
    filters && savedFilters ? mergeFilters(filters, savedFilters) : filters;
  const newHistory = history ? mergeData(history, savedHistory) : savedHistory;
  const newWalkthrough = walkthrough
    ? mergeData(walkthrough, savedWalkthrough)
    : savedWalkthrough;
  const newNotifications = notifications
    ? mergeData(notifications, savedNotifications)
    : savedNotifications;

  return {
    filters: newFilters,
    history: newHistory,
    walkthrough: newWalkthrough,
    notifications: newNotifications,
  };
};
