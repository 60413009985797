import { useContext, useRef } from "react";
import { COLORS } from "../styles/utils/colors";
import Button from "./Button";
import TruncatedText from "./TruncatedText";
import LinkContext from "../contexts/LinkContext";
import CopyToClipboard from "./CopyToClipboard";
import {
  SNACKBAR_STATES_NAMES,
  SNACKBAR_TEXTS,
  SNACKBAR_TEXTS_EN,
} from "../states/contexts/SnackContext";
import QRCode from "qrcode.react";
import SnackBarContext from "../contexts/SnackBarContext";
import { useTranslate, T, useTolgee } from "@tolgee/react";

const ShareLinkForm = ({ setShowModal }) => {
  const { activeLink, magicLinkURL } = useContext(LinkContext);
  const { setSnack } = useContext(SnackBarContext);
  const qrRef = useRef(null);
  const { t } = useTranslate();
  const tolgee = useTolgee(["language"]);
  const isLangEs = tolgee.getLanguage() === "es-MX";

  const copyImage = async () => {
    if (!window.ClipboardItem) {
      const snackState = {
        show: true,
        error: {
          err: true,
          errText: isLangEs
            ? SNACKBAR_TEXTS.NO_COPY_JSON
            : SNACKBAR_TEXTS_EN.NO_COPY_JSON,
          errTextTitle: isLangEs
            ? SNACKBAR_TEXTS.ERROR_TITLE
            : SNACKBAR_TEXTS_EN.ERROR_TITLE,
        },
      };

      setSnack(SNACKBAR_STATES_NAMES.COPY_JSON, snackState);
      return;
    }

    const canvas = qrRef.current.querySelector("canvas");
    const img = canvas.toDataURL("image/png");
    const blob = await fetch(img).then((r) => r.blob());
    const clipboardItem = new ClipboardItem({ "image/png": blob });
    navigator.clipboard.write([clipboardItem]);

    const snackState = {
      show: true,
      text: isLangEs ? SNACKBAR_TEXTS.QR_COPY : SNACKBAR_TEXTS_EN.QR_COPY,
    };

    setSnack(SNACKBAR_STATES_NAMES.COPY_JSON, snackState);
  };

  const downloadImage = () => {
    const canvas = qrRef.current.querySelector("canvas");
    const img = canvas.toDataURL("image/png");
    const link = document.createElement("a");
    link.href = img;
    link.download = "codigoQR.png";
    document.body.appendChild(link);
    try {
      link.click();
    } catch (error) {
      const snackState = {
        show: true,
        error: {
          err: true,
          errText: isLangEs
            ? SNACKBAR_TEXTS.ERROR_TEXT
            : SNACKBAR_TEXTS_EN.ERROR_TEXT,
          errTextTitle: isLangEs
            ? SNACKBAR_TEXTS.ERROR_TITLE
            : SNACKBAR_TEXTS_EN.ERROR_TITLE,
        },
      };

      setSnack(SNACKBAR_STATES_NAMES.COPY_JSON, snackState);
    }
    document.body.removeChild(link);
  };

  return (
    <div className="shareLinkForm">
      <header className="shareLinkForm__header">
        <button
          className="icon-close shareLinkForm__header__icon"
          onClick={() => setShowModal(() => false)}
        ></button>
        <h2 className="heading-2">
          <T keyName="share_magic_link_header" />
        </h2>
      </header>
      <section className="shareLinkForm__container">
        <h4 className="text-1--bold">
          <T keyName="share_magic_link_header_2" />
        </h4>
        <div className="shareLinkForm__container__web">
          <div className="shareLinkForm__container__web__input-group">
            <span className="text-2 color--shuttle-gray">Link</span>
            <div className="shareLinkForm__container__web__input-container">
              <TruncatedText
                text={activeLink ? activeLink.magic_link_url : magicLinkURL}
              />
              <CopyToClipboard
                text={activeLink ? activeLink.magic_link_url : magicLinkURL}
                snackData={{
                  text: isLangEs
                    ? SNACKBAR_TEXTS.COPY_URL_TEXT
                    : SNACKBAR_TEXTS_EN.COPY_URL_TEXT,
                }}
              />
            </div>
          </div>
        </div>
      </section>
      <section className="shareLinkForm__container">
        <h4 className="text-1--bold">
          <T keyName="share_magic_link_qr_code" />
        </h4>
        <div className="shareLinkForm__container__qr">
          <div
            className="shareLinkForm__container__qr__img-container"
            ref={qrRef}
          >
            <QRCode
              value={activeLink ? activeLink.magic_link_url : magicLinkURL}
              fgColor={COLORS.DODGER_BLUE}
            />
          </div>
          <div className="shareLinkForm__container__qr__btn-container">
            <Button
              styles={{
                color: COLORS.SHARK,
                backgroundColor: COLORS.WHITE,
                border: `1px solid ${COLORS.CATSKILL_WHITE}`,
                padding: "4px 12px",
              }}
              text={t("share_magic_link_copy_image")}
              handleClick={copyImage}
            />
            <Button
              icon="icon-descargar"
              styles={{
                padding: "4px 12px",
              }}
              text={t("share_magic_link_download_image")}
              handleClick={downloadImage}
            />
          </div>
        </div>
      </section>
    </div>
  );
};
export default ShareLinkForm;
