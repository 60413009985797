const TabsButtons = ({ id, handleBtn, active, text, inlineStyles }) => {
  let styles = active
    ? "layout__main__section__dashboard__buttons--btn layout__main__section__dashboard__buttons--btn--active text-2--bold color--dodger-blue"
    : "layout__main__section__dashboard__buttons--btn text-2";

  styles +=
    text === "Resumen de Consultas" || "Nueva Búsqueda"
      ? " layout__main__section__dashboard__buttons--btn--space"
      : " layout__main__section__dashboard__buttons--btn--space-invert";

  return (
    <button
      style={inlineStyles}
      className={styles}
      onClick={() => handleBtn(id)}
    >
      {text}
    </button>
  );
};
export default TabsButtons;
