import { ROLES } from "../reducers/authReducer";
import TabsButtons from "./TabsButtons";
import { T } from "@tolgee/react";

const PerfilHeader = ({ tabs, changeTab, role, translate }) => {
  return (
    <>
      <h2 className="heading-1 color--gulf-blue ">Perfil</h2>
      <div className="layout__main__section__dashboard__buttons">
        {role !== "" &&
          (role === ROLES.PROPIETARIO || role === ROLES.ADMIN) &&
          tabs.map((el, i) => (
            <TabsButtons
              key={el.id}
              handleBtn={changeTab}
              id={el.id}
              active={el.selected}
              text={translate(el.text)}
              inlineStyles={tabs.length - 1 === i ? { marginRight: 0 } : {}}
            />
          ))}
      </div>
      {tabs[0].selected && (
        <p className="text-2">
          <T keyName="profile_header_text" />
        </p>
      )}
      {/* {tabs[2].selected && (
        <p className="text-2">
          Personaliza las reglas del{" "}
          <b className="color--dodger-blue">Decision Maker </b>
          para determinar qué conjuntos de datos son aceptados, enviados a
          revisión o marcados como amenaza.
        </p>
      )} */}
    </>
  );
};
export default PerfilHeader;
