import ComponentsSlider from "./ComponentsSlider";
import SliderNextArrow from "./SliderNextArrow";
import SliderPrevArrow from "./SliderPrevArrow";
import MagicLinkInitPreview from "./MagicLinkInitPreview";
import MagicLinkFormPreview from "./MagicLinkFormPreview";
import { generateUrl } from "../helpers/generateUrl";
import { T } from "@tolgee/react";

const LinkPreview = ({ linkData, brandPreview, identityPreview }) => {
  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SliderNextArrow />,
    prevArrow: <SliderPrevArrow />,
    className: "slider__container",
  };

  const componentsArr = [
    {
      Component: MagicLinkInitPreview,
      componentProps: {
        logo: brandPreview?.logoPreview || generateUrl(linkData?.brand.logo),
        primaryColor:
          brandPreview?.colors.primary || linkData?.brand.colors.primary,
        secondaryColor:
          brandPreview?.colors.secondary || linkData?.brand.colors.secondary,
        primaryTextColor:
          brandPreview?.colors.textPrimary ||
          linkData?.brand.colors.textPrimary,
      },
      id: "preview-init",
    },
    {
      Component: MagicLinkFormPreview,
      componentProps: {
        logo: brandPreview?.logoPreview || generateUrl(linkData?.brand.logo),
        primaryColor:
          brandPreview?.colors.primary || linkData?.brand.colors.primary,
        secondaryColor:
          brandPreview?.colors.secondary || linkData?.brand.colors.secondary,
        primaryTextColor:
          brandPreview?.colors.textPrimary ||
          linkData?.brand.colors.textPrimary,
        secondaryTextColor:
          brandPreview?.colors.textSecondary ||
          linkData?.brand.colors.textSecondary,
        inputs: identityPreview || linkData?.identity,
        heading1: brandPreview?.font?.heading1 || linkData?.font?.heading1,
        heading2: brandPreview?.font?.heading2 || linkData?.font?.heading2,
        text: brandPreview?.font?.text || linkData?.font?.text,
        identifyUser: linkData?.config?.identify_user,
      },
      id: "preview-form",
    },
  ];

  return (
    <section className="linkPage__configuration__preview linkPreview">
      <header className="linkPreview__header">
        <h2 className="heading-2"><T keyName="link_preview_header" /></h2>
        <div className="linkPreview__separator"></div>
      </header>
      <div className="linkPreview__container">
        <ComponentsSlider
          settings={sliderSettings}
          componentsArr={
            identityPreview
              ? [componentsArr[1], componentsArr[0]]
              : componentsArr
          }
        />
      </div>
    </section>
  );
};
export default LinkPreview;
