import SingleDescription from "./SingleDescription";
import { useState } from "react";

const MultipleDescriptions = ({ multipleDescriptions, SubDropdown }) => {
  const { valuesToShow, list } = multipleDescriptions;
  const [descriptions, setDescriptions] = useState(
    generateDescriptions(valuesToShow)
  );

  function generateDescriptions(valuesToShow) {
    const arr = [];

    if (list.length === 0) return [];

    list.forEach((item, i) => {
      let data = {
        items: [],
        dataList: [],
        SubDropdown,
      };

      for (const key in valuesToShow) {
        const el = valuesToShow[key];
        if (!el) continue;
        const subKey = el.keyOfText;
        data = {
          ...data,
          items: [
            ...data.items,
            {
              title: el.title,
              text: item[subKey],
            },
          ],
          detailsList: [list[i]],
        };
      }

      arr.push(data);
    });

    return arr;
  }

  return (
    <>
      {descriptions.map((description, i) => (
        <SingleDescription
          detailsList={description.detailsList}
          SubDropdown={description.SubDropdown}
          description={description.items}
          key={`multiDescription-${i}`}
        />
      ))}
    </>
  );
};
export default MultipleDescriptions;
