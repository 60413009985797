import React, { useContext } from "react";
import AuthContext from "../contexts/AuthContext";
import DataContext from "../contexts/DataContext";
import DatesContext from "../contexts/DatesContext";
import ReportContext from "../contexts/ReportContext";
import { useNavigate } from "react-router-dom";
import { PUBLIC_ROUTES } from "./pages/routes";
import ChangePassForm from "./ChangePassForm";
import ModalButtons from "./ModalButtons";
import AddUserForm from "./AddUserForm";
import Button from "./Button";
import GenerateLinkForm from "./GenerateLinkForm";
import ShareLinkForm from "./ShareLinkForm";
import ProcessingBatch from "./ProcessingBatch";
import { T } from "@tolgee/react";
import { useTranslate } from '@tolgee/react';

const ModalContainerWarning = ({
  logout,
  regenerar,
  delUser,
  setShowModal,
  password,
  handleConfirm,
  addUser,
  generateLink,
  handleCreate,
  shareLink,
  startBatchProcess,
  imagesMissing,
}) => {
  const { logout: fnLogout, sessionExpiredText } = useContext(AuthContext);
  const { resetData } = useContext(DataContext);
  const { resetDates } = useContext(DatesContext);
  const { restartReport } = useContext(ReportContext);
  const { t } = useTranslate();
  const navigate = useNavigate();

  const handleLogOut = () => {
    resetData();
    resetDates();
    restartReport();
    fnLogout();
    navigate(PUBLIC_ROUTES.SIGNIN);
  };

  if (generateLink) {
    return (
      <GenerateLinkForm
        setShowModal={setShowModal}
        handleCreate={handleCreate}
      />
    );
  }

  if (shareLink) {
    return <ShareLinkForm setShowModal={setShowModal} />;
  }

  if (password) {
    return <ChangePassForm setShowModal={setShowModal} />;
  }

  if (addUser) {
    return (
      <AddUserForm setShowModal={setShowModal} handleConfirm={handleConfirm} />
    );
  }

  if (startBatchProcess) {
    return (
      <ProcessingBatch
        setShowModal={setShowModal}
        imagesMissing={imagesMissing}
      />
    );
  }

  return (
    <div className="modal--center-container__container">
      <span className="icon-revisa modal--center-container__container--icon"></span>
      {logout ? (
        <p className="heading-3 modal--center-container__container--text">
          {sessionExpiredText}
        </p>
      ) : regenerar ? (
        <p className="text-1">
          <T keyName="regenerate_api_key_warning"/> <br /> <T keyName="confirm_action"/>
        </p>
      ) : delUser ? (
        <p className="text-1">
          <T keyName="delete_user_warning" /> <br /> <T keyName="confirm_action"/>
        </p>
      ) : (
        <></>
      )}
      <div className="modal--center-container__container__buttons">
        {logout ? (
          <Button
            handleClick={handleLogOut}
            styles={{ padding: "9px 12px" }}
            text={t("accept_button")}
          />
        ) : (
          <ModalButtons
            setShowModal={setShowModal}
            handleConfirm={handleConfirm}
          />
        )}
      </div>
    </div>
  );
};
export default ModalContainerWarning;
