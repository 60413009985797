import { useContext, useEffect, useState } from "react";
import FormTeam from "../FormTeam";
import Modal from "../Modal";
import UserContext, { USER_ERRORS } from "../../contexts/UserContext";
import AuthContext from "../../contexts/AuthContext";
import { ROLES } from "../../reducers/authReducer";
import { useNavigate } from "react-router-dom";
import { PRIVATE_ROUTES } from "./routes";
import TableContainer from "../TableContainer";
import Button from "../Button";
import { COLORS } from "../../styles/utils/colors";
import { showDropdown } from "../../helpers/showDropdown";
import { T, useTranslate } from "@tolgee/react";

export const MODAL_TO_SET = {
  DELETE_USER: "DELETE_USER",
  ADD_USER: "ADD_USER",
};

const Team = () => {
  const {
    user: { role },
  } = useContext(AuthContext);
  const {
    getLastsUsers,
    userToDelete,
    deleteUser,
    addUser,
    userToAdd,
    headers,
    usersList,
    usersToShow,
    userSearched,
    requestStartOn,
    usersLoading,
    usersError,
    scrollLoader,
    createTableData,
    changeHeaders,
    sortBy,
    noUsers,
  } = useContext(UserContext);
  const [showModal, setShowModal] = useState(false);
  const [modalToOpen, setModalToOpen] = useState({ name: "" });
  const { t } = useTranslate();
  const navigate = useNavigate();

  useEffect(() => {
    if (!role) return;

    const roleInStorage = sessionStorage.getItem("role");

    if (
      (roleInStorage && roleInStorage === ROLES.ANALISTA) ||
      role === ROLES.ANALISTA
    ) {
      navigate(PRIVATE_ROUTES.DASHBOARD);
      return;
    }

    sessionStorage.setItem("role", role);

    getLastsUsers(0);
  }, [role]);

  useEffect(() => {
    if (usersList.length !== 0) createTableData({ data: usersList });
  }, [usersList]);

  const handleDelete = () => {
    deleteUser(userToDelete, setShowModal);
  };

  const handleAdd = () => {
    addUser(userToAdd, setShowModal);
  };

  const openModal = (modalToOpen) => {
    setModalToOpen(modalToOpen);
    setShowModal(true);
  };

  const handleScroll = () => {
    const noLoader = true;
    getLastsUsers(requestStartOn + 1, noLoader);
  };

  const headersBtnClick = (title, dataType, dataKey, sortedBy) => {
    const newOrder = sortedBy === "asc" ? "desc" : "asc";
    changeHeaders(title, newOrder);
    sortBy(newOrder, dataType, dataKey, sortedBy);
  };

  const handleShowAccountsTypes = (event, id, show) => {
    if (show) {
      const userToHandle = usersToShow.find((user) => user.id === id);

      const dropdownPosition = {
        y: 45,
        x: 0,
      };

      showDropdown({
        createNewData: true,
        tableData: usersToShow,
        anchorElement: userToHandle,
        handleShow: (data) => createTableData({ data }),
        dropdownPosition,
      });
    } else {
      showDropdown({
        createNewData: true,
        tableData: usersToShow,
        handleShow: (data) => createTableData({ data }),
      });
    }
  };

  return (
    <section className="team">
      {showModal ? (
        modalToOpen.name === MODAL_TO_SET.DELETE_USER ? (
          <Modal
            setShowModal={setShowModal}
            handleConfirm={handleDelete}
            delUser={true}
          />
        ) : (
          <Modal
            setShowModal={setShowModal}
            handleConfirm={handleAdd}
            addUser={true}
          />
        )
      ) : (
        <></>
      )}
      <div className="ajustes__title">
        <h2 className="heading-1 color--gulf-blue">
          <T keyName="team_header_text" />
        </h2>
        <p className="text-2">
          <T keyName="team_message_text" />
        </p>
      </div>
      <section className="layout__main__section__consultas border border-light rounded-2 team__table-container">
        {usersToShow?.length > 0 && (
          <>
            {usersError.errText !== USER_ERRORS.UNAUTHORIZED && (
              <div className="team__form-search">
                <FormTeam />
                <Button
                  handleClick={() => openModal({ name: MODAL_TO_SET.ADD_USER })}
                  text={t("team_add_member_text")}
                  styles={{
                    color: COLORS.SHARK,
                    backgroundColor: COLORS.WHITE,
                    border: `1px solid ${COLORS.CATSKILL_WHITE}`,
                    padding: "9px 12px",
                  }}
                />
              </div>
            )}
          </>
        )}
        <TableContainer
          headers={headers}
          dataToShow={usersToShow}
          loading={usersLoading}
          error={usersError}
          requestStartOn={requestStartOn}
          userSearched={userSearched}
          handleScroll={handleScroll}
          noUsers={noUsers}
          scrollLoader={scrollLoader}
          handleClick={openModal}
          headersBtnClick={headersBtnClick}
          inTeam={true}
          handleShowAccountsTypes={handleShowAccountsTypes}
        />
      </section>
    </section>
  );
};
export default Team;
