import { createContext, useContext, useMemo, useReducer } from "react";
import { billingReducer } from "../reducers/billingReducer";
import AuthContext from "./AuthContext";
import { TYPES } from "../actions/billingActions";
import { generateRequest } from "../helpers/helpHttp";
import { url } from "./domain";
import DatesContext from "./DatesContext";
import { changeHeadersHelper } from "../helpers/changeHeadersHelper";
import { sorting } from "../helpers/sorting";
import { initialState } from "../states/contexts/BillingContext";

const BillingContext = createContext();

const BillingProvider = ({ children }) => {
  const { terminateSession, invalidSession } = useContext(AuthContext);
  const { billingDates } = useContext(DatesContext);
  const [state, dispatch] = useReducer(billingReducer, initialState);

  const {
    lastInvoices,
    dataToShow,
    headers,
    invoiceLoading,
    errorInvoice,
    scrollLoader,
    requestStartOn,
    contract,
    contractError,
    totals,
    totalsError,
  } = state;

  const changeHeaders = (title, sortedBy) => {
    const newHeaders = changeHeadersHelper(headers, title, sortedBy);

    dispatch({
      type: TYPES.CHANGE_HEADERS,
      payload: newHeaders,
    });
  };

  const createTableData = (data) => {
    if (data.length === 0) {
      dispatch({
        type: TYPES.NO_INVOICE_DATA,
      });
      return;
    }

    dispatch({
      type: TYPES.SET_TABLE_DATA,
      payload: {
        data,
      },
    });
  };

  const setLoader = (type) =>
    dispatch({
      type: type,
    });

  const getLastInvoices = async (requestStartOn, noLoader) => {
    if (!noLoader) setLoader(TYPES.INVOICES_LOADING);
    else setLoader(TYPES.SCROLL_LOADER);

    try {
      const requestData = {
        url: `${url}/billing/invoices?page=${requestStartOn}&offset=10`,
        terminateSession,
        invalidSession,
      };

      const data = await generateRequest.get(requestData);

      if (data.err) throw data;

      if (data.invoices.length === 0) {
        dispatch({
          type: TYPES.NO_INVOICE_DATA,
          payload: requestStartOn,
        });
        return;
      }

      if (requestStartOn === 0)
        dispatch({
          type: TYPES.GET_LAST_INVOICES,
          payload: {
            data: data.invoices,
            requestStartOn: 0,
          },
        });
      else {
        const payload = {
          data: data.invoices,
          requestStartOn,
        };
        dispatch({ type: TYPES.LOAD_DATA, payload });
      }
    } catch (error) {
      if (error.status === 404) {
        dispatch({
          type: TYPES.GET_LAST_INVOICES,
          payload: {
            data: [],
            requestStartOn,
          },
        });
        return;
      }

      const err = {
        ...error,
        errText: "Lo sentimos. No se pudo cargar el historial",
      };

      dispatch({
        type: TYPES.INVOICES_ERROR,
        payload: err,
      });
    }
  };

  const getTotals = async () => {
    const { from, to } = billingDates;

    const startYear = from.year;
    const startMonth = from.monthNumber + 1;
    const startDay = from.day;

    const endYear = to.year;
    const endMonth = to.monthNumber + 1;
    const endDay = to.day;

    try {
      const requestData = {
        url: `${url}/billing/usage?start_date=${startYear}-${startMonth}-${startDay}&end_date=${endYear}-${endMonth}-${endDay}`,
        terminateSession,
        invalidSession,
      };

      const data = await generateRequest.get(requestData);

      if (data.err) throw data;

      dispatch({
        type: TYPES.GET_TOTALS,
        payload: data,
      });
    } catch (error) {
      const err = {
        ...error,
        errText: "Lo sentimos. No se pudieron cargar los totales de búsqueda",
      };

      dispatch({
        type: TYPES.ERROR_TOTALS,
        payload: err,
      });
    }
  };

  const sortBy = (order, type, key) => {
    const newDataToShow = sorting(dataToShow, order, type, key);
    createTableData(newDataToShow);
  };

  const getContractInfo = async () => {
    try {
      const requestData = {
        url: `${url}/billing/contracts`,
        terminateSession,
        invalidSession,
      };

      const data = await generateRequest.get(requestData);

      if (data.err) throw data;

      dispatch({
        type: TYPES.GET_CONTRACT_DATA,
        payload: data,
      });
    } catch (error) {
      const err = {
        ...error,
        errText:
          "Lo sentimos. No pudimos recuperar la información del contrato",
      };

      dispatch({
        type: TYPES.CONTRACT_ERROR,
        payload: err,
      });
    }
  };

  const data = useMemo(
    () => ({
      lastInvoices,
      dataToShow,
      headers,
      requestStartOn,
      invoiceLoading,
      errorInvoice,
      scrollLoader,
      contract,
      contractError,
      totals,
      totalsError,
      changeHeaders,
      getLastInvoices,
      createTableData,
      getTotals,
      sortBy,
      getContractInfo,
    }),
    [
      lastInvoices,
      dataToShow,
      headers,
      requestStartOn,
      invoiceLoading,
      errorInvoice,
      scrollLoader,
      contract,
      contractError,
      totals,
      totalsError,
      changeHeaders,
      getLastInvoices,
      createTableData,
      getTotals,
      sortBy,
      getContractInfo,
    ]
  );

  return (
    <BillingContext.Provider value={data}>{children}</BillingContext.Provider>
  );
};

export { BillingProvider };
export default BillingContext;
