import { useContext, useEffect, useState } from "react";
import Modal from "./Modal";
import UserContext from "../contexts/UserContext";
import { T, useTolgee } from "@tolgee/react";

const Ajustes = () => {
  const { apiKey, getAPIKey } = useContext(UserContext);
  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const tolgee = useTolgee(["language"]);
  const isLangEs = tolgee.getLanguage() === "es-MX";

  const translate = (text) => {
    if (isLangEs) return text;

    if (text === "Ocultar") return "Hide";
    else return "Show";
  };

  useEffect(() => {
    getAPIKey();
  }, []);

  return (
    <>
      {showModal && <Modal setShowModal={setShowModal} />}
      <section className="ajustes">
        <div className="ajustes__content">
          <div className="ajustes__content__api">
            <h3 className="heading-2 ajustes__content__api--h3">Api Key</h3>
            <p>
              <T keyName="settings_api_key_message" />
            </p>
            <div className="ajustes__content__api--container">
              {show ? apiKey : "**********************"}
            </div>
            <div className="ajustes__content__api--buttons">
              <button onClick={() => setShow(!show)}>
                {show ? translate("Ocultar") : translate("Mostrar")}
              </button>
              {/*<button onClick={handleClick}>Regenerar</button>*/}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Ajustes;
