import { getSavedData } from "../../helpers/getSavedData";

export const initialFilters = [
  {
    name: "document_image",
    label: "Frente INE / IFE o Pasaporte",
    show: true,
  },
  {
    name: "rfc",
    label: "RFC",
    show: true,
  },
  {
    name: "curp",
    label: "CURP",
    show: true,
  },
  {
    name: "ip",
    label: "IP",
    show: true,
  },
  {
    name: "email",
    label: "Email",
    show: true,
  },
  {
    name: "image",
    label: "Selfie",
    show: true,
  },
  {
    name: "address",
    label: "Dirección",
    show: true,
  },
  {
    name: "phone_number",
    label: "Teléfono",
    show: true,
  },
  {
    name: "location",
    label: "Latitud, Longitud",
    show: true,
  },
  {
    name: "document_image_back",
    label: "Dorso INE / IFE",
    show: true,
  },
  // {
  //   name: "device",
  //   label: "Dispositivo",
  //   show: true,
  // },
];

export const initialWalkthrough = {
  dash: true,
  busqueda: {
    form: true,
    report: true,
  },
  consultas: true,
};

export const initialHistory = {
  date: true,
  productName: true,
  id: true,
  externalId: false,
  informador: false,
  estatus: true,
};

export const initialNotifications = {
  list: [],
  not_seen: 0,
};

export const getMetadataSaved = () =>
  getSavedData({
    filters: initialFilters,
    walkthrough: true,
    history: initialHistory,
    notifications: initialNotifications,
  });

export const {
  filters: reportFilters,
  walkthrough,
  history,
  notifications,
} = getMetadataSaved();

export const initialState = {
  token: "",
  user: {
    company: "",
    role: "",
    email: "",
    first_name: "",
    last_name: "",
    phone_number: "",
    id: 0,
    metadata: {
      walkthrough: {
        dash: walkthrough?.dash,
        busqueda: {
          form: walkthrough?.busqueda?.form,
          report: walkthrough?.busqueda?.report,
        },
        consultas: walkthrough?.consultas,
      },
      filters: {
        document_image: reportFilters.find(
          (filter) => filter.name === "document_image"
        )?.show,
        rfc: reportFilters.find((filter) => filter.name === "rfc")?.show,
        curp: reportFilters.find((filter) => filter.name === "curp")?.show,
        ip: reportFilters.find((filter) => filter.name === "ip")?.show,
        email: reportFilters.find((filter) => filter.name === "email")?.show,
        image: reportFilters.find((filter) => filter.name === "image")?.show,
        address: reportFilters.find((filter) => filter.name === "address")
          ?.show,
        phone_number: reportFilters.find(
          (filter) => filter.name === "phone_number"
        )?.show,
        location: reportFilters.find((filter) => filter.name === "location")
          ?.show,
        document_image_back: reportFilters.find(
          (filter) => filter.name === "document_image_back"
        )?.show,
        // device: reportFilters.find((filter) => filter.name === "device")?.show,
      },
      history,
    },
    notifications,
  },
  error: false,
  loading: false,
  sessionExpired: false,
  sessionExpiredText: "",
  sendMailError: false,
  mailSended: false,
  newPassError: false,
  passChanged: false,
  signupError: false,
  isFacekey: window.location.href.includes("facekey.asofom.mx"),
};

export const SESSION_MESSAGES = {
  DOUBLE_SESSION_MESSAGE:
    "Se ha iniciado sesión en otro lado. Debemos terminar la sesión actual",
  SESSION_EXPIRED_MESSAGE:
    "Tu sesión expiró. Haz click en Aceptar para volver a iniciar sesión",
  NETWORK_ERROR_MESSAGE: "Debemos cerrar tu sesión debido a problemas de red",
};
