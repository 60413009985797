import { useContext, useState } from "react";
import ReportContext from "../contexts/ReportContext";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import amazonImg from "../styles/icons/redes/amazon.svg";
import discordImg from "../styles/icons/redes/discord.svg";
import facebookImg from "../styles/icons/redes/facebook.svg";
import githubImg from "../styles/icons/redes/github.svg";
import googleImg from "../styles/icons/redes/google.svg";
import instagramImg from "../styles/icons/redes/instagram.svg";
import linkedinImg from "../styles/icons/redes/linkedin.svg";
import netflixImg from "../styles/icons/redes/netflix.svg";
import spotifyImg from "../styles/icons/redes/spotify.svg";
import twitterImg from "../styles/icons/redes/twitter.svg";
import { useTranslate } from "@tolgee/react";
// import { formatUTCDate } from "../helpers/formatDate";

const EmailSubDropdown = () => {
  const {
    report: {
      email_social_media_accounts,
      // email_social_media_accounts_details,
    },
  } = useContext(ReportContext);
  const [expanded, setExpanded] = useState(false);
  const { t } = useTranslate();

  const handleChange = (panel) => (isExpanded) => {
    if (panel === "") setExpanded(false);
    setExpanded(isExpanded ? panel : false);
  };

  // const linkedin = {
  //   name: email_social_media_accounts_details?.linkedin?.name,
  //   current_company:
  //     email_social_media_accounts_details?.linkedin?.current_company,
  //   profile_link: email_social_media_accounts_details?.linkedin?.profile_link,
  //   profile_picture:
  //     email_social_media_accounts_details?.linkedin?.profile_picture,
  // };

  // const google = {
  //   name: email_social_media_accounts_details?.google?.name,
  //   last_updated: email_social_media_accounts_details?.google?.last_updated,
  //   profile_picture:
  //     email_social_media_accounts_details?.google?.profile_picture,
  // };

  const {
    amazon,
    discord,
    facebook,
    github,
    google: googleLogo,
    instagram,
    linkedin: linkedinLogo,
    netflix,
    spotify,
    twitter,
  } = email_social_media_accounts;

  return (
    <Accordion
      key="email-sub"
      expanded={expanded}
      onChange={!expanded ? handleChange("email-sub") : handleChange("")}
      className="reporteDataAccordion"
      style={{ minHeight: "none", paddingTop: "16px" }}
    >
      <AccordionSummary
        aria-controls="email-sub-content"
        id="email-sub-header"
        className="reporteDataAccordion__title--blue"
      >
        <div className="reporteDataAccordion__title__text--blue">
          <button>
            {!expanded ? (
              <div className="icon-down reporteDataAccordion__title__icon color--dodger-blue"></div>
            ) : (
              <div className="icon-expand-menos reporteDataAccordion__title__icon color--dodger-blue"></div>
            )}
          </button>
          <h3 className="text-2--bold color--dodger-blue">
            {t("email_sub_drop_title")}
          </h3>
        </div>
      </AccordionSummary>
      <AccordionDetails
        style={{
          padding: "0 16px",
        }}
        sx={{ transition: "height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;" }}
      >
        <div className="reporteDataAccordion__sub-email">
          {/* {linkedin?.name && (
            <div className="reporteDataAccordion__sub-email__container">
              <div className="reporteDataAccordion__sub-email__title">
                <img src={linkedinImg} alt="linkedin icon" />
                <h5 className="text-2--bold">Linkdin</h5>
              </div>
              <div className="reporteDataAccordion__sub-email__info">
                <div className="reporteDataAccordion__sub-email__picture">
                  <img src={linkedin.profile_picture} alt="linkedin profile" />
                  <h5 className="text-2--bold">{linkedin.name}</h5>
                </div>
                <div className="reporteDataAccordion__description">
                  <h5 className="text-2 color--shuttle-gray">Empresa actual</h5>
                  <span className="text-2">{linkedin.current_company}</span>
                </div>
                <div className="reporteDataAccordion__description">
                  <h5 className="text-2 color--shuttle-gray">Link</h5>
                  <span className="text-2">{linkedin.profile_link}</span>
                </div>
              </div>
            </div>
          )}
          {google?.name && (
            <div className="reporteDataAccordion__sub-email__container">
              <div className="reporteDataAccordion__sub-email__title">
                <img src={googleImg} alt="google icon" />
                <h5 className="text-2--bold">Google</h5>
              </div>
              <div className="reporteDataAccordion__sub-email__info">
                <div className="reporteDataAccordion__sub-email__picture">
                  <img src={google.profile_picture} alt="google profile" />
                  <h5 className="text-2--bold">{google.name}</h5>
                </div>
                <div className="reporteDataAccordion__description">
                  <h5 className="text-2 color--shuttle-gray">
                    Última actualización
                  </h5>
                  <span className="text-2">
                    {formatUTCDate(
                      google.last_updated,
                      "D [de] MMM YYYY, hh:mm:ss A"
                    )}
                  </span>
                </div>
              </div>
            </div>
          )} */}
          {(amazon ||
            discord ||
            facebook ||
            github ||
            googleLogo ||
            instagram ||
            linkedinLogo ||
            netflix ||
            spotify ||
            twitter) && (
            <div
              className="reporteDataAccordion__sub-email__icons"
              style={{ padding: "0", paddingBottom: "16px" }}
            >
              {/* <h5 className="text-2--bold">Otras redes sociales encontradas</h5> */}
              <div className="reporteDataAccordion__sub-email__icons-container">
                {amazon && <img src={amazonImg} alt="amazon" />}
                {discord && <img src={discordImg} alt="discord" />}
                {facebook && <img src={facebookImg} alt="facebook" />}
                {github && <img src={githubImg} alt="github" />}
                {googleLogo && <img src={googleImg} alt="google" />}
                {instagram && <img src={instagramImg} alt="instagram" />}
                {linkedinLogo && <img src={linkedinImg} alt="linkedin" />}
                {netflix && <img src={netflixImg} alt="netflix" />}
                {spotify && <img src={spotifyImg} alt="spotify" />}
                {twitter && <img src={twitterImg} alt="twitter" />}
              </div>
            </div>
          )}
        </div>
      </AccordionDetails>
    </Accordion>
  );
};
export default EmailSubDropdown;
