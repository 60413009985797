import { COLORS } from "../styles/utils/colors";

const TextDataResumen = ({ description, value, border, report_network }) => {
  const styles =
    border === "top"
      ? { borderTop: `1px solid ${COLORS.CATSKILL_WHITE}` }
      : border === "bottom"
      ? { borderBottom: `1px solid ${COLORS.CATSKILL_WHITE}` }
      : {
          borderTop: `1px solid ${COLORS.CATSKILL_WHITE}`,
          borderBottom: `1px solid ${COLORS.CATSKILL_WHITE}`,
        };

  if (report_network) {
    return (
      <div className="textDataResumen__container">
        <span className="text-2 color--shuttle-gray">{description}</span>
        <span className="text-2">{value}</span>
      </div>
    );
  }

  return (
    <div style={styles} className="textDataResumen__container">
      <span className="text-2">{description}</span>
      <span className="text-2">{value}</span>
    </div>
  );
};
export default TextDataResumen;
