import React from "react";
import Slider from "react-slick";
import SliderElement from "./SliderElement";

const ComponentsSlider = ({ settings, componentsArr }) => {
  return (
    <Slider {...settings}>
      {componentsArr.map((component) => (
        <SliderElement key={component.id} {...component} />
      ))}
    </Slider>
  );
};
export default ComponentsSlider;
