import logo from "../styles/images/logo-trully.svg";
import icon from "../styles/images/icon-trully.svg";
import faceLogo from "../styles/images/facekey.png";
import faceIcon from "../styles/images/face-icon.png";
import { useContext } from "react";
import { Link, NavLink } from "react-router-dom";
import AuthContext from "../contexts/AuthContext";
import DataContext from "../contexts/DataContext";
import DatesContext from "../contexts/DatesContext";
import ReportContext from "../contexts/ReportContext";
import { PRIVATE_ROUTES, PUBLIC_ROUTES } from "./pages/routes";
import { ROLES } from "../reducers/authReducer";
import iconRules from "../styles/icons/images/icon_menu-Reglas.svg";
import iconLink from "../styles/icons/images/icon_SDK.svg";
import { useTranslate } from "@tolgee/react";

const NavBar = ({ open, setOpenSlide }) => {
  const {
    logout,
    user: { role },
    isFacekey
  } = useContext(AuthContext);
  const { resetData } = useContext(DataContext);
  const { resetDates } = useContext(DatesContext);
  const { restartReport } = useContext(ReportContext);
  const { t } = useTranslate();

  const stateClasses = open
    ? "layout__sidebar__nav"
    : "layout__sidebar--close__nav";

  return (
    <nav className={stateClasses}>
      <div className={`${stateClasses}__title-section`}>
        <div className={`${stateClasses}__title`}>
          <Link
            to={PRIVATE_ROUTES.DASHBOARD}
            className={`${stateClasses}--link-title`}
          >
            {open ? (
              <img
                className={`${stateClasses}__title--logo${isFacekey ? '--trueFace' : ''}`}
                src={isFacekey ? faceLogo : logo}
                alt="logo trully"
              />
            ) : (
              <img
                className={`${stateClasses}__title--logo ${stateClasses}__title--logo${isFacekey ? '--trueFace' : ''}-max`}
                src={isFacekey ? faceIcon : icon}
                alt="logo trully"
              />
            )}
          </Link>
        </div>
        <div className={`${stateClasses}__btn-toggle`}>
          {open ? (
            <button
              onClick={() => setOpenSlide(false)}
              className="icon-cerrar-menu"
            ></button>
          ) : (
            <button
              onClick={() => setOpenSlide(true)}
              className="icon-abrir-menu"
            ></button>
          )}
        </div>
      </div>
      <div className={`${stateClasses}__links-section`}>
        <div className={`${stateClasses}--section`}>
          <div className={`${stateClasses}--section-1`}>
            <NavLink
              className={({ isActive }) =>
                isActive
                  ? `${stateClasses}--active-link`
                  : `${stateClasses}--link`
              }
              to={PRIVATE_ROUTES.DASHBOARD}
            >
              <span className="icon-menu-dash">
                {open && <span>{t("nav_bar_home")}</span>}
              </span>
            </NavLink>
            <NavLink
              className={({ isActive }) =>
                isActive
                  ? `${stateClasses}--active-link`
                  : `${stateClasses}--link`
              }
              to={PRIVATE_ROUTES.BUSQUEDA}
            >
              <span className="icon-menu-busq">
                {open && <span>{t("nav_bar_search")}</span>}
              </span>
            </NavLink>
            <NavLink
              className={({ isActive }) =>
                isActive
                  ? `${stateClasses}--active-link`
                  : `${stateClasses}--link`
              }
              to={PRIVATE_ROUTES.CONSULTAS}
            >
              <span className="icon-menu-cons">
                {open && <span>{t("nav_bar_query")}</span>}
              </span>
            </NavLink>
            {/* <NavLink
              className={({ isActive }) =>
                isActive
                  ? `${stateClasses}--active-link`
                  : `${stateClasses}--link`
              }
              to={PRIVATE_ROUTES.REVIEWS}
            >
              <span className="icon-lista">
                {open && <span>Revisiones</span>}
              </span>
            </NavLink> */}
            {role && (role === ROLES.PROPIETARIO || role === ROLES.ADMIN) && (
              <NavLink
                className={({ isActive }) =>
                  isActive
                    ? `${stateClasses}--active-link linkImageActive`
                    : `${stateClasses}--link linkImage`
                }
                to={PRIVATE_ROUTES.RULES}
              >
                <img src={iconRules} alt="icon-reglas" />
                {open && <span>{t("nav_bar_rules")}</span>}
              </NavLink>
            )}
            <NavLink
              className={({ isActive }) =>
                isActive
                  ? `${stateClasses}--active-link linkImageActive`
                  : `${stateClasses}--link linkImage`
              }
              to={PRIVATE_ROUTES.LINK}
            >
              <img src={iconLink} alt="icon-link" />
              {open && <span>{t("nav_bar_magic_link")}</span>}
            </NavLink>
            {role && (role === ROLES.PROPIETARIO || role === ROLES.ADMIN) && (
              <NavLink
                className={({ isActive }) =>
                  isActive
                    ? `${stateClasses}--active-link`
                    : `${stateClasses}--link`
                }
                to={PRIVATE_ROUTES.TEAM}
              >
                <span className="icon-menu-team">
                  {open && <span>{t("nav_bar_team")}</span>}
                </span>
              </NavLink>
            )}
            <a
              href="https://docs.trully.ai/"
              target="_blank"
              rel="noreferrer"
              className={`${stateClasses}--link`}
            >
              <span className="icon-menu-docs">
                {open && <span>{t("nav_bar_docs")}</span>}
              </span>
            </a>
          </div>
        </div>
        <div className={`${stateClasses}--section`}>
          <div className={`${stateClasses}--section-2`}>
            {/* {role !== ROLES.ANALISTA && (
              <NavLink
                className={({ isActive }) =>
                  isActive
                    ? `${stateClasses}--active-link`
                    : `${stateClasses}--link`
                }
                to={PRIVATE_ROUTES.BILLING}
              >
                <span className="icon-menu-bill">
                  {open && <span>{t("nav_bar_billing")}</span>}
                </span>
              </NavLink>
            )} */}
            <Link
              to={PUBLIC_ROUTES.SIGNIN}
              onClick={() => {
                resetData();
                resetDates();
                restartReport();
                logout();
              }}
              className={`${stateClasses}--link`}
            >
              <span className="icon-menu-out">
                {open && <span>{t("nav_bar_exit")}</span>}
              </span>
            </Link>
          </div>
        </div>
      </div>
    </nav>
  );
};
export default NavBar;
