const SmallLoader = () => {
  return (
    <div className="lds-ring-small">
      <div className="ringFragment"></div>
      <div className="ringFragment"></div>
      <div className="ringFragment"></div>
      <div className="ringFragment"></div>
    </div>
  );
};
export default SmallLoader;
