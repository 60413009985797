import { Box, LinearProgress } from "@mui/material";

const LinearLoader = () => {
  return (
    <div className="layout__main__section__consultas--loader">
      <Box sx={{ width: "50%" }}>
        <LinearProgress />
      </Box>
      <span className="layout__main__section__consultas--loader-text">
        Loading
      </span>
    </div>
  );
};
export default LinearLoader;
