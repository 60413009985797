import { useContext, useEffect, useState } from "react";
import img from "../styles/icons/images/emoticon-hola.svg";
import TextInput from "./TextInput";
import { validateEmail } from "../helpers/formValidations";
import InputPass from "./InputPass";
import AuthContext from "../contexts/AuthContext";
import SmallLoader from "./SmallLoader";
import { Link } from "react-router-dom";
import ReCaptchaComponent from "./ReCaptchaComponent";
import { PUBLIC_ROUTES } from "./pages/routes";
import { useFormValidation } from "../hooks/useFormValidation";
import ErrorSlideTimeOut from "./ErrorSlideTimeOut";
import Button from "./Button";
import { useTolgee, useTranslate } from "@tolgee/react";
import { GoogleLogin } from "@react-oauth/google";

const initialState = {
  email: "",
  password: "",
  captcha: "",
};

const initialValidateForm = [
  {
    name: "email",
    valid: true,
  },
];

const SignIn = () => {
  const {
    error,
    loading,
    login,
    ssoLogin,
    ssoLoginError,
    setLogError,
    resetError,
    resetUser,
    sessionExpired,
    sessionExpiredText,
    isFacekey
  } = useContext(AuthContext);
  const [form, setForm] = useState(initialState);
  const [validCaptcha, setValidCaptcha] = useState('');
  const { validateForm, setValidateForm, handleFormValidation } =
    useFormValidation(initialValidateForm);
  const [sending, setSending] = useState(false);
  const tolgee = useTolgee(["language"]);
  const isLangEs = tolgee.getLanguage() === "es-MX";
  const { t } = useTranslate();

  const handleSubmit = (e) => {
    e.preventDefault();

    const email = form.email;
    const password = form.password;

    if (!email || !password || !validCaptcha) {
      setLogError(t("signin_error"));
      return;
    }

    setSending(true);
    login(validateForm, email, password, validCaptcha);
    setValidateForm(initialValidateForm);
  };

  useEffect(() => {
    if (error) {
      setSending(false);
      setValidCaptcha('');
    }
  }, [error]);

  useEffect(() => {
    if (sessionExpired || sessionExpiredText !== "") {
      resetUser();
    }
  }, [sessionExpired, sessionExpiredText]);

  const styles = {
    boxShadow:
      "0px 6px 40px rgba(0, 0, 0, 0.04), 0px 10px 16px rgba(0, 0, 0, 0.0274815)",
  };

  return (
    <section className="signIn">
      <div className="signIn__form-container">
        <div className="signIn__title">
          <div className="signIn__title--title">
            <h2 className="heading-1">{t("signin_title")}</h2>
            <img src={img} alt="saludo" />
          </div>
          <p className="text-1">{t("signin_greeting")}</p>
        </div>
        <form onSubmit={handleSubmit} className="signIn__form">
          <TextInput
            form={form}
            inputName="email"
            inputLabel={t("input_email_label")}
            description={t("input_email_description")}
            setValue={setForm}
            validate={validateEmail}
            validateErrorText={t("input_email_error")}
            handleFormValidation={handleFormValidation}
            styles={styles}
            inputStylesMUI={{ maxWidth: "auto" }}
          />
          <InputPass
            form={form}
            inputName="password"
            description={t("input_pass_description")}
            setValue={setForm}
            validate={() => undefined}
            infoText={t("signin_pass_info")}
            iconStyles={{ width: "300px" }}
            styles={styles}
            inputStylesMUI={{ maxWidth: "auto" }}
          />
          <Link
            className="text-2 color--dodger-blue signIn__form--link"
            to={PUBLIC_ROUTES.RESET}
          >
            {t("signin_forgot_pass")}
          </Link>
          <ReCaptchaComponent
            validCaptcha={validCaptcha}
            setValidCaptcha={setValidCaptcha}
          />
          <Button
            text={t("signin_btn")}
            disabled={!(validCaptcha && !sending)}
            styles={{
              width: "100%",
              maxWidth: "500px",
              marginTop: "12px",
              marginBottom: "12px",
            }}
            type="submit"
          />

          {loading && (
            <div className="signIn__submit">
              <SmallLoader />
              <p className="signIn__submit--text">{t("loading")}</p>
            </div>
          )}
        </form>
        <ErrorSlideTimeOut
          error={error || { err: false, errText: "" }}
          restartFn={resetError}
          styles={{ paddingLeft: 0 }}
        />
      </div>
      <div className="signIn__separator">
        <hr />
        <span>{t("or")}</span>
        <hr />
      </div>
      {!isFacekey && (
        <GoogleLogin
          key={isLangEs ? "es" : "en"}
          onSuccess={(credentialResponse) => {
            ssoLogin(credentialResponse);
          }}
          onError={() => {
            ssoLoginError();
          }}
          locale={isLangEs ? "es" : "en"}
          size="large"
          shape="pill"
          text="continue_with"
          cancel_on_tap_outside={true}
          logo_alignment="center"
        />
      )}
    </section>
  );
};

export default SignIn;
