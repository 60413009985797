import React, { useEffect } from "react";
import FontPicker from "font-picker";

const FontPickerComponent = ({
  title,
  setFont,
  initialFont = "DM Sans",
  fontType,
  handleFontPickerShowing,
}) => {
  useEffect(() => {
    const fontPicker = new FontPicker(
      "AIzaSyDm2-e78fk0mpbhJ9Sc_c6vUS8J8rNW6y4",
      initialFont,
      { limit: 30, pickerId: fontType, variants: ["regular", "700"] }
    );

    fontPicker.setOnChange(({ family }) => {
      setFont({ font: family, name: fontType });
    });
  }, []);

  return (
    <div className="fontPicker">
      <span className="text-2 color--shuttle-gray">{title}</span>
      <div
        id={`font-picker-${fontType}`}
        className="fontPicker__dropdown-button"
        onClick={
          handleFontPickerShowing ? (e) => handleFontPickerShowing(e) : () => {}
        }
      ></div>
    </div>
  );
};

export default FontPickerComponent;
