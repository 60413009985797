export const TYPES = {
  GET_TOKEN: "GET_TOKEN",
  REFRESH_TOKEN: "REFRESH_TOKEN",
  LOG_ERROR: "LOG_ERROR",
  SESSION_EXPIRED: "SESSION_EXPIRED",
  RELOAD_SESSION: "RELOAD_SESSION",
  ACTIVE_SESSION: "ACTIVE_SESSION",
  LOADING: "LOADING",
  LOGOUT: "LOGOUT",
  SIGNUP: "SIGNUP",
  RESET_ERROR: "RESET_ERROR",
  SEND_MAIL_ERROR: "SEND_MAIL_ERROR",
  SEND_MAIL: "SEND_MAIL",
  NEW_PASS_ERROR: "NEW_PASS_ERROR",
  RESET_NEW_PASS: "RESET_NEW_PASS",
  CHANGE_PASS: "CHANGE_PASS",
  PASS_TOKEN: "PASS_TOKEN",
  SIGNUP_ERROR: "SIGNUP_ERROR",
  UPDATE_USER_DATA: "UPDATE_USER_DATA",
  INVALID_SESSION: "INVALID_SESSION",
};
