import { useTolgee } from "@tolgee/react";
import {
  SNACKBAR_TEXTS,
  SNACKBAR_TEXTS_EN,
} from "../states/contexts/SnackContext";
import CopyToClipboard from "./CopyToClipboard";
import { JsonView, defaultStyles } from "react-json-view-lite";
import "react-json-view-lite/dist/index.css";

const JSONReport = ({ report }) => {
  const tolgee = useTolgee(["language"]);
  const isLangEs = tolgee.getLanguage() === "es-MX";
  const json = { ...report };
  delete json?.images;

  const formatJSON = () => {
    const text = json?.response || json;
    let str = JSON.stringify(text, null, "\t");
    str = str.replace(/\t/g, " ");

    return str;
  };

  return (
    <div className="json-report border border-light rounded-2">
      <div className="json-report__json-container">
        <CopyToClipboard
          text={formatJSON()}
          snackData={{
            text: isLangEs
              ? SNACKBAR_TEXTS.COPY_JSON_TEXT
              : SNACKBAR_TEXTS_EN.COPY_JSON_TEXT,
          }}
        />
        <JsonView
          data={
            json.response
              ? {
                  response: json?.response,
                  warnings: json?.warnings,
                  company_id: json?.company_id,
                  created_on: json?.created_on,
                  response_status: json?.response_status,
                  version: json?.version,
                }
              : json
          }
          style={defaultStyles}
          shouldExpandNode={(level) => level === 0 || level === 1}
        />
      </div>
    </div>
  );
};
export default JSONReport;
