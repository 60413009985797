import { useContext, useEffect, useState } from "react";
import Calendar from "./Calendar";
import DatesContext from "../contexts/DatesContext";
import DataContext from "../contexts/DataContext";
import { DATE_TYPE } from "../constants";
import { formatUTCDate } from "../helpers/formatDate";
import Loader from "./Loader";
import ErrorComponent from "./ErrorComponent";
import { changeHeadersHelper } from "../helpers/changeHeadersHelper";
import { sorting } from "../helpers/sorting";
import ResumenRules from "./ResumenRules";
import { useTolgee, useTranslate } from "@tolgee/react";

const initialHeaders = [
  {
    id: "rule-header",
    title: "Reglas",
    dataType: "string",
    dataKey: "rule",
    sortedBy: "desc",
  },
  {
    id: "last_date",
    title: "Última fecha",
    dataType: "date",
    dataKey: "last_date",
    sortedBy: "desc",
  },
  {
    id: "total_count",
    title: "Conteo",
    dataType: "number",
    dataKey: "total",
    sortedBy: "desc",
  },
  {
    id: "percentage",
    title: "Porcentaje",
    dataType: "string",
    dataKey: "percentage",
    sortedBy: "desc",
  },
];

const ELEMENTS_PER_PAGE = 7;

const setRulesPage = (rules, page) => {
  const start = (page - 1) * ELEMENTS_PER_PAGE;

  return rules.slice(start, start + ELEMENTS_PER_PAGE);
};

const numberOfRules = (page, totalRules) => {
  const firstElement = (page - 1) * ELEMENTS_PER_PAGE + 1;
  let lastElement = page * ELEMENTS_PER_PAGE;
  lastElement = lastElement > totalRules ? totalRules : lastElement;

  return {
    text: `${firstElement} - ${lastElement}`,
    firstElement,
    lastElement,
  };
};

const CommandPanelRules = () => {
  const { handleDates, updateRules, setUpdateRules, rulesDates } =
    useContext(DatesContext);
  const { getDashboardRules, rules, rulesLoading, noRulesData, rulesTotals } =
    useContext(DataContext);
  const [page, setPage] = useState(1);
  const [rulesToShow, setRulesToShow] = useState(() =>
    setRulesPage(rules, page)
  );
  const [headers, setHeaders] = useState(initialHeaders);
  const totalRules = rules?.length;
  const { t } = useTranslate();
  const tolgee = useTolgee(["language"]);
  const isLangEs = tolgee.getLanguage() === "es-MX";

  const headersBtnClick = (title, dataType, dataKey, sortedBy) => {
    const newOrder = sortedBy === "asc" ? "desc" : "asc";
    setHeaders((prev) => changeHeadersHelper(prev, title, newOrder));
    setRulesToShow(() => {
      const newRules = sorting(rules, newOrder, dataType, dataKey);
      return setRulesPage(newRules, page);
    });
  };

  const translate = (title) => {
    if (isLangEs) return title;

    switch (title) {
      case "Reglas":
        return "Rules";
      case "Última fecha":
        return "Last date";
      case "Conteo":
        return "Count";
      case "Porcentaje":
        return "Percentage";

      default:
        return title;
    }
  };

  useEffect(() => {
    if (updateRules) {
      getDashboardRules(rulesDates);
      setPage(() => 1);
      setUpdateRules(false);
    }
  }, [updateRules]);

  useEffect(() => {
    setRulesToShow(() => setRulesPage(rules, page));
  }, [page, rules]);

  return (
    <section className="dashboard__panel-section">
      <h2 className="heading-2 chart--title__text">
        {t("command_panel_rules")}
      </h2>
      <Calendar
        dates={rulesDates}
        setDates={handleDates}
        setUpdateChart={setUpdateRules}
        dateType={DATE_TYPE.RULES}
      />
      <div className="dashboard__resumen-container">
        <ResumenRules totals={rulesTotals} errorTotals={noRulesData} />
      </div>
      <div className="layout__main__section__dashboard__component--container border border-light rounded-2 commandPanelRules">
        <div className="commandPanelRules__title-container">
          <h2 className="heading-2">{t("command_panel_rules_fraud_rules")}</h2>
          <div className="commandPanelRules__buttons-container">
            <button
              className="icon-anterior commandPanelRules__btn"
              onClick={() => setPage((prev) => prev - 1)}
              disabled={page === 1}
            ></button>
            <p className="text-3">
              {numberOfRules(page, totalRules).text}{" "}
              {t("command_panel_rules_of")} {totalRules}
            </p>
            <button
              className="icon-siguiente commandPanelRules__btn"
              onClick={() => setPage((prev) => prev + 1)}
              disabled={
                numberOfRules(page, totalRules).lastElement === totalRules
              }
            ></button>
          </div>
        </div>
        <div className="commandPanelRules__table-container">
          {rulesLoading ? (
            <Loader />
          ) : (
            <>
              {noRulesData ? (
                <ErrorComponent
                  error={400}
                  title={t("command_panel_rules_error_title")}
                  text={t("command_panel_rules_error_text")}
                  styles={{ maxWidth: "none" }}
                />
              ) : (
                <table className="commandPanelRules__table">
                  <thead>
                    <tr>
                      {headers.map((header) => {
                        const { id, title, dataType, dataKey, sortedBy } =
                          header;
                        return (
                          <th key={id}>
                            <div className="commandPanelRules__table-title">
                              <span className="text-3">{translate(title)}</span>
                              {title !== "Reglas" && (
                                <button
                                  className={`${
                                    sortedBy === "desc"
                                      ? "icon-down"
                                      : "icon-expand-menos"
                                  } commandPanelRules__table-btn`}
                                  onClick={() =>
                                    headersBtnClick(
                                      title,
                                      dataType,
                                      dataKey,
                                      sortedBy
                                    )
                                  }
                                ></button>
                              )}
                            </div>
                          </th>
                        );
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {rulesToShow?.map((rule, i) => (
                      <tr key={`rule-${i}`}>
                        <td className="text-2">
                          {isLangEs ? rule.rule : rule.rule_en}
                        </td>
                        <td className="text-2">
                          {formatUTCDate(
                            rule.last_date,
                            "MM/DD/YYYY, hh:mm:ss A"
                          )}
                        </td>
                        <td className="text-2">{rule.total}</td>
                        <td className="text-2">{rule.percentage}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </>
          )}
        </div>
      </div>
    </section>
  );
};
export default CommandPanelRules;
