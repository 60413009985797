import { reportFilters } from "./AuthContext";

const headers = [
  {
    id: "name",
    title: "Nombre",
    dataType: "string",
    dataKey: "name",
    sortedBy: "desc",
  },
  {
    id: "email",
    title: "Correo",
    dataType: "string",
    dataKey: "email",
    sortedBy: "desc",
  },
  {
    id: "created_on",
    title: "Cuenta creada",
    dataType: "date",
    dataKey: "created_on",
    sortedBy: "desc",
  },
  {
    id: "account_type",
    title: "Tipo de cuenta",
    dataType: "number",
    dataKey: "dashboard_role_id",
    sortedBy: "desc",
  },
  {
    id: "team-button",
    title: "",
    dataType: "",
    dataKey: "team-button",
    sortedBy: "desc",
  },
];

export const initialState = {
  changePassLoading: false,
  changePassError: false,
  usersList: [],
  usersToShow: [],
  requestStartOn: 0,
  usersLoading: true,
  usersError: false,
  scrollLoader: false,
  headers,
  noUsers: false,
  userToDelete: null,
  deleteUserLoading: false,
  deleteUserError: false,
  userToAdd: null,
  addUserLoading: false,
  addUserError: false,
  updateUserLoading: false,
  apiKey: "",
  loadingRules: false,
  errorRules: false,
  rulesToShow: [],
  rulesToHire: [],
  showFilters: reportFilters,
};
