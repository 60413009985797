import {
  FormControlLabel,
  InputAdornment,
  Slide,
  TextField,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import IconInfo from "./IconInfo";
import { useEffect, useRef, useState } from "react";
import DropDown, { TRANSITION_TYPES } from "./DropDown";
import { COLORS } from "../styles/utils/colors";

const InputPhone = ({
  form,
  inputName,
  inputLabel,
  description,
  setValue,
  infoText,
  arrElementos,
  setElementos,
  tooltipPosition,
  validate,
  validateErrorText,
  handleFormValidation,
  textStyles,
  textClass,
  inputStylesMUI,
  keyProp
}) => {
  const [labelVisible, setLabelVisible] = useState(true);
  const [flagToShow, setFlagToShow] = useState(arrElementos[0]);
  const [mostrar, setMostrar] = useState(false);
  const [isValid, setIsValid] = useState(undefined);
  const containerErrorRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    if (handleFormValidation) {
      const input = {
        name: inputName,
        valid: typeof isValid === "undefined" ? true : isValid,
      };
      handleFormValidation(input);
    }
  }, [isValid]);

  useEffect(() => {
    if (form[inputName] === "" || typeof form[inputName] === "undefined")
      setIsValid(undefined);
  }, [form[inputName]]);

  const handleInputFocus = (show) => {
    setLabelVisible(show);

    if (typeof form[inputName] === "undefined")
      setValue({
        ...form,
        [inputName]: "+52 ",
      });
  };

  const handleInputBlur = (e) => {
    setTimeout(() => {
      const {
        target: { value },
      } = e;

      if (value === "" || typeof value === "undefined") handleInputFocus(true);
    }, 200);
  };

  const handleChange = (e) => {
    const {
      target: { name, value },
    } = e;

    closeList();

    const textToValidate = value.substring(1).replace(/\s+/g, "");
    const valid = validate(textToValidate);

    if (typeof valid === "undefined" || value.length === 4)
      setIsValid(undefined);
    else if (
      (!valid && form[inputName] === "+52 ") ||
      form[inputName] === "+54 "
    )
      setIsValid(undefined);
    else if (valid) setIsValid(true);
    else setIsValid(false);

    if (
      e.nativeEvent.inputType === "deleteContentBackward" &&
      value.length > 4
    ) {
      setValue((prev) => {
        return {
          ...prev,
          [name]: value,
        };
      });
    } else {
      for (let i = 0; i < value.length; i++) {
        if (i === 5 || i === 10)
          setValue((prev) => {
            return {
              ...prev,
              [name]: `${value} `,
            };
          });
        else if (value.length > 3) {
          setValue((prev) => {
            return {
              ...prev,
              [name]: value,
            };
          });
        }
      }
    }
  };

  const handleClick = (value) => {
    inputRef.current.focus();
    const flagToShow = arrElementos.find((el) => el.preFix === value);
    const newElementsState = arrElementos.map((item) =>
      item.preFix === value
        ? { ...item, selected: true }
        : { ...item, selected: false }
    );
    setElementos(() => newElementsState);
    setFlagToShow(() => flagToShow);
    setValue({
      ...form,
      phone_number: `${value} `,
    });
  };

  const handlePaste = (e) => {
    const pastedText = e.clipboardData.getData("text/plain");
    const {
      target: { value, name },
    } = e;

    let tel;
    if (pastedText.includes("+52") || pastedText.includes("+54"))
      tel = pastedText.substring(3, pastedText.length).trim();
    else tel = pastedText;

    const valid = validate(value + tel);

    if (tel.length > 10 || !valid) {
      setTimeout(() => {
        setIsValid(false);
      }, 200);
      return;
    } else {
      let newNumber = tel.substring(0, 2);
      newNumber += " " + tel.substring(2, 6);
      newNumber += " " + tel.substring(6);

      setTimeout(() => {
        setIsValid(true);
        setValue((prev) => {
          return {
            ...prev,
            [name]: value + newNumber,
          };
        });
      }, 0);
    }
  };

  const openList = () => setMostrar(true);

  const closeList = () => setMostrar(false);

  const theme = createTheme({
    typography: {
      fontFamily: textStyles?.fontFamily || "DM Sans",
      allVariants: {
        color: textStyles?.secondaryColor || COLORS.SHUTTLE_GRAY,
      },
    },
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            width: inputStylesMUI?.width || "100%",
            maxWidth: inputStylesMUI?.maxWidth || "350px",
            fontFamily: textStyles?.fontFamily || "DM Sans",
            // Estilo para el texto ingresado en el input
            "& .MuiInputBase-input": {
              color: textStyles?.primaryColor || COLORS.SHARK,
            },
            // Estilo para el label
            "& label": {
              color: textStyles?.secondaryColor || COLORS.SHUTTLE_GRAY,
            },
            // Estilo para el label cuando está enfocado
            "& label.Mui-focused": {
              color: textStyles?.primaryColor || COLORS.SHARK,
            },
            // Estilo para el placeholder
            "& .MuiInputBase-input::placeholder": {
              color: textStyles?.secondaryColor || COLORS.SHUTTLE_GRAY,
              opacity: 1,
            },
          },
        },
      },
      MuiFormControlLabel: {
        styleOverrides: {
          root: {
            width: inputStylesMUI?.width || "100%",
            maxWidth: inputStylesMUI?.maxWidth || "350px",
          },
          label: {
            width: inputStylesMUI?.width || "100%",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            fontFamily: textStyles?.fontFamily || "DM Sans",
            color: textStyles?.secondaryColor || COLORS.SHUTTLE_GRAY,
          },
        },
      },
    },
  });

  return (
    <div className="textInputComponent inputAuto" key={keyProp}>
      <div className="textInputComponent--title inputAuto--title">
        <span
          style={{ color: textStyles?.primaryColor || COLORS.SHUTTLE_GRAY }}
          className={`${
            textClass === "apply-font-text"
              ? textClass
              : "text-2 color--shuttle-gray"
          }`}
        >
          {description}
        </span>
        {infoText && <IconInfo text={infoText} styles={tooltipPosition} />}
      </div>
      <ThemeProvider theme={theme}>
        <FormControlLabel
          control={
            <TextField
              type="text"
              name={inputName}
              value={form[inputName] || ""}
              onPaste={handlePaste}
              onChange={handleChange}
              onFocus={() => handleInputFocus(false)}
              onBlur={handleInputBlur}
              label={labelVisible ? inputLabel : ""}
              className={`custom-input inputDropdown ${
                typeof isValid === "undefined"
                  ? ""
                  : isValid
                  ? "valid-input"
                  : "invalid-input"
              }`}
              InputLabelProps={{ shrink: false }}
              variant="outlined"
              inputRef={inputRef}
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    position="start"
                    className="inputAuto--dropdown"
                    onClick={openList}
                  >
                    <img
                      src={flagToShow.flag}
                      alt="bandera México"
                      className="autoCompleteSelect__content--flag inputAuto--dropdown--flag"
                    />
                    {!mostrar ? (
                      <span className="icon-down inputAuto--dropdown--icon"></span>
                    ) : (
                      <span className="icon-expand-menos inputAuto--dropdown--icon"></span>
                    )}
                  </InputAdornment>
                ),
              }}
            />
          }
          className="textInputComponent--label custom-label inputList-label inputAuto--dropdown__label"
        />
      </ThemeProvider>
      <div ref={containerErrorRef} className="slideContainer">
        <Slide
          direction="down"
          in={isValid === false}
          mountOnEnter
          unmountOnExit
          timeout={500}
          container={containerErrorRef.current}
        >
          <p className="inputTextErrMsg">{validateErrorText}</p>
        </Slide>
      </div>
      <DropDown
        enter={mostrar}
        arrElementos={arrElementos}
        handleClick={handleClick}
        closeList={closeList}
        transitionClassNames={"slideInDown"}
        type={TRANSITION_TYPES.COUNTRIES}
      />
    </div>
  );
};
export default InputPhone;
