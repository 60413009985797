export const getColumnPercentages = (columnIds) => {
  const percentages = {};
  let remainingPercentage = 100; // 100% es el total disponible

  // Definimos los porcentajes máximos y mínimos para las columnas
  const maxDateAndTimePercentage = 13;
  const maxIdPercentage = 17;
  const maxExternalIdPercentage = 18;
  const maxProductPercentage = 25;
  const minProductPercentage = 20;
  const maxStatusPercentage = 17;
  const minStatusPercentage = 15; // Mínimo para "estatus"

  // Calculamos cuántas columnas de cada tipo hay
  const dateAndTimeCount = columnIds.filter((id) =>
    ["date", "time"].includes(id)
  ).length;
  const idCount = columnIds.filter((id) => id === "id").length;
  const externalIdCount = columnIds.filter((id) => id === "externalId").length;
  const productCount = columnIds.filter((id) => id === "productName").length;
  const statusCount = columnIds.filter((id) => id === "estatus").length;

  // Inicializamos las variables para los porcentajes
  let productPercentage = Math.max(
    (100 - maxDateAndTimePercentage * dateAndTimeCount) /
      (productCount + idCount + externalIdCount + statusCount),
    minProductPercentage
  );
  let dateAndTimePercentage = Math.min(
    productPercentage - 5,
    maxDateAndTimePercentage
  );
  let idPercentage = Math.min(productPercentage - 10, maxIdPercentage);
  let externalIdPercentage = Math.min(
    productPercentage - 2,
    maxExternalIdPercentage
  );

  // Ajustamos el porcentaje para 'productName' de acuerdo con los límites
  productPercentage = Math.min(productPercentage, maxProductPercentage);

  // Asignamos los porcentajes a las columnas y calculamos el espacio restante
  columnIds.forEach((id) => {
    switch (id) {
      case "date":
      case "time":
        percentages[id] = dateAndTimePercentage;
        remainingPercentage -= dateAndTimePercentage;
        break;
      case "id":
        percentages[id] = idPercentage;
        remainingPercentage -= idPercentage;
        break;
      case "informador":
      case "externalId":
        percentages[id] = externalIdPercentage;
        remainingPercentage -= externalIdPercentage;
        break;
      case "productName":
        percentages[id] = productPercentage;
        remainingPercentage -= productPercentage;
        break;
      case "estatus":
        const statusPercentage = Math.max(
          Math.min(remainingPercentage / statusCount, maxStatusPercentage),
          minStatusPercentage
        );
        percentages[id] = statusPercentage;
        remainingPercentage -= statusPercentage;
        break;
      default:
        console.warn(`ID de columna desconocida: ${id}`);
        break;
    }
  });

  // Distribuir el porcentaje sobrante de forma equitativa entre todas las columnas
  const extraPercentagePerColumn = remainingPercentage / columnIds.length;
  for (let id of columnIds) {
    percentages[id] += extraPercentagePerColumn;
  }

  return percentages;
};
