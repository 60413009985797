import { useContext, useEffect, useState } from "react";
import CheckBoxOutlineBlankRoundedIcon from "@mui/icons-material/CheckBoxOutlineBlankRounded";
import CheckBoxRoundedIcon from "@mui/icons-material/CheckBoxRounded";
import CheckBox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { TextareaAutosize } from "@mui/material";
import ReportContext from "../contexts/ReportContext";
import ReportarFlagged from "./ReportarFlagged";
import ErrorSlideTimeOut from "./ErrorSlideTimeOut";
import moment from "moment";
import IconInfo from "./IconInfo";
import ReportarButtons from "./ReportarButtons";
import { FLAGS_NAMES } from "../states/contexts/ReportContext";
import { useTolgee, useTranslate } from "@tolgee/react";

const initialForm = {
  first_party_fraud: false,
  identity_fraud: false,
  first_payment_default: false,
  abuse: false,
  others: false,
  chargeback: false,
  tipo: "",
  comentario: "",
};

const texts = {
  first_party_fraud: {
    name: "first_party_fraud",
    value: "Cuenta Mula",
    info: "Este tipo de acciones se refiere a casos en los que una cuenta bancaria es utilizada para el lavado de dinero u otras actividades ilegales, a menudo sin el conocimiento del titular de la cuenta.",
  },
  identity_fraud: {
    name: "identity_fraud",
    value: "Suplantación de identidad",
    info: "La suplantación de identidad ocurre cuando alguien usa la información personal o biométrica de otra persona para hacerse pasar por ella.",
  },
  first_payment_default: {
    name: "first_payment_default",
    value: "Morosidad",
    info: "Incumplimiento del pago en término de las obligaciones crediticias adquiridas legalmente por más de un mes. (detallar en comentarios cantidad de meses de impago)",
  },
  abuse: {
    name: "abuse",
    value: "Comportamiento Sospechoso",
    info: "Este tipo de acciones incluyen inconsistencies en la información proporcionada y aunque no son siempre de manera intencional, pueden indicar un posible intento de fraude",
  },
  chargeback: {
    name: "chargeback",
    value: "Contracargo",
    info: "Uso injustificado de contracargos de cobros realizados a la cuenta bancaria. (detallar en comentarios el número de contracargos y el monto total)",
  },
  others: {
    name: "others",
    value: "Otros",
    info: "",
  },
};

const translate = (text, texts, isLangEs) => {
  if (isLangEs) return text;

  switch (text) {
    case texts.first_party_fraud.value:
      return "Mule Account";
    case texts.first_party_fraud.info:
      return "These types of actions refer to cases in which a bank account is used for money laundering or other illegal activities, often without the knowledge of the account holder.";
    case texts.identity_fraud.value:
      return "Identity fraud";
    case texts.identity_fraud.info:
      return "It occurs when someone uses another person's personal or biometric information to impersonate them.";
    case texts.first_payment_default.value:
      return "Defaulter";
    case texts.first_payment_default.info:
      return "Failure to pay legally acquired credit obligations on time for more than one month. (detail in comments number of months of non-payment)";
    case texts.abuse.value:
      return "Abusive Behavior";
    case texts.abuse.info:
      return "These types of actions include inconsistencies in the information provided and although they are not always intentional, they can indicate a possible fraud attempt.";
    case texts.chargeback.value:
      return "Chargeback";
    case texts.chargeback.info:
      return "Unjustified use of chargebacks for charges made to the bank account. (detail in comments the number of chargebacks and the total amount)";
    case texts.others.value:
      return "Others";
    case texts.others.info:
      return "";
    case "Debe agregar un tipo de fraude y una descripción":
      return "You must add a fraude type and a description";
    case "Descripción del reporte":
      return "Describe the flag";
    default:
      return text;
  }
};

function ReportarForm() {
  const {
    reportToFlag,
    toggleFlaggingForm,
    updateReportStatus,
    report,
    reportFromStatus,
  } = useContext(ReportContext);
  const { t } = useTranslate();
  const tolgee = useTolgee(["language"]);
  const isLangEs = tolgee.getLanguage() === "es-MX";

  const [flag, setFlag] = useState(false);
  const [showResumen, setShowResumen] = useState(false);
  const [form, setForm] = useState(initialForm);
  const [error, setError] = useState({
    err: false,
    errText: "Debe agregar un tipo de fraude y una descripción",
  });

  useEffect(() => {
    if (reportToFlag.flaggedFor.wasFlagged) {
      const { types, description, fraud_reason_id } = reportToFlag.flaggedFor;

      const tipo = types.find((type) => type.id === fraud_reason_id).reason;
      const first_party_fraud = tipo.includes("first_party_fraud");
      const identity_fraud = tipo.includes("identity_fraud");
      const first_payment_default = tipo.includes("first_payment_default");
      const abuse = tipo.includes("abuse");
      const comentario = description;

      setForm({
        ...initialForm,
        first_party_fraud,
        identity_fraud,
        first_payment_default,
        abuse,
        tipo,
        comentario,
      });
    } else setForm(initialForm);
  }, [reportToFlag.flaggedFor]);

  const handleChange = ({ target: { value, name, checked } }) => {
    if (name !== "comentario") {
      setForm({
        ...initialForm,
        [name]: checked,
        tipo: name,
        comentario: form.comentario,
      });

      return;
    }

    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (form.tipo === "" || form.comentario === "") {
      setError({
        ...error,
        err: true,
      });
      return;
    }

    const { types, request_id, identity_id } = reportToFlag.flaggedFor;
    const reason = types.find((type) => type.reason === form.tipo);
    const reasonID = reason.id;
    const reasonDescription = reason.description_es;

    const flag = {
      fraud_reason_id: reasonID,
      task: "mark_as_fraud",
      request_id,
      identity_id,
      description: form.comentario,
      reason_type: reasonDescription,
      wasFlagged: reportToFlag.wasFlagged,
      types,
      created_on: moment().utc(),
    };

    setFlag(flag);
    setShowResumen(true);
    if (reportFromStatus)
      updateReportStatus({
        reportID: report.id,
        newStatus: "reject",
        label: FLAGS_NAMES.POTENTIAL_THREAT,
      });
  };

  const handleReset = () => {
    setForm(initialForm);
  };

  const hideError = () => {
    setError({
      ...error,
      err: false,
    });
  };

  if (showResumen) {
    return (
      <ReportarFlagged
        flag={flag}
        handleReset={handleReset}
        setShowResumen={setShowResumen}
      />
    );
  }

  return (
    <form className="reportarForm" onSubmit={handleSubmit}>
      <div className="reportarForm__checkbox">
        <div>
          <h3 className="heading-4">{t("report_flag_title_1")}</h3>
          <div className="reportarForm__input-group">
            <FormControlLabel
              control={
                <CheckBox
                  name={texts.identity_fraud.name}
                  checked={form.identity_fraud}
                  icon={<CheckBoxOutlineBlankRoundedIcon />}
                  checkedIcon={<CheckBoxRoundedIcon />}
                  onChange={handleChange}
                  inputProps={{ "aria-label": "secondary checkbox" }}
                  value={texts.identity_fraud.value}
                />
              }
              label={translate(texts.identity_fraud.value, texts, isLangEs)}
            />
            <IconInfo
              text={translate(texts.identity_fraud.info, texts, isLangEs)}
              styles={{ width: "260px", top: "-100px", left: "-165px" }}
            />
          </div>
        </div>
        <div>
          <h3 className="heading-4">{t("report_flag_title_2")}</h3>
          <div className="reportarForm__input-group">
            <FormControlLabel
              control={
                <CheckBox
                  name={texts.abuse.name}
                  checked={form.abuse}
                  icon={<CheckBoxOutlineBlankRoundedIcon />}
                  checkedIcon={<CheckBoxRoundedIcon />}
                  onChange={handleChange}
                  inputProps={{ "aria-label": "secondary checkbox" }}
                  value={texts.abuse.value}
                />
              }
              label={translate(texts.abuse.value, texts, isLangEs)}
            />
            <IconInfo
              text={translate(texts.abuse.info, texts, isLangEs)}
              styles={{ width: "330px", left: "-150px", top: "-100px" }}
            />
          </div>
          <div className="reportarForm__input-group">
            <FormControlLabel
              control={
                <CheckBox
                  name={texts.others.name}
                  checked={form.others}
                  icon={<CheckBoxOutlineBlankRoundedIcon />}
                  checkedIcon={<CheckBoxRoundedIcon />}
                  onChange={handleChange}
                  inputProps={{ "aria-label": "secondary checkbox" }}
                  value={texts.others.value}
                />
              }
              label={translate(texts.others.value, texts, isLangEs)}
            />
          </div>
        </div>
        <div>
          <h3 className="heading-4">{t("report_flag_title_3")}</h3>
          <div className="reportarForm__input-group">
            <FormControlLabel
              control={
                <CheckBox
                  name={texts.first_payment_default.name}
                  checked={form.first_payment_default}
                  icon={<CheckBoxOutlineBlankRoundedIcon />}
                  checkedIcon={<CheckBoxRoundedIcon />}
                  onChange={handleChange}
                  inputProps={{ "aria-label": "secondary checkbox" }}
                  value={texts.first_payment_default.value}
                />
              }
              label={translate(
                texts.first_payment_default.value,
                texts,
                isLangEs
              )}
            />
            <IconInfo
              text={translate(
                texts.first_payment_default.info,
                texts,
                isLangEs
              )}
              styles={{ width: "325px", left: "-85px", top: "-100px" }}
            />
          </div>
          <div className="reportarForm__input-group">
            <FormControlLabel
              control={
                <CheckBox
                  name={texts.chargeback.name}
                  checked={form.chargeback}
                  icon={<CheckBoxOutlineBlankRoundedIcon />}
                  checkedIcon={<CheckBoxRoundedIcon />}
                  onChange={handleChange}
                  inputProps={{ "aria-label": "secondary checkbox" }}
                  value={texts.chargeback.value}
                />
              }
              label={translate(texts.chargeback.value, texts, isLangEs)}
            />
            <IconInfo
              text={translate(texts.chargeback.info, texts, isLangEs)}
              styles={{ width: "280px", top: "-100px", left: "-115px" }}
            />
          </div>
          <div className="reportarForm__input-group">
            <FormControlLabel
              control={
                <CheckBox
                  name={texts.first_party_fraud.name}
                  checked={form.first_party_fraud}
                  icon={<CheckBoxOutlineBlankRoundedIcon />}
                  checkedIcon={<CheckBoxRoundedIcon />}
                  onChange={handleChange}
                  inputProps={{ "aria-label": "secondary checkbox" }}
                  value={texts.first_party_fraud.value}
                />
              }
              label={translate(texts.first_party_fraud.value, texts, isLangEs)}
            />
            <IconInfo
              text={translate(texts.first_party_fraud.info, texts, isLangEs)}
              styles={{ width: "350px", top: "-100px", left: "-115px" }}
            />
          </div>
        </div>
      </div>
      <div className="reportarForm__comentario">
        <h3 className="heading-2 color--shark">
          {translate("Descripción del reporte", texts, isLangEs)}
        </h3>
        <FormControlLabel
          control={
            <TextareaAutosize
              name="comentario"
              value={form.comentario}
              onChange={handleChange}
              minRows={10}
              maxRows={10}
              className="reportarForm-textarea"
            />
          }
          style={{
            margin: "16px auto",
            width: "inherit",
          }}
        />
      </div>
      <ReportarButtons
        handleReset={handleReset}
        toggleFlaggingForm={toggleFlaggingForm}
        reportFromStatus={reportFromStatus}
        reportID={report.id}
        updateReportStatus={updateReportStatus}
      />
      <ErrorSlideTimeOut
        error={error}
        restartFn={hideError}
        styles={{ paddingLeft: "0" }}
      />
    </form>
  );
}
export default ReportarForm;
