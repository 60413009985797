import { useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { useTranslate } from "@tolgee/react";

const Position = ({ title, date }) => {
  const { t } = useTranslate();
  return (
    <div className="reporteDataAccordion__sub-pep__position">
      <h5 className="text-2--bold reporteDataAccordion__sub-pep__position__title">
        {title}
      </h5>
      <div className="reporteDataAccordion__sub-pep__date">
        <span className="text-2 color--shuttle-gray">
          {t("listed_weeks_sub_drop_1")}
        </span>
        <span className="text-2 color--shuttle-gray">{date}</span>
      </div>
    </div>
  );
};

const DataItem = ({ title, text }) => {
  return (
    <div className="reporteDataAccordion__sub-pep__dataItem">
      <span className="text-2 color--shuttle-gray">{title}</span>
      <span className="text-2 color--shuttle-gray">{text}</span>
    </div>
  );
};

const PepSubDropdown = ({ detailsList }) => {
  const [expanded, setExpanded] = useState(false);
  const { t } = useTranslate();

  const handleChange = (panel) => (isExpanded) => {
    if (panel === "") setExpanded(false);
    setExpanded(isExpanded ? panel : false);
  };

  const generatePosition = (string) => {
    const regExp = /Since\s(\d{4}-\d{2}-\d{2})/;
    const findLast = string.split(regExp);

    if (findLast[0] === "") {
      const date = `${findLast[1]} - Sin Baja`;
      return <Position title={findLast[2].trim()} date={date} />;
    }

    //Formato fecha: 000-00-00 - 0000-00-00
    const regExpDate1 =
      /(\d{4}(?:-\d{2}-\d{2})? - \d{4}(?:-\d{2}-\d{2})?) ([\w\s]+(?:[\w\s]+)?)/g;
    const segments = string.split(regExpDate1);
    if (segments[0] === "") {
      return <Position title={segments[2].trim()} date={segments[1].trim()} />;
    }

    //Formato fecha: 0000-0000
    const regExpDate2 = /([\w\s]+)\s(\d{4}-\d{4})/;
    const segments1 = string.split(regExpDate2);
    if (segments1[0] === "") {
      return (
        <Position title={segments1[1].trim()} date={segments1[2].trim()} />
      );
    }
  };

  return (
    <Accordion
      key="pep-sub"
      expanded={expanded}
      onChange={!expanded ? handleChange("pep-sub") : handleChange("")}
      className="reporteDataAccordion"
      style={{ minHeight: "none", paddingTop: "16px" }}
    >
      <AccordionSummary
        aria-controls="pep-sub-content"
        id="pep-sub-header"
        className="reporteDataAccordion__title--blue"
      >
        <div className="reporteDataAccordion__title__text--blue">
          <button>
            {!expanded ? (
              <div className="icon-down reporteDataAccordion__title__icon color--dodger-blue"></div>
            ) : (
              <div className="icon-expand-menos reporteDataAccordion__title__icon color--dodger-blue"></div>
            )}
          </button>
          <h3 className="text-2--bold color--dodger-blue">
            {t("pep_sub_drop_title")}
          </h3>
        </div>
      </AccordionSummary>
      <AccordionDetails
        style={{
          maxHeight: "335px",
          overflowY: "scroll",
        }}
        sx={{ transition: "height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;" }}
      >
        {detailsList?.map((el, i) => {
          const {
            positions,
            links,
            date_of_birth,
            last_names,
            gender,
            citizenship,
            description,
            source_type,
            given_names,
            occupations,
            spouse,
            place_of_birth,
            entity_type,
            children,
            end_date,
            institution,
            position,
            start_date,
            state,
          } = el;
          return (
            <div
              key={`pep-${i}`}
              className={`
                ${i < detailsList.length - 1 && "reporteDataAccordion__sub-pep"}
              `}
            >
              {position && (
                <Position
                  title={position}
                  date={`${start_date} - ${end_date}`}
                />
              )}
              {institution && (
                <DataItem title={t("pep_sub_drop_1")} text={institution} />
              )}
              {state && <DataItem title={t("pep_sub_drop_2")} text={state} />}
              {positions && positions.length > 0 && (
                <>
                  <h4 className="color--shuttle-gray text-2">
                    {t("pep_sub_drop_3")}
                  </h4>
                  {positions.map((position, index) => (
                    <div key={`position-${i}-${index}`}>
                      <div className="reporteDataAccordion__sub-pep__container">
                        {generatePosition(position)}
                      </div>
                    </div>
                  ))}
                  <div className="reporteDataAccordion__sub-pep__separator"></div>
                </>
              )}
              {links && links.length > 0 && (
                <>
                  <h4 className="color--shuttle-gray text-2">Links:</h4>
                  <div className="reporteDataAccordion__sub-pep__container">
                    {links.map((link, index) => (
                      <a
                        className="text-2--bold color--shark reporteDataAccordion__sub-pep__link"
                        href={link}
                        rel="noreferrer"
                        target="_blank"
                        key={`link-${i}-${index}`}
                      >
                        {link}
                      </a>
                    ))}
                  </div>
                  <div className="reporteDataAccordion__sub-pep__separator"></div>
                </>
              )}
              {date_of_birth && date_of_birth.length > 0 && (
                <DataItem
                  title={t("dm_report_doc_4")}
                  text={date_of_birth.join(", ")}
                />
              )}
              {last_names && last_names.length > 0 && (
                <DataItem
                  title={t("pep_sub_drop_4")}
                  text={last_names.join(" ")}
                />
              )}
              {gender && gender !== "UNKNOWN" && (
                <DataItem title={t("dm_report_doc_5")} text={gender} />
              )}
              {citizenship && citizenship.length > 0 && (
                <DataItem
                  title={t("pep_sub_drop_5")}
                  text={citizenship.join(", ")}
                />
              )}
              {description && description.length > 0 && (
                <DataItem
                  title={t("pep_sub_drop_6")}
                  text={description.join(", ")}
                />
              )}
              {source_type && source_type !== "UNKNOWN" && (
                <DataItem title={t("pep_sub_drop_7")} text={source_type} />
              )}
              {given_names && given_names.length > 0 && (
                <DataItem
                  title={t("pep_sub_drop_8")}
                  text={given_names.join(", ")}
                />
              )}
              {occupations && occupations.length > 0 && (
                <DataItem
                  title={t("pep_sub_drop_9")}
                  text={occupations.join(", ")}
                />
              )}
              {spouse && spouse.length > 0 && (
                <DataItem
                  title={t("pep_sub_drop_10")}
                  text={spouse.join(" ")}
                />
              )}
              {place_of_birth && place_of_birth.length > 0 && (
                <DataItem
                  title={t("pep_sub_drop_11")}
                  text={place_of_birth.join(" ")}
                />
              )}
              {entity_type && entity_type !== "UNKNOWN" && (
                <DataItem title="Entidad" text={entity_type} />
              )}
              {children && children.length > 0 && (
                <div className="reporteDataAccordion__sub-pep__container--sub">
                  <h4 className="color--shuttle-gray text-2">
                    {t("pep_sub_drop_12")}
                  </h4>
                  <div className="reporteDataAccordion__sub-pep__container">
                    {children.map((child, index) => (
                      <p
                        className="text-2--bold color--shark reporteDataAccordion__sub-pep__link"
                        key={`children-${i}-${index}`}
                      >
                        {child}
                      </p>
                    ))}
                  </div>
                </div>
              )}
            </div>
          );
        })}
      </AccordionDetails>
    </Accordion>
  );
};
export default PepSubDropdown;
