import { useState } from "react";
import TextInput from "./TextInput";
import { CSSTransition } from "react-transition-group";
import SwitchComponent from "./SwitchComponent";
import { useTranslate } from "@tolgee/react";

const InputUserID = ({
  form,
  inputName,
  description,
  setValue,
  validate,
  validateErrorText,
}) => {
  const [checked, setChecked] = useState(false);
  const [display, setDisplay] = useState(false);
  const { t } = useTranslate();

  const handleChange = ({ target: { checked } }) => {
    if (checked) {
      setChecked(checked);
      setTimeout(() => {
        setDisplay(true);
      }, 0);
    } else {
      setDisplay(false);
      setTimeout(() => {
        setChecked(checked);
      }, 100);
    }
  };

  return (
    <div className="inputUserID">
      <SwitchComponent checked={checked} handleChange={handleChange} />
      <CSSTransition in={display} timeout={1000} classNames="input-slide">
        <div
          className="inputUserID__input"
          style={{ display: checked ? "block" : "none" }}
        >
          <TextInput
            form={form}
            inputName={inputName}
            inputLabel="user-id"
            description={description}
            setValue={setValue}
            validate={validate}
            validateErrorText={validateErrorText}
            hint={t("user_id_hint")}
          />
        </div>
      </CSSTransition>
    </div>
  );
};
export default InputUserID;
