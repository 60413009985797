import React, { useState, useEffect } from "react";
import { SketchPicker } from "react-color";
import { COLORS } from "../styles/utils/colors";

const ColorPicker = ({
  colorType,
  initialColor = COLORS.DODGER_BLUE,
  title,
  setColor,
  colorPickersToShow,
  handlePickerShowing,
}) => {
  const [colorValue, setColorValue] = useState({});
  const [hexValue, setHexValue] = useState(initialColor);
  const showPicker = colorPickersToShow.find(
    (picker) => picker.name === colorType
  ).show;

  useEffect(() => {
    const hexToRgba = (hex) => {
      hex = hex.replace("#", "");

      if (hex.length === 6) {
        hex += "FF";
      }

      let r = parseInt(hex.substring(0, 2), 16);
      let g = parseInt(hex.substring(2, 4), 16);
      let b = parseInt(hex.substring(4, 6), 16);
      let a = parseInt(hex.substring(6, 8), 16) / 255;

      return { r, g, b, a: a.toFixed(2) };
    };

    setColorValue(hexToRgba(initialColor));
  }, [initialColor]);

  useEffect(() => {
    setColor({
      color: rgbaToHex8(colorValue).substring(0, 9),
      name: colorType,
    });
  }, [colorValue]);

  const rgbaToHex8 = (rgba) => {
    const alphaHex = Math.round(rgba.a * 255)
      .toString(16)
      .padStart(2, "0");
    return `${hexValue}${alphaHex}`;
  };

  const rgbaToString = (rgba) => {
    return `rgba(${rgba.r}, ${rgba.g}, ${rgba.b}, ${rgba.a})`;
  };

  return (
    <div className="colorPicker">
      <span className="text-2 color--shuttle-gray">{title}</span>
      <div className="colorPicker__container">
        <div
          className="colorPicker__sample"
          style={{ backgroundColor: rgbaToString(colorValue) }}
          onClick={() => handlePickerShowing(colorType, !showPicker)}
        ></div>
        <span className="colorPicker__value text-2 color--shuttle-gray">
          {rgbaToHex8(colorValue).substring(0, 9)}
        </span>
      </div>
      {showPicker && (
        <div className="colorPicker__picker-container">
          <SketchPicker
            color={colorValue}
            onChangeComplete={(colorEvent) => {
              setColorValue(colorEvent.rgb);
              setHexValue(colorEvent.hex);
            }}
          />
        </div>
      )}
    </div>
  );
};

export default ColorPicker;
