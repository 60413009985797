import img from "../../styles/images/EmptyState.svg";
import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { PRIVATE_ROUTES } from "./routes";
import { T } from "@tolgee/react";

const Page404 = () => {
  const [counter, setCounter] = useState(3);

  useEffect(() => {
    const interval = setInterval(() => {
      setCounter((prev) => prev - 1);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  if (counter === 0) {
    return <Navigate to={PRIVATE_ROUTES.DASHBOARD} />;
  }

  return (
    <section className="page404">
      <div className="page404__container">
        <img src={img} alt="error" className="page404__img" />
        <h1 className="heading-1 color--gulf-blue ">404</h1>
        <h2 className="heading-2">
          <T keyName="not_found_title" />
        </h2>
        <p className="text-1">
          <T keyName="not_found_text1" />
          {counter}
          <T keyName="not_found_text2" />
        </p>
      </div>
    </section>
  );
};
export default Page404;
