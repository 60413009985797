import ModalContainerWarning from "./ModalContainerWarning";

const Modal = ({
  logout,
  setShowModal,
  regenerar,
  password,
  delUser,
  addUser,
  handleConfirm,
  generateLink,
  handleCreate,
  shareLink,
  startBatchProcess,
  imagesMissing,
}) => {
  return (
    <section className={`modal ${logout && "modal--logout"}`}>
      <div className="modal--center-container">
        {logout && <ModalContainerWarning logout={logout} />}
        {regenerar && (
          <ModalContainerWarning
            regenerar={regenerar}
            setShowModal={setShowModal}
            handleConfirm={handleConfirm}
          />
        )}
        {(delUser || addUser) && (
          <ModalContainerWarning
            delUser={delUser}
            addUser={addUser}
            setShowModal={setShowModal}
            handleConfirm={handleConfirm}
          />
        )}
        {password && (
          <ModalContainerWarning
            password={password}
            setShowModal={setShowModal}
          />
        )}
        {generateLink && (
          <ModalContainerWarning
            generateLink={generateLink}
            setShowModal={setShowModal}
            handleCreate={handleCreate}
          />
        )}
        {shareLink && (
          <ModalContainerWarning
            shareLink={shareLink}
            setShowModal={setShowModal}
          />
        )}
        {startBatchProcess && (
          <ModalContainerWarning
            startBatchProcess={startBatchProcess}
            setShowModal={setShowModal}
            imagesMissing={imagesMissing}
          />
        )}
      </div>
    </section>
  );
};
export default Modal;
