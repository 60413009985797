import NameBadge from "./NameBadge";

const TruncatedText = ({
  styles,
  text,
  icon,
  iconClass,
  nameBadge,
  textClass,
}) => {
  return (
    <div className="truncateText" style={{ ...styles }}>
      {icon && <span className={`${icon} ${iconClass}`}></span>}
      {nameBadge?.show && (
        <NameBadge name={nameBadge.name} color={nameBadge.color} />
      )}
      <span className={`truncateText__span ${textClass}`}>{text}</span>
    </div>
  );
};

export default TruncatedText;
