import { useTranslate } from "@tolgee/react";
import codeIcon from "../../styles/icons/images/code-tag.svg";
import landingLinkMobile from "../../styles/images/landing-link-mobile.webp";
import landingLinkPage from "../../styles/images/landing-link.webp";

const LandingLink = () => {
  const { t } = useTranslate();

  return (
    <section className="landing__link">
      <div className="landing__link__header">
        <h1>{t("landing_link_header")}</h1>
        <h2>{t("landing_link_description")}</h2>
      </div>
      <div className="landing__link__content">
        <div className="landing__link__content__card">
          <div className="landing__link__content__card__header">
            <div className="landing__link__content__card__header__code">
              <img
                src={codeIcon}
                alt="codeIcon"
              />
              <spam>{t("landing_link_code")}</spam>
            </div>
            <p>
              <strong>{t("landing_link_card1_title")}</strong> {t("landing_link_card1_text")}
            </p>
          </div>
          <img
            src={landingLinkPage}
            alt="landingLinkPage"
            className="landing__link__content__card__img"
          />
        </div>
        <div className="landing__link__content__card">
          <div className="landing__link__content__card__header">
            <div className="landing__link__content__card__header__code">
              <img
                src={codeIcon}
                alt="codeIcon"
              />
              <spam>{t("landing_link_code")}</spam>
            </div>
            <p>
              <strong>{t("landing_link_card2_title")}</strong> {t("landing_link_card2_text")}
            </p>
          </div>
          <img
            src={landingLinkMobile}
            alt="landingLinkMobile"
            className="landing__link__content__card__img"

          />
        </div>
      </div>

    </section>
  );
};

export default LandingLink;
