import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { T, useTolgee, useTranslate } from "@tolgee/react";
import InputRadio from "./InputRadio";
import { FRAUD_LABEL_ID } from "../reducers/userReducer";
import { useContext, useState } from "react";
import UserContext from "../contexts/UserContext";
import { rulesGroupText } from "../helpers/rulesGroupText";

const RulesAccordionItem = ({ ruleGroup }) => {
  const {
    setRule,
  } = useContext(UserContext);

  const { t } = useTranslate();

  const tolgee = useTolgee(["language"]);
  const isLangEs = tolgee.getLanguage() === "es-MX";
  const [translateText] = useState(rulesGroupText(ruleGroup.group_rule_id));

  const handleCheck = ({ target: { id } }) => {
    const dashIndex = id.indexOf("-");
    const inputName = id.substring(0, dashIndex);
    const objID = id.substring(dashIndex + 1);
    const isLabel = inputName !== "ignore";
    const active = isLabel;

    let newLabelID;
    if (inputName === FRAUD_LABEL_ID.ID_2_TEXT.front)
      newLabelID = FRAUD_LABEL_ID.ID_2;
    else if (inputName === FRAUD_LABEL_ID.ID_1_TEXT.front)
      newLabelID = FRAUD_LABEL_ID.ID_1;
    else if (!isLabel) {
      const rule = ruleGroup.items.find((rule) => rule.id.toString() === objID);
      newLabelID =
        rule.label === FRAUD_LABEL_ID.ID_1_TEXT.back
          ? FRAUD_LABEL_ID.ID_1
          : FRAUD_LABEL_ID.ID_2;
    }

    setRule({ id: objID, active, labelID: newLabelID });
  };

  return (
    <Accordion
    sx={{ 
      boxShadow: 'none',
      '&.Mui-expanded:before': {
        opacity: 1,
      }
    }}
    >
      <AccordionSummary
        expandIcon={<div className="icon-down reporteDataAccordion__title__icon"></div>}
        aria-controls="panel1-content"
        id="panel1-header"
        className="noCode__accordion__title"
        sx={{ padding: '1rem' }}
      >
        {t(translateText.title)}
      </AccordionSummary>
      <AccordionDetails>
        <div className="noCode__accordion__description">
          <p>{t(translateText.description)}</p>
          <ul className="noCode__accordion__description__list">
            {translateText.listItems.map((item) => (
              <li>{t(item)}</li>
            ))}
          </ul>
        </div>

        {/* table */}
        <table className="noCode__table">
          <thead>
            <tr>
              <th className="text-2--bold noCode__row--separator">
                <T keyName="no_code_rules_message" />
              </th>
              <th className="text-2--bold noCode__row--separator--center">
                <T keyName="no_code_category_ignore_text" />
              </th>
              <th className="text-2--bold noCode__row--separator--center">
                <T keyName="change_status_btn_review" />
              </th>
              <th className="text-2--bold noCode__row--separator--center">
                <T keyName="no_code_category_reject_text" />
              </th>
            </tr>
          </thead>
          <tbody>
            {ruleGroup?.items.map((el, i) => {
              const {
                id,
                label,
                message_es,
                message_en,
                formData,
              } = el;
              const last = i === ruleGroup.length - 1;

              return (
                <tr key={`rule-${id}`}>
                  <td className={`noCode__row${last ? "" : "--separator"}`}>
                    {isLangEs ? message_es : message_en}
                  </td>
                  <td
                    className={`noCode__row${
                      last ? "--center" : "--separator--center"
                    }`}
                  >
                    <InputRadio
                      color="blue"
                      name={`group-${i}`}
                      checked={formData["ignore"]}
                      handleCheck={handleCheck}
                      value={label}
                      id={`ignore-${id}`}
                    />
                  </td>
                  <td
                    className={`noCode__row${
                      last ? "--center" : "--separator--center"
                    }`}
                  >
                    <InputRadio
                      color="blue"
                      name={`group-${i}`}
                      checked={formData["review"]}
                      handleCheck={handleCheck}
                      value={label}
                      id={`review-${id}`}
                    />
                  </td>
                  <td
                    className={`noCode__row${
                      last ? "--center" : "--separator--center"
                    }`}
                  >
                    <InputRadio
                      color="blue"
                      name={`group-${i}`}
                      checked={formData["reject"]}
                      handleCheck={handleCheck}
                      value={label}
                      id={`reject-${id}`}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </AccordionDetails>
    </Accordion>
  )
}

export default RulesAccordionItem;