import { createContext, useContext, useMemo, useReducer } from "react";
import { userReducer } from "../reducers/userReducer";
import { TYPES } from "../actions/userActions";
import { generateRequest } from "../helpers/helpHttp";
import { url } from "./domain";
import SnackBarContext from "./SnackBarContext";
import AuthContext from "./AuthContext";
import { changeHeadersHelper } from "../helpers/changeHeadersHelper";
import { sorting } from "../helpers/sorting";
import {
  SNACKBAR_STATES_NAMES,
  SNACKBAR_TEXTS,
  SNACKBAR_TEXTS_EN,
} from "../states/contexts/SnackContext";
import { initialState } from "../states/contexts/UserContext";
import { useNotifications } from "../hooks/useNotifications";
import moment from "moment";
import { saveMetadata } from "../helpers/saveMetadata";
import { ROLES } from "../reducers/authReducer";
import { initialNotifications } from "../states/contexts/AuthContext";
import { useTolgee } from "@tolgee/react";

export const USER_ERRORS = {
  UNAUTHORIZED: "No tienes acceso a esta información",
  NO_USERS:
    "Lo sentimos. No se pudo cargar el listado de miembros. Vuelve a intentarlo más tarde",
};

const UserContext = createContext();

const UserProvider = ({ children }) => {
  const { terminateSession, updateUser, user, invalidSession } =
    useContext(AuthContext);
  const { setSnack } = useContext(SnackBarContext);
  const [state, dispatch] = useReducer(userReducer, initialState);
  const { snackbarList, addSnackbarToList, removeSnackbarFromList } =
    useNotifications();
  const tolgee = useTolgee(["language"]);
  const isLangEs = tolgee.getLanguage() === "es-MX";

  const {
    changePassError,
    changePassLoading,
    usersList,
    usersToShow,
    requestStartOn,
    usersLoading,
    usersError,
    scrollLoader,
    headers,
    noUsers,
    userToDelete,
    deleteUserLoading,
    deleteUserError,
    userToAdd,
    addUserLoading,
    addUserError,
    updateUserLoading,
    apiKey,
    loadingRules,
    errorRules,
    rules,
    rulesToShow,
    rulesToHire,
    showFilters,
  } = state;

  const changePass = async (formToValidate, datos, showModal) => {
    const validateForm = (formToValidate) => {
      return formToValidate.find((el) => !el.valid);
    };

    const handleInvalidForm = () => {
      const err = {
        err: true,
        errText: isLangEs
          ? "Revisar los datos ingresados"
          : "Make sure you have entered valid data",
      };

      dispatch({
        type: TYPES.CHANGE_PASS_PERFIL_ERROR,
        payload: err,
      });
    };

    const createDatosRequest = (datos) => ({
      url: `${url}/profile`,
      options: {
        headers: {
          "content-type": "application/json",
        },
        body: {
          password: datos.new_password1,
          old_password: datos.password,
        },
      },
      terminateSession,
      invalidSession,
    });

    const handleSuccess = (data) => {
      const snackState = {
        show: true,
        text: isLangEs
          ? SNACKBAR_TEXTS.PASS_CHANGE_TEXT
          : SNACKBAR_TEXTS_EN.PASS_CHANGE_TEXT,
      };
      setSnack(SNACKBAR_STATES_NAMES.PERFIL_PASS_CHANGE, snackState);
      showModal(false);
      updateUser(data);
      dispatch({
        type: TYPES.CHANGE_PASS_PERFIL,
      });
    };

    const handleError = (error) => {
      const snackState = {
        show: true,
        error: {
          err: true,
          errText:
            error.response_status === 400
              ? isLangEs
                ? SNACKBAR_TEXTS.PASS_CHANGE_ERROR
                : SNACKBAR_TEXTS_EN.PASS_CHANGE_ERROR
              : isLangEs
              ? SNACKBAR_TEXTS.ERROR_TEXT
              : SNACKBAR_TEXTS_EN.ERROR_TEXT,
          errTextTitle: isLangEs
            ? SNACKBAR_TEXTS.ERROR_TITLE
            : SNACKBAR_TEXTS_EN.ERROR_TITLE,
        },
      };

      setSnack(SNACKBAR_STATES_NAMES.PERFIL_PASS_CHANGE, snackState);
      showModal(false);
      dispatch({
        type: TYPES.CHANGE_PASS_PERFIL_ERROR,
      });
    };

    const invalid = validateForm(formToValidate);
    if (invalid) {
      handleInvalidForm();
      return;
    }

    dispatch({
      type: TYPES.CHANGE_PASS_PERFIL_LOADING,
    });

    try {
      const datosRequest = createDatosRequest(datos);
      const data = await generateRequest.patch(datosRequest);

      if (data.err) throw data;

      handleSuccess(data);
    } catch (error) {
      handleError(error);
    }
  };

  const changeHeaders = (title, sortedBy) => {
    const newHeaders = changeHeadersHelper(headers, title, sortedBy);

    dispatch({
      type: TYPES.CHANGE_HEADERS,
      payload: newHeaders,
    });
  };

  const createTableData = (dataObj) => {
    const data = dataObj.data;
    const userSearched = dataObj.serviceSearched || "";

    dispatch({
      type: TYPES.CREATE_TABLE_DATA,
      payload: {
        data,
        search: userSearched,
      },
    });
  };

  const setLoader = (type) =>
    dispatch({
      type: type,
    });

  const getLastsUsers = async (requestStartOn, noLoader) => {
    const setLoaderState = (noLoader) => {
      if (!noLoader) setLoader(TYPES.GET_USERS_LOADING);
      else setLoader(TYPES.SCROLL_LOADER);
    };

    const createDatosRequest = () => ({
      url: `${url}/admin/users`,
      terminateSession,
      invalidSession,
    });

    const handleSuccess = (data, requestStartOn) => {
      const payload = {
        data: data.users,
        requestStartOn,
        userSearched: "",
      };
      dispatch({
        type: TYPES.GET_USERS,
        payload,
      });
    };

    const handleError = (error) => {
      const { status } = error;
      const err = {
        ...error,
        errText:
          status === 404 ? USER_ERRORS.UNAUTHORIZED : USER_ERRORS.NO_USERS,
      };

      dispatch({
        type: TYPES.GET_USERS_ERROR,
        payload: err,
      });
    };

    setLoaderState(noLoader);

    try {
      const datosRequest = createDatosRequest();
      const data = await generateRequest.get(datosRequest);

      if (data.err) throw data;

      handleSuccess(data, requestStartOn);
    } catch (error) {
      handleError(error);
    }
  };

  const searchBy = ({ e, value }) => {
    e.preventDefault();

    const handleEmptyValue = () => {
      createTableData({ data: usersList });
    };

    const filterUsers = (value) => {
      return usersToShow.filter((user) => {
        const name = `${user.first_name} ${user.last_name}`.toLowerCase();
        return name.includes(value);
      });
    };

    const handleNoResults = () => {
      dispatch({
        type: TYPES.NO_USERS,
        payload: isLangEs
          ? "No hay resultados para la búsqueda"
          : "There are no results to show",
      });
    };

    const handleResults = (newUsersToShow) => {
      createTableData({ data: newUsersToShow });
    };

    if (value === "" || typeof value === "undefined") {
      handleEmptyValue();
      return;
    }

    const newUsersToShow = filterUsers(value);

    if (newUsersToShow.length === 0) {
      handleNoResults();
    } else {
      handleResults(newUsersToShow);
    }
  };

  const sortBy = (order, type, key) => {
    const inUsers = true;
    const newDataToShow = sorting(usersToShow, order, type, key, inUsers);
    createTableData({ data: newDataToShow });
  };

  const setUserToDelete = (id) => {
    dispatch({
      type: TYPES.SET_USER_TO_DELETE,
      payload: id,
    });
  };

  const setUserToAdd = (user) => {
    dispatch({
      type: TYPES.SET_USER_TO_ADD,
      payload: user,
    });
  };

  const addUser = async (user, showModal) => {
    const setLoaderState = () => {
      setLoader(TYPES.ADD_USER_LOADING);
    };

    const extractEmail = (emails) => {
      return emails.split(",")[0].trim(); // Por el momento solo se puede hacer con uno
    };

    const createDatosRequest = (email, role_id) => ({
      url: `${url}/admin/user/invite`,
      options: {
        headers: {
          "content-type": "application/json",
        },
        body: {
          email,
          role_id,
        },
      },
      terminateSession,
      invalidSession,
    });

    const handleSuccess = (data) => {
      const newUsersToShow = [...usersToShow, data.user];
      const snackState = {
        show: true,
        text: isLangEs
          ? SNACKBAR_TEXTS.ADD_USER_TEXT
          : SNACKBAR_TEXTS_EN.ADD_USER_TEXT,
      };
      setSnack(SNACKBAR_STATES_NAMES.ADD_USER, snackState);
      showModal(false);
      dispatch({
        type: TYPES.ADD_USER,
        payload: newUsersToShow,
      });
    };

    const handleError = (error) => {
      const errText =
        error.arrErrors &&
        error.arrErrors[0].message === "The email is registered"
          ? isLangEs
            ? "El mail ya se encuentra registrado"
            : "The email is registered"
          : isLangEs
          ? SNACKBAR_TEXTS.ADD_USER_ERROR
          : SNACKBAR_TEXTS_EN.ADD_USER_ERROR;

      const snackState = {
        show: true,
        error: {
          err: true,
          errText,
          errTextTitle: isLangEs
            ? SNACKBAR_TEXTS.ERROR_TITLE
            : SNACKBAR_TEXTS_EN.ERROR_TITLE,
        },
      };

      setSnack(SNACKBAR_STATES_NAMES.ADD_USER, snackState);
      showModal(false);
      dispatch({
        type: TYPES.ADD_USER_ERROR,
      });
    };

    setLoaderState();
    const email = extractEmail(user.emails);
    const role_id = user.account_type;

    try {
      const datosRequest = createDatosRequest(email, role_id);
      const data = await generateRequest.post(datosRequest);

      if (data.arrErrors || data.err) throw data;

      handleSuccess(data);
    } catch (error) {
      handleError(error);
    }
  };

  const deleteUser = async (userID, showModal) => {
    const setLoaderState = () => {
      setLoader(TYPES.DELETE_USER_LOADING);
    };

    const createDatosRequest = (userID) => ({
      url: `${url}/admin/user/${userID}/deactivate`,
      terminateSession,
      invalidSession,
    });

    const handleSuccess = (data) => {
      const newUsersToShow = usersToShow.filter((user) => user.id !== data.id);

      const snackState = {
        show: true,
        text: isLangEs
          ? SNACKBAR_TEXTS.DELETE_USER_TEXT
          : SNACKBAR_TEXTS_EN.DELETE_USER_TEXT,
      };
      setSnack(SNACKBAR_STATES_NAMES.DELETE_USER, snackState);

      showModal(false);

      dispatch({
        type: TYPES.DELETE_USER,
        payload: newUsersToShow,
      });
    };

    const handleError = () => {
      const snackState = {
        show: true,
        error: {
          err: true,
          errText: isLangEs
            ? SNACKBAR_TEXTS.ERROR_TEXT
            : SNACKBAR_TEXTS_EN.ERROR_TEXT,
          errTextTitle: isLangEs
            ? SNACKBAR_TEXTS.ERROR_TITLE
            : SNACKBAR_TEXTS_EN.ERROR_TITLE,
        },
      };

      setSnack(SNACKBAR_STATES_NAMES.DELETE_USER, snackState);

      showModal(false);

      dispatch({
        type: TYPES.DELETE_USER_ERROR,
      });
    };

    setLoaderState();

    try {
      const datosRequest = createDatosRequest(userID);
      const data = await generateRequest.put(datosRequest);

      if (data.err) throw data.err;

      handleSuccess(data);
    } catch (error) {
      handleError();
    }
  };

  const changeUserType = async (userID, newRoleID) => {
    const setLoaderState = () => {
      setLoader(TYPES.GET_USERS_LOADING);
    };

    const createDatosRequest = (userID, newRoleID) => ({
      url: `${url}/admin/user/${userID}`,
      options: {
        headers: {
          "content-type": "application/json",
        },
        body: {
          role_id: newRoleID,
        },
      },
      terminateSession,
      invalidSession,
    });

    const handleSuccess = (data) => {
      const newUsersToShow = usersToShow.map((user) =>
        user.id === data.id ? data : user
      );

      const snackState = {
        show: true,
        text: isLangEs
          ? SNACKBAR_TEXTS.UPDATE_USER_TEXT
          : SNACKBAR_TEXTS_EN.UPDATE_USER_TEXT,
      };
      setSnack(SNACKBAR_STATES_NAMES.UPDATE_USER, snackState);

      dispatch({
        type: TYPES.UPDATE_USER_TYPE,
        payload: newUsersToShow,
      });
    };

    const handleError = () => {
      const snackState = {
        show: true,
        error: {
          err: true,
          errText: isLangEs
            ? SNACKBAR_TEXTS.ERROR_TEXT
            : SNACKBAR_TEXTS_EN.ERROR_TEXT,
          errTextTitle: isLangEs
            ? SNACKBAR_TEXTS.ERROR_TITLE
            : SNACKBAR_TEXTS_EN.ERROR_TITLE,
        },
      };

      setSnack(SNACKBAR_STATES_NAMES.UPDATE_USER, snackState);

      dispatch({
        type: TYPES.UPDATE_USER_TYPE_ERROR,
      });
    };

    setLoaderState();

    try {
      const datosRequest = createDatosRequest(userID, newRoleID);
      const data = await generateRequest.patch(datosRequest);

      if (data.err) throw data.err;

      handleSuccess(data);
    } catch (error) {
      handleError();
    }
  };

  const changeUserData = async (datos) => {
    const setLoaderState = () => {
      setLoader(TYPES.UPDATE_USER_DATA_LOADING);
    };

    const createDatosRequest = (datos) => ({
      url: `${url}/profile`,
      options: {
        headers: {
          "content-type": "application/json",
        },
        body: datos,
      },
      terminateSession,
      invalidSession,
    });

    const handleSuccess = (data) => {
      const snackState = {
        show: true,
        text: isLangEs
          ? SNACKBAR_TEXTS.UPDATE_USER_TEXT
          : SNACKBAR_TEXTS_EN.UPDATE_USER_TEXT,
      };
      setSnack(SNACKBAR_STATES_NAMES.UPDATE_USER, snackState);

      const updateRoleName = {
        ...data,
        role:
          data.role === ROLES.OLD.OWNER
            ? ROLES.PROPIETARIO
            : data.role === ROLES.OLD.ADMIN
            ? ROLES.ADMIN
            : data.role === ROLES.OLD.USER
            ? ROLES.ANALISTA
            : data.role,
      };

      updateUser(updateRoleName);
      dispatch({
        type: TYPES.UPDATE_USER_DATA,
      });
    };

    const handleError = () => {
      const snackState = {
        show: true,
        error: {
          err: true,
          errText: isLangEs
            ? SNACKBAR_TEXTS.ERROR_TEXT
            : SNACKBAR_TEXTS_EN.ERROR_TEXT,
          errTextTitle: isLangEs
            ? SNACKBAR_TEXTS.ERROR_TITLE
            : SNACKBAR_TEXTS_EN.ERROR_TITLE,
        },
      };

      setSnack(SNACKBAR_STATES_NAMES.UPDATE_USER, snackState);
      dispatch({
        type: TYPES.UPDATE_USER_DATA_ERROR,
      });
    };

    setLoaderState();

    try {
      const datosRequest = createDatosRequest(datos);
      const data = await generateRequest.patch(datosRequest);

      if (data.err) throw data.err;

      handleSuccess(data);
    } catch (error) {
      handleError();
    }
  };

  const getAPIKey = async () => {
    const createDatosRequest = () => ({
      url: `${url}/admin/company`,
      terminateSession,
      invalidSession,
    });

    const handleSuccess = (data) => {
      dispatch({
        type: TYPES.GET_API_KEY,
        payload: data.api_key,
      });
    };

    const handleError = () => {
      dispatch({
        type: TYPES.GET_API_KEY,
        payload: isLangEs
          ? "No pudimos obtener el dato"
          : "We could not find the API key",
      });
    };

    try {
      const datosRequest = createDatosRequest();
      const data = await generateRequest.get(datosRequest);

      if (data.err) throw data;

      handleSuccess(data);
    } catch (error) {
      handleError();
    }
  };

  const transferOwnership = async (newOwnerID) => {
    const setLoaderState = () => {
      setLoader(TYPES.GET_USERS_LOADING);
    };

    const createDatosRequestNewOwner = (newOwnerID) => ({
      url: `${url}/admin/user/${newOwnerID}/transfer-owner`,
      terminateSession,
      invalidSession,
    });

    const createDatosRequestOldOwner = () => ({
      url: `${url}/profile`,
      terminateSession,
      invalidSession,
    });

    const handleSuccess = (newOwner, oldOwner) => {
      updateUser(oldOwner);

      const newUsersToShow = usersToShow.map((user) =>
        user.id === newOwner.id
          ? newOwner
          : user.id === oldOwner.id
          ? oldOwner
          : user
      );

      const snackState = {
        show: true,
        text: isLangEs
          ? SNACKBAR_TEXTS.UPDATE_USER_TEXT
          : SNACKBAR_TEXTS_EN.UPDATE_USER_TEXT,
      };
      setSnack(SNACKBAR_STATES_NAMES.UPDATE_USER, snackState);

      dispatch({
        type: TYPES.UPDATE_USER_TYPE,
        payload: newUsersToShow,
      });
    };

    const handleError = () => {
      const snackState = {
        show: true,
        error: {
          err: true,
          errText: isLangEs
            ? SNACKBAR_TEXTS.ERROR_TEXT
            : SNACKBAR_TEXTS_EN.ERROR_TEXT,
          errTextTitle: isLangEs
            ? SNACKBAR_TEXTS.ERROR_TITLE
            : SNACKBAR_TEXTS_EN.ERROR_TITLE,
        },
      };

      setSnack(SNACKBAR_STATES_NAMES.UPDATE_USER, snackState);

      dispatch({
        type: TYPES.UPDATE_USER_TYPE_ERROR,
      });
    };

    setLoaderState();

    try {
      const datosRequestNewOwner = createDatosRequestNewOwner(newOwnerID);
      const newOwner = await generateRequest.post(datosRequestNewOwner);

      if (newOwner.err) throw newOwner.err;

      const datosRequestOldOwner = createDatosRequestOldOwner();
      const oldOwner = await generateRequest.get(datosRequestOldOwner);

      handleSuccess(newOwner, oldOwner);
    } catch (error) {
      handleError();
    }
  };

  const updateWalkthroughState = (newState) => {
    const createNewMeta = (newState) => ({
      metadata: {
        walkthrough: {
          "busqueda.form": newState.metadata.walkthrough.busqueda.form,
          "busqueda.report": newState.metadata.walkthrough.busqueda.report,
          "consultas.consultas": newState.metadata.walkthrough.consultas,
          "dashboard.dashboard": newState.metadata.walkthrough.dash,
        },
      },
    });

    const createDatosRequest = (newMeta) => ({
      url: `${url}/profile`,
      options: {
        headers: {
          "content-type": "application/json",
        },
        body: newMeta,
      },
      terminateSession,
      invalidSession,
    });

    const sendPatchRequest = async (datosRequest) => {
      try {
        await generateRequest.patch(datosRequest);
      } catch (error) {
        console.error("Error updating walkthrough state:", error);
      }
    };

    const newMeta = createNewMeta(newState);
    const datosRequest = createDatosRequest(newMeta);

    sendPatchRequest(datosRequest);
    updateUser(newState);
    saveMetadata({
      metadata: { walkthrough: { ...newState.metadata.walkthrough } },
    });
  };

  const filtersToShow = (filters) => {
    const createNewState = (filters) => {
      return {
        document_image: filters.find((el) => el.name === "document_image")?.show || false,
        rfc: filters.find((el) => el.name === "rfc")?.show || false,
        curp: filters.find((el) => el.name === "curp")?.show || false,
        ip: filters.find((el) => el.name === "ip")?.show || false,
        email: filters.find((el) => el.name === "email")?.show || false,
        image: filters.find((el) => el.name === "image")?.show || false,
        address: filters.find((el) => el.name === "address")?.show || false,
        phone_number: filters.find((el) => el.name === "phone_number")?.show || false,
        location: filters.find((el) => el.name === "location")?.show || false,
        document_image_back: filters.find(
          (el) => el.name === "document_image_back"
        )?.show || false,
        // device: filters.find(el => el.name === "device").show,
      };
    };

    const createNewMeta = (newState) => {
      return {
        metadata: {
          filters: {
            "filter.document_image": newState.document_image,
            "filter.rfc": newState.rfc,
            "filter.curp": newState.curp,
            "filter.ip": newState.ip,
            "filter.email": newState.email,
            "filter.image": newState.image,
            "filter.address": newState.address,
            "filter.phone_number": newState.phone_number,
            "filter.location": newState.location,
            "filter.document_image_back": newState.document_image_back,
            // "filter.device": newState.device,
          },
        },
      };
    };

    const createRequestData = (newMeta) => ({
      url: `${url}/profile`,
      options: {
        headers: {
          "content-type": "application/json",
        },
        body: newMeta,
      },
      terminateSession,
      invalidSession,
    });

    const updateMetadata = (newState) => {
      saveMetadata({ metadata: { filters: { ...newState } } });
    };

    const newState = createNewState(filters);
    const newMeta = createNewMeta(newState);
    const requestData = createRequestData(newMeta);

    generateRequest.patch(requestData);
    updateUser(newState);
    updateMetadata(newState);

    dispatch({
      type: TYPES.SET_FILTERS,
      payload: filters,
    });
  };

  const getRules = async () => {
    const setLoaderState = () => {
      setLoader(TYPES.LOADING_RULES);
    };

    const createDatosRequest = () => ({
      url: `${url}/admin/company/fraud-rules`,
      terminateSession,
      invalidSession,
    });

    const handleSuccess = (data) => {
      dispatch({
        type: TYPES.GET_RULES,
        payload: data.data,
      });
    };

    const handleError = (error) => {
      dispatch({
        type: TYPES.RULES_ERROR,
        payload: {
          ...error,
          message: isLangEs
            ? "No pudimos cargar las reglas"
            : "The rules could not be loaded correctly",
        },
      });
    };

    setLoaderState();

    try {
      const datosRequest = createDatosRequest();
      const data = await generateRequest.get(datosRequest);

      if (data.err) throw data;

      handleSuccess(data);
    } catch (error) {
      handleError(error);
    }
  };

  const setRule = async ({ id, active, labelID }) => {
    const createDatosRequest = (id, active, labelID) => ({
      url: `${url}/admin/company/fraud-rules/${id}`,
      options: {
        headers: {
          "content-type": "application/json",
        },
        body: {
          active,
          fraud_label_id: labelID,
        },
      },
      terminateSession,
      invalidSession,
    });

    const handleSuccess = (data) => {
      dispatch({
        type: TYPES.SET_RULE,
        payload: {
          rules: rulesToShow,
          updatedRule: data,
        },
      });

      const snackState = {
        show: true,
        text: isLangEs
          ? SNACKBAR_TEXTS.SET_RULES_TEXT
          : SNACKBAR_TEXTS_EN.SET_RULES_TEXT,
      };
      setSnack(SNACKBAR_STATES_NAMES.UPDATE_USER, snackState);
    };

    const handleError = () => {
      const snackState = {
        show: true,
        error: {
          err: true,
          errText: isLangEs
            ? SNACKBAR_TEXTS.SET_RULES_ERROR
            : SNACKBAR_TEXTS_EN.SET_RULES_ERROR,
          errTextTitle: isLangEs
            ? SNACKBAR_TEXTS.ERROR_TITLE
            : SNACKBAR_TEXTS_EN.ERROR_TITLE,
        },
      };

      setSnack(SNACKBAR_STATES_NAMES.SET_RULES, snackState);

      dispatch({
        type: TYPES.RULES_ERROR,
      });
    };

    try {
      const datosRequest = createDatosRequest(id, active, labelID);
      const data = await generateRequest.post(datosRequest);

      if (data.err) throw data;

      handleSuccess(data);
    } catch (error) {
      handleError();
    }
  };

  const markNotificationAsSeen = async (id) => {
    const findNotificationToUpdate = (id) => {
      return user.notifications.list.find((el) => el.id === id);
    };

    const createDatosRequest = (id) => ({
      url: `${url}/profile/notification/${id}`,
      terminateSession,
      invalidSession,
    });

    const updateNotificationList = (id) => {
      return user.notifications.list.map((el) =>
        el.id === id ? { ...el, active: false } : el
      );
    };

    const createNewUserState = (newNotificationsList) => {
      const notifications = {
        list: newNotificationsList,
        not_seen: user.notifications.not_seen - 1,
      };

      return {
        ...user,
        notifications,
      };
    };

    const notificationToUpdate = findNotificationToUpdate(id);

    if (!notificationToUpdate.active) return;

    const datosRequest = createDatosRequest(id);
    await generateRequest.post(datosRequest);

    const newNotificationsList = updateNotificationList(id);
    const newUserState = createNewUserState(newNotificationsList);

    saveMetadata(newUserState.notifications);
    updateUser(newUserState);
  };

  const getStorageNotifications = (userNotifications) => {
    const getNotificationsFromStorage = () => {
      const storedMetadata = sessionStorage.getItem("metadata");
      if (storedMetadata) {
        return JSON.parse(storedMetadata).notifications;
      }
      return null;
    };

    const notificationsInStorage = getNotificationsFromStorage();
    return userNotifications || notificationsInStorage || initialNotifications;
  };

  const updateNotifications = ({ list, notSeen, newNotification }) => {
    const calculateNotSeenNotifications = (notSeen) => {
      return notSeen + 1 > 99 ? "99+" : notSeen + 1;
    };

    const createNotificationsList = (newNotification, list) => {
      return [newNotification, ...list];
    };

    const notSeenNotifications = calculateNotSeenNotifications(notSeen);
    const notificationsList = createNotificationsList(newNotification, list);

    return {
      notSeenNotifications,
      notificationsList,
    };
  };

  const showNewNotification = (newNotification) => {
    const createNewNotification = (notification) => ({
      ...notification,
      created_on: moment().utc().local().format("DD MMM. YYYY, h:mm:ss A"),
      active: true,
    });

    const getUpdatedNotifications = (newNotification) => {
      const userNotifications = user.notifications;
      const notificationsInStorage = getStorageNotifications(userNotifications);

      return updateNotifications({
        list: notificationsInStorage.list,
        notSeen: notificationsInStorage.not_seen,
        newNotification,
      });
    };

    const updateUserState = (notifications) => {
      const newUserState = {
        ...user,
        notifications,
      };

      saveMetadata(notifications);
      updateUser(newUserState);
    };

    newNotification = createNewNotification(newNotification);

    const { notSeenNotifications, notificationsList } =
      getUpdatedNotifications(newNotification);

    const notifications = {
      list: notificationsList,
      not_seen: notSeenNotifications,
    };

    updateUserState(notifications);

    addSnackbarToList(newNotification);
  };

  const resentInvite = async (userID) => {
    const createDatosRequest = (userID) => ({
      url: `${url}/admin/user/${userID}/invite`,
      options: {
        headers: {
          "content-type": "application/json",
        },
        body: {},
      },
      terminateSession,
      invalidSession,
    });

    const handleSuccess = () => {
      const snackState = {
        show: true,
        text: isLangEs
          ? SNACKBAR_TEXTS.RESENT_INVITE_TEXT
          : SNACKBAR_TEXTS_EN.RESENT_INVITE_TEXT,
      };
      setSnack(SNACKBAR_STATES_NAMES.RESENT_INVITE, snackState);
    };

    const handleError = () => {
      const errText = isLangEs
        ? SNACKBAR_TEXTS.RESENT_INVITE_ERROR
        : SNACKBAR_TEXTS_EN.RESENT_INVITE_ERROR;

      const snackState = {
        show: true,
        error: {
          err: true,
          errText,
          errTextTitle: isLangEs
            ? SNACKBAR_TEXTS.ERROR_TITLE
            : SNACKBAR_TEXTS_EN.ERROR_TITLE,
        },
      };

      setSnack(SNACKBAR_STATES_NAMES.ADD_USER, snackState);
    };

    try {
      const datosRequest = createDatosRequest(userID);
      const data = await generateRequest.post(datosRequest);

      if (data.arrErrors || data.err) throw data;

      handleSuccess();
    } catch (error) {
      handleError();
    }
  };

  const data = useMemo(
    () => ({
      changePass,
      changePassError,
      changePassLoading,
      usersList,
      usersToShow,
      requestStartOn,
      usersLoading,
      usersError,
      scrollLoader,
      getLastsUsers,
      searchBy,
      sortBy,
      headers,
      changeHeaders,
      createTableData,
      noUsers,
      deleteUser,
      userToDelete,
      deleteUserLoading,
      deleteUserError,
      setUserToDelete,
      changeUserType,
      addUser,
      userToAdd,
      addUserError,
      addUserLoading,
      setUserToAdd,
      changeUserData,
      updateUserLoading,
      getAPIKey,
      apiKey,
      transferOwnership,
      updateWalkthroughState,
      loadingRules,
      errorRules,
      rules,
      setRule,
      getRules,
      rulesToShow,
      rulesToHire,
      filtersToShow,
      showFilters,
      snackbarList,
      showNewNotification,
      removeSnackbarFromList,
      markNotificationAsSeen,
      resentInvite,
    }),
    [
      changePass,
      changePassError,
      changePassLoading,
      usersList,
      usersToShow,
      requestStartOn,
      usersLoading,
      usersError,
      scrollLoader,
      getLastsUsers,
      searchBy,
      sortBy,
      headers,
      changeHeaders,
      createTableData,
      noUsers,
      deleteUser,
      userToDelete,
      deleteUserLoading,
      deleteUserError,
      setUserToDelete,
      changeUserType,
      addUser,
      userToAdd,
      addUserError,
      addUserLoading,
      setUserToAdd,
      changeUserData,
      updateUserLoading,
      getAPIKey,
      apiKey,
      transferOwnership,
      updateWalkthroughState,
      loadingRules,
      errorRules,
      rules,
      setRule,
      getRules,
      rulesToShow,
      rulesToHire,
      filtersToShow,
      showFilters,
      snackbarList,
      showNewNotification,
      removeSnackbarFromList,
      markNotificationAsSeen,
      resentInvite,
    ]
  );

  return <UserContext.Provider value={data}>{children}</UserContext.Provider>;
};

export { UserProvider };
export default UserContext;
