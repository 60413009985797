import { useContext, useEffect, useState } from "react";
import TabsButtons from "./TabsButtons";
import { changeSelection } from "../helpers/changeSelection";
import ReporteHeader from "./ReporteHeader";
import DecisionMakerReport from "./DecisionMakerReport";
import ReportContext from "../contexts/ReportContext";
import Loader from "./Loader";
import JSONReport from "./JSONReport";
import ReporteWarnings from "./ReporteWarnings";
import { FLAGS_NAMES } from "../states/contexts/ReportContext";
import { useTolgee } from "@tolgee/react";

const availableTabs = [
  {
    id: "tab-1",
    selected: true,
    text: "Resumen",
  },
  {
    id: "tab-2",
    selected: false,
    text: "JSON",
  },
];

const ReporteData = ({ closeReportForm }) => {
  const { report, loading, findReport } = useContext(ReportContext);
  
  const [tabs, setTabs] = useState(availableTabs);
  const [first, setFirst] = useState(true);
  const tolgee = useTolgee(["language"]);
  const isLangEs = tolgee.getLanguage() === "es-MX";

  const changeTab = (id) => changeSelection(id, tabs, setTabs);

  const translate = (text) => {
    if (isLangEs) return text;

    return text === availableTabs[0].text ? "Summary" : text;
  };

  useEffect(() => {
    if (first) {
      setFirst(false);
      return;
    }

    findReport({ idToSearch: report.id });
  }, [isLangEs]);

  return (
    <article className="reporte__container">
      {loading && <Loader />}
      {!loading && (
        <>
          <div className="reporte__container__header__container">
            <ReporteHeader closeReportForm={closeReportForm} />
            {!loading &&
              report.label !== FLAGS_NAMES.NO_THREAT &&
              report.label !== FLAGS_NAMES.NOT_FRAUD &&
              report.warnings &&
              report.warnings.length > 0 && (
                <ReporteWarnings warnings={report.warnings} />
              )}
            <section className="reporte__container__tabs">
              <div className="layout__main__section__buttons">
                {tabs.map((el, i) => (
                  <TabsButtons
                    key={el.id}
                    handleBtn={changeTab}
                    id={el.id}
                    active={el.selected}
                    text={translate(el.text)}
                    inlineStyles={
                      tabs.length - 1 === i ? { marginRight: 0 } : {}
                    }
                  />
                ))}
              </div>
            </section>
            <div className="reporte__container__header__container__separator"></div>
          </div>
          {tabs[0].selected && <DecisionMakerReport isLangEs={isLangEs} />}
          {tabs[1].selected && <JSONReport report={report.json} />}
        </>
      )}
    </article>
  );
};
export default ReporteData;
