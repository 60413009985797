import { debounce } from "./debounce";

export function createScrollFn({ container, prevScrollY, handleScroll }) {
  const handleScrollDebounced = debounce(handleScroll, 500);

  return function scrollFn() {
    const { scrollTop, scrollHeight, clientHeight } = container;
    const deltaY = scrollTop - prevScrollY.current;
    prevScrollY.current = scrollTop;
    const tolerance = 15;

    if (
      deltaY !== 0 &&
      Math.abs(scrollHeight - scrollTop - clientHeight) < tolerance
    ) {
      handleScrollDebounced();
    }
  };
}
