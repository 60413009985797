import { useContext, useEffect, useState } from "react";
import { changeSelection } from "../../helpers/changeSelection";
import TabsButtons from "../TabsButtons";
import Reporte from "../Reporte";
import ReportContext from "../../contexts/ReportContext";
import BtnFiltros from "../BtnFiltros";
import { useFormValidation } from "../../hooks/useFormValidation";
import { stepsOptions } from "./Dashboard";
import UserContext from "../../contexts/UserContext";
import IDForm from "./IDForm";
import BusquedaReportForm from "./BusquedaReportForm";
import {
  initialTabState,
  initialValidateForm,
  steps,
} from "../../states/components/Busqueda";
import { useLocation, useParams } from "react-router-dom";
import {
  SNACKBAR_STATES_NAMES,
  SNACKBAR_TEXTS,
  SNACKBAR_TEXTS_EN,
} from "../../states/contexts/SnackContext";
import SnackBarContext from "../../contexts/SnackBarContext";
import { COLORS } from "../../styles/utils/colors";
import iconFiltros from "../../styles/icons/images/icon-filtros.svg";
import SearchDetails from "../SearchDetails";
import CargaBatch from "./CargaBatch";
import { useTolgee, useTranslate } from "@tolgee/react";
import { getMetadataSaved } from "../../states/contexts/AuthContext";
import AuthContext from "../../contexts/AuthContext";

const delete9090 = ['phone_number', 'address', 'ip', 'rfc', 'email'];

const getSnackState = (isLangEs) => ({
  show: true,
  error: {
    err: true,
    errText: isLangEs
      ? SNACKBAR_TEXTS.SHOW_NOTIFICATION_REPORT_ERROR
      : SNACKBAR_TEXTS_EN.SHOW_NOTIFICATION_REPORT_ERROR,
    errTextTitle: isLangEs
      ? SNACKBAR_TEXTS.ERROR_TITLE
      : SNACKBAR_TEXTS_EN.ERROR_TITLE,
  },
});

const translateText = (text, isLangEs) => {
  if (isLangEs) return text;

  switch (text) {
    case initialTabState[0].text:
      return "New applicant search";
    case initialTabState[1].text:
      return "Search applicant by ID";
    case initialTabState[2].text:
      return "Batch applicants search";

    default:
      return text;
  }
};

const translateDescription = (description, tabs, isLangEs) => {
  if (isLangEs) return description;

  if (description === tabs[0].description)
    return "Organize and select the data according to your needs. No field is required";

  if (description === tabs[1].description)
    return "Find someone who has already been assigned an ID within your organization";
};

const Busqueda = () => {
  const {
    restartReport,
    showReport,
    findReport,
    sendReport,
    // getDevices,
    // devices,
    closeReport,
    error,
    openReport,
  } = useContext(ReportContext);
  const { showFilters, filtersToShow } = useContext(UserContext);
  const { user } = useContext(AuthContext);
  const is9090 = user.contract_type === '90-90';
  const { setSnack } = useContext(SnackBarContext);
  const { reporte } = useParams();
  const location = useLocation();
  const [form, setForm] = useState({});
  const { validateForm, setValidateForm, handleFormValidation } =
    useFormValidation(initialValidateForm);
  const [tabs, setTabs] = useState(initialTabState);
  const [resetFile, setResetFile] = useState(false);
  const idForm = tabs[1].selected;
  const cargaBatch = tabs[2].selected;
  const { t } = useTranslate();
  const tolgee = useTolgee(["language"]);
  const isLangEs = tolgee.getLanguage() === "es-MX";

  useEffect(() => {
    filtersToShow(getMetadataSaved().filters);
  }, [])
  
  useEffect(() => {
    if (reporte === "sin-reporte") {
      restartReport();
      const snackState = getSnackState(isLangEs);

      setSnack(SNACKBAR_STATES_NAMES.SHOW_NOTIFICATION_REPORT, snackState);
      return;
    }

    if (reporte === "con-reporte") openReport();
  }, [location.pathname]);

  useEffect(() => {
    setForm({});
    if (!reporte) closeReport();
  }, [idForm]);

  const changeTab = (id) => {
    changeSelection(id, tabs, setTabs);
  };

  const handleIDForm = () => {
    const is_external = form.idUser !== undefined;
    const is_identity = form.idTrully !== undefined;
    findReport(form, is_external, is_identity);
  };

  const handleSendReport = () => {
    sendReport(form, validateForm);
    setValidateForm(initialValidateForm);
  };

  const reset = (close) => {
    setResetFile(true);
    setForm({});
    if (close) closeReport();
  };

  return (
    <section className="busqueda">
      {!showReport && (
        <section className="busqueda__form border border-light rounded-2">
          <header className="busqueda__form__container__header">
            <div className="busqueda__form__container__header--title">
              <section>
                <h2 className="heading-2">{t("search_title")} </h2>
                <span className="text-2 color--shuttle-gray">
                  {t("search_title_warning")}
                </span>
              </section>
              {!idForm && !cargaBatch && (
                <BtnFiltros
                  filter="inputs"
                  icon={iconFiltros}
                  title={t("btn_filtros_filters_title")}
                  filters={showFilters.filter((item) => {
                    if (is9090)
                      return !delete9090.includes(item.name);
                    return true;
                  })}
                  styles={{
                    backgroundColor: COLORS.WHITE,
                    border: `1px solid ${COLORS.SHUTTLE_GRAY}`,
                    color: COLORS.SHARK,
                    boxShadow: "none",
                    padding: "8px 12px",
                  }}
                />
              )}
            </div>

            <div className="layout__main__section__dashboard__buttons busqueda__form__container__header--tabs">
              {tabs.map((el, i) => (
                <TabsButtons
                  key={el.id}
                  handleBtn={changeTab}
                  id={el.id}
                  active={el.selected}
                  text={translateText(el.text, isLangEs)}
                  inlineStyles={tabs.length - 1 === i ? { marginRight: 0 } : {}}
                />
              ))}
            </div>
            <p className="busqueda__form__container__header--description text-2 color--shuttle-gray">
              {idForm &&
                translateDescription(tabs[1].description, tabs, isLangEs)}
              {!idForm &&
                translateDescription(
                  !cargaBatch && tabs[0].description,
                  tabs,
                  isLangEs
                )}
            </p>
          </header>
          {idForm && (
            <IDForm
              form={form}
              setForm={setForm}
              showReport={showReport}
              reset={reset}
              handleIDForm={handleIDForm}
              error={error}
              restartReport={restartReport}
              validation={validateForm}
            />
          )}
          {!idForm && !cargaBatch && (
            <BusquedaReportForm
              enabled={false}
              steps={steps}
              initialStep={0}
              onExit={() => {}}
              stepsOptions={stepsOptions}
              form={form}
              setForm={setForm}
              filters={showFilters}
              handleFormValidation={handleFormValidation}
              showReport={showReport}
              reset={reset}
              handleSendReport={handleSendReport}
              error={error}
              restartReport={restartReport}
              resetFile={resetFile}
              setResetFile={setResetFile}
              validation={validateForm}
            />
          )}
          {cargaBatch && <CargaBatch />}
        </section>
      )}
      {showReport && (
        <section className="busqueda__reporte__container">
          <section className="busqueda__reporte__details-container border border-light rounded-2">
            <SearchDetails details={form} reset={reset} />
          </section>
          <section className="busqueda__reporte border border-light rounded-2">
            <Reporte closeReportForm={reset} />
          </section>
        </section>
      )}
    </section>
  );
};
export default Busqueda;
