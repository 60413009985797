import { useContext, useState } from "react";
import InputPass from "./InputPass";
import { validatePass } from "../helpers/formValidations";
import { useLocation, useNavigate } from "react-router-dom";
import { PRIVATE_ROUTES } from "./pages/routes";
import AuthContext from "../contexts/AuthContext";
import SmallLoader from "./SmallLoader";
import ErrorSlideTimeOut from "./ErrorSlideTimeOut";
import ChangePassModal from "./ChangePassModal";
import { useFormValidation } from "../hooks/useFormValidation";
import { useTranslate } from "@tolgee/react";

const initialState = {
  password: "",
  passRepeat: "",
};

const initialValidateForm = [
  { name: "password", valid: true },
  { name: "passRepeat", valid: true },
];

const EnterPassword = () => {
  const {
    newPassError,
    resetNewPassError,
    setNewPassError,
    loading,
    passChanged,
    changePass,
  } = useContext(AuthContext);
  const [form, setForm] = useState(initialState);
  const { validateForm, setValidateForm, handleFormValidation } =
    useFormValidation(initialValidateForm);
  const location = useLocation();
  const passToken = location.pathname.replace("/reset/", "");
  const navigate = useNavigate();
  const { t } = useTranslate();

  const handleSubmit = (e) => {
    e.preventDefault();

    const pass = form.password;
    const repeat = form.passRepeat;

    if (pass !== repeat) {
      setNewPassError(t("enter_pass_error"));
      return;
    }

    const data = { password1: pass, password2: repeat, token: passToken };

    changePass(validateForm, data);
    setValidateForm(initialValidateForm);
  };

  const handleClick = () => {
    resetNewPassError();
    navigate(PRIVATE_ROUTES.DASHBOARD);
  };

  const styles = {
    boxShadow:
      "0px 6px 40px rgba(0, 0, 0, 0.04), 0px 10px 16px rgba(0, 0, 0, 0.0274815)",
  };

  return (
    <>
      {passChanged && <ChangePassModal handleClick={handleClick} />}
      <div className="sendPassword">
        <form
          onSubmit={handleSubmit}
          className="sendPassword__form sendPassword__change"
        >
          <div className="sendPassword--element">
            <InputPass
              form={form}
              inputName="password"
              description={t("input_pass_description")}
              setValue={setForm}
              validate={validatePass}
              infoText={t("enter_pass_input_info")}
              validateErrorText={t("enter_pass_input_error")}
              iconStyles={{ width: "240px", top: "-20px" }}
              handleFormValidation={handleFormValidation}
              styles={styles}
            />
          </div>
          <div className="sendPassword--element">
            <InputPass
              form={form}
              inputName="passRepeat"
              description={t("input_pass_repeat_description")}
              setValue={setForm}
              validate={() => undefined}
              handleFormValidation={handleFormValidation}
              styles={styles}
            />
          </div>
          <input
            type="submit"
            value={t("enter_pass_input_create")}
            className="signIn__form--btn"
          />
        </form>
        {loading && <SmallLoader />}
        <ErrorSlideTimeOut
          error={newPassError}
          restartFn={resetNewPassError}
          styles={{ textAlign: "center", paddingLeft: 0 }}
        />
      </div>
    </>
  );
};
export default EnterPassword;
