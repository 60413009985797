import { useTranslate } from "@tolgee/react";
import Button from "../Button";
import StepperBatch from "../StepperBatch";

const DownloadExample = () => {
  const { t } = useTranslate();

  const startDownload = () => {
    const url =
      "https://trully-public-images.s3.amazonaws.com/busqueda-masiva-ejemplo.zip";

    window.open(url, "_blank");
  };

  return (
    <div className="cargaBatch__download">
      <div className="cargaBatch__download__text-container">
        <h3 className="text-2--bold">{t("carga_batch_title")}</h3>
        <p className="text-2">{t("carga_batch_description")}</p>
      </div>
      <Button text={t("download_btn")} handleClick={startDownload} />
    </div>
  );
};

const CargaBatch = () => {
  const { t } = useTranslate();

  return (
    <section className="cargaBatch">
      <DownloadExample />
      <div className="cargaBatch__stepper-container">
        <h2 className="text-1--bold">{t("carga_batch_stepper_title")}</h2>
        <StepperBatch />
      </div>
    </section>
  );
};
export default CargaBatch;
