import { useState } from "react";

const IconInfo = ({ text, styles }) => {
  const [showText, setShowText] = useState(false);

  const handleToggle = () => setShowText(!showText);

  return (
    <div className="iconInfo">
      <div
        className="icon-info iconInfo--icon"
        onMouseEnter={handleToggle}
        onMouseLeave={handleToggle}
      ></div>

      {showText && (
        <span className="iconInfo--text text-2 rounded-1" style={styles}>
          {text}
        </span>
      )}
    </div>
  );
};
export default IconInfo;
