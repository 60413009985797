export const TYPES = {
  GET_LAST_INVOICES: "GET_LAST_INVOICES",
  INVOICES_LOADING: "INVOICES_LOADING",
  SCROLL_LOADER: "SCROLL_LOADER",
  INVOICES_ERROR: "INVOICES_ERROR",
  SET_TABLE_DATA: "SET_TABLE_DATA",
  CHANGE_HEADERS: "CHANGE_HEADERS",
  NO_INVOICE_DATA: "NO_INVOICE_DATA",
  LOAD_DATA: "LOAD_DATA",
  TOTALS_LOADING: "TOTALS_LOADING",
  ERROR_TOTALS: "ERROR_TOTALS",
  GET_TOTALS: "GET_TOTALS",
  CONTRACT_ERROR: "CONTRACT_ERROR",
  GET_CONTRACT_DATA: "GET_CONTRACT_DATA",
};
