import { TYPES } from "../actions/authActions";
import { initialState } from "../states/contexts/AuthContext";

export const ROLES = {
  PROPIETARIO: "Administrador",
  ADMIN: "Manager",
  ANALISTA: "Analista",
  OLD: {
    OWNER: "owner",
    ADMIN: "admin",
    USER: "analista",
  },
};

export const ACCOUNT_TYPES = [
  { id: 0, role: ROLES.PROPIETARIO },
  { id: 1, role: ROLES.ADMIN },
  { id: 2, role: ROLES.ANALISTA },
];

const getRoleById = (dashboard_role_id) => {
  switch (dashboard_role_id) {
    case 0:
      return ROLES.PROPIETARIO;
    case 1:
      return ROLES.ADMIN;
    default:
      return ROLES.ANALISTA;
  }
};

const getMetadata = (metadata, state) => {
  return {
    walkthrough: metadata?.walkthrough
      ? {
          dash: metadata.walkthrough["dashboard.dashboard"],
          busqueda: {
            form: metadata.walkthrough["busqueda.form"],
            report: metadata.walkthrough["busqueda.report"],
          },
          consultas: metadata.walkthrough["consultas.consultas"],
        }
      : state.user.metadata.walkthrough,
    filters: metadata?.filters
      ? {
          document_image: metadata.filters["filter.document_image"],
          rfc: metadata.filters["filter.rfc"],
          curp: metadata.filters["filter.curp"],
          ip: metadata.filters["filter.ip"],
          email: metadata.filters["filter.email"],
          image: metadata.filters["filter.image"],
          address: metadata.filters["filter.address"],
          location: metadata.filters["filter.location"],
          phone_number: metadata.filters["filter.phone_number"],
        }
      : state.user.metadata.filters,
    history: metadata?.history
      ? {
          date: metadata.history["history.date"],
          productName: metadata.history["history.productName"],
          id: metadata.history["history.id"],
          externalId: metadata.history["history.externalId"],
          informador: metadata.history["history.informador"],
          estatus: metadata.history["history.estatus"],
        }
      : state.user.metadata.history,
  };
};

const getUserData = (payload, state) => {
  const {
    company,
    first_name,
    last_name,
    email,
    dashboard_role_id,
    phone_number,
    id,
    metadata,
    contract_type
  } = payload.userData;
  const role = getRoleById(dashboard_role_id);
  const userMetadata = getMetadata(metadata, state);
  const { notifications } = payload;

  return {
    ...state.user,
    company,
    role,
    email,
    first_name,
    last_name,
    phone_number,
    id,
    contract_type,
    metadata: userMetadata,
    notifications: {
      list: notifications?.notifications,
      not_seen:
        notifications?.active_count > 99 ? "99+" : notifications?.active_count,
    },
  };
};

const handleResetError = (state, payload) => {
  const { errorType, sendMailError, mailSended, newPassError, signupError } =
    payload;

  switch (errorType) {
    case "SendMail":
      return {
        ...state,
        sendMailError,
        mailSended,
      };
    case "ChangePass":
      return {
        ...state,
        newPassError,
      };
    case "Signup":
      return {
        ...state,
        signupError,
      };
    default:
      return {
        ...state,
        error: false,
      };
  }
};

export const authReducer = (state, action) => {
  switch (action.type) {
    case TYPES.GET_TOKEN: {
      const user = getUserData(action.payload, state);
      return {
        ...state,
        user,
        loading: false,
      };
    }

    case TYPES.REFRESH_TOKEN: {
      const { token, refresh_token } = action.payload;
      return {
        ...state,
        token,
        refreshToken: refresh_token,
      };
    }

    case TYPES.LOADING:
      return {
        ...state,
        loading: true,
      };

    case TYPES.LOG_ERROR: {
      const error = action.payload;
      return {
        ...state,
        error,
        loading: false,
      };
    }

    case TYPES.LOGOUT: {
      const { initialState } = action.payload;
      return {
        ...initialState,
        sessionExpired: false,
        sessionExpiredText: "",
      };
    }

    case TYPES.SIGNUP:
      return {
        ...state,
        loading: false,
      };

    case TYPES.ACTIVE_SESSION: {
      const { user, refreshToken } = action.payload;
      return {
        ...state,
        user,
        refreshToken,
      };
    }

    case TYPES.SESSION_EXPIRED: {
      const { sessionExpired, sessionExpiredText } = action.payload;
      return {
        ...state,
        sessionExpired,
        sessionExpiredText,
      };
    }

    case TYPES.INVALID_SESSION:
      return initialState;

    case TYPES.RESET_ERROR:
      return handleResetError(state, action.payload);

    case TYPES.SEND_MAIL_ERROR:
      return {
        ...state,
        loading: false,
        sendMailError: action.payload,
      };

    case TYPES.NEW_PASS_ERROR:
      return {
        ...state,
        loading: false,
        newPassError: action.payload,
      };

    case TYPES.SEND_MAIL:
      return {
        ...state,
        loading: false,
        mailSended: action.payload,
      };

    case TYPES.CHANGE_PASS:
      return {
        ...state,
        loading: false,
        passChanged: action.payload,
      };

    case TYPES.PASS_TOKEN:
      return {
        ...state,
        passToken: action.payload,
      };

    case TYPES.SIGNUP_ERROR:
      return {
        ...state,
        loading: false,
        signupError: action.payload,
      };

    case TYPES.UPDATE_USER_DATA:
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload,
        },
      };

    default:
      return state;
  }
};
