import { useContext, useEffect, useState } from "react";
import ReporteData from "./ReporteData";
import Reportar from "./Reportar";
import ReportContext from "../contexts/ReportContext";
import ReporteHeader from "./ReporteHeader";
import ErrorComponent from "./ErrorComponent";
import { useLocation, useNavigate } from "react-router-dom";
import { PRIVATE_ROUTES } from "./pages/routes";
import { COLORS } from "../styles/utils/colors";
import { useTranslate } from "@tolgee/react";

const Reporte = ({ closeReportForm }) => {
  const { reportToFlag, report } = useContext(ReportContext);
  const statusError = report.response_status;
  const navigate = useNavigate();
  const location = useLocation();
  const [route, setRoute] = useState(PRIVATE_ROUTES.BUSQUEDA);
  const { t } = useTranslate();

  useEffect(() => {
    return () => {
      const sinReporte = `${route}/sin-reporte`;
      const conReporte = `${route}/con-reporte`;
      if (location.pathname === sinReporte || location.pathname === conReporte)
        navigate(route);
    };
  }, []);

  if (statusError === 500 || statusError === 400)
    return (
      <div
        style={{
          padding: "16px",
          backgroundColor: COLORS.WHITE,
          borderTopLeftRadius: "10px",
          borderTopRightRadius: "10px",
        }}
      >
        <ReporteHeader error={true} closeReportForm={closeReportForm} />
        <ErrorComponent
          error={statusError}
          title={
            statusError === 500
              ? t("request_500_error_title")
              : t("request_400_error_title")
          }
          text={
            statusError === 500
              ? t("request_500_error_text")
              : t("request_400_error_text")
          }
        />
      </div>
    );

  return (
    <>
      {!reportToFlag && <ReporteData closeReportForm={closeReportForm} />}
      {reportToFlag && <Reportar reportToFlag={reportToFlag} />}
    </>
  );
};
export default Reporte;
