// import { Checkbox, FormControlLabel } from "@mui/material";
// import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
// import CheckBoxIcon from "@mui/icons-material/CheckBox";
import moment from "moment";
import { useContext, useState } from "react";
import BillingContext from "../contexts/BillingContext";
import PaidBadge from "./PaidBadge";
import { formatUTCDate } from "../helpers/formatDate";

const BillingTableRowTd = ({ el, handleSelection, deleteSelection }) => {
  const { headers } = useContext(BillingContext);
  const columnsNumber = [...Array(headers.length).keys()];
  const [selected, setSelected] = useState(false);

  let date = moment(el.invoice_date).isValid()
    ? formatUTCDate(el.invoice_date, "MMMM DD, YYYY")
    : "-";
  date = date.charAt(0).toUpperCase() + date.slice(1);

  // const changeSelection = () => {
  //   !selected ? handleSelection(el) : deleteSelection(el.id);
  //   setSelected(!selected);
  // };

  return (
    <tr className="layout__main__section__consultas__table__tbody__tr">
      {columnsNumber?.map((column) => {
        if (column >= 0 && column < 4) {
          return (
            <td
              key={`invoice-${el.id}-column-${column}`}
              className={`layout__main__section__consultas__table__tbody__td ${
                selected && column === 0
                  ? "border-table-start"
                  : selected && "border-table-middle"
              }`}
            >
              {/* {column === 0 && (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selected}
                        icon={<CheckBoxOutlineBlankIcon />}
                        checkedIcon={<CheckBoxIcon />}
                        onChange={changeSelection}
                        inputProps={{ "aria-label": "secoondary checkbox" }}
                      />
                    }
                  />
                )} 
                {column === 1 && el?.name}
                {column === 2 && date}
                {column === 3 && `usd $${el.amount_usd}`}
                {column === 4 && <PaidBadge paid={el.status} />}*/}
              {column === 0 && el?.name}
              {column === 1 && date}
              {column === 2 && `usd $${el.amount_usd}`}
              {column === 3 && <PaidBadge paid={el.status} />}
            </td>
          );
        } else {
          return (
            <td
              key={`invoice-${el.id}-column-${column}`}
              className={`layout__main__section__consultas__table__tbody__td ${
                selected && "border-table-end"
              }`}
            >
              <a
                style={{ marginRight: "10px" }}
                className="billing-card__button-container__btn billing__download"
                target="_blank"
                href={el.download_pdf_url}
                rel="noreferrer"
              >
                <span className="icon-descargar"></span>
                <span>PDF</span>
              </a>
              <a
                className="billing-card__button-container__btn billing__download"
                target="_blank"
                href={el.download_xml_url}
                rel="noreferrer"
              >
                <span className="icon-descargar"></span>
                <span>XML</span>
              </a>
            </td>
          );
        }
      })}
    </tr>
  );
};
export default BillingTableRowTd;
