import {
  Tolgee,
  TolgeeProvider,
  DevTools,
  FormatSimple,
  LanguageDetector,
} from "@tolgee/react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./components/pages/Layout";
import Dashboard from "./components/pages/Dashboard";
import Busqueda from "./components/pages/Busqueda";
import Consultas from "./components/pages/Consultas";
// import Billing from "./components/pages/Billing";
import { DataProvider } from "./contexts/DataContext";
import { ReportProvider } from "./contexts/ReportContext";
import { AuthProvider } from "./contexts/AuthContext";
import { DatesProvider } from "./contexts/DatesContext";
import { BillingProvider } from "./contexts/BillingContext";
import { LinkProvider } from "./contexts/LinkContext";
import PublicLayout from "./components/pages/PublicLayout";
import { CheckAuth } from "./components/pages/CheckAuth";
import SignIn from "./components/SignIn";
import RecoveryLayout from "./components/pages/RecoveryLayout";
import SendMail from "./components/SendMail";
import EnterPassword from "./components/EnterPassword";
import SignUp from "./components/SignUp";
import { PRIVATE_ROUTES, PUBLIC_ROUTES } from "./components/pages/routes";
import Perfil from "./components/pages/Perfil";
import { SnackBarProvider } from "./contexts/SnackBarContext";
import { UserProvider } from "./contexts/UserContext";
import Team from "./components/pages/Team";
import "intro.js/introjs.css";
import Page404 from "./components/pages/Page404";
import Reviews from "./components/pages/Reviews";
import NoCode from "./components/pages/NoCode";
import { SDKProvider } from "./contexts/SDKContext";
import { CheckLink } from "./components/pages/CheckLink";
import { useEffect } from "react";

const tolgee = Tolgee()
  .use(DevTools())
  .use(FormatSimple())
  // .use(BackendFetch())
  .use(LanguageDetector())
  .init({
    apiUrl: process.env.REACT_APP_TOLGEE_API_URL,
    apiKey: process.env.REACT_APP_TOLGEE_API_KEY,
    projectId: process.env.REACT_APP_TOLGEE_PROJECT_ID,
    availableLanguages: ["en", "es-MX"],
    defaultLanguage: "es-MX",
    staticData: {
      en: () => import("./i18n/en.json"),
      "es-MX": () => import("./i18n/es-MX.json"),
    },
  });


function App() {
  useEffect(() => {
    const domain = window.location.href;

    if (domain.includes('facekey.asofom.mx')) {
      document.title = 'Facekey';
      changeFavicon('favicon-facekey.png');
    }
  }, []);

  const changeFavicon = (faviconUrl) => {
    const links = document.querySelectorAll("link[rel~='icon']");
    links.forEach((link) => {
      link.href = `/favicon/${faviconUrl}`;
    });
  };

  return (
    <BrowserRouter>
      <TolgeeProvider tolgee={tolgee}>
        <AuthProvider>
          <DatesProvider>
            <SnackBarProvider>
              <UserProvider>
                <DataProvider>
                  <SDKProvider>
                    <ReportProvider>
                      <BillingProvider>
                        <LinkProvider>
                          <Routes>
                            {/* Rutas Públicas */}
                            <Route
                              path={PUBLIC_ROUTES.SIGNIN}
                              element={<PublicLayout />}
                            >
                              <Route path="" element={<SignIn />} />
                            </Route>
                            <Route
                              path={PUBLIC_ROUTES.RESET}
                              element={<RecoveryLayout step={1} />}
                            >
                              <Route path="" element={<SendMail />} />
                            </Route>
                            <Route
                              path={PUBLIC_ROUTES.RESET_TOKEN_PARAM}
                              element={<RecoveryLayout step={2} />}
                            >
                              <Route path="" element={<EnterPassword />} />
                            </Route>
                            <Route
                              path={PUBLIC_ROUTES.SIGNUP}
                              element={<PublicLayout />}
                            >
                              <Route path="" element={<SignUp />} />
                            </Route>

                            {/* Rutas Privadas */}
                            <Route path="" element={<CheckAuth />}>
                              <Route path="" element={<Layout />}>
                                <Route
                                  path={PRIVATE_ROUTES.DASHBOARD}
                                  element={<Dashboard />}
                                />
                                <Route
                                  path={PRIVATE_ROUTES.BUSQUEDA}
                                  element={<Busqueda />}
                                />
                                <Route
                                  path={`${PRIVATE_ROUTES.BUSQUEDA}/:reporte`}
                                  element={<Busqueda />}
                                />
                                <Route
                                  path={PRIVATE_ROUTES.CONSULTAS}
                                  element={<Consultas />}
                                />
                                <Route
                                  path={PRIVATE_ROUTES.REVIEWS}
                                  element={<Reviews />}
                                />
                                <Route
                                  path={PRIVATE_ROUTES.RULES}
                                  element={<NoCode />}
                                />
                                {/* <Route
                                  path={PRIVATE_ROUTES.BILLING}
                                  element={<Billing />}
                                /> */}
                                <Route
                                  path={PRIVATE_ROUTES.PERFIL}
                                  element={<Perfil />}
                                />
                                <Route
                                  path={PRIVATE_ROUTES.TEAM}
                                  element={<Team />}
                                />
                                <Route
                                  path={PRIVATE_ROUTES.LINK}
                                  element={<CheckLink />}
                                />
                                {/* 404 Page */}
                                <Route path="*" element={<Page404 />} />
                              </Route>
                            </Route>
                          </Routes>
                        </LinkProvider>
                      </BillingProvider>
                    </ReportProvider>
                  </SDKProvider>
                </DataProvider>
              </UserProvider>
            </SnackBarProvider>
          </DatesProvider>
        </AuthProvider>
      </TolgeeProvider>
    </BrowserRouter>
  );
}

export default App;
