import React, { useEffect, useState } from "react";
import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateSize,
  FilePondPluginFileValidateType
);

const initialErrorObjStr = { title: "", text: "" };

const FileUploader = ({
  allowMultiple,
  allowReorder,
  dropZoneHTML,
  allowFileSizeValidation = false,
  maxFileSize = null,
  maxTotalFileSize = null,
  allowFileTypeValidation = false,
  acceptedFileTypes = [],
  fileValidateTypeLabelExpectedTypes,
  allowImagePreview = true,
  imagePreviewFilterItem = (fileItem) => true,
  setEnabledUpload,
  setFilesInState,
  serverURL,
  validateFiles,
  serverConnection,
  deleteElement,
}) => {
  const [files, setFiles] = useState([]);
  const [totalSizeExceed, setTotalSizeExceed] = useState(null);
  const [sizeExceed, setSizeExceed] = useState(null);
  const [currentTotalSize, setCurrentTotalSize] = useState(0);
  const [typeError, setTypeError] = useState(null);
  const [errorObjStr, setErrorObjStr] = useState(initialErrorObjStr);
  const [filesToUpload, setFilesToUpload] = useState(0);
  const [filesUploaded, setFilesUploaded] = useState(0);

  const getMaxTotalFileSizeInBytes = (max) => {
    const maxTotalSizeMB = parseInt(max?.replace("MB", ""));
    return maxTotalSizeMB * 1024 * 1024;
  };

  const bytesToMB = (bytes) => {
    return (bytes / 1024 / 1024).toFixed(2);
  };

  useEffect(() => {
    if (errorObjStr?.title !== "La imagen ya existe") {
      if (files.length !== 0) {
        const allTypesValid = files.every((f) =>
          acceptedFileTypes.includes(f.fileType)
        );

        setTypeError(() => !allTypesValid);
      }

      const totalSize = files.reduce(
        (total, file) => total + (file.fileSize || 0),
        0
      );

      const maxTotalSizeInBytes = getMaxTotalFileSizeInBytes(maxTotalFileSize);

      setCurrentTotalSize(() => totalSize);
      setTotalSizeExceed(() => totalSize > maxTotalSizeInBytes);
      setSizeExceed(() => {
        const maxFileSizeInBytes = getMaxTotalFileSizeInBytes(maxFileSize);
        const sizeExceed = files.find(
          (file) => file.file.size > maxFileSizeInBytes
        );
        return sizeExceed ? true : false;
      });
    } else {
      const uniqueElements = new Set();
      let hasDuplicates = false;

      for (const element of files) {
        if (uniqueElements.has(element.file.name)) {
          hasDuplicates = true;
          break;
        }
        uniqueElements.add(element.file.name);
      }
      setTypeError(() => hasDuplicates);
    }
  }, [files]);

  useEffect(() => {
    setEnabledUpload(() => {
      if (typeError) return false;

      if (totalSizeExceed || sizeExceed) return false;

      if (filesUploaded !== filesToUpload) return false;

      return filesToUpload !== 0;
    });
  }, [filesUploaded, typeError, filesToUpload, totalSizeExceed, sizeExceed]);

  const connectToServer = ({
    url,
    fieldName,
    file,
    progress,
    load,
    error,
    abort,
  }) => {
    const formData = new FormData();
    formData.append(fieldName, file, file.name);

    const request = new XMLHttpRequest();
    request.open("POST", url);

    request.upload.onprogress = (e) => {
      progress(e.lengthComputable, e.loaded, e.total);
    };

    request.onload = function () {
      if (request.status >= 200 && request.status < 300) {
        load(request.responseText);
        setFilesUploaded((prev) => prev + 1);
      } else {
        setEnabledUpload(() => false);
        setTypeError(() => true);
        setFilesInState && setFilesInState(() => []);
        error("oh no");
      }
    };

    request.send(formData);

    return {
      abort: () => {
        request.abort();

        abort();
      },
    };
  };

  return (
    <div className="fileUploader-container">
      <div
        className={
          sizeExceed || totalSizeExceed || typeError
            ? "fileUploader--error"
            : "fileUploader"
        }
      >
        <FilePond
          files={files}
          allowRevert={false}
          allowReorder={allowReorder}
          allowMultiple={allowMultiple}
          maxParallelUploads={5}
          labelIdle={dropZoneHTML}
          credits={false}
          dropOnPage={true}
          allowPaste={false}
          allowReplace={true}
          allowProcess={true}
          allowFileTypeValidation={allowFileTypeValidation}
          acceptedFileTypes={acceptedFileTypes}
          allowFileSizeValidation={allowFileSizeValidation}
          allowImagePreview={allowImagePreview}
          imagePreviewFilterItem={imagePreviewFilterItem}
          maxFileSize={maxFileSize}
          maxTotalFileSize={maxTotalFileSize}
          labelMaxFileSizeExceeded="El archivo supera el tamaño máximo"
          labelMaxFileSize={`Tamaño máximo de ${maxFileSize} soportado`}
          labelMaxTotalFileSizeExceeded={`Tus archivos superan el tamaño máximo ${maxTotalFileSize}`}
          labelMaxTotalFileSize={`Los archivos no deben superar los ${maxTotalFileSize}`}
          labelFileTypeNotAllowed="Tipo de archivo no aceptado"
          labelFileLoading="Cargando"
          labelFileProcessing="Cargando"
          labelFileProcessingComplete="Archivo subido"
          labelFileProcessingAborted={
            errorObjStr.title ? errorObjStr.title : "Proceso cancelado"
          }
          labelFileProcessingError={
            errorObjStr.title
              ? errorObjStr.title
              : "No se pudo subir el archivo"
          }
          labelTapToCancel=""
          labelTapToRetry={
            errorObjStr.text
              ? errorObjStr.text
              : "Haga click en el botón para reintentar"
          }
          labelTapToUndo=""
          fileValidateTypeLabelExpectedTypes={
            fileValidateTypeLabelExpectedTypes
          }
          onupdatefiles={(files) => {
            setEnabledUpload(() => false);
            setFiles(files);
          }}
          onerror={() => {
            setFilesToUpload((prev) => (prev - 1 < 0 ? 0 : prev - 1));
            setTypeError(() => true);
          }}
          onremovefile={(_, file) => {
            if (files.length - 1 === 0) setEnabledUpload(() => false);
            deleteElement(file.filename);
          }}
          onaddfile={() => setFilesToUpload((prev) => prev + 1)}
          onprocessfile={(_, file) => {
            if (!typeError && filesUploaded === filesToUpload)
              setEnabledUpload(() => true);
          }}
          server={{
            process: (
              fieldName,
              file,
              metadata,
              load,
              error,
              progress,
              abort,
              transfer,
              options
            ) => {
              validateFiles({ file, error, setErrorObjStr }).then(
                (validFiles) => {
                  if (validFiles) {
                    setFilesInState &&
                      setFilesInState((prev) => [...prev, file]);

                    serverConnection
                      ? serverConnection({
                          file,
                          setEnabledUpload,
                          setTypeError,
                          setErrorObjStr,
                          setFilesInState,
                          error,
                          load,
                          setFilesUploaded,
                        })
                      : connectToServer({
                          url: serverURL,
                          fieldName,
                          file,
                          progress,
                          load,
                          error,
                          abort,
                          setFilesUploaded,
                        });
                  }
                }
              );
            },
          }}
        />
      </div>
      <span
        style={{ alignSelf: "flex-end" }}
        className={`text-2 ${
          sizeExceed || totalSizeExceed || typeError
            ? "color--copperfield"
            : "color--shuttle-gray"
        }`}
      >
        {bytesToMB(currentTotalSize)}MB/{maxTotalFileSize || maxFileSize}
      </span>
    </div>
  );
};
export default FileUploader;
