export const TYPES = {
  OPEN_REPORT: "OPEN_REPORT",
  CLOSE_REPORT: "CLOSE_REPORT",
  TOGGLE_FLAGGING_FORM: "TOGGLE_FLAGGING_FORM",
  FLAG_REPORT: "FLAG_REPORT",
  UPDATE_REPORT_FLAG: "UPDATE_REPORT_FLAG",
  RESTART: "RESTART",
  FIND_REPORT: "FIND_REPORT",
  SET_DEVICES: "SET_DEVICES",
  NO_REPORT: "NO_REPORT",
  LOADING: "LOADING",
  ADD_EXTERNAL_ID: "ADD_EXTERNAL_ID",
  ADD_NETWORK_REPORTS: "ADD_NETWORK_REPORTS",
  ADD_IDENTITY_REPORTED: "ADD_IDENTITY_REPORTED",
  DELETE_IDENTITY_REPORTED: "DELETE_IDENTITY_REPORTED",
  UPDATE_REPORT_LABEL: "UPDATE_REPORT_LABEL",
  LOADING_SCROLL: "LOADING_SCROLL"
};
