import { createContext, useContext, useMemo, useReducer } from "react";
import { initialState } from "../states/contexts/LinkContext";
import { linkReducer } from "../reducers/linkReducer";
import { TYPES } from "../actions/linkActions";
import AuthContext from "./AuthContext";
import { url } from "./domain";
import { generateRequest } from "../helpers/helpHttp";
import { sorting } from "../helpers/sorting";
import SnackBarContext from "./SnackBarContext";
import {
  SNACKBAR_STATES_NAMES,
  SNACKBAR_TEXTS,
  SNACKBAR_TEXTS_EN,
} from "../states/contexts/SnackContext";
import { generateBase64FromBlob } from "../helpers/genereteBase64FromBlob";
import { useTolgee } from "@tolgee/react";

export const getLinkID = (url) => {
  const regex = /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/i;
  const match = url.match(regex);
  return match[0] ?? "";
};

const LinkContext = createContext();

const LinkProvider = ({ children }) => {
  const [state, dispatch] = useReducer(linkReducer, initialState);
  const { terminateSession, invalidSession } = useContext(AuthContext);
  const { setSnack } = useContext(SnackBarContext);
  const tolgee = useTolgee(["language"]);
  const isLangEs = tolgee.getLanguage() === "es-MX";

  const {
    dataToShow,
    headers,
    linksLoading,
    linksError,
    scrollLoader,
    requestStartOn,
    activeLink,
    noLinksToShow,
    isEdit,
    magicLinkURL,
  } = state;

  const getLinksList = async (requestStartOn, noLoader) => {
    if (!noLoader) setLoader(TYPES.GET_LINKS_LOADER);
    else setLoader(TYPES.SCROLL_LOADER);

    try {
      const datosRequest = {
        url: `${url}/product/magic-link?page=${requestStartOn}&offset=40`,
        terminateSession,
        invalidSession,
      };

      const data = await generateRequest.get(datosRequest);
      if (data.err) throw data;

      if (data.data.length === 0) {
        dispatch({
          type: TYPES.NO_LINKS_TO_SHOW,
        });

        return;
      }

      dispatch({
        type: TYPES.GET_LINKS_LIST,
        payload: {
          data: data.data.map((el) => ({
            id: getLinkID(el?.magic_link_url),
            ...el,
          })),
          requestStartOn,
        },
      });
    } catch (error) {
      if (error.status === 404) {
        dispatch({
          type: TYPES.GET_LINKS_LIST,
          payload: {
            data: [],
            requestStartOn,
          },
        });
        return;
      }

      dispatch({
        type: TYPES.GET_LINKS_ERROR,
        payload: error,
      });
    }
  };

  const createTableData = ({ data }) =>
    dispatch({
      type: TYPES.DATA_TO_SHOW,
      payload: {
        data: data.map((el) => ({
          id: getLinkID(el?.magic_link_url),
          ...el,
        })),
      },
    });

  const sortBy = (order, type, key, newHeaders) => {
    const newDataToShow = sorting(dataToShow, order, type, key);
    createTableData({ data: newDataToShow });
    dispatch({
      type: TYPES.NEW_HEADERS_TO_SHOW,
      payload: { headers: newHeaders },
    });
  };

  const setLoader = (type) => dispatch({ type });

  const setActiveLink = (activeLink, isEdit) => {
    if (!activeLink)
      dispatch({
        type: TYPES.SET_ACTIVE_LINK,
        payload: { activeLink: null, isEdit: false },
      });
    else
      dispatch({
        type: TYPES.SET_ACTIVE_LINK,
        payload: { activeLink, isEdit },
      });
  };

  const createBodyRequest = async (linkData) => {
    const logo = await generateBase64FromBlob(linkData.brand.logo);

    return {
      one_time_only: linkData.config.type,
      title: linkData.title,
      external_id: linkData.config.external_id,
      identify_user: linkData.config.identify_user,
      sections: {
        ...linkData.identity,
      },
      metadata: {
        webhook_url: linkData.config.webhook_url,
        redirect_url: linkData.config.redirect_url,
        font_primary_heading: linkData.brand.font.heading1,
        font_secondary_heading: linkData.brand.font.heading2,
        font_text: linkData.brand.font.text,
        logo: logo,
        primary_color: linkData.brand.colors.primary,
        secondary_color: linkData.brand.colors.secondary,
        text_primary_color: linkData.brand.colors.textPrimary,
        text_secondary_color: linkData.brand.colors.textSecondary,
      },
    };
  };

  const setMagicLinkURL = (url) =>
    dispatch({
      type: TYPES.SET_MAGIC_LINK_URL,
      payload: url,
    });

  const createLink = async ({ linkData, setShowShareLinkModal }) => {
    try {
      const datosRequest = {
        url: `${url}/product/magic-link`,
        terminateSession,
        invalidSession,
        options: {
          body: await createBodyRequest(linkData),
        },
      };

      const data = await generateRequest.post(datosRequest);

      if (data.err) throw data;

      const snackState = {
        show: true,
        text: isLangEs
          ? SNACKBAR_TEXTS.MAGIC_LINK_CREATED
          : SNACKBAR_TEXTS_EN.MAGIC_LINK_CREATED,
      };

      setSnack(SNACKBAR_STATES_NAMES.MAGIC_LINK, snackState);
      const newRowToShow = {
        magic_link_url: data.magic_link_url,
        title: data.title,
        created_on: data.created_on,
        times_used: data.times_used || 0,
        is_active: data.is_active,
        token: data.token,
      };
      createTableData({ data: [newRowToShow, ...dataToShow] });
      setMagicLinkURL(data.magic_link_url);
      setShowShareLinkModal(() => true);
    } catch (error) {
      const snackState = {
        show: true,
        error: {
          err: true,
          errText: isLangEs
            ? SNACKBAR_TEXTS.ERROR_TEXT
            : SNACKBAR_TEXTS_EN.ERROR_TEXT,
          errTextTitle: isLangEs
            ? SNACKBAR_TEXTS.ERROR_TITLE
            : SNACKBAR_TEXTS_EN.ERROR_TITLE,
        },
      };

      setSnack(SNACKBAR_STATES_NAMES.MAGIC_LINK, snackState);

      return false;
    }
  };

  const getLinkData = async (token) => {
    const datosRequestGet = {
      url: `${url}/product/magic-link/${token}`,
      terminateSession,
      invalidSession,
    };

    const linkData = await generateRequest.get(datosRequestGet);

    return linkData;
  };

  const setLinkToEdit = async ({
    token,
    setShowConfiguration,
    handleShowOptions,
  }) => {
    try {
      const linkData = await getLinkData(token);

      if (linkData.error) throw linkData;

      const isEdit = true;
      setActiveLink(linkData, isEdit);
      setShowConfiguration(() => true);
      handleShowOptions({ id: token, isOpen: true });
    } catch (error) {
      const snackState = {
        show: true,
        error: {
          err: true,
          errText: isLangEs
            ? SNACKBAR_TEXTS.MAGIC_LINK_EDIT_ERROR
            : SNACKBAR_TEXTS_EN.MAGIC_LINK_EDIT_ERROR,
          errTextTitle: isLangEs
            ? SNACKBAR_TEXTS.ERROR_TITLE
            : SNACKBAR_TEXTS_EN.ERROR_TITLE,
        },
      };

      setSnack(SNACKBAR_STATES_NAMES.MAGIC_LINK, snackState);

      return false;
    }
  };

  const changeActiveStatus = async (token) => {
    try {
      const linkToUpdate = await getLinkData(token);

      const datosRequestPatch = {
        url: `${url}/product/magic-link/${token}`,
        terminateSession,
        invalidSession,
        options: {
          body: {
            ...linkToUpdate,
            is_active: !linkToUpdate.is_active,
          },
        },
      };

      const data = await generateRequest.patch(datosRequestPatch);

      if (data.err) throw data;

      const snackState = {
        show: true,
        text: isLangEs
          ? SNACKBAR_TEXTS.MAGIC_LINK_UPDATE
          : SNACKBAR_TEXTS_EN.MAGIC_LINK_UPDATE,
      };

      setSnack(SNACKBAR_STATES_NAMES.MAGIC_LINK, snackState);

      dispatch({
        type: TYPES.UPDATE_LINK,
        payload: data,
      });
    } catch (error) {
      const snackState = {
        show: true,
        error: {
          err: true,
          errText: isLangEs
            ? SNACKBAR_TEXTS.ERROR_TEXT
            : SNACKBAR_TEXTS_EN.ERROR_TEXT,
          errTextTitle: isLangEs
            ? SNACKBAR_TEXTS.ERROR_TITLE
            : SNACKBAR_TEXTS_EN.ERROR_TITLE,
        },
      };

      setSnack(SNACKBAR_STATES_NAMES.ADD_USER, snackState);
    }
  };

  const saveLinkChanges = async (newData) => {
    const bodyData = await createBodyRequest(newData);
    try {
      const datosRequestPatch = {
        url: `${url}/product/magic-link/${activeLink.token}`,
        terminateSession,
        invalidSession,
        options: {
          body: {
            ...activeLink,
            ...bodyData,
          },
        },
      };

      const data = await generateRequest.patch(datosRequestPatch);

      if (data.err) throw data;

      const snackState = {
        show: true,
        text: isLangEs
          ? SNACKBAR_TEXTS.MAGIC_LINK_UPDATE
          : SNACKBAR_TEXTS_EN.MAGIC_LINK_UPDATE,
      };

      setSnack(SNACKBAR_STATES_NAMES.MAGIC_LINK, snackState);

      dispatch({
        type: TYPES.UPDATE_LINK,
        payload: data,
      });
    } catch (error) {
      const snackState = {
        show: true,
        error: {
          err: true,
          errText: isLangEs
            ? SNACKBAR_TEXTS.ERROR_TEXT
            : SNACKBAR_TEXTS_EN.ERROR_TEXT,
          errTextTitle: isLangEs
            ? SNACKBAR_TEXTS.ERROR_TITLE
            : SNACKBAR_TEXTS_EN.ERROR_TITLE,
        },
      };

      setSnack(SNACKBAR_STATES_NAMES.ADD_USER, snackState);
    }
  };

  const showLinkURL = async ({ token, setShowModal, handleShowOptions }) => {
    const linkData = await getLinkData(token);

    if (linkData.error) throw linkData;

    setActiveLink(linkData);
    setShowModal(() => true);
    handleShowOptions({ id: token, isOpen: true });
  };

  const data = useMemo(
    () => ({
      dataToShow,
      headers,
      linksLoading,
      linksError,
      scrollLoader,
      requestStartOn,
      getLinksList,
      createTableData,
      sortBy,
      activeLink,
      setActiveLink,
      createLink,
      changeActiveStatus,
      noLinksToShow,
      setLinkToEdit,
      showLinkURL,
      saveLinkChanges,
      isEdit,
      magicLinkURL,
      setMagicLinkURL,
    }),
    [
      dataToShow,
      headers,
      linksLoading,
      linksError,
      scrollLoader,
      requestStartOn,
      getLinksList,
      createTableData,
      sortBy,
      activeLink,
      setActiveLink,
      createLink,
      changeActiveStatus,
      noLinksToShow,
      setLinkToEdit,
      showLinkURL,
      saveLinkChanges,
      isEdit,
      magicLinkURL,
      setMagicLinkURL,
    ]
  );

  return <LinkContext.Provider value={data}>{children}</LinkContext.Provider>;
};

export { LinkProvider };
export default LinkContext;
