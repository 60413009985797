import { TYPES } from "../actions/userActions";
import { USER_ERRORS } from "../contexts/UserContext";

export const FRAUD_LABEL_ID = {
  ID_1_TEXT: {
    back: "Review",
    front: "review",
  },
  ID_1: 1,
  ID_2_TEXT: {
    back: "Potential Threat",
    front: "reject",
  },
  ID_2: 2,
};

export function userReducer(state, action) {
  switch (action.type) {
    case TYPES.CHANGE_PASS_PERFIL:
      return handleChangePassPerfil(state);

    case TYPES.CHANGE_PASS_PERFIL_LOADING:
      return handleChangePassPerfilLoading(state);

    case TYPES.CHANGE_PASS_PERFIL_ERROR:
      return handleChangePassPerfilError(state);

    case TYPES.CHANGE_HEADERS:
      return handleChangeHeaders(state, action.payload);

    case TYPES.GET_USERS:
      return handleGetUsers(state, action.payload);

    case TYPES.CREATE_TABLE_DATA:
      return handleCreateTableData(state, action.payload);

    case TYPES.GET_USERS_LOADING:
      return handleGetUsersLoading(state);

    case TYPES.GET_USERS_ERROR:
      return handleGetUsersError(state, action.payload);

    case TYPES.SCROLL_LOADER:
      return handleScrollLoader(state);

    case TYPES.NO_USERS:
      return handleNoUsers(state);

    case TYPES.SET_USER_TO_DELETE:
      return handleSetUserToDelete(state, action.payload);

    case TYPES.DELETE_USER:
      return handleDeleteUser(state, action.payload);

    case TYPES.DELETE_USER_LOADING:
      return handleDeleteUserLoading(state);

    case TYPES.DELETE_USER_ERROR:
      return handleDeleteUserError(state);

    case TYPES.UPDATE_USER_TYPE:
      return handleUpdateUserType(state, action.payload);

    case TYPES.UPDATE_USER_TYPE_ERROR:
      return handleUpdateUserTypeError(state);

    case TYPES.SET_USER_TO_ADD:
      return handleSetUserToAdd(state, action.payload);

    case TYPES.ADD_USER:
      return handleAddUser(state, action.payload);

    case TYPES.ADD_USER_LOADING:
      return handleAddUserLoading(state);

    case TYPES.ADD_USER_ERROR:
      return handleAddUserError(state);

    case TYPES.UPDATE_USER_DATA_LOADING:
      return handleUpdateUserDataLoading(state);

    case TYPES.UPDATE_USER_DATA:
    case TYPES.UPDATE_USER_DATA_ERROR:
      return handleUpdateUserData(state);

    case TYPES.GET_API_KEY:
      return handleGetApiKey(state, action.payload);

    case TYPES.GET_RULES:
      return handleGetRules(state, action.payload);

    case TYPES.SET_RULE:
      return handleSetRule(state, action.payload);

    case TYPES.LOADING_RULES:
      return handleLoadingRules(state);

    case TYPES.RULES_ERROR:
      return handleRulesError(state);

    case TYPES.SET_FILTERS:
      return handleSetFilters(state, action.payload);

    default:
      return state;
  }
}

const handleChangePassPerfil = (state) => ({
  ...state,
  changePassLoading: false,
  changePassError: false,
});

const handleChangePassPerfilLoading = (state) => ({
  ...state,
  changePassLoading: true,
});

const handleChangePassPerfilError = (state) => ({
  ...state,
  changePassLoading: false,
});

const handleChangeHeaders = (state, headers) => ({
  ...state,
  headers,
});

const handleGetUsers = (state, { data, requestStartOn }) => ({
  ...state,
  usersLoading: false,
  usersError: false,
  scrollLoader: false,
  usersList: data,
  requestStartOn,
  noUsers: false,
});

const handleCreateTableData = (state, { data, search }) => ({
  ...state,
  usersLoading: false,
  scrollLoader: false,
  usersError: false,
  usersToShow: data,
  search,
  noUsers: false,
});

const handleGetUsersLoading = (state) => ({
  ...state,
  usersLoading: true,
  scrollLoader: false,
});

const handleGetUsersError = (state, usersError) => ({
  ...state,
  usersLoading: false,
  scrollLoader: false,
  usersError,
  usersToShow: [],
  requestStartOn: 0,
  noUsers: false,
});

const handleScrollLoader = (state) => ({
  ...state,
  scrollLoader: true,
  usersLoading: false,
});

const handleNoUsers = (state) => ({
  ...state,
  usersLoading: false,
  scrollLoader: false,
  usersError: true,
  usersToShow: [],
  noUsers: {
    errText: USER_ERRORS.NO_USERS,
  },
});

const handleSetUserToDelete = (state, userID) => ({
  ...state,
  userToDelete: userID,
});

const handleDeleteUser = (state, newUsersToShow) => ({
  ...state,
  deleteUserLoading: false,
  deleteUserError: false,
  usersToShow: newUsersToShow,
});

const handleDeleteUserLoading = (state) => ({
  ...state,
  deleteUserLoading: true,
});

const handleDeleteUserError = (state) => ({
  ...state,
  deleteUserLoading: false,
});

const handleUpdateUserType = (state, newUsersToShow) => ({
  ...state,
  usersLoading: false,
  usersToShow: newUsersToShow,
});

const handleUpdateUserTypeError = (state) => ({
  ...state,
  usersLoading: false,
});

const handleSetUserToAdd = (state, userToAdd) => ({
  ...state,
  userToAdd,
});

const handleAddUser = (state, newUsersToShow) => ({
  ...state,
  addUserLoading: false,
  addUserError: false,
  usersToShow: newUsersToShow,
});

const handleAddUserLoading = (state) => ({
  ...state,
  addUserLoading: true,
});

const handleAddUserError = (state) => ({
  ...state,
  addUserLoading: false,
});

const handleUpdateUserDataLoading = (state) => ({
  ...state,
  updateUserLoading: true,
});

const handleUpdateUserData = (state) => ({
  ...state,
  updateUserLoading: false,
});

const handleGetApiKey = (state, apiKey) => ({
  ...state,
  apiKey,
});

const handleGetRules = (state, { acquired, others }) => {
  const rulesToShow = acquired?.map((rule) => ({
    ...rule,
    formData: {
      ignore: !rule.active,
      review: rule.label === FRAUD_LABEL_ID.ID_1_TEXT.back && rule.active,
      reject: rule.label === FRAUD_LABEL_ID.ID_2_TEXT.back && rule.active,
      id: rule.id,
      rule_id: rule?.index,
    },
  }));

  return {
    ...state,
    loadingRules: false,
    errorRules: false,
    rulesToShow,
    rulesToHire: others,
  };
};

const handleSetRule = (state, { rules, updatedRule }) => {
  const rulesToShow = rules.map((rule) =>
    rule.id === updatedRule.id
      ? {
          ...rule,
          ...updatedRule,
          formData: {
            ignore: !updatedRule.active,
            review:
              updatedRule.fraud_label_id === FRAUD_LABEL_ID.ID_1 &&
              updatedRule.active,
            reject:
              updatedRule.fraud_label_id === FRAUD_LABEL_ID.ID_2 &&
              updatedRule.active,
            id: updatedRule.id,
          },
        }
      : rule
  );

  return {
    ...state,
    loadingRules: false,
    errorRules: false,
    rulesToShow,
  };
};

const handleLoadingRules = (state) => ({
  ...state,
  loadingRules: true,
  errorRules: false,
});

const handleRulesError = (state) => ({
  ...state,
  loadingRules: false,
});

const handleSetFilters = (state, showFilters) => ({
  ...state,
  showFilters,
});
