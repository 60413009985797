import { createContext, useContext, useMemo, useReducer } from "react";
import { sdkReducer } from "../reducers/sdkReducers";
import { initialState } from "../states/contexts/SDKContext";
import { TYPES } from "../actions/sdkActions";
import { url } from "./domain";
import AuthContext from "./AuthContext";
import { generateRequest } from "../helpers/helpHttp";

const SDKContext = createContext();

const SDKProvider = ({ children }) => {
  const { terminateSession, invalidSession } = useContext(AuthContext);

  const [state, dispatch] = useReducer(sdkReducer, initialState);
  const {
    webData,
    androidData,
    magicLinkData,
    loading,
    sdkPercentages,
    sdkToShow,
    noData,
  } = state;

  const getChartData = async (dates) => {
    try {
      const { from, to } = dates;

      const startYear = from.year;
      const startMonth = from.monthNumber + 1;
      const startDay = from.day;

      const endYear = to.year;
      const endMonth = to.monthNumber + 1;
      const endDay = to.day;

      dispatch({
        type: TYPES.LOADING_DATA,
      });

      const requestData = {
        url: `${url}/usage/track?start_date=${startYear}-${startMonth}-${startDay}&end_date=${endYear}-${endMonth}-${endDay}&period=day`,
        terminateSession,
        invalidSession,
      };

      const { data } = await generateRequest.get(requestData);

      if (data.err) throw data;

      // if (!data.WEB && !data.ANDROID && !data.IOS) {
      //   dispatch({
      //     type: TYPES.NO_DATA,
      //   });

      //   return;
      // }

      dispatch({
        type: TYPES.GET_DATA,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: TYPES.NO_DATA,
      });
    }
  };

  const setSDKToShow = (newList) => {
    dispatch({
      type: TYPES.SET_SDK_TO_SHOW,
      payload: newList,
    });
  };

  const data = useMemo(
    () => ({
      webData,
      androidData,
      magicLinkData,
      loading,
      sdkPercentages,
      sdkToShow,
      getChartData,
      setSDKToShow,
      noData,
    }),
    [
      webData,
      androidData,
      magicLinkData,
      loading,
      sdkPercentages,
      sdkToShow,
      getChartData,
      setSDKToShow,
      noData,
    ]
  );

  return <SDKContext.Provider value={data}>{children}</SDKContext.Provider>;
};

export { SDKProvider };
export default SDKContext;
