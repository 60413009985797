import { TYPES } from "../actions/reportActions";
import { generateReport } from "../helpers/generateReport";

export const reportReducer = (state, action) => {
  const findReport = (payload) => {
    const { data, showReport, error, loading, types, network_reports } =
      payload;

    const handleEmptyData = (state) => {
      return state;
    };

    const handleResponseStatus400 = (state, data) => {
      return {
        ...state,
        report: data,
        showReport,
        error,
        loading,
      };
    };

    const handleGenerateReport = (data, types, network_reports) => {
      return generateReport({
        completeData: data,
        data: data?.response ? data.response.raw_data : data.raw_data,
        network_reports: {
          list: network_reports.network_reports,
          total_reports: network_reports.total_reports,
        },
        types,
      });
    };

    if (!data) {
      return handleEmptyData(state);
    }

    if (data?.response_status === 400) {
      return handleResponseStatus400(state, data);
    }

    const report = handleGenerateReport(data, types, network_reports);

    return {
      ...state,
      report,
      showReport,
      error,
      loading,
    };
  };

  const addIdentityReported = (payload) => {
    const { identity_reported, notification_identity_id } = payload;

    return {
      ...state,
      identity_reported,
      notification_identity_id,
    };
  };

  const deleteIdentityReported = () => {
    const newState = { ...state };
    delete newState.identity_reported;
    delete newState.notification_identity_id;

    return newState;
  };

  const closeReport = () => {
    return {
      ...state,
      report: null,
      showReport: false,
    };
  };

  const toggleFlaggingForm = (payload) => {
    const { reportToFlag, reportFromStatus } = payload;

    return {
      ...state,
      reportToFlag,
      reportFromStatus,
    };
  };

  const flagReport = (payload) => {
    const flag = payload;

    const createReportFlagged = (state, flag) => {
      if (Array.isArray(state.report.network_reports.list)) {
        return {
          ...state.report,
          network_reports: {
            list: [flag, ...state.report.network_reports.list],
            total_reports: state.report.network_reports.total_reports + 1,
          },
          flag,
        };
      } else {
        return {
          ...state.report,
          network_reports: {
            list: [flag],
            total_reports: 1,
          },
          flag,
        };
      }
    };

    const reportFlagged = createReportFlagged(state, flag);

    return {
      ...state,
      report: reportFlagged,
    };
  };

  const updateReportFlag = (payload) => {
    const flag = payload;

    return {
      ...state,
      report: {
        ...state.report,
        flaggedFor: { ...flag, wasFlagged: true },
      },
    };
  };

  const setDevices = (payload) => {
    const devices = payload;

    return {
      ...state,
      devices,
    };
  };

  const openReport = (payload) => {
    const { loading, showReport } = payload;

    return {
      ...state,
      showReport,
      loading,
      report: {},
    };
  };

  const restart = () => {
    return {
      ...state,
      showReport: false,
      report: null,
      reportToFlag: false,
      loading: false,
      error: false,
    };
  };

  const setLoading = (payload) => {
    return {
      ...state,
      loading: payload,
    };
  };

  const noReport = (payload) => {
    return {
      ...state,
      error: payload,
      loading: false,
      showReport: false,
    };
  };

  const addExternalID = (payload) => {
    return {
      ...state,
      report: { ...state.report, external_id: payload },
    };
  };

  const addNetworkReports = (payload) => {
    const { network_reports } = payload;

    if (Array.isArray(network_reports)) {
      return {
        ...state,
        scrollLoader: false,
        report: {
          ...state.report,
          network_reports: {
            ...state.report.network_reports,
            list: [...state.report.network_reports.list, ...network_reports],
          },
        },
      };
    } else {
      return {
        ...state,
        scrollLoader: false,
      };
    }
  };

  const updateReportLabel = (payload) => {
    const { label, assigned_to } = payload;

    return {
      ...state,
      report: {
        ...state.report,
        label,
        assigned_to,
      },
    };
  };

  const handleScrollLoader = (state) => ({
    ...state,
    scrollLoader: true,
  });

  switch (action.type) {
    case TYPES.FIND_REPORT:
      return findReport(action.payload);
    case TYPES.ADD_IDENTITY_REPORTED:
      return addIdentityReported(action.payload);
    case TYPES.DELETE_IDENTITY_REPORTED:
      return deleteIdentityReported();
    case TYPES.CLOSE_REPORT:
      return closeReport();
    case TYPES.TOGGLE_FLAGGING_FORM:
      return toggleFlaggingForm(action.payload);
    case TYPES.FLAG_REPORT:
      return flagReport(action.payload);
    case TYPES.UPDATE_REPORT_FLAG:
      return updateReportFlag(action.payload);
    case TYPES.SET_DEVICES:
      return setDevices(action.payload);
    case TYPES.OPEN_REPORT:
      return openReport(action.payload);
    case TYPES.RESTART:
      return restart();
    case TYPES.LOADING:
      return setLoading(action.payload);
    case TYPES.NO_REPORT:
      return noReport(action.payload);
    case TYPES.ADD_EXTERNAL_ID:
      return addExternalID(action.payload);
    case TYPES.ADD_NETWORK_REPORTS:
      return addNetworkReports(action.payload);
    case TYPES.UPDATE_REPORT_LABEL:
      return updateReportLabel(action.payload);
    case TYPES.LOADING_SCROLL:
      return handleScrollLoader(state);
    default:
      return state;
  }
};
