import CheckBoxOutlineBlankRoundedIcon from "@mui/icons-material/CheckBoxOutlineBlankRounded";
import CheckBoxRoundedIcon from "@mui/icons-material/CheckBoxRounded";
import CheckBox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useContext, useState } from "react";
import DataContext from "../contexts/DataContext";
import { useTranslate } from "@tolgee/react";

const SelectRows = ({ setOpenForm }) => {
  const { columnsToShow, showColumns, headers } = useContext(DataContext);
  const [form, setForm] = useState(showColumns);
  const [list, setList] = useState(headers);
  const [dragging, setDragging] = useState(null);
  const { t } = useTranslate();

  const handleDragStart = (event, index) => {
    setDragging(index);
    event.dataTransfer.effectAllowed = "move";
  };

  const handleDragOver = (event, index) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
    const newList = [...list];
    const draggingItem = newList[dragging];
    newList.splice(dragging, 1);
    newList.splice(index, 0, draggingItem);
    setList(newList);
    setDragging(index);
  };

  const handleDragEnd = () => {
    setDragging(null);
  };

  const handleChange = ({ target: { name, checked } }) => {
    setForm({
      ...form,
      [name]: checked,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    columnsToShow(form);
    setOpenForm(false);
  };

  return (
    <form onSubmit={handleSubmit} className="selectRows">
      {list.map((el, i) => (
        <div
          className="selectRows--inputs"
          key={`headers-${i}`}
          draggable
          onDragStart={(event) => handleDragStart(event, i)}
          onDragOver={(event) => handleDragOver(event, i)}
          onDragEnd={handleDragEnd}
          style={{
            opacity: dragging === i ? 0.5 : 1,
            cursor: "move",
          }}
        >
          <FormControlLabel
            control={
              <CheckBox
                name={el.id}
                checked={form[el.id]}
                icon={<CheckBoxOutlineBlankRoundedIcon />}
                checkedIcon={<CheckBoxRoundedIcon />}
                onChange={handleChange}
                inputProps={{ "aria-label": "secondary checkbox" }}
                className="text-2"
              />
            }
            label={el.title}
          />
          <span className="icon-arrastrar"></span>
        </div>
      ))}
      <button
        type="submit"
        className="text-2 color--dodger-blue selectRows--btn"
      >
        {t("apply_filters_btn")}
      </button>
    </form>
  );
};
export default SelectRows;
