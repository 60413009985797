import { useTranslate } from "@tolgee/react";
import { ACCOUNT_TYPES, ROLES } from "../reducers/authReducer";

const DropdownAccountsContainer = ({
  classNames,
  containerData,
  functions,
}) => {
  const { t } = useTranslate();

  return (
    <>
      {ACCOUNT_TYPES.map((el, i) => {
        if (i === 0) return <></>;

        const { addUser, userID, account_type, role } = containerData;
        const { handleClick, handleTransferir } = functions;

        if (!addUser && i === ACCOUNT_TYPES.length - 1) {
          return (
            <div key={`${el.role}-${userID}`}>
              <button
                type="button"
                className={`${classNames.dropdownContentElement}`}
                onClick={() => {
                  const roleID = el.id;
                  handleClick(userID, roleID);
                }}
              >
                {el.role === account_type && (
                  <span className="icon-check-plano"></span>
                )}
                <span className="text-1">{el.role}</span>
              </button>
              {role === ROLES.PROPIETARIO && (
                <button
                  type="button"
                  className="team--dropdown__content--element"
                  onClick={() => {
                    handleTransferir(userID);
                  }}
                >
                  <span className="text-1">{t("drop_accounts_container")}</span>
                </button>
              )}
            </div>
          );
        }

        return (
          <button
            key={`${el.role}-${userID}`}
            className={`${classNames.dropdownContentElement}`}
            type="button"
            onClick={() => {
              const roleID = el.id;
              addUser ? handleClick(roleID) : handleClick(userID, roleID);
            }}
          >
            {el.role === account_type && (
              <span className="icon-check-plano"></span>
            )}
            <span className="text-1">{el.role}</span>
          </button>
        );
      })}
    </>
  );
};
export default DropdownAccountsContainer;
