import {
  Link,
  Navigate,
  Outlet,
  useLocation,
  //useNavigate,
} from "react-router-dom";
import { VERSION, styles } from "../../version";
import { PUBLIC_ROUTES } from "./routes";
import logo from "../../styles/images/logo-trully.svg";
import logoFace from "../../styles/images/facekey.png";
import { T } from "@tolgee/react";
import { useContext } from "react";
import AuthContext from "../../contexts/AuthContext";

const RecoveryLayout = ({ step }) => {
  const {
    isFacekey
  } = useContext(AuthContext);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("token");
  //const navigate = useNavigate();

  if (token) {
    return <Navigate to={`/reset/${token}`} />;
  }

  return (
    <>
      <main className="recovery">
        <section className="recovery__title">
          <img
            src={isFacekey ? logoFace : logo}
            alt="logo trully"
            className="publicLayout__form--logo"
          />
          <h2 className="heading-1">
            <T keyName="recovery_layout_title" />
          </h2>
          {step === 1 ? (
            <p className="text-1">
              <T keyName="recovery_layout_description" />
            </p>
          ) : (
            <p className="text-1">
              <T keyName="recovery_layout_pass" />
            </p>
          )}
        </section>
        <section className="recovery__form">
          <Outlet />
        </section>
        {step === 1 && (
          <Link to={PUBLIC_ROUTES.SIGNIN} className="recovery--link">
            <T keyName="recovery_layout_back" />
          </Link>
        )}
        {/* <div className="signIn__new recovery--link1">
          <span style={{ marginRight: "8px" }} className="text-2">
            ¿Aún no tienes una cuenta?
          </span>
          <button
            onClick={() => navigate(PUBLIC_ROUTES.SIGNUP)}
            className="text-2 color--dodger-blue"
          >
            Regístrate
          </button>
        </div> */}
      </main>
      <h4 style={styles}>{VERSION}</h4>
    </>
  );
};
export default RecoveryLayout;
