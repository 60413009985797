import { useContext, useState } from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import AuthContext from "../../contexts/AuthContext";
import { PUBLIC_ROUTES } from "./routes";

export function CheckAuth() {
  const { keepSessionOpen } = useContext(AuthContext);
  const [storageUser, setStorageUser] = useState(() => {
    const metadata = JSON.parse(sessionStorage.getItem("metadata"));
    const logged = sessionStorage.getItem("logged");

    if (logged) {
      sessionStorage.removeItem("logged");
      return true;
    }

    if (metadata) {
      keepSessionOpen();
      return true;
    } else return false;
  });
  const location = useLocation();

  return (
    <>
      {storageUser ? (
        <Outlet />
      ) : (
        <Navigate
          to={PUBLIC_ROUTES.SIGNIN}
          state={{ from: location }}
          replace
        />
      )}
    </>
  );
}
