export const validateNumbers = (text) => {
  if (text.trim() === "") return;

  const number = Number(text);

  if (!Number.isInteger(number)) return false;

  return !Number.isNaN(number);
};

export const validateImage = (imageName) => {
  const regExp = /\.(jpe?g|png|webp)$/i;

  return regExp.test(imageName);
};

export const validateCURP = (curp) => {
  if (curp.trim() === "") return;
  const regExp = /^[A-z]{4}[0-9]{6}[HhMm][A-z]{5}[A-z0-9]{2}$/;

  return regExp.test(curp);
};

export const validateEmail = (email) => {
  if (email.trim() === "") return;
  const regExp = /^[^@\s]+@([\w-]+\.)+[\w-]+$/;

  return regExp.test(email);
};

export const validateAddress = (address) => {
  if (address.trim() === "") return;
  const regExp =
    /^(?=.*[A-Za-zÁáÉéÍíÓóÚúÜüÑñ])(?=.*[0-9])[A-z0-9ÑñÁáÉéÍíÓóÚúÜü., -#]+$/;

  return regExp.test(address);
};

export const validateAddressCoordLong = (address) => {
  if (address.trim() === "") return;
  let valid;

  const regExp = /^([-+]?\d+)(\.\d+)?$/;

  if (regExp.test(address))
    valid =
      parseInt(address) <= -86.697677046 && parseInt(address) >= -118.377794421;

  return valid;
};

export const validateAddressCoordLat = (address) => {
  if (address.trim() === "") return;
  let valid;

  const regExp = /^([-+]?\d+)(\.\d+)?$/;

  if (regExp.test(address))
    valid =
      parseInt(address) <= 32.727652907 && parseInt(address) >= 14.521250588;

  return valid;
};

export const validateRFC = (rfc) => {
  if (rfc.trim() === "") return;
  const regExp = /^[A-ZÑ&]{3,4}\d{6}[A-V1-9][0-9A-Z]{2}$/i;

  return regExp.test(rfc);
};

export const validateIP = (ip) => {
  if (ip.trim() === "") return;

  const regExp =
    /^((\d{1,2}|1\d{2}|2[0-4]\d|25[0-5])\.){3}(\d{1,2}|1\d{2}|2[0-4]\d|25[0-5])$/;

  return regExp.test(ip);
};

export const validatePass = (pass) => {
  if (pass.trim() === "") return;

  const regExp =
    /^(?=(?:.*\d)+)(?=(?:.*[A-Z])+)(?=(?:.*[a-z])+)(?=(?:.*[@$!#%*?&])+)[a-zA-Z\d@$!#%*?&]{8,}$/g;

  return regExp.test(pass);
};

export const validateName = (name) => {
  if (name.trim() === "") return;

  const regExp = /^[a-zA-Z\u00C0-\u017F\s]+$/g;

  return regExp.test(name);
};

export const validateRepeatPass = (pass1, pass2) => {
  if (!pass1 || !pass2) return;
  return pass1.trim() === pass2.trim();
};

export const validateEmails = (emails) => {
  if (emails.includes(",")) {
    const emailsArr = emails.split(",");
    const validations = [];
    emailsArr.forEach((email) => {
      validations.push(validateEmails(email));
    });
    const errors = validations.find((el) => el !== true);
    return errors;
  } else {
    return validateEmail(emails);
  }
};

export const validatePhone = (phone) => {
  if (phone.trim() === "") return;

  let numbers = phone.slice(2);
  const regExp = /^[0-9+\s]{10,14}$/;

  return regExp.test(numbers);
};

export const validateString = (text) => {
  const regExp = /^[a-zA-Z0-9]*$/;

  return regExp.test(text);
};

export const validateExternalID = (text) => {
  const regExp = /^[-A-z0-9]*$/;

  return regExp.test(text);
};

export const validateTrullyID = (text) => {
  const regExp = /^[A-z0-9]*$/;

  return regExp.test(text);
};

export const validateURL = (url) => {
  if (url.trim() === "") return;

  const regExp =
    /^(https?:\/\/)(www\.)?([\da-z.-]+)([\/\w .-]*[\w\+.-]*)?(\?[^\s]*)?$/;
  return regExp.test(url);
};

export const validateWebhookURL = (url) => {
  if (url.trim() === "") return;

  const regExp =
    /^(https?:\/\/)(www\.)?(?=[\da-z-]+\.[a-z]{2,6}\/?)([\da-z-]+\.[a-z]{2,6})([\/\w \.-]*)*\/?$/;
  return regExp.test(url);
};
