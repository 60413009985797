import { useTolgee } from "@tolgee/react";

const ResumenRules = ({ totals, errorTotals }) => {
  const tolgee = useTolgee(["language"]);
  const isLangEs = tolgee.getLanguage() === "es-MX";

  const translateTitle = (title) => {
    if (isLangEs) return title;

    switch (title) {
      case "Aceptados":
        return "Accepted";
      case "Revisar":
        return "Review";
      case "Rechazados":
        return "Rejected";
      default:
        return "Total";
    }
  };

  if (errorTotals) {
    return (
      <div className="resumenConsultas">
        {errorTotals.map((el, i) => (
          <div
            key={`totals-${i}`}
            className={`resumenConsultas__element${i !== 0 ? "--border" : ""}`}
          >
            <div className="resumenConsultas__element__title--row">
              {el.title !== "Total" && (
                <div
                  className={`${el.icon} resumenConsultas__element__icon`}
                ></div>
              )}
              <h3 className="btn-text">{translateTitle(el.title)}</h3>
            </div>
            <span className="heading-1">
              {Intl.NumberFormat("en-US").format(el.total)}
            </span>
          </div>
        ))}
      </div>
    );
  }

  return (
    <div className="resumenConsultas">
      {totals.map((el, i) => (
        <div
          key={`totals-${i}`}
          className={`resumenConsultas__element${i !== 0 ? "--border" : ""}`}
        >
          <div className="resumenConsultas__element__title--row">
            {el.title !== "Total" && (
              <div
                className={`${el.icon} resumenConsultas__element__icon`}
              ></div>
            )}
            <h3 className="btn-text">{translateTitle(el.title)}</h3>
          </div>
          <span className="heading-1">
            {Intl.NumberFormat("en-US").format(el.total)}
          </span>
        </div>
      ))}
    </div>
  );
};
export default ResumenRules;
