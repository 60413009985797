const Devices = ({
  arrElementos,
  searchText,
  searchResults,
  handleClick,
  highlightSearchText,
}) => {
  return (
    <>
      <div className="autoCompleteSelect  slideInDown--floating">
        <div className="autoCompleteSelect__title">
          <div className="icon-buscar autoComplete__title--icon"></div>
          <span className="autoComplete__title--text">{searchText}</span>
        </div>
        <div className="autoCompleteSelect--border"></div>
        <div className="autoCompleteSelect__content">
          {searchResults.length !== 0
            ? searchResults.map((el, i) => (
                <button
                  key={`${el.model}-${i}`}
                  className="autoCompleteSelect__content--element"
                  onClick={handleClick}
                >
                  <span
                    className="autoCompleteSelect__content--text"
                    dangerouslySetInnerHTML={{
                      __html: highlightSearchText(el.model),
                    }}
                  ></span>
                </button>
              ))
            : arrElementos.map((el, i) => (
                <button
                  key={`${el.model}-${i}`}
                  className="autoCompleteSelect__content--element"
                  onClick={handleClick}
                >
                  <span className="autoCompleteSelect__content--text">
                    {el.model}
                  </span>
                </button>
              ))}
        </div>
      </div>
    </>
  );
};
export default Devices;
