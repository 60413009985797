import Button from "./Button";

const ChangePassModal = ({ handleClick }) => {
  return (
    <section className="modal sendMailModal">
      <div className="modal--center-container">
        <div className="modal--center-container__container">
          <span className="icon-check changePassModal--icon"></span>
          <p className="heading-3 modal--center-container__container--text sendMailModal--center-container__container--text">
            Su contraseña ha sido cambiada correctamente
          </p>
          <div className="modal--center-container__container__buttons">
            <Button
              handleClick={handleClick}
              styles={{ padding: "9px 12px" }}
              text="Aceptar"
            />
          </div>
        </div>
      </div>
    </section>
  );
};
export default ChangePassModal;
