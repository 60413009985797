import { useAutocomplete } from "@mui/base/useAutocomplete";
import {
  InputWrapper,
  Listbox,
  Root,
  StyledTag,
} from "../styles/AutoCompleteStyles";
import { useTolgee } from "@tolgee/react";

const AutoCompleteSelect = ({
  id,
  multiple = false,
  filters,
  value,
  selectedOption,
  autoHighlight = true,
  placeholder = "",
  inputText,
  handleChange,
  handleInputChange,
  handleSubmit,
  handleDelete,
}) => {
  const tolgee = useTolgee(["language"]);

  const {
    getRootProps,
    getInputProps,
    getTagProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    focused,
    value: valueProp,
    setAnchorEl,
    anchorEl,
  } = useAutocomplete({
    id,
    multiple,
    options: filters,
    getOptionLabel: (option) => (option ? option.title : ""),
    autoHighlight,
    value,
    onChange: (event, newValue) => {
      handleChange(newValue);
    },
    onInputChange: (event, newInputValue) => {
      handleInputChange(newInputValue);
    },
  });

  const translateDescription = (description) => {
    const isLangEs = tolgee.getLanguage() === "es-MX";
    if (isLangEs) return description;

    switch (description) {
      case "Búsqueda por ID":
        return "Search by ID";
      case "Búsqueda por ID de un usuario":
        return "Search by User ID";
      case "Búsqueda por ID de la Red Trully":
        return "Search by Trully Network ID";
      case "Búsqueda por ID de Magic Link":
        return "Search by Magic Link url";
      case "Búsqueda por Tipo de Canal":
        return "Search by SDK channel";

      default:
        return description;
    }
  };

  return (
    <Root>
      <div {...getRootProps()}>
        <InputWrapper
          ref={setAnchorEl}
          className={focused ? "focused" : ""}
          onSubmit={(e) => handleSubmit({ e, anchorEl, valueProp, id })}
        >
          {selectedOption && (
            <StyledTag
              label={selectedOption.title}
              {...getTagProps({ index: 0 })}
              onDelete={() => handleDelete(anchorEl, id)}
            />
          )}
          {placeholder && (
            <div className="icon-buscar SearchBarWithFilters__icon"></div>
          )}
          <input
            {...getInputProps()}
            value={inputText}
            placeholder={placeholder}
          />
        </InputWrapper>
      </div>
      {groupedOptions.length > 0 ? (
        <Listbox {...getListboxProps()}>
          {groupedOptions.map((option, index) => (
            <li {...getOptionProps({ option, index })}>
              <span className="SearchBarWithFilters__li-title">
                {option.title}
              </span>
              <span className="SearchBarWithFilters__li-description">
                {translateDescription(option.description)}
              </span>
            </li>
          ))}
        </Listbox>
      ) : null}
    </Root>
  );
};
export default AutoCompleteSelect;
