import { COLORS } from "../styles/utils/colors";
import Button from "./Button";
import { useTranslate } from "@tolgee/react";

const UserData = ({
  title,
  data,
  edit,
  inputName,
  handleChange,
  handleBtn,
  form,
}) => {
  const { t } = useTranslate();
  return (
    <div className="perfil__container--data-text">
      <span>{title}</span>
      {edit && inputName && (
        <input
          type="text"
          name={inputName}
          onChange={({ target: { value, name } }) => handleChange(value, name)}
          value={form[inputName]}
          className="perfil__container--data-text--input text-2"
        />
      )}{" "}
      {!edit && <span>{data}</span>}
      {edit && !inputName && (
        <Button
          text={t("change_pass_text")}
          handleClick={handleBtn}
          styles={{
            color: COLORS.SHARK,
            backgroundColor: COLORS.WHITE,
            border: `1px solid ${COLORS.CATSKILL_WHITE}`,
            padding: "6px 12px",
          }}
        />
      )}
    </div>
  );
};
export default UserData;
