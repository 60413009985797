import { useContext, useState } from "react";
import TextInput from "./TextInput";
import AuthContext from "../contexts/AuthContext";
import { validateEmail } from "../helpers/formValidations";
import ErrorSlideTimeOut from "./ErrorSlideTimeOut";
import SendMailModal from "./SendMailModal";
import SmallLoader from "./SmallLoader";
import { useFormValidation } from "../hooks/useFormValidation";
import Button from "./Button";
import { useTranslate } from "@tolgee/react";

const initialState = {
  email: "",
};

const initialValidateForm = [{ name: "email", valid: true }];

const SendMail = () => {
  const {
    sendMailError,
    setSendMailError,
    sendRecoveryMail,
    resetSendMailError,
    mailSended,
    loading,
  } = useContext(AuthContext);
  const [form, setForm] = useState(initialState);
  const { validateForm, setValidateForm, handleFormValidation } =
    useFormValidation(initialValidateForm);
  const { t } = useTranslate();

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!form.email) {
      setSendMailError(t("send_mail_error"));
      return;
    }

    sendRecoveryMail(validateForm, form.email);
    setForm(initialState);
    setValidateForm(initialValidateForm);
  };

  const styles = {
    boxShadow:
      "0px 6px 40px rgba(0, 0, 0, 0.04), 0px 10px 16px rgba(0, 0, 0, 0.0274815)",
  };

  return (
    <>
      {mailSended && <SendMailModal handleClick={resetSendMailError} />}
      <div className="sendPassword">
        <form onSubmit={handleSubmit} className="sendPassword__form">
          <TextInput
            form={form}
            inputName="email"
            inputLabel={t("input_email_label")}
            description={t("input_email_description")}
            setValue={setForm}
            validate={validateEmail}
            handleFormValidation={handleFormValidation}
            styles={styles}
            inputStylesMUI={{ width: "375px", maxWidth: "500px" }}
          />
          <Button
            type="submit"
            text={t("send_mail_btn")}
            handleClick={() => {}}
            disabled={!validateEmail(form.email)}
          />
        </form>
        {loading && <SmallLoader />}
        <ErrorSlideTimeOut
          error={sendMailError}
          restartFn={resetSendMailError}
          styles={{ textAlign: "center", paddingLeft: 0 }}
        />
      </div>
    </>
  );
};
export default SendMail;
