import { useEffect, useState } from "react";
import Button from "./Button";
import { FLAGS_NAMES } from "../states/contexts/ReportContext";
import NameBadge from "./NameBadge";
import { COLORS } from "../styles/utils/colors";
import SelectStatus from "./SelectStatus";
import { useTranslate } from "@tolgee/react";

const ChangeStatus = ({
  reportID,
  status,
  assigned_to,
  flagReport,
  report,
  updateReportStatus,
}) => {
  const [showSelector, setShowSelector] = useState(false);
  const [label, setLabel] = useState(status);
  const [assignedTo, setAssignedTo] = useState(() => assigned_to);
  const { t } = useTranslate();

  const sendReview = () => {
    const statusToSend =
      label === FLAGS_NAMES.NO_THREAT ? "approved" : "reject";
    if (statusToSend === "approved")
      updateReportStatus({ reportID, newStatus: statusToSend, label });
    else {
      const reportFromStatus = true;
      flagReport(report, reportFromStatus);
    }
    setShowSelector(() => false);
  };

  const openSelector = () => setShowSelector((prev) => !prev);

  useEffect(() => {
    setLabel(() => status);
    setAssignedTo(() => assigned_to);
  }, [status, assigned_to]);

  return (
    <div className="changeStatus">
      <NameBadge name={assignedTo} color={COLORS.BUTTERMILK} />
      <div className="changeStatus__btnContainer">
        {label === FLAGS_NAMES.FRAUD ||
        label === FLAGS_NAMES.POTENTIAL_THREAT ? (
          <Button
            icon={showSelector ? "icon-expand-menos" : "icon-down"}
            styles={{
              color: COLORS.COPPERFIELD,
              backgroundColor: COLORS.PEACH_SCHNAPPS,
              border: `1px solid ${COLORS.COPPERFIELD}`,
              flexDirection: "row-reverse",
              padding: "8px 12px",
            }}
            btnIconClass="changeStatus__colorShark"
            text={t("change_status_btn_threat")}
            handleClick={openSelector}
          />
        ) : label === FLAGS_NAMES.NO_THREAT ||
          label === FLAGS_NAMES.NOT_FRAUD ? (
          <Button
            icon={showSelector ? "icon-expand-menos" : "icon-down"}
            styles={{
              color: COLORS.KEPPEL,
              backgroundColor: COLORS.ICEBERG,
              border: `1px solid ${COLORS.KEPPEL}`,
              flexDirection: "row-reverse",
              padding: "8px 12px",
            }}
            btnIconClass="changeStatus__colorShark"
            text={t("change_status_btn_correct")}
            handleClick={openSelector}
          />
        ) : (
          <Button
            icon={showSelector ? "icon-expand-menos" : "icon-down"}
            styles={{
              color: COLORS.COM,
              backgroundColor: COLORS.BUTTERMILK,
              border: `1px solid ${COLORS.COM}`,
              flexDirection: "row-reverse",
              padding: "8px 12px",
            }}
            text={t("change_status_btn_review")}
            btnIconClass="changeStatus__colorShark"
            handleClick={openSelector}
          />
        )}
        <Button
          text={t("change_status_btn")}
          handleClick={sendReview}
          styles={{
            padding: "10px 16px",
          }}
        />
        {showSelector && (
          <SelectStatus status={label} setNewStatus={setLabel} />
        )}
      </div>
    </div>
  );
};
export default ChangeStatus;
