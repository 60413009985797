import { useNavigate } from "react-router-dom";
import { COLORS } from "../styles/utils/colors";
import Button from "./Button";
import { PRIVATE_ROUTES } from "./pages/routes";
import BtnFiltros from "./BtnFiltros";
import iconFiltros from "../styles/icons/images/icon-filtros.svg";
import DataContext from "../contexts/DataContext";
import { useContext } from "react";
import SearchBarWithFilters from "./SearchBarWithFilters";
import { useTranslate } from "@tolgee/react";

const ConsultasHeader = ({ closeReport, setOpenModal, showButtons }) => {
  const navigate = useNavigate();
  const {
    searchBy,
    getLastRequests,
    restartRequestsCount,
    serviceSearched,
    setServiceSearched,
  } = useContext(DataContext);
  const { t } = useTranslate();

  const handleFormSubmit = async ({ searchType, value }) => {
    if (value?.length === 0) {
      restartRequestsCount();
      getLastRequests({ requestStartOn: 0 });
      return;
    }

    searchBy({ serviceSearched: searchType, value, noLoader: false });
  };

  return (
    <div
      style={!showButtons ? { marginBottom: 0, height: "auto" } : {}}
      className="layout__main__section__consultas__header"
    >
      <div className="layout__main__section__consultas__header--title">
        <h2 style={{ display: "flex" }} className="heading-2">
          <button
            className="icon-anterior"
            onClick={() =>
              closeReport ? closeReport() : navigate(PRIVATE_ROUTES.DASHBOARD)
            }
          ></button>
          <span>{t("requests_title")}</span>
        </h2>
      </div>
      {showButtons && (
        <div className="layout__main__section__consultas__header--search-modifiers">
          <SearchBarWithFilters
            handleFormSubmit={handleFormSubmit}
            serviceSearched={serviceSearched}
            setServiceSearched={setServiceSearched}
          />
          <BtnFiltros
            filter="columns"
            icon={iconFiltros}
            title={t("btn_filtros_requests_title")}
            styles={{
              backgroundColor: COLORS.WHITE,
              border: `1px solid ${COLORS.SHUTTLE_GRAY}`,
              color: COLORS.SHARK,
              boxShadow: "none",
              padding: "8px 12px",
            }}
          />
          <Button
            icon="icon-descargar"
            text={t("export_btn")}
            styles={{
              backgroundColor: COLORS.WHITE,
              border: `1px solid ${COLORS.SHUTTLE_GRAY}`,
              color: COLORS.SHARK,
              boxShadow: "none",
              padding: "8px 12px",
            }}
            handleClick={() => setOpenModal(() => true)}
          />
        </div>
      )}
    </div>
  );
};
export default ConsultasHeader;
