import { T } from "@tolgee/react";

const Reviews = () => {
  return (
    <section className="reviews">
      <header className="reviews__header">
        <h2 className="heading-2"><T keyName="reviews_header" /></h2>
        <div className="reviews__searchContainer"></div>
      </header>
    </section>
  );
};
export default Reviews;
