import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";
import {
  IconButton,
  InputAdornment,
  Slide,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import IconInfo from "./IconInfo";
import { useEffect, useRef, useState } from "react";
import { convertToWebp } from "../helpers/convertToWebp";
import { COLORS } from "../styles/utils/colors";

const initialFile = {
  name: "",
  file: null,
  hasFile: false,
  fileName: "",
};

const FileInput = ({
  form,
  inputName,
  description,
  label,
  setValue,
  infoText,
  validate,
  validateErrorText,
  iconStyles,
  resetFile,
  setResetFile,
  handleFormValidation,
  styles,
  textClass,
  textStyles,
  inputStylesMUI,
  keyProp
}) => {
  const [isValid, setIsValid] = useState(undefined);
  const containerRef = useRef(null);
  const [file, setFile] = useState(initialFile);
  const fileInputRef = useRef(null);

  useEffect(() => {
    if (resetFile) {
      setFile(initialFile);
      setResetFile(false);
    }
  }, [resetFile]);

  useEffect(() => {
    if (!file.file) return;
    setValue(() => ({
      ...form,
      [file.name]: {
        file: file.file,
        hasFile: file.hasFile,
      },
    }));
  }, [file.file, file.name, file.hasFile, file.fileName]);

  useEffect(() => {
    if (handleFormValidation) {
      const input = {
        name: inputName,
        valid: typeof isValid === "undefined" ? true : isValid,
      };
      handleFormValidation(input);
    }
  }, [isValid]);

  useEffect(() => {
    if (form[inputName] === "" || typeof form[inputName] === "undefined")
      setIsValid(undefined);
  }, [form[inputName]]);

  const handleFileChange = (event) => {
    (async () => {
      const selectedFile = event.target.files[0];
      const valid = validate(selectedFile.name);

      if (typeof valid === "undefined") setIsValid(valid);
      else if (valid) {
        const maxSize = 4.5 * 1024 * 1024;

        if (selectedFile?.size > maxSize) {
          setIsValid(false);
          return;
        } else {
          setIsValid(true);
        }
      } else {
        setIsValid(false);
        return;
      }

      const img = await convertToWebp(selectedFile);
      if (!img) return;

      setFile({
        name: [event.target.name],
        file: img,
        hasFile: true,
        fileName: selectedFile.name,
      });
    })();
  };

  const handleClearFile = (event) => {
    event.preventDefault();
    setFile({
      name: [event.target.name],
      file: null,
      hasFile: false,
      fileName: "",
    });

    setValue((prev) => {
      const form = { ...prev };
      delete form[inputName];
      return form;
    });

    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const theme = createTheme({
    typography: {
      fontFamily: textStyles?.fontFamily || "DM Sans",
      allVariants: {
        color: textStyles?.secondaryColor || COLORS.SHUTTLE_GRAY,
      },
    },
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            width: inputStylesMUI?.width || "100%",
            maxWidth: inputStylesMUI?.maxWidth || "350px",
            fontFamily: textStyles?.fontFamily || "DM Sans",
            "& .MuiInputBase-input": {
              color: textStyles?.primaryColor || COLORS.SHARK,
            },
            "& label": {
              color: textStyles?.secondaryColor || COLORS.SHUTTLE_GRAY,
            },
            "& label.Mui-focused": {
              color: textStyles?.primaryColor || COLORS.SHARK,
            },
            "& .MuiInputBase-input::placeholder": {
              color: textStyles?.secondaryColor || COLORS.SHUTTLE_GRAY,
              opacity: 1,
            },
          },
        },
      },
      MuiFormControlLabel: {
        styleOverrides: {
          root: {
            width: inputStylesMUI?.width || "100%",
            maxWidth: inputStylesMUI?.maxWidth || "350px",
          },
          label: {
            width: inputStylesMUI?.width || "100%",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            fontFamily: textStyles?.fontFamily || "DM Sans",
            color: textStyles?.secondaryColor || COLORS.SHUTTLE_GRAY,
          },
        },
      },
    },
  });

  return (
    <div className="textInputComponent " key={keyProp}>
      <div className="textInputComponent--title">
        <span
          style={{ color: textStyles?.primaryColor || COLORS.SHUTTLE_GRAY }}
          className={`${
            textClass === "apply-font-text"
              ? textClass
              : "text-2 color--shuttle-gray"
          }`}
        >
          {description}
        </span>
        {infoText && <IconInfo text={infoText} styles={iconStyles} />}
      </div>
      <ThemeProvider theme={theme}>
        <FormControlLabel
          control={
            <TextField
              type="file"
              className="deleteDefaultInputFileStyle"
              name={inputName}
              onChange={handleFileChange}
              inputRef={fileInputRef}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                    className={`${!file.hasFile && "fileInputLabel--icon"} ${
                      isValid
                        ? "fileInputLabel--icon--valid"
                        : "fileInputLabel--icon--invalid"
                    }`}
                  >
                    {file.hasFile ? (
                      <IconButton name={inputName} onClick={handleClearFile}>
                        <div className="icon-quitar"></div>
                      </IconButton>
                    ) : (
                      <IconButton component="label">
                        <div className="icon-img"></div>
                        <input
                          type="file"
                          style={{ display: "none" }}
                          onChange={handleFileChange}
                          name={inputName}
                        />
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
              }}
            />
          }
          className={`custom-input custom-label textInputComponent--label fileInputLabel ${
            file.hasFile && "fileInputLabel--selected"
          }`}
          label={file.fileName || label}
          style={styles}
        />
      </ThemeProvider>
      <div ref={containerRef} className="slideContainer">
        <Slide
          direction="down"
          in={isValid === false}
          mountOnEnter
          unmountOnExit
          timeout={500}
          container={containerRef.current}
        >
          <p className="inputTextErrMsg">{validateErrorText}</p>
        </Slide>
      </div>
    </div>
  );
};
export default FileInput;
