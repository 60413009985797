import { useContext, useState } from "react";
import TextInput from "./TextInput";
import {
  validateName,
  validatePass,
  validateRepeatPass,
} from "../helpers/formValidations";
import InputPass from "./InputPass";
import { useFormValidation } from "../hooks/useFormValidation";
import SmallLoader from "./SmallLoader";
import ErrorSlideTimeOut from "./ErrorSlideTimeOut";
import AuthContext from "../contexts/AuthContext";
import { useLocation /*useNavigate*/ } from "react-router-dom";
// import { PUBLIC_ROUTES } from "./pages/routes";
import { Checkbox, FormControlLabel } from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const initialState = (email, token = null) => {
  return {
    first_name: "",
    last_name: "",
    email,
    password1: "",
    password2: "",
    token,
  };
};

const initialValidateForm = [
  {
    name: "first_name",
    valid: true,
  },
  {
    name: "last_name",
    valid: true,
  },
  {
    name: "password1",
    valid: true,
  },
  {
    name: "password2",
    valid: true,
  },
];

const SignUp = () => {
  const location = useLocation();
  const [acceptTerms, setAcceptTerms] = useState(false);
  const { signupError, loading, signup, setSignupError, resetSignupError } =
    useContext(AuthContext);
  const { validateForm, setValidateForm, handleFormValidation } =
    useFormValidation(initialValidateForm);
  //const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const [form, setForm] = useState(
    initialState(searchParams.get("email"), searchParams.get("token"))
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    const first_name = form.first_name;
    const last_name = form.first_name;
    const email = form.email;
    const password1 = form.password1;
    const password2 = form.password2;

    if (!first_name || !last_name || !email || !password1 || !password2) {
      setSignupError("Debe completar todos los campos");
      return;
    }

    signup(validateForm, form);
    setValidateForm(initialValidateForm);
  };

  const styles = {
    boxShadow:
      "0px 6px 40px rgba(0, 0, 0, 0.04), 0px 10px 16px rgba(0, 0, 0, 0.0274815)",
  };

  return (
    <>
      <section className="signIn__title signup__title">
        <h2 className="heading-1">Forma parte de nuestra red</h2>
        <h2 className="heading-1 signup--h2-mb">Empieza ahora</h2>
        <p className="text-1">Ingresa tus datos</p>
      </section>
      <form onSubmit={handleSubmit} className="signIn__form signup__form">
        <div className="signup__form--group">
          <TextInput
            form={form}
            inputName="first_name"
            inputLabel="Juan"
            description="Nombre"
            setValue={setForm}
            validate={validateName}
            validateErrorText="Sólo letras y espacios"
            handleFormValidation={handleFormValidation}
            styles={styles}
            inputStylesMUI={{ maxWidth: "none" }}
          />
          <TextInput
            form={form}
            inputName="last_name"
            inputLabel="Pérez"
            description="Apellido"
            setValue={setForm}
            validate={validateName}
            validateErrorText="Sólo letras y espacios"
            handleFormValidation={handleFormValidation}
            styles={styles}
            inputStylesMUI={{ maxWidth: "none" }}
          />
        </div>
        <TextInput
          form={form}
          inputName="email"
          description="Correo electrónico"
          disabled={true}
          validateErrorText="Debe ingresar un email válido"
          handleFormValidation={handleFormValidation}
          styles={styles}
          inputStylesMUI={{ maxWidth: "none" }}
        />
        <InputPass
          form={form}
          inputName="password1"
          description="Contraseña"
          setValue={setForm}
          validate={validatePass}
          infoText="Incluye al menos una letra mayúscula, una minúscula, un número y uno de los siguientes caracteres @$!#%*?&"
          validateErrorText="Debe ingresar una contraseña válida"
          iconStyles={{ width: "300px" }}
          handleFormValidation={handleFormValidation}
          styles={styles}
          inputStylesMUI={{ maxWidth: "none" }}
        />
        <InputPass
          form={form}
          inputName="password2"
          description="Repetir Contraseña"
          setValue={setForm}
          validate={validateRepeatPass}
          handleFormValidation={handleFormValidation}
          styles={styles}
          inputStylesMUI={{ maxWidth: "none" }}
        />

        <FormControlLabel
          control={
            <Checkbox
              checked={acceptTerms}
              icon={<CheckBoxOutlineBlankIcon />}
              checkedIcon={<CheckBoxIcon />}
              onChange={() => setAcceptTerms(true)}
              inputProps={{ "aria-label": "secondary checkbox" }}
            />
          }
          label={
            <>
              <span className="text-2">He leído los </span>
              <a
                href="https://www.trully.ai/es/aviso_privacidad_producto"
                target="_blank"
                rel="noopener noreferrer"
                className="text-2 color--dodger-blue"
                style={{ textDecoration: "none" }}
              >
                términos de privacidad
              </a>
            </>
          }
        />

        {acceptTerms ? (
          <input
            type="submit"
            value="Crear cuenta"
            className="signIn__form--btn signup__form--btn"
          />
        ) : (
          <button
            disabled="disabled"
            className="signIn__form--btn--disable signup__form--btn--disable"
            onClick={signup}
          >
            Crear cuenta
          </button>
        )}
      </form>
      {loading && (
        <div className="signIn__submit signup--loader">
          <SmallLoader />
        </div>
      )}
      <ErrorSlideTimeOut
        error={signupError}
        restartFn={resetSignupError}
        styles={{ paddingLeft: 0 }}
      />
      {/* <div className="signIn__new">
        <span style={{ marginRight: "8px" }} className="text-2">
          ¿Ya tienes una cuenta?
        </span>
        <button
          onClick={() => navigate(PUBLIC_ROUTES.SIGNIN)}
          className="text-2 color--dodger-blue"
        >
          Inicia sesión
        </button>
      </div> */}
    </>
  );
};
export default SignUp;
