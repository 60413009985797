import { useContext, useState } from "react";
import Calendar from "./Calendar";
import DatesContext from "../contexts/DatesContext";
import Button from "./Button";
import ReportContext from "../contexts/ReportContext";
import { useTranslate } from "@tolgee/react";

const InitialPage = ({
  handleClick,
  setOpenModal,
  csvDates,
  handleCSVDates,
}) => {
  const { t } = useTranslate();

  return (
    <div className="modal__csv">
      <div className="modal__csv__header">
        <button
          className="icon-close modal__csv__btn"
          onClick={() => setOpenModal(() => false)}
        ></button>
        <h4 className="heading-2">{t("modal_csv_init_title")}</h4>
      </div>
      <div className="modal__csv__container">
        <p className="text-2">{t("modal_csv_init_text")}</p>
        <Calendar dates={csvDates} setDates={handleCSVDates} csvDates={true} />
      </div>
      <Button
        handleClick={handleClick}
        styles={{ alignSelf: "flex-end" }}
        text={t("modal_csv_btn")}
        icon="icon-descargar"
      />
    </div>
  );
};

const SendPage = ({ handleClick }) => {
  const { t } = useTranslate();

  return (
    <div className="modal__csv">
      <div className="modal__csv--send">
        <div className="modal__csv__sendHeader">
          <div className="icon-check modal__csv__sendHeader__icon"></div>
          <h4 className="heading-2">{t("modal_csv_send_title")}</h4>
        </div>
        <div className="modal__csv__sendContainer">
          <p className="text-2">{t("modal_csv_send_text")}</p>
        </div>
      </div>
      <Button
        handleClick={handleClick}
        styles={{ alignSelf: "flex-end" }}
        text={t("confirm_button")}
      />
    </div>
  );
};

const initialList = [
  { name: "init", show: true },
  { name: "send", show: false },
];

const ModalCSV = ({ setOpenModal }) => {
  const [pageList, setPageList] = useState(() => initialList);
  const [pageToShow, setPageToShow] = useState(() => {
    const pageToShow = pageList.find((page) => page.show);
    return pageToShow;
  });
  const { csvDates, handleCSVDates } = useContext(DatesContext);
  const { generateCSVReport } = useContext(ReportContext);

  const nextPage = () => {
    const currentPageName = pageToShow.name;
    const currentPageIndex = pageList.findIndex(
      (page) => page.name === currentPageName
    );
    setPageList((prev) => {
      const temp = prev.map((page) =>
        page.name === currentPageName ? { ...page, show: false } : page
      );
      const nextPageIndex = currentPageIndex + 1;
      temp[nextPageIndex] = { ...temp[nextPageIndex], show: true };

      setPageToShow(() => temp[nextPageIndex]);
      return temp;
    });
  };

  const sendCSV = () => {
    const { from, to } = csvDates;
    generateCSVReport({ from, to });
    setPageList(() => initialList);
    setOpenModal(() => false);
  };

  return (
    <section className="modal">
      <div className="modal--center-container">
        {pageToShow && pageToShow.name === "init" && (
          <InitialPage
            setOpenModal={setOpenModal}
            csvDates={csvDates}
            handleCSVDates={handleCSVDates}
            handleClick={nextPage}
          />
        )}
        {pageToShow && pageToShow.name === "send" && (
          <SendPage handleClick={sendCSV} />
        )}
      </div>
    </section>
  );
};
export default ModalCSV;
