const Button = ({
  icon,
  iconSvg,
  text,
  handleClick,
  styles,
  disabled,
  type,
  btnIconClass,
  btnClass,
  btnTextClass,
}) => {
  return (
    <button
      onClick={disabled ? () => {} : handleClick}
      style={styles ? { ...styles } : {}}
      className={`${disabled ? "button--disabled" : "button"} ${btnClass}`}
      type={type || "button"}
      disabled={disabled}
    >
      {icon && <div className={`button__icon ${icon} ${btnIconClass}`}></div>}
      {iconSvg && (
        <img
          src={iconSvg}
          alt="icono"
          className={`button__icon ${btnIconClass}`}
        />
      )}
      <span className={`btn-text ${btnTextClass}`}>{text}</span>
    </button>
  );
};
export default Button;
