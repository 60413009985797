const DropdownLinkOptionsContainer = ({
  classNames,
  containerData,
  functions,
}) => {
  const { options, linkId, isActive } = containerData;
  const { shareLink, editLink, seeResponses, handleActivation } = functions;

  return (
    <>
      {options.map((option, i) => (
        <div key={`options-${i}`}>
          <button
            type="button"
            className={`${classNames.dropdownContentElement}`}
            onClick={() => {
              switch (option.id) {
                case "share": {
                  shareLink(linkId);
                  break;
                }
                case "edit": {
                  editLink(linkId);
                  break;
                }

                case "see": {
                  seeResponses(linkId);
                  break;
                }
                case "activate": {
                  handleActivation(linkId);
                  break;
                }

                default:
                  break;
              }
            }}
          >
            {option.icon && (
              <span className={`${option.icon} ${classNames.icon}`}></span>
            )}
            <span
              className={`text-2 ${
                option.id === "activate"
                  ? isActive
                    ? "color--copperfield"
                    : "color--dodger-blue"
                  : ""
              }`}
            >
              {option.title}
            </span>
          </button>
        </div>
      ))}
    </>
  );
};
export default DropdownLinkOptionsContainer;
