import { useContext, useState } from "react";
import Button from "./Button";
import DropDownOptions from "./DropDownOptions";
import SDKContext from "../contexts/SDKContext";

const SelectSDK = () => {
  const { sdkToShow, setSDKToShow } = useContext(SDKContext);
  const [showList, setShowList] = useState(false);
  const [sdkShown, setSdkShown] = useState(() =>
    sdkToShow.find((sdk) => sdk.show)
  );
  const [sdksList, setSdksList] = useState(() => sdkToShow);

  const handleShow = (selection) => {
    setSdksList((prev) => {
      const newList = prev.map((sdk) =>
        sdk.id === selection.id
          ? { ...selection, show: true }
          : { ...sdk, show: false }
      );

      setSdkShown(() => newList.find((sdk) => sdk.show));
      setShowList(() => false);
      setSDKToShow(newList);

      return newList;
    });
  };

  return (
    <div className="selectSDK">
      <Button
        icon={showList ? "icon-expand-menos" : "icon-down"}
        handleClick={() => setShowList((prev) => !prev)}
        text={sdkShown.title}
        btnClass="selectSDK__btn"
        btnTextClass="text-2 "
      />
      <DropDownOptions
        show={showList}
        dropdownPosition={{ y: 50, x: -95 }}
        functions={{
          handleShow,
        }}
        containerData={{
          options: sdksList,
        }}
        classNames={{
          container: "team--dropdown-container",
          transition: "slideInSDK",
          dropdown: "selectSDK__dropdown",
          dropdownContent:
            "linkPage__options__dropdown__content selectSDK__dropdown__content",
          dropdownContentElement:
            "team--dropdown__content--element selectSDK__dropdown__element",
          icon: "selectSDK__dropdown__icon",
        }}
        inSDK={true}
      />
    </div>
  );
};
export default SelectSDK;
