import { useLocation, useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import AuthContext from "../contexts/AuthContext";
import { PRIVATE_ROUTES } from "./pages/routes";
import SnackBar from "./SnackBar";
import SnackBarContext from "../contexts/SnackBarContext";
import { CSSTransition } from "react-transition-group";
import SnackBarNotification from "./SnackBarNotification";
import NotificationsList from "./NotificationsList";
import contactIcon from "../styles/icons/images/menu-contact.svg";
import { COLORS } from "../styles/utils/colors";
import LangSelector from "./LangSelector";
import { T, useTolgee } from "@tolgee/react";
import { translateAccountType } from "./TeamTableRowTd";

const Header = ({ styles }) => {
  const {
    user: {
      role,
      first_name,
      last_name,
      notifications: { not_seen },
    },
  } = useContext(AuthContext);
  const location = useLocation();
  const navigate = useNavigate();
  const dash = location.pathname === PRIVATE_ROUTES.DASHBOARD;
  const perfil = location.pathname === PRIVATE_ROUTES.PERFIL;
  const [openNotifications, setOpenNotifications] = useState(false);
  const { snackToShow } = useContext(SnackBarContext);
  const tolgee = useTolgee(["language"]);
  const isLangEs = tolgee.getLanguage() === "es-MX";

  useEffect(() => {
    setOpenNotifications(false);
  }, [location.pathname]);

  return (
    <header className={`${styles} ${!dash && "header--align-end"}`}>
      {dash && (
        <h3 className="layout__main__header__title heading-3">
          <T
            keyName="header_welcome"
            defaultValue="Bienvenido, {first_name} {last_name}"
            params={{ first_name, last_name }}
          />
        </h3>
      )}

      <section className="layout__main__header__icons">
        <span className="text-4">{translateAccountType(role, isLangEs)}</span>
        <LangSelector />
        <button
          onClick={() => setOpenNotifications(!openNotifications)}
          className="layout__main__header__icons--btn"
        >
          {not_seen !== 0 && (
            <div className="layout__main__header__icons--notificar">
              <span className="text-4">{not_seen}</span>
            </div>
          )}
          {not_seen !== 0 ? (
            <span className="icon-flag-fill color--copperfield"></span>
          ) : (
            <span className="icon-flag"></span>
          )}
        </button>
        <a
          href="https://trullyai.atlassian.net/servicedesk/customer/portal/1"
          target="_blank"
          rel="noopener noreferrer "
          className="layout__main__header__icons--contact"
        >
          <img src={contactIcon} alt="Icono de contacto" />
        </a>
        <button
          className="layout__main__header__icons--user"
          onClick={() => navigate(PRIVATE_ROUTES.PERFIL)}
          style={{ color: perfil ? COLORS.DODGER_BLUE : COLORS.SHARK }}
        >
          <span className="icon-menu-perfil"></span>
        </button>
      </section>
      {openNotifications && (
        <NotificationsList setOpenNotifications={setOpenNotifications} />
      )}
      <CSSTransition
        in={snackToShow.show}
        timeout={500}
        classNames="slide"
        unmountOnExit
        mountOnEnter
      >
        <div className="contenedorSnackSlide">
          <SnackBar />
        </div>
      </CSSTransition>
      <div className="contenedorSnackSlide--notifications">
        <SnackBarNotification />
      </div>
    </header>
  );
};
export default Header;
