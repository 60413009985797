import { useTolgee } from "@tolgee/react";
import { getColumnPercentages } from "../helpers/getColumnPercentages";
import { useWindowSize } from "../hooks/useWindowSize";
import IconInfo from "./IconInfo";

const TableTh = ({
  id,
  title,
  icon,
  dataType,
  dataKey,
  sortedBy,
  headersBtnClick,
  showColumns,
  inConsultas,
  report,
  inTeam,
  inBilling,
  inLinks,
}) => {
  const stylesHora = {
    top: "35px",
    left: "0px",
    width: "190px",
  };

  const stylesID = {
    top: "-15px",
    left: "-105px",
  };

  const { width: windowSize } = useWindowSize();
  const smallScreen = windowSize < 1440;
  const now = new Date();
  const timeZoneOffset = now.getTimezoneOffset();
  const sign = timeZoneOffset > 0 ? "-" : "+";
  const hours = Math.floor(Math.abs(timeZoneOffset) / 60);
  const minutes = Math.abs(timeZoneOffset) % 60;
  const timeZone = `UTC ${sign}${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}hs`;

  const columnIds = [];
  for (const column in showColumns) {
    const bool = showColumns[column];

    if (bool) columnIds.push(column);
  }

  const percentages = getColumnPercentages(columnIds);
  const tolgee = useTolgee(["language"]);
  const isLangEs = tolgee.getLanguage() === "es-MX";

  const translate = (text) => {
    if (isLangEs) return text;

    switch (text) {
      case "Nombre":
        return "Name";
      case "Correo":
        return "Email";
      case "Cuenta creada":
      case "Fecha de creación":
        return "Created on";
      case "Tipo de cuenta":
        return "Account type";
      case "Hora":
        return "Time";
      case "Fecha":
        return "Date";
      case "Producto Usado":
        return "Product Used";
      case "Estatus":
        return "Status";
      case "Título":
        return "Title";
      case "Respuestas":
        return "Responses";
      case "ID de usuario":
        return "User ID";
      case "Informante":
        return "Informant";

      default:
        return text;
    }
  };

  if (inConsultas) {
    return (
      <>
        {showColumns[id] && (
          <th
            className={`layout__main__section__consultas__table__thead__th ${
              report && "withReport"
            }`}
            style={
              id === "date"
                ? { width: `${smallScreen ? "27.6" : percentages.date}%` }
                : id === "productName"
                ? { width: `${smallScreen ? "20" : percentages.productName}%` }
                : id === "id"
                ? { width: `${percentages.id}%` }
                : id === "externalId"
                ? { width: `${percentages.externalId}%` }
                : id === "informador"
                ? { width: `${percentages.informador}%` }
                : { width: `${percentages.estatus}%` }
            }
          >
            <span>{translate(title)}</span>
            {icon && (
              <IconInfo
                text={title === "Fecha" && `Hora local: ${timeZone}`}
                styles={
                  title === "ID"
                    ? stylesID
                    : title === "Fecha"
                    ? stylesHora
                    : {}
                }
              />
            )}
          </th>
        )}
      </>
    );
  }

  return (
    <th
      className={`layout__main__section__consultas__table__thead__th ${
        inTeam && "team"
      } ${inBilling && "billing"}`}
      style={
        inLinks && title === "Link ID"
          ? { width: "25%" }
          : title === "Link URL"
          ? { width: "20%" }
          : title === "Título"
          ? { width: "10%" }
          : title === "Fecha de creación"
          ? { width: "16%" }
          : title === "Respuestas"
          ? { width: "12%" }
          : {}
      }
    >
      <span>{translate(title)}</span>
      {title && (
        <button
          className={`${
            sortedBy === "desc" ? "icon-down" : "icon-expand-menos"
          }`}
          onClick={() => {
            const key = dataKey === "response_status" ? "label" : dataKey;

            headersBtnClick(title, dataType, key, sortedBy);
          }}
        ></button>
      )}
    </th>
  );
};
export default TableTh;
