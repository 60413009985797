import { base64ToSend } from "./base64ToSend";
import { convertBlobToWebPBase64 } from "./convertBlobToWebPBase64";

export const generateBase64FromBlob = async (blob) => {
  if (!blob) return null;

  if (typeof blob === "string") return blob;

  return base64ToSend(await convertBlobToWebPBase64(blob));
};
