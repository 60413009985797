import error500 from "../styles/images/EmptyState.svg";
import error400 from "../styles/images/noEncontrado.svg";

const ErrorComponent = ({ error, title, text, styles }) => {
  return (
    <div className="errorComponent" style={styles || {}}>
      <img
        className="errorComponent__img"
        alt="error"
        src={error === 500 ? error500 : error400}
      />
      <div className="errorComponent__text-container ">
        <h3 className="heading-2 errorComponent__title">{title}</h3>
        <span className="text-1--bold">{text}</span>
      </div>
    </div>
  );
};
export default ErrorComponent;
