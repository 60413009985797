import { useContext, useState } from "react";
import ReportContext from "../contexts/ReportContext";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { useTranslate } from "@tolgee/react";

const StudiesSubDropdown = () => {
  const {
    report: { studies_details },
  } = useContext(ReportContext);
  const { t } = useTranslate();

  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (isExpanded) => {
    if (panel === "") setExpanded(false);
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Accordion
      key="studies-sub"
      expanded={expanded}
      onChange={!expanded ? handleChange("studies-sub") : handleChange("")}
      className="reporteDataAccordion"
      style={{ minHeight: "none", paddingTop: "16px" }}
    >
      <AccordionSummary
        aria-controls="studies-sub-content"
        id="studies-sub-header"
        className="reporteDataAccordion__title--blue"
      >
        <div className="reporteDataAccordion__title__text--blue">
          <button>
            {!expanded ? (
              <div className="icon-down reporteDataAccordion__title__icon color--dodger-blue"></div>
            ) : (
              <div className="icon-expand-menos reporteDataAccordion__title__icon color--dodger-blue"></div>
            )}
          </button>
          <h3 className="text-2--bold color--dodger-blue">
            {t("studies_sub_drop_title")}
          </h3>
        </div>
      </AccordionSummary>
      <AccordionDetails
        style={{
          maxHeight: "335px",
          overflowY: "scroll",
        }}
        sx={{ transition: "height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;" }}
      >
        {studies_details.map((el, i) => {
          const { career, credential, institution, year } = el;
          return (
            <div
              key={`${institution}-${i}`}
              className={`
                ${
                  i < studies_details.length - 1 &&
                  "reporteDataAccordion__sub-studies"
                }
              `}
            >
              <h3 className="text-2--bold">{institution}</h3>
              <div className="reporteDataAccordion__description">
                <h4 className="text-2 color--shuttle-gray">
                  {t("studies_sub_drop_1")}
                </h4>
                <span className="text-2">{institution}</span>
              </div>
              <div className="reporteDataAccordion__description">
                <h4 className="text-2 color--shuttle-gray">
                  {t("studies_sub_drop_2")}
                </h4>
                <span className="text-2">{career}</span>
              </div>
              <div className="reporteDataAccordion__description">
                <h4 className="text-2 color--shuttle-gray">
                  {t("studies_sub_drop_3")}
                </h4>
                <span className="text-2">{year || "-"}</span>
              </div>
              <div className="reporteDataAccordion__description">
                <h4 className="text-2 color--shuttle-gray">
                  {t("studies_sub_drop_4")}
                </h4>
                <span className="text-2">{credential}</span>
              </div>
            </div>
          );
        })}
      </AccordionDetails>
    </Accordion>
  );
};
export default StudiesSubDropdown;
