export const TYPES = {
  GET_LAST_REQUEST: "GET_LAST_REQUEST",
  SET_TABLE_DATA: "SET_TABLE_DATA",
  LOAD_DATA: "LOAD_DATA",
  CHANGE_HEADERS: "CHANGE_HEADERS",
  SET_COLUMNS_TO_SHOW: "SET_COLUMNS_TO_SHOW",
  RESET_REQUEST_COUNT: "RESET_REQUEST_COUNT",
  GET_TOTALS: "GET_TOTALS",
  GET_CHART_DATA: "GET_CHART_DATA",
  NO_REQUEST_DATA: "NO_REQUEST_DATA",
  NO_CHART_DATA: "NO_CHART_DATA",
  LOADING: "LOADING",
  REQUEST_LOADING: "REQUEST_LOADING",
  CHART_LOADING: "CHART_LOADING",
  ERROR_REQUEST: "ERROR_REQUEST",
  ERROR_TOTALS: "TOTALS",
  ERROR_CHART: "ERROR_CHART",
  RESET_DATA: "RESET_DATA",
  SCROLL_LOADER: "SCROLL_LOADER",
  REQUESTS_FROM_LINKS: "REQUESTS_FROM_LINKS",
  CHANGE_SERVICES_TO_SHOW: "CHANGE_SERVICES_TO_SHOW",
  ERROR_PERCENTAGES: "ERROR_PERCENTAGES",
  GET_PERCENTAGES: "GET_PERCENTAGES",
  SET_SERVICE_SEARCHED: "SET_SERVICE_SEARCHED",
  SET_DASHBOARD_RULES: "SET_DASHBOARD_RULES",
  RULES_LOADING: "RULES_LOADING",
  NO_RULES_DATA: "NO_RULES_DATA",
};
