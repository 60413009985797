import { useContext, useEffect, useRef, useState } from "react";
import ReportContext from "../contexts/ReportContext";
import { validateExternalID } from "../helpers/formValidations";
import SnackBarContext from "../contexts/SnackBarContext";
import {
  SNACKBAR_STATES_NAMES,
  SNACKBAR_TEXTS,
  SNACKBAR_TEXTS_EN,
} from "../states/contexts/SnackContext";
import DataContext from "../contexts/DataContext";
import { useLocation } from "react-router-dom";
import { useTolgee, useTranslate } from "@tolgee/react";
const ExternaIDInput = () => {
  const {
    report: { id: reportID, external_id },
    generateExternalID,
  } = useContext(ReportContext);
  const { setExternalIDToTableData } = useContext(DataContext);
  const { setSnack } = useContext(SnackBarContext);
  const [userID, setUserID] = useState(external_id);
  const [edit, setEdit] = useState(false);
  const inputRef = useRef(null);
  const location = useLocation();
  const inBusq = location.pathname.includes("/busqueda");
  const { t } = useTranslate();
  const tolgee = useTolgee(["language"]);
  const isLangEs = tolgee.getLanguage() === "es-MX";

  useEffect(() => {
    if (!inputRef.current) return;

    inputRef.current.focus();
  }, [edit]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (userID === "") {
      setEdit(false);
      return;
    }

    const valid = validateExternalID(userID);

    if (valid) {
      generateExternalID(reportID, userID, setExternalIDToTableData);
      setEdit(false);
    } else {
      const snackState = {
        error: {
          err: true,
          errTextTitle: isLangEs
            ? SNACKBAR_TEXTS.ERROR_TITLE
            : SNACKBAR_TEXTS_EN.ERROR_TITLE,
          errText: isLangEs
            ? SNACKBAR_TEXTS.CREATE_EXTERNAL_ID_ERROR
            : SNACKBAR_TEXTS_EN.CREATE_EXTERNAL_ID_ERROR,
        },
      };
      setSnack(SNACKBAR_STATES_NAMES.CREATE_EXTERNAL_ID, snackState);
    }
  };

  return (
    <div className="textDataResumen__container--user-id">
      {" "}
      {edit ? (
        <form
          className="textDataResumen__container--user-id__form"
          onSubmit={handleSubmit}
        >
          <input
            type="text"
            value={userID}
            onChange={({ target: { value } }) => setUserID(() => value)}
            className="textDataResumen__container--user-id__form-input text-2"
            ref={inputRef}
          />
          <div className="textDataResumen__container--user-id__btn text-2">
            <div className="icon-guardar"></div>
            <button type="submit" className="text-2 color--dodger-blue">
              {t("save_btn")}
            </button>
          </div>
        </form>
      ) : (
        <div className="textDataResumen__container--user-id__form-elements--edit">
          <span className="text-2 color--shuttle-gray">
            {external_id || t("no_user_id")}
          </span>

          {!inBusq && (
            <div className="textDataResumen__container--user-id__btn text-2">
              <div className="icon-editar"></div>
              <button
                type="button"
                onClick={() => setEdit(true)}
                className="text-2 color--dodger-blue"
              >
                {t("btn_edit")}
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
export default ExternaIDInput;
