import { useContext, useEffect, useState } from "react";
import { changeSelection } from "../../helpers/changeSelection";
import PerfilDataComponent from "../PerfilDataComponent";
import Ajustes from "../Ajustes";
import ReportContext from "../../contexts/ReportContext";
import AuthContext from "../../contexts/AuthContext";
import { ROLES } from "../../reducers/authReducer";
import PerfilHeader from "../PerfilHeader";
import { useTolgee } from "@tolgee/react";

const initialTabStateConfig = [
  {
    id: "info-perfil",
    text: "Información del perfil",
    selected: true,
  },
  {
    id: "info-cuenta",
    text: "Ajustes de cuenta",
    selected: false,
  },
];

const Perfil = () => {
  const {
    user: { role },
  } = useContext(AuthContext);
  const tolgee = useTolgee(["language"]);
  const isLangEs = tolgee.getLanguage() === "es-MX";
  const { report, closeReport } = useContext(ReportContext);
  const [tabs, setTabs] = useState(initialTabStateConfig);

  const changeTab = (id) => changeSelection(id, tabs, setTabs);

  const translate = (text) => {
    if (isLangEs) return text;

    if (text === "Información del perfil") return "Profile information";
    else return "Account settings";
  };

  useEffect(() => {
    if (report) closeReport();
  }, []);

  return (
    <section className="perfil">
      <div className="ajustes__title">
        <PerfilHeader
          tabs={tabs}
          changeTab={changeTab}
          role={role}
          translate={translate}
        />
      </div>

      {role !== "" && (role === ROLES.PROPIETARIO || role === ROLES.ADMIN) ? (
        tabs[0].selected ? (
          <PerfilDataComponent />
        ) : (
          <Ajustes />
        )
      ) : (
        <PerfilDataComponent />
      )}
    </section>
  );
};
export default Perfil;
