import { useContext, useEffect, useState } from "react";
import ConfigLink from "./ConfigLink";
import ConfigIdentity from "./ConfigIdentity";
import ConfigBrand from "./ConfigBrand";
import LinkPreview from "./LinkPreview";
import LinkConfigBtnContainer from "./LinkConfigBtnContainer";
import LinkContext from "../contexts/LinkContext";
import { initialState } from "../states/contexts/LinkContext";
import { COLORS } from "../styles/utils/colors";
import { FONT_FAMILY } from "../styles/utils/FONT_FAMILY";
import { T, useTolgee, useTranslate } from "@tolgee/react";

const optionsConfig = {
  "es-MX": [
    { title: "Initial Tab", id: "initialTab", show: true },
    { title: "Configuración de Link", id: "config", show: false },
    { title: "Verificación de identidad", id: "identity", show: false },
    { title: "Elementos de marca", id: "brand", show: false },
  ],
  en: [
    { title: "Initial Tab", id: "initialTab", show: true },
    { title: "Link Configuration", id: "config", show: false },
    { title: "Identity Verification", id: "identity", show: false },
    { title: "Brand Elements", id: "brand", show: false },
  ],
};

const InitialTab = ({
  sdkName,
  options,
  showConfigPage,
  closeConfig,
  generateMagicLink,
  isEdit,
  linkData,
  linkDataToUpdate,
  setActiveLink,
}) => {
  const [allowSend, setAllowSend] = useState(() => !isEdit);
  const { t } = useTranslate();
  useEffect(() => {
    if (!isEdit) return;

    const {
      config: { type, external_id, redirect_url, webhook_url, identify_user },
      identity: {
        location,
        email,
        // email_otp,
        curp,
        phone_number,
        // phone_number_otp,
        personal_info,
        address,
        document,
        selfie,
      },
      brand: {
        logo,
        colors: { primary, secondary, textPrimary, textSecondary },
        font: { heading1, heading2, text },
      },
    } = linkData;

    const {
      one_time_only: initialType,
      external_id: initialExternalID,
      identify_user: initialIdentify_user,
      sections: {
        location: initialLocation,
        email: initialEmail,
        // email_otp: initialOtp,
        curp: initialCurp,
        phone_number: initialPhoneNumber,
        // phone_number_otp: initialPhoneNumberOtp,
        personal_info: initialPersonalInfo,
        address: initialAddress,
        document: initialDocument,
        selfie: initialSelfie,
      },
      metadata: {
        font_primary_heading: initialHeading1,
        font_secondary_heading: initialHeading2,
        font_text: initialText,
        logo: initialLogo,
        primary_color: initialPrimaryColor,
        secondary_color: initialSecondaryColor,
        text_primary_color: initialPrimaryTextColor,
        text_secondary_color: initialSecondaryTextColor,
        redirect_url: initialRedirect_url,
        webhook_url: initialWebhook_url,
      },
    } = linkDataToUpdate;

    if (
      type !== initialType ||
      external_id !== initialExternalID ||
      redirect_url !== initialRedirect_url ||
      webhook_url !== initialWebhook_url ||
      identify_user !== initialIdentify_user ||
      location !== initialLocation ||
      email !== initialEmail ||
      curp !== initialCurp ||
      phone_number !== initialPhoneNumber ||
      personal_info !== initialPersonalInfo ||
      address !== initialAddress ||
      document !== initialDocument ||
      selfie !== initialSelfie ||
      heading1 !== initialHeading1 ||
      heading2 !== initialHeading2 ||
      text !== initialText ||
      logo !== initialLogo ||
      primary !== initialPrimaryColor ||
      secondary !== initialSecondaryColor ||
      textPrimary !== initialPrimaryTextColor ||
      textSecondary !== initialSecondaryTextColor
    )
      setAllowSend(() => true);
  }, []);

  return (
    <section className="linkPage__configuration">
      <div className="linkPage__configuration__menu">
        <div className="linkPage__configuration__menu__container">
          <h2 className="heading-2">{sdkName}</h2>
          <p className="text-2">
            <T keyName="initial_tab_text" />
          </p>
          {options.map((option, i) => {
            if (i === 0) return <></>;
            return (
              <Option
                key={`links-${option.id}`}
                {...option}
                handleClick={showConfigPage}
              />
            );
          })}
        </div>

        <LinkConfigBtnContainer
          cancelBtnConfig={{
            text: t("cancel_button"),
            handleClick: () => {
              setActiveLink(initialState.activeLink, initialState.isEdit);
              closeConfig();
            },
          }}
          saveBtnConfig={{
            text: t("save_publish_button"),
            handleClick: generateMagicLink,
          }}
          enableSave={allowSend}
        />
      </div>
      <LinkPreview linkData={linkData} />
    </section>
  );
};

const Option = ({ title, id, handleClick }) => {
  return (
    <button className="linkPage__option" onClick={() => handleClick(id)}>
      <h4 className="text-1--bold">{title}</h4>
      <button className="linkPage__option__btn">
        <span className="icon-siguiente"></span>
      </button>
    </button>
  );
};

const LinkConfiguration = ({
  sdkName,
  closeConfig,
  linkDataToUpdate,
  setShowShareLinkModal,
}) => {
  const tolgee = useTolgee(["language"]);
  const initialOptions =
    optionsConfig[tolgee.getLanguage()] || optionsConfig["es-MX"];
  const [options, setOptions] = useState(initialOptions);
  const [configPageToShow, setConfigPageToShow] = useState(initialOptions[0]);
  const { isEdit, saveLinkChanges, createLink, setActiveLink } =
    useContext(LinkContext);

  const [linkData, setLinkData] = useState(() => ({
    title: linkDataToUpdate?.title || sdkName,
    config: {
      type:
        typeof linkDataToUpdate?.one_time_only === "undefined"
          ? true
          : linkDataToUpdate?.one_time_only,
      external_id: linkDataToUpdate?.external_id || "",
      redirect_url: linkDataToUpdate?.metadata?.redirect_url || null,
      webhook_url: linkDataToUpdate?.metadata?.webhook_url || null,
      identify_user: linkDataToUpdate?.identify_user || false,
    },
    identity: {
      location: linkDataToUpdate?.sections?.location || true,
      email: linkDataToUpdate?.sections?.email || false,
      // email_otp: linkDataToUpdate?.sections?.email_otp || false,
      curp: linkDataToUpdate?.sections?.curp || false,
      phone_number: linkDataToUpdate?.sections?.phone_number || false,
      // phone_number_otp: linkDataToUpdate.sections?.phone_number_otp || false,
      personal_info: linkDataToUpdate?.sections?.personal_info || false,
      address: linkDataToUpdate?.sections?.address || false,
      document: linkDataToUpdate ? linkDataToUpdate?.sections?.document : true,
      selfie: linkDataToUpdate ? linkDataToUpdate?.sections?.selfie : true,
    },
    brand: {
      logo: linkDataToUpdate?.metadata?.logo || null,
      colors: {
        primary:
          linkDataToUpdate?.metadata?.primary_color || COLORS.DODGER_BLUE,
        secondary: linkDataToUpdate?.metadata?.secondary_color || COLORS.MAUVE,
        textPrimary:
          linkDataToUpdate?.metadata?.text_primary_color || COLORS.SHARK,
        textSecondary:
          linkDataToUpdate?.metadata?.text_secondary_color ||
          COLORS.SHUTTLE_GRAY,
      },
      font: {
        heading1:
          linkDataToUpdate?.metadata?.font_primary_heading || FONT_FAMILY,
        heading2:
          linkDataToUpdate?.metadata?.font_secondary_heading || FONT_FAMILY,
        text: linkDataToUpdate?.metadata?.font_text || FONT_FAMILY,
      },
    },
  }));

  const generateMagicLink = () => {
    if (isEdit) {
      saveLinkChanges(linkData);
      closeConfig();
    } else {
      createLink({ linkData, setShowShareLinkModal });
      closeConfig();
    }
  };

  const showConfigPage = (pageId) => {
    setOptions((prev) => {
      const newOptions = prev.map((page) =>
        page.id === pageId ? { ...page, show: true } : { ...page, show: false }
      );

      setConfigPageToShow(() => newOptions.find((page) => page.show));

      return newOptions;
    });
  };

  const hideConfigPage = () => {
    setConfigPageToShow(() => {
      setOptions(() => initialOptions);
      return initialOptions[0];
    });
  };

  switch (configPageToShow.id) {
    case initialOptions[1].id: {
      return (
        <ConfigLink
          closeTab={hideConfigPage}
          setLinkData={setLinkData}
          initialForm={{
            single: linkData.config.type,
            multiple: !linkData.config.type,
            external_id: linkData.config.external_id,
            redirect_url: linkData.config.redirect_url,
            webhook_url: linkData.config.webhook_url,
            identify_user: linkData.config.identify_user,
          }}
          linkData={linkData}
        />
      );
    }

    case initialOptions[2].id: {
      return (
        <ConfigIdentity
          closeTab={hideConfigPage}
          setLinkData={setLinkData}
          initialForm={{
            location: linkData.identity.location,
            email: linkData.identity.email,
            // email_otp: linkData.identity.email_otp,
            curp: linkData.identity.curp,
            phone_number: linkData.identity.phone_number,
            // phone_number_otp: linkData.identity.phone_number_otp,
            personal_info: linkData.identity.personal_info,
            address: linkData.identity.address,
            document: linkData.identity.document,
            selfie: linkData.identity.selfie,
          }}
          linkData={linkData}
        />
      );
    }

    case initialOptions[3].id: {
      return (
        <ConfigBrand
          closeTab={hideConfigPage}
          setLinkData={setLinkData}
          initialForm={{
            logo: linkData.brand.logo,
            colors: {
              primary: linkData.brand.colors.primary,
              secondary: linkData.brand.colors.secondary,
              textPrimary: linkData.brand.colors.textPrimary,
              textSecondary: linkData.brand.colors.textSecondary,
            },
            font: {
              heading1: linkData.brand.font.heading1,
              heading2: linkData.brand.font.heading2,
              text: linkData.brand.font.text,
            },
          }}
          linkData={linkData}
        />
      );
    }

    default:
      return (
        <InitialTab
          sdkName={linkData.title}
          options={options}
          showConfigPage={showConfigPage}
          closeConfig={closeConfig}
          generateMagicLink={generateMagicLink}
          isEdit={isEdit}
          linkData={linkData}
          linkDataToUpdate={linkDataToUpdate}
          setActiveLink={setActiveLink}
        />
      );
  }
};
export default LinkConfiguration;
