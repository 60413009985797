// import { Link } from "react-router-dom";
// import { PRIVATE_ROUTES } from "./pages/routes";
import { useContext, useEffect, useRef, useState } from "react";
import Notification from "./Notification";
import AuthContext from "../contexts/AuthContext";
import ReportContext from "../contexts/ReportContext";
import { T } from "@tolgee/react";

const NotificationsList = ({ setOpenNotifications }) => {
  const { findReportByIdentity } = useContext(ReportContext);
  const {
    getNotifications,
    user: {
      notifications: { list },
    },
  } = useContext(AuthContext);
  const [requestStartOn, setRequestStartOn] = useState(0);
  const containerRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;
    function scrollFn() {
      if (
        container.scrollHeight - container.scrollTop ===
        container.clientHeight
      ) {
        getNotifications({ requestStartOn: requestStartOn + 1 });
        setRequestStartOn((prev) => prev + 1);
      }
    }
    container.addEventListener("scroll", scrollFn);
    return () => container.removeEventListener("scroll", scrollFn);
  }, [requestStartOn]);

  const handleClick = ({ id, identity_id }) => {
    findReportByIdentity({ id, identity_id });
    setOpenNotifications(false);
  };

  return (
    <div className="notifications-list">
      <div className="notifications-list__title">
        <div>
          <button
            className="notifications-list__btn"
            onClick={() => setOpenNotifications(false)}
          >
            <div className="icon-close"></div>
          </button>
          <h4 className="heading-3">
            <T keyName="notification_list_title" />
          </h4>
        </div>
        {/* <Link to={PRIVATE_ROUTES.PERFIL} className="notifications-list__btn">
          <div className="icon-menu-config"></div>
        </Link> */}
      </div>

      <div className="notifications-list__container" ref={containerRef}>
        {list.map((item) => (
          <Notification
            key={item.id}
            notification={item}
            handleClick={handleClick}
          />
        ))}
      </div>
    </div>
  );
};
export default NotificationsList;
