export const changeHeadersHelper = (headers, title, sortedBy) => {
  let changingHeader = headers.find((el) => el.title === title);

  changingHeader = {
    ...changingHeader,
    sortedBy,
  };

  const newHeaders = headers.map((el) =>
    el.title === title ? changingHeader : el
  );

  return newHeaders;
};
