import { useState } from "react";
import { CSSTransition } from "react-transition-group";
import { COLORS } from "../styles/utils/colors";
import { useTranslate } from "@tolgee/react";

const ReporteWarnings = ({ warnings }) => {
  const [openWarnings, setOpenWarnings] = useState(false);
  const { t } = useTranslate();

  return (
    <div className="reporte__container__resumen__warnings">
      <div className="reporte__container__resumen__warnings--text-container">
        <span className="icon-error reporte__container__resumen__warnings--icon"></span>
        <span className="text-1--bold">{t("reporte_warnings_title")}</span>
      </div>
      <button
        onClick={() => setOpenWarnings(!openWarnings)}
        className="text-1 reporte__container__resumen__warnings--btn layout__main__section__dashboard__component--data__element--title--btn"
        style={{
          color: COLORS.SHUTTLE_GRAY,
          border: `1px solid ${COLORS.SHUTTLE_GRAY}`,
        }}
      >
        {t("reporte_warnings_btn")}
      </button>

      <CSSTransition
        in={openWarnings}
        timeout={300}
        classNames="warnings"
        mountOnEnter
        unmountOnExit
      >
        <div className="reporte__container__resumen__warnings__modal">
          <div className="reporte__container__resumen__warnings__modal__container">
            <div className="reporte__container__resumen__warnings__modal__container--title">
              <h2 className="heading-3">
                {t("reporte_warnings_container_title")}
              </h2>
              <button onClick={() => setOpenWarnings(false)}>
                <span className="icon-close"></span>
              </button>
            </div>
            <div className="reporte__container__resumen__warnings__modal__container--texts">
              {warnings.map((warning, i) => (
                <p
                  key={`warning-${i}`}
                  className="text-1 reporte__container__resumen__warnings__modal__container--text"
                >
                  {i + 1}.- {warning}
                </p>
              ))}
            </div>
          </div>
        </div>
      </CSSTransition>
    </div>
  );
};
export default ReporteWarnings;
