export const servicesNames = {
  DECISION_MAKER: {
    backName: "trully-decision-maker",
    frontName: "Fraud",
  },
  DECISION_MAKER_INVOKE: {
    backName: "trully-decision-maker-invoke",
    frontName: "Fraud",
  },
  USER_SCORE: {
    backName: "trully-user-score",
    frontName: "Score",
  },
};
