import moment from "moment";
import "moment/locale/es";

export const start = moment().subtract(30, "days");
export const end = moment();

const billingStart = moment().startOf("month");
const billingEnd = moment();

export const initialState = {
  from: {
    year: Number(start.format("YYYY")),
    month: start.format("MMMM"),
    monthNumber: start.month(),
    day: Number(start.format("D")),
  },
  to: {
    year: Number(end.format("YYYY")),
    month: end.format("MMMM"),
    monthNumber: end.month(),
    day: Number(end.format("D")),
  },
};

export const initialSDKState = {
  from: {
    year: Number(start.format("YYYY")),
    month: start.format("MMMM"),
    monthNumber: start.month(),
    day: Number(start.format("D")),
  },
  to: {
    year: Number(end.format("YYYY")),
    month: end.format("MMMM"),
    monthNumber: end.month(),
    day: Number(end.format("D")),
  },
};

export const initialMonthState = {
  from: {
    year: Number(billingStart.format("YYYY")),
    month: billingStart.format("MMMM"),
    monthNumber: billingStart.month(),
    day: Number(billingStart.format("D")),
  },
  to: {
    year: Number(billingEnd.format("YYYY")),
    month: billingEnd.format("MMMM"),
    monthNumber: billingEnd.month(),
    day: Number(billingEnd.format("D")),
  },
};
