import { useTranslate } from '@tolgee/react';

const SDKPercentages = ({ percentages }) => {
  const { t } = useTranslate();

  if (!percentages) {
    return <></>;
  }

  return (
    <div className="sdkPercentages">
      <div className="sdkPercentages__row">
        <span className="text-3">Paso</span>
        <span className="text-3 sdkPercentages__text-end">Caída total</span>
      </div>
      {percentages?.map((item) => (
        <div key={item.title} className="sdkPercentages__row">
          <span className="text-2">{item.title}</span>
          <span className="text-2 sdkPercentages__text-end">
            {item.percentage} ({Intl.NumberFormat("en-US").format(item.total)})
          </span>
        </div>
      ))}
      <p className="text-3 sdkPercentages__warning">
        {t("command_panel_sdk_table_text")}
      </p>
    </div>
  );
};
export default SDKPercentages;
