import { useContext } from "react";
import ResumenTab from "./ResumenTab";
import PerfilData from "./PerfilData";
import ReportContext from "../contexts/ReportContext";
import NetworkReports from "./NetworkReports";
import { useTranslate } from "@tolgee/react";

const DecisionMakerReport = ({ isLangEs }) => {
  const { report, identity_reported, notification_identity_id } =
    useContext(ReportContext);
  const { t } = useTranslate();

  const getFraudType = (fraudList, isLangEs) => {
    return isLangEs
      ? fraudList.find((type) => type.id === report.flaggedFor.fraud_reason_id)
          .description_es
      : fraudList.find((type) => type.id === report.flaggedFor.fraud_reason_id)
          .description_en;
  };

  const fraudType = report?.flaggedFor?.wasFlagged
    ? getFraudType(report.flaggedFor.types)
    : t("dm_report_no_flag");

  return (
    <>
      <section className="reporte__container__resumen">
        <PerfilData
          status={report.label}
          firstSeen={report.selfie_first_seen}
          fraudType={fraudType}
          lastSeen={report.selfie_last_seen}
          timesSeen={report.times_seen_last_month}
        />
      </section>
      <section className="reporte__container__resumen__network-reports">
        {report?.network_reports?.list?.length > 0 && (
          <h3 className="text-1--bold">{t("dm_report_reports_title")}</h3>
        )}
        {report?.network_reports?.list?.length > 0 && (
          <NetworkReports
            reportsList={report?.network_reports?.list}
            total_reports={report?.network_reports?.total_reports}
            fraudTypes={report?.flaggedFor?.types}
            reportID={report?.request_id}
            identityReported={identity_reported}
            identity_id={notification_identity_id}
            isLangEs={isLangEs}
          />
        )}
      </section>
      <section className="reporte__container__resumen__data-tab">
        <h3 className="text-1--bold">Resultados</h3>
        <ResumenTab report={report} />
      </section>
    </>
  );
};

export default DecisionMakerReport;
