import { useContext, useEffect, useRef } from "react";
import ConsultasHeader from "../ConsultasHeader";
import ReportTableDetails from "../ReportTableDetails";
import ReportContext from "../../contexts/ReportContext";
import LinearLoader from "../LinearLoader";
import { createScrollFn } from "../../helpers/createScrollFn";

const ConsultasDetails = ({
  dataToShow,
  dataHeader,
  handleClick,
  scrollLoader,
  handleScroll,
  requestStartOn,
}) => {
  const { dash, showReport, showButtons, closeReport } = dataHeader;
  const {
    report: { id },
  } = useContext(ReportContext);

  const containerRef = useRef(null);
  const elementRef = useRef(null);
  const prevScrollY = useRef(0);

  useEffect(() => {
    const container = containerRef.current;
    const scrollFn = createScrollFn({ container, prevScrollY, handleScroll });
    container.addEventListener("scroll", scrollFn);
    return () => container.removeEventListener("scroll", scrollFn);
  }, [requestStartOn]);

  useEffect(() => {
    const container = containerRef.current;
    const element = elementRef.current;

    if (container && element) {
      const elementPosition = element.offsetTop;
      container.scrollTo({
        top: elementPosition - 175,
        behavior: "smooth",
      });
    }
  }, [id, dataToShow]);

  return (
    <div className="searchDetails">
      <ConsultasHeader
        dash={dash}
        showReport={showReport}
        showButtons={showButtons}
        closeReport={closeReport}
      />
      <div className="consultas__reporte__searchDetails" ref={containerRef}>
        {dataToShow?.map((item) =>
          item.id === id ? (
            <ReportTableDetails
              key={item.id}
              {...item}
              reportSelectedID={id}
              handleClick={handleClick}
              ref={elementRef}
            />
          ) : (
            <ReportTableDetails
              key={item.id}
              {...item}
              reportSelectedID={id}
              handleClick={handleClick}
            />
          )
        )}
        {scrollLoader && <LinearLoader />}
      </div>
    </div>
  );
};
export default ConsultasDetails;
