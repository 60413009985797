import { SESSION_MESSAGES } from "../states/contexts/AuthContext";

const helpHttp = () => {
  const customFetch = async ({
    endpoint,
    options,
    terminateSession,
    invalidSession,
  }) => {
    const defaultHeader = {
      accept: "application/json",
    };

    options.method = options.method || "GET";
    options.credentials = "include";

    options.headers = options.headers
      ? { ...defaultHeader, ...options.headers }
      : defaultHeader;

    const multipart = options.body instanceof FormData;

    options.body = multipart
      ? options.body
      : JSON.stringify(options.body) || false;
    if (!options.body) delete options.body;

    if (multipart) delete options.headers["content-type"];

    const controller = new AbortController();
    options.signal = controller.signal;

    return fetch(endpoint, options)
      .then((res) => {
        if (res.status === 401 || res.status === 403) {
          return res.json().then((message) => {
            if (!terminateSession)
              return {
                err: true,
                response_status: res.status,
                message,
              };

            message === "Invalid Session"
              ? terminateSession(SESSION_MESSAGES.DOUBLE_SESSION_MESSAGE)
              : terminateSession(SESSION_MESSAGES.SESSION_EXPIRED_MESSAGE);
          });
        }

        if (res.status === 400 || res.status === 500) {
          return res.json().then((data) => {
            return {
              err: true,
              response_status: res.status,
              arrErrors: data.error,
            };
          });
        }

        getObjectHash(res.body).then((hashHex) => {
          const checksum = res.headers.get("checksum");
          if (!checksum) return;

          if (checksum !== hashHex) invalidSession();
        });

        return res.ok
          ? res.json()
          : Promise.reject({
              err: true,
              status: res.status || "00",
              statusText: res.statusText || "Ocurrió un error",
              message: res.message || "Mensaje",
            });
      })
      .catch((error) => {
        return error;
      })
      .finally(() => controller.abort());
  };

  const get = ({
    url,
    options = {},
    terminateSession = null,
    invalidSession,
  }) =>
    customFetch({ endpoint: url, options, terminateSession, invalidSession });

  const post = ({
    url,
    options = {},
    terminateSession = null,
    invalidSession,
  }) => {
    options.method = "POST";
    const defaultHeader = {
      "content-type": "application/json",
    };
    options.headers = options.headers
      ? { ...defaultHeader, ...options.headers }
      : defaultHeader;
    return customFetch({
      endpoint: url,
      options,
      terminateSession,
      invalidSession,
    });
  };

  const put = ({
    url,
    options = {},
    terminateSession = null,
    invalidSession,
  }) => {
    options.method = "PUT";
    options.headers = {
      "content-type": "application/json",
    };
    return customFetch({
      endpoint: url,
      options,
      terminateSession,
      invalidSession,
    });
  };

  const patch = ({
    url,
    options = {},
    terminateSession = null,
    invalidSession,
  }) => {
    options.method = "PATCH";
    options.headers = {
      "content-type": "application/json",
    };
    return customFetch({
      endpoint: url,
      options,
      terminateSession,
      invalidSession,
    });
  };

  const del = ({
    url,
    options = {},
    terminateSession = null,
    invalidSession,
  }) => {
    options.method = "DELETE";
    return customFetch({
      endpoint: url,
      options,
      terminateSession,
      invalidSession,
    });
  };

  return {
    get,
    post,
    put,
    patch,
    del,
  };
};

export const generateRequest = helpHttp();

async function getObjectHash(obj) {
  const objString = JSON.stringify(obj);
  const buffer = new TextEncoder().encode(objString);
  const hashBuffer = await crypto.subtle.digest("SHA-256", buffer);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray
    .map((b) => b.toString(16).padStart(2, "0"))
    .join("");
  return hashHex;
}
