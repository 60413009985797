//Públicas
export const PUBLIC_ROUTES = {
  SIGNIN: "/signin",
  SIGNUP: "/signup",
  RESET: "/reset",
  RESET_TOKEN_PARAM: "/reset/:token",
};

//Privadas
export const PRIVATE_ROUTES = {
  DASHBOARD: "/inicio",
  BUSQUEDA: "/busqueda",
  CONSULTAS: "/consultas",
  BILLING: "/facturacion",
  PERFIL: "/perfil",
  TEAM: "/equipo",
  REVIEWS: "/revisar",
  RULES: "/reglas",
  LINK: "/link",
};
