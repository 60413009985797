import { useTolgee } from "@tolgee/react";

const LangSelector = () => {
  const tolgee = useTolgee(["language"]);

  return (
    <select
      onChange={(e) => tolgee.changeLanguage(e.target.value)}
      value={tolgee.getLanguage()}
      className="langSelector text-2"
    >
      <option value="es-MX">Español</option>
      <option value="en">English</option>
    </select>
  );
};
export default LangSelector;
