import { useContext } from "react";
import DropDownOptions from "./DropDownOptions";
import LinkContext from "../contexts/LinkContext";
import DataContext from "../contexts/DataContext";
import { useTolgee } from "@tolgee/react";

const DropdownLinkOptions = ({
  showOptions,
  dropdownPosition,
  handleShowOptions,
  linkId,
  isActive,
  setShowModal,
  setShowConfiguration,
}) => {
  const { changeActiveStatus, setLinkToEdit, showLinkURL } =
    useContext(LinkContext);
  const { isRequestsFromLink, getRequestsForLinks } = useContext(DataContext);
  const tolgee = useTolgee(["language"]);
  const isLangEs = tolgee.getLanguage() === "es-MX";
  const shareLink = (linkId) => {
    showLinkURL({ token: linkId, setShowModal, handleShowOptions });
  };

  const editLink = (linkId) => {
    setLinkToEdit({ token: linkId, setShowConfiguration, handleShowOptions });
  };

  const seeResponses = (linkId) => {
    isRequestsFromLink({ answer: true, token: linkId });
    handleShowOptions({ id: linkId, isOpen: true });
    getRequestsForLinks({
      token: linkId,
      showLoader: true,
      inLinks: true,
      requestStartOn: 0,
    });
  };

  const handleActivation = (linkId) => {
    changeActiveStatus(linkId);
    handleShowOptions({ id: linkId, isOpen: true });
  };

  const translate = (text) => {
    if (isLangEs) return text;

    switch (text) {
      case "Compartir":
        return "Share";
      case "Editar":
        return "Edit";
      case "Ver respuestas":
        return "See responses";
      case "Desactivar":
        return "Deactivate";
      case "Activar":
        return "Activate";

      default:
        return text;
    }
  };

  return (
    <DropDownOptions
      show={showOptions}
      dropdownPosition={dropdownPosition}
      functions={{
        handleShowOptions,
        shareLink,
        editLink,
        seeResponses,
        handleActivation,
      }}
      containerData={{
        options: [
          { title: translate("Compartir"), icon: "icon-enviar", id: "share" },
          { title: translate("Editar"), icon: "", id: "edit" },
          { title: translate("Ver respuestas"), icon: "", id: "see" },
          {
            title: isActive ? translate("Desactivar") : translate("Activar"),
            icon: "",
            id: "activate",
          },
        ],
        linkId,
        isActive,
      }}
      classNames={{
        container: "team--dropdown-container",
        transition: "slideInLinkOption",
        dropdown: "linkPage__options__dropdown",
        dropdownContent: "linkPage__options__dropdown__content",
        dropdownContentElement: "team--dropdown__content--element",
        icon: "linkPage__options__dropdown__icon",
      }}
      inLinks={true}
    />
  );
};
export default DropdownLinkOptions;
