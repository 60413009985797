import { useContext } from "react";
import ReportContext from "../contexts/ReportContext";
import ChangeStatus from "./ChangeStatus";
import { servicesNames } from "../contexts/serviceName";
import { useLocation } from "react-router-dom";
import { PRIVATE_ROUTES } from "./pages/routes";
import NameBadge from "./NameBadge";
import { COLORS } from "../styles/utils/colors";
import { useTranslate } from "@tolgee/react";

const ReporteHeader = ({ error, closeReportForm }) => {
  const location = useLocation();
  const inHistory = location.pathname === PRIVATE_ROUTES.CONSULTAS;
  const {
    report,
    toggleFlaggingForm,
    closeReport,
    identity_reported,
    notification_identity_id,
    deleteIdentityReported,
    updateReportStatus,
  } = useContext(ReportContext);
  const { t } = useTranslate();

  return (
    <div>
      <header className="reporte__container__header">
        <div className="reporte__container__header--title">
          <button
            onClick={() => {
              if (identity_reported && notification_identity_id)
                deleteIdentityReported();
              closeReportForm ? closeReportForm(true) : closeReport();
            }}
          >
            <span className="icon-close"></span>
          </button>
          <h1 className="heading-3">
            {t("report_header_title")}
            {report.id && `: ID-${report.id}`}
          </h1>
        </div>
      </header>
      {report.service_name === servicesNames.DECISION_MAKER.backName &&
      inHistory ? (
        <ChangeStatus
          reportID={report.id}
          status={report.label}
          assigned_to={report?.assigned_to}
          flagReport={toggleFlaggingForm}
          report={report}
          updateReportStatus={updateReportStatus}
        />
      ) : (
        <div>
          <NameBadge name={report?.assigned_to} color={COLORS.BUTTERMILK} />
        </div>
      )}
    </div>
  );
};
export default ReporteHeader;
