import iconPhone from "../styles/icons/rules/phone_number.svg";
import iconEmail from "../styles/icons/rules/email.svg";
import iconIP from "../styles/icons/rules/ip.svg";
import iconGeo from "../styles/icons/rules/geospatial.svg";
import iconDocSelfie from "../styles/icons/rules/document_selfie.svg";
import iconPepSanctions from "../styles/icons/rules/pep_sanctions.svg";
import iconRfcCurp from "../styles/icons/rules/rfc_curp.svg";
import iconIdentity from "../styles/icons/rules/identity.svg";
import iconSDK from "../styles/icons/rules/sdk.svg";

export const getRuleIcon = (ruleName) => {
  switch (ruleName) {
    case "Número de teléfono":
      return iconPhone;

    case "Correo Electrónico":
      return iconEmail;

    case "IP":
      return iconIP;

    case "Geospacial":
      return iconGeo;

    case "Documento + Selfie":
      return iconDocSelfie;

    case "Listas PEP y Sanciones":
      return iconPepSanctions;

    case "RFC + CURP":
      return iconRfcCurp;

    case "Identidad":
      return iconIdentity;

    case "SDK":
      return iconSDK;

    default:
      return "";
  }
};
