import { COLORS } from "../styles/utils/colors";
import iconFiltros from "../styles/icons/images/icon-filtros.svg";
import BtnFiltros from "./BtnFiltros";
import { servicesNames } from "../contexts/serviceName";
import { useTranslate } from "@tolgee/react";

const Services = ({ percentages }) => {
  const { t } = useTranslate();

  return (
    <div className="chart__calendar--data__services">
      <BtnFiltros
        icon={iconFiltros}
        title={t("btn_filtros_filter_title")}
        styles={{
          backgroundColor: COLORS.WHITE,
          border: `1px solid ${COLORS.SHUTTLE_GRAY}`,
          color: COLORS.SHARK,
          boxShadow: "none",
          padding: "8px 12px",
        }}
        filter="services"
      />
      <table className="chart__calendar--data__services__table">
        <thead>
          <tr>
            <th></th>
            <th className="text-3">{t("services_th_1")}</th>
            <th className="text-3">{t("services_th_2")}</th>
          </tr>
        </thead>
        <tbody>
          {percentages?.map((el) => {
            if (!el) return <></>;

            const { id, title, totalUsers, percentage } = el;

            return (
              <tr key={id}>
                <td>
                  <div className="chart__calendar--data__services--element">
                    <div
                      style={
                        id === servicesNames.DECISION_MAKER.backName
                          ? { backgroundColor: COLORS.DODGER_BLUE }
                          : { backgroundColor: COLORS.KEPPEL }
                      }
                      className="chart__calendar--data__services--element-color"
                    ></div>
                    <span className="text-2">{title}</span>
                  </div>
                </td>
                <td>{Intl.NumberFormat("en-US").format(totalUsers)}</td>
                <td>{percentage}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
export default Services;
