import { useEffect, useState } from "react";
import Button from "./Button";
import SearchDetail from "./SearchDetail";
import { ID_FORM_INPUT_DATA } from "./pages/IDForm";
import { reportFilters } from "../states/contexts/AuthContext";

const getDescriptionFromKey = (key) => {
  switch (key) {
    case ID_FORM_INPUT_DATA.ID_TO_SEARCH.NAME:
      return ID_FORM_INPUT_DATA.ID_TO_SEARCH.DESCRIPTION;
    case ID_FORM_INPUT_DATA.ID_TRULLY.NAME:
      return ID_FORM_INPUT_DATA.ID_TRULLY.DESCRIPTION;
    case ID_FORM_INPUT_DATA.ID_USER.NAME:
      return ID_FORM_INPUT_DATA.ID_USER.DESCRIPTION;
    default:
      return key;
  }
};

const getLabelFromName = (name) => {
  for (const filter of reportFilters) {
    if (name === filter.name) {
      return filter.label;
    }
  }
  switch (name) {
    case "lat":
      return "Latitud";
    case "lng":
      return "Longitud";
    default:
      return "User ID";
  }
};

const getValueFromDetails = (value) => {
  return typeof value === "object" ? value?.file?.name : value;
};

const numberDoesntExist = (number) => number === "+52" || number === "+54";

const SearchDetails = ({ details, reset }) => {
  const [detailsArr, setDetailsArr] = useState(() => {
    return Object.keys(details).map((key) => {
      const description = getDescriptionFromKey(key);
      const name = getLabelFromName(description);
      const value = getValueFromDetails(details[key]);
      return { name, value };
    });
  });

  const moreThanOneWord = (str) => {
    const words = str.split(" ");
    return words.length > 1;
  };

  useEffect(() => {
    const tel = detailsArr.find((input) => input.name === "Teléfono");
    if (tel && numberDoesntExist(tel.value.trim())) {
      setDetailsArr((prev) =>
        prev.filter((input) => input.name !== "Teléfono")
      );
    }
  }, []);

  return (
    <div className="searchDetails">
      <h3 className="heading-2">Detalles de búsqueda</h3>
      <div className="searchDetails__detailsContainer">
        {detailsArr?.map((detail) => (
          <SearchDetail
            key={detail.name}
            name={detail.name}
            value={detail.value}
            titleClassName={
              moreThanOneWord(detail.name) ? "" : "searchDetail__title"
            }
          />
        ))}
        <Button
          text="Nueva búsqueda"
          handleClick={() => reset(true)}
          styles={{ alignSelf: "flex-start", marginTop: "8px" }}
        />
      </div>
    </div>
  );
};
export default SearchDetails;
