import mex from "../../styles/icons/images/emoticon-mex.svg";
import col from "../../styles/icons/images/emoticon-col.svg";
import { Steps } from "intro.js-react";
import TextInput from "../TextInput";
import InputPhone from "../InputPhone";
import FileInput from "../FileInput";
import ErrorSlideTimeOut from "../ErrorSlideTimeOut";
import {
  validateAddress,
  validateAddressCoordLat,
  validateAddressCoordLong,
  validateCURP,
  validateEmail,
  validateExternalID,
  validateIP,
  validateImage,
  validatePhone,
  validateRFC,
} from "../../helpers/formValidations";
import InputUserID from "../InputUserID";
import InputAddress from "../InputAddress";
import { useEffect, useState } from "react";
import Button from "../Button";
import { useTolgee, useTranslate } from "@tolgee/react";

const initialCountries = [
  { flag: mex, name: "México", preFix: "+52", selected: true },
  { flag: col, name: "Colombia", preFix: "+57", selected: false },
];

const translateFilter = (filter, isLangEs) => {
  if (isLangEs) return filter;

  switch (filter) {
    case "Frente INE / IFE o Pasaporte":
      return "Front INE / IFE or Passport";
    case "Dorso INE / IFE":
      return "Back INE / IFE";
    case "Dirección":
      return "Address";
    case "Teléfono":
      return "Phone Number";
    default:
      return filter;
  }
};

const BusquedaReportForm = ({
  enabled,
  steps,
  initialStep,
  onExit,
  stepsOptions,
  form,
  setForm,
  filters,
  handleFormValidation,
  handleSendReport,
  error,
  restartReport,
  resetFile,
  setResetFile,
  validation,
}) => {
  const [countries, setCountries] = useState(initialCountries);
  const [allowSend, setAllowSend] = useState(false);
  const { t } = useTranslate();
  const tolgee = useTolgee(["language"]);
  const isLangEs = tolgee.getLanguage() === "es-MX";

  useEffect(() => {
    setAllowSend(() => {
      if (Object.keys(form).length === 0) return false;

      if (
        Object.keys(form).length === 1 &&
        form?.phone_number &&
        (form?.phone_number === "+52 " || form?.phone_number === "+57 ")
      )
        return false;

      if (
        Object.keys(form).every(
          (clave) =>
            form[clave] === "" ||
            form[clave] === "+52 " ||
            form[clave] === "+57 "
        )
      )
        return false;

      if (form?.document_image_back && !form?.document_image) return false;

      if (form?.lat && !form?.lng) return false;
      if (form?.lng && !form?.lat) return false;

      const someInvalidInput = validation.find((el) => !el.valid);
      return !someInvalidInput;
    });
  }, [form, validation]);

  const selectFilterComponent = (filter) => {
    if (filter.show) {
      switch (filter.name) {
        case "email":
          return (
            <TextInput
              keyProp="input_email"
              form={form}
              inputName={translateFilter(filter.name, isLangEs)}
              inputLabel={t("input_email_label")}
              description={t("input_email_description")}
              setValue={setForm}
              validate={validateEmail}
              validateErrorText={t("input_email_error")}
              handleFormValidation={handleFormValidation}
            />
          );
        case "curp":
          return (
            <TextInput
              keyProp="input_curp"
              form={form}
              inputName={translateFilter(filter.name, isLangEs)}
              inputLabel={t("input_curp_label")}
              description="CURP"
              setValue={setForm}
              infoText={t("input_curp_text")}
              validate={validateCURP}
              validateErrorText={t("input_invalid_format")}
              iconStyles={{ width: "150px" }}
              handleFormValidation={handleFormValidation}
            />
          );
        case "rfc":
          return (
            <TextInput
              keyProp="input_rfc"
              form={form}
              inputName={translateFilter(filter.name, isLangEs)}
              inputLabel={t("input_rfc_label")}
              description="RFC"
              setValue={setForm}
              infoText={t("input_rfc_text")}
              validate={validateRFC}
              validateErrorText={t("input_invalid_format")}
              iconStyles={{ width: "150px" }}
              handleFormValidation={handleFormValidation}
            />
          );
        case "ip":
          return (
            <TextInput
              keyProp="input_ip"
              form={form}
              inputName={translateFilter(filter.name, isLangEs)}
              inputLabel="0.0.0.0"
              description="IPv4"
              setValue={setForm}
              infoText={t("input_ip_text")}
              validate={validateIP}
              validateErrorText={t("input_invalid_format")}
              handleFormValidation={handleFormValidation}
            />
          );
        case "phone_number":
          return (
            <InputPhone
              keyProp="input_phone"
              form={form}
              inputName={translateFilter(filter.name, isLangEs)}
              inputLabel="+52 "
              description={t("input_phone_label")}
              setValue={setForm}
              infoText={t("input_phone_text")}
              arrElementos={countries}
              tooltipPosition={{ width: "150px" }}
              validate={validatePhone}
              validateErrorText={t("input_phone_error")}
              handleFormValidation={handleFormValidation}
              setElementos={setCountries}
            />
          );
        case "document_image":
          return (
            <FileInput
              keyProp="input_ine"
              form={form}
              inputName={translateFilter(filter.name, isLangEs)}
              description={t("input_ine_label")}
              setValue={setForm}
              infoText={t("input_ine_text")}
              validate={validateImage}
              validateErrorText={t("input_img_error")}
              iconStyles={{ width: "150px" }}
              resetFile={resetFile}
              setResetFile={setResetFile}
              handleFormValidation={handleFormValidation}
              label=".jpg, .jpeg, .png o .webp"
            />
          );
        case "document_image_back":
          return (
            <FileInput
              keyProp="input_ine_back"
              form={form}
              inputName={translateFilter(filter.name, isLangEs)}
              description={t("input_ine_back_label")}
              setValue={setForm}
              infoText={t("input_ine_back_text")}
              validate={validateImage}
              validateErrorText={t("input_img_error")}
              iconStyles={{ width: "200px" }}
              resetFile={resetFile}
              setResetFile={setResetFile}
              handleFormValidation={handleFormValidation}
              label=".jpg, .jpeg, .png o .webp"
            />
          );
        case "image":
          return (
            <FileInput
              keyProp="input_selfie"
              form={form}
              inputName={translateFilter(filter.name, isLangEs)}
              description="Selfie"
              setValue={setForm}
              infoText={t("input_selfie_text")}
              validate={validateImage}
              validateErrorText={t("input_img_error")}
              iconStyles={{ width: "150px" }}
              resetFile={resetFile}
              setResetFile={setResetFile}
              handleFormValidation={handleFormValidation}
              label=".jpg, .jpeg, .png o .webp"
            />
          );
        case "address":
          return (
            <InputAddress
              key="input_address"
              form={form}
              inputName={translateFilter(filter.name, isLangEs)}
              inputLabel={t("input_address_label")}
              description={t("input_address_description")}
              setValue={setForm}
              infoText={t("input_address_text")}
              iconStyles={{ width: "200px" }}
              validate={validateAddress}
              validateErrorText={t("input_address_error")}
              handleFormValidation={handleFormValidation}
            />
          );
        case "location":
          return (
            <div className="busqueda__form__group">
              <InputAddress
                key="input_lat_description"
                form={form}
                inputName="lat"
                inputLabel="00.000"
                description={t("input_lat_description")}
                setValue={setForm}
                infoText={t("input_lat_text")}
                iconStyles={{ width: "200px" }}
                validate={validateAddressCoordLat}
                validateErrorText={t("input_loc_error")}
                handleFormValidation={handleFormValidation}
              />
              <InputAddress
                key="input_lng_description"
                form={form}
                inputName="lng"
                inputLabel="000.000"
                description={t("input_lng_description")}
                setValue={setForm}
                infoText={t("input_lng_text")}
                iconStyles={{ width: "200px" }}
                validate={validateAddressCoordLong}
                validateErrorText={t("input_loc_error")}
                handleFormValidation={handleFormValidation}
              />
            </div>
          );
        default:
          return <></>;
      }
    } else return <></>;
  };

  return (
    <>
      <Steps
        enabled={enabled}
        steps={steps}
        initialStep={initialStep}
        onExit={onExit}
        options={stepsOptions}
      />

      <form
        style={{ minHeight: "20vh" }}
        className="busqueda__form__container--complete-form"
        onSubmit={(e) => e.preventDefault()}
      >
        {filters.map((filter) => selectFilterComponent(filter))}
      </form>
      <InputUserID
        form={form}
        inputName="external_id"
        inputLabel={t("input_external_id_label")}
        description="User ID"
        validate={validateExternalID}
        validateErrorText={t("input_external_id_error")}
        setValue={setForm}
      />
      <div className="busqueda__btnContainer">
        <Button
          text={t("show_results_btn")}
          disabled={!allowSend}
          handleClick={handleSendReport}
        />
        <ErrorSlideTimeOut error={error} restartFn={restartReport} />
      </div>
    </>
  );
};
export default BusquedaReportForm;
