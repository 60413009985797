export const getRulesTotals = (rules) => {
  let noThreat = 0;
  let review = 0;
  let potentialThreat = 0;

  rules.forEach((rule) => {
    const { label, count } = rule;

    switch (label) {
      case "No Threat":
        noThreat += count;
        break;
      case "Review":
        review += count;
        break;
      case "Potential Threat":
        potentialThreat += count;
        break;
      default:
        break;
    }
  });

  return [
    { title: "Total", total: noThreat + review + potentialThreat },
    {
      title: "Aceptados",
      total: noThreat,
      icon: "icon-check color--keppel",
    },
    { title: "Revisar", total: review, icon: "icon-revisa color--com" },
    {
      title: "Rechazados",
      total: potentialThreat,
      icon: "icon-amenaza color--copperfield",
    },
  ];
};
