export const rulesGroupText = (rule) => {
  switch (rule) {
    case 1:
      return {
        title: "group_rule_document_title",
        description: "group_rule_document_description",
        listItems: ["group_rule_document_item_1", "group_rule_document_item_2", "group_rule_document_item_3", "group_rule_document_item_4"],
      }
    case 2:
      return {
        title: "group_rule_identity_title",
        description: "group_rule_identity_description",
        listItems: ["group_rule_identity_item_1", "group_rule_identity_item_2", "group_rule_identity_item_3", "group_rule_identity_item_4", "group_rule_identity_item_5"],
      }
    case 3:
      return {
        title: "group_rule_ubication_title",
        description: "group_rule_ubication_description",
        listItems: ["group_rule_ubication_item_1", "group_rule_ubication_item_2", "group_rule_ubication_item_3", "group_rule_ubication_item_4"],
      }
    case 4:
      return {
        title: "group_rule_ip_title",
        description: "group_rule_ip_description",
        listItems: ["group_rule_ip_item_1", "group_rule_ip_item_2"],
      }
    case 5 :
      return {
        title: "group_rule_email_title",
        description: "group_rule_email_description",
        listItems: ["group_rule_email_item_1", "group_rule_email_item_2", "group_rule_email_item_3"],
      }
    case 6:
      return {
        title: "group_rule_phone_title",
        description: "group_rule_phone_description",
        listItems: ["group_rule_phone_item_1", "group_rule_phone_item_2", "group_rule_phone_item_3"],
      }
    case 7:
      return {
        title: "group_rule_pep_title",
        description: "group_rule_pep_description",
        listItems: ["group_rule_pep_item_1", "group_rule_pep_item_2", "group_rule_pep_item_3"],
      }
  
    default:
      return {
        title: "group_rule_document_title",
        description: "group_rule_document_description",
        listItems: ["group_rule_document_item_1", "group_rule_document_item_2", "group_rule_document_item_3", "group_rule_document_item_4"],
      }
  }
};