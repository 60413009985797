import Switch from "@mui/material/Switch";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import { COLORS } from "../styles/utils/colors";
import { useTranslate } from "@tolgee/react";

const SwitchComponent = ({ checked, handleChange }) => {
  const { t } = useTranslate();
  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: "2rem",
    height: "1.125rem",
    padding: 0,
    display: "flex",
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: "1rem",
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(0.9rem)",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 1,
      "&.Mui-checked": {
        transform: "translateX(0.85rem)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor:
            theme.palette.mode === "dark" ? "#C1C0C9" : COLORS.DODGER_BLUE,
        },
      },
    },
    "& .MuiSwitch-thumb": {
      width: "1rem",
      height: "1rem",
      borderRadius: "1rem",
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: "50px",
      opacity: 1,
      backgroundColor:
        theme.palette.mode === "dark"
          ? "rgba(255,255,255,.35)"
          : "rgba(0,0,0,.25)",
      boxSizing: "border-box",
    },
  }));

  return (
    <div className="inputUserID__switch">
      <Stack direction="row" spacing={1} alignItems="center">
        <AntSwitch
          checked={checked}
          onChange={handleChange}
          inputProps={{ "aria-label": "ant design" }}
        />
      </Stack>
      <span className="text-1">{t("switch_title")}</span>
    </div>
  );
};
export default SwitchComponent;
