import { useTolgee } from "@tolgee/react";

const Badge = ({ icon, color, bgColor, text, styles }) => {
  const tolgee = useTolgee(["language"]);
  const isLangEs = tolgee.getLanguage() === "es-MX";

  const translate = (text) => {
    if (isLangEs) return text;

    switch (text) {
      case "¡Correcto!":
        return "¡Correct!";
      case "Revisar":
        return "Review";
      case "Amenaza":
        return "Threat";
      default:
        return text;
    }
  };

  return (
    <div
      style={
        styles?.container
          ? { ...styles?.container, color: color, backgroundColor: bgColor }
          : { color: color, backgroundColor: bgColor }
      }
      className="badge"
    >
      {icon && (
        <div
          style={styles?.icon ? { ...styles?.icon } : {}}
          className={`badge__icon ${icon}`}
        ></div>
      )}
      <span
        style={styles?.text ? { ...styles?.text } : {}}
        className="badge__text"
      >
        {translate(text)}
      </span>
    </div>
  );
};
export default Badge;
