import { servicesNames } from "../../contexts/serviceName";

const initialHeaders = [
  // {
  //   id: "invoice_select",
  //   title: "",
  //   dataType: "",
  //   dataKey: "",
  //   sortedBy: "",
  // },
  {
    id: "invoice",
    title: "Factura",
    dataType: "string",
    dataKey: "name",
    sortedBy: "asc",
  },
  {
    id: "date",
    title: "Fecha",
    dataType: "date",
    dataKey: "invoice_date",
    sortedBy: "asc",
  },
  {
    id: "amount",
    title: "Cantidad",
    dataType: "number",
    dataKey: "amount_usd",
    sortedBy: "asc",
  },
  {
    id: "estatus",
    title: "Estatus",
    dataType: "string",
    dataKey: "status",
    sortedBy: "asc",
  },
  {
    id: "invoice_buttons",
    title: "",
    dataType: "",
    dataKey: "",
    sortedBy: "",
  },
];

export const initialState = {
  lastInvoices: [],
  dataToShow: [],
  headers: initialHeaders,
  invoiceLoading: false,
  errorInvoice: false,
  scrollLoader: false,
  requestStartOn: 0,
  totalsError: false,
  totals: {
    total_calls: 0,
    total_spent: 0,
    usage: [
      {
        id: "billing-element-decisionMaker",
        title: servicesNames.DECISION_MAKER.frontName,
        shown: false,
        petitions: { good: 0, bad: 0 },
        spent: 0,
        icon: "icon-Frame",
        hired: false,
        total_calls: 0,
      },
      {
        id: "billing-element-userScore",
        title: servicesNames.USER_SCORE.frontName,
        shown: false,
        petitions: { good: 0, bad: 0 },
        spent: 0,
        icon: "icon-Face-Analysis",
        hired: false,
        total_calls: 0,
      },
    ],
  },
  contract: null,
  contractError: false,
};
