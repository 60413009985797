import { useTranslate } from "@tolgee/react";
import { FLAGS_NAMES } from "../states/contexts/ReportContext";
import { COLORS } from "../styles/utils/colors";
import Button from "./Button";

const ReportarButtons = ({
  handleReset,
  toggleFlaggingForm,
  reportFromStatus,
  reportID,
  updateReportStatus,
}) => {
  const { t } = useTranslate();

  if (reportFromStatus) {
    return (
      <div className="reportarForm__buttons--fromStatus">
        <Button
          type="submit"
          text={t("continue_without_flagging_btn")}
          styles={{
            backgroundColor: COLORS.WHITE,
            border: `1px solid ${COLORS.CATSKILL_WHITE}`,
            color: COLORS.SHARK,
          }}
          handleClick={() => {
            updateReportStatus({
              reportID,
              newStatus: "reject",
              label: FLAGS_NAMES.POTENTIAL_THREAT,
            });
            toggleFlaggingForm(null);
          }}
        />

        <div className="reportarForm__buttons--fromStatus__formButtons">
          <Button
            type="reset"
            handleClick={() => {
              handleReset();
              toggleFlaggingForm(null);
            }}
            text={t("cancel_button")}
            styles={{
              backgroundColor: COLORS.WHITE,
              border: `1px solid ${COLORS.COPPERFIELD}`,
              color: COLORS.COPPERFIELD,
            }}
          />

          <Button
            type="submit"
            text={t("continue_btn")}
            handleClick={() => {}}
          />
        </div>
      </div>
    );
  }

  return (
    <div className="reportarForm__buttons">
      <Button
        type="reset"
        handleClick={() => {
          handleReset();
          toggleFlaggingForm(null);
        }}
        text={t("cancel_button")}
        styles={{
          backgroundColor: COLORS.WHITE,
          border: `1px solid ${COLORS.COPPERFIELD}`,
          color: COLORS.COPPERFIELD,
        }}
      />

      <Button type="submit" text={t("continue_btn")} handleClick={() => {}} />
    </div>
  );
};
export default ReportarButtons;
