const SliderNextArrow = ({ className, style, onClick }) => {
  return (
    <div
      className={`${className} slider__arrow slider__arrow__next`}
      style={{ ...style }}
      onClick={onClick}
    ></div>
  );
};
export default SliderNextArrow;
