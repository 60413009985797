import { TYPES } from "../actions/dataActions";
import { servicesNames } from "../contexts/serviceName";
import { calculateTotals } from "../helpers/calculateTotals";
import { initialTotals, TOTALS_TITLE } from "../states/contexts/DataContext";

export const SIN_CONSULTAS = "No hay consultas para mostrar";

export const dataReducer = (state, action) => {
  switch (action.type) {
    case TYPES.GET_LAST_REQUEST:
      return handleGetLastRequest(state, action.payload);

    case TYPES.SET_TABLE_DATA:
      return handleSetTableData(state, action.payload);

    case TYPES.CHANGE_HEADERS:
      return handleChangeHeaders(state, action.payload);

    case TYPES.SET_COLUMNS_TO_SHOW:
      return handleSetColumnsToShow(state, action.payload);

    case TYPES.LOAD_DATA:
      return handleLoadData(state, action.payload);

    case TYPES.SET_SERVICE_SEARCHED:
      return handleSetServiceSearched(state, action.payload);

    case TYPES.RESET_REQUEST_COUNT:
      return handleResetRequestCount(state);

    case TYPES.NO_REQUEST_DATA:
      return handleNoRequestData(state, action.payload);

    case TYPES.GET_TOTALS:
      return handleGetTotals(state, action.payload);

    case TYPES.GET_CHART_DATA:
      return handleGetChartData(state, action.payload);

    case TYPES.REQUEST_LOADING:
      return handleRequestLoading(state);

    case TYPES.CHART_LOADING:
      return handleChartLoading(state);

    case TYPES.ERROR_REQUEST:
      return handleErrorRequest(state, action.payload);

    case TYPES.ERROR_TOTALS:
      return handleErrorTotals(state, action.payload);

    case TYPES.ERROR_CHART:
      return handleErrorChart(state, action.payload);

    case TYPES.NO_CHART_DATA:
      return handleNoChartData(state);

    case TYPES.SCROLL_LOADER:
      return handleScrollLoader(state, action.payload);

    case TYPES.RESET_DATA:
      return handleResetData(action.payload);

    case TYPES.REQUESTS_FROM_LINKS:
      return handleRequestsFromLinks(state, action.payload);

    case TYPES.CHANGE_SERVICES_TO_SHOW:
      return handleChangeServicesToShow(state, action.payload);

    case TYPES.GET_PERCENTAGES:
      return handleGetPercentages(state, action.payload);

    case TYPES.ERROR_PERCENTAGES:
      return handleErrorPercentages(state, action.payload);

    case TYPES.RULES_LOADING:
      return handleRulesLoading(state);

    case TYPES.SET_DASHBOARD_RULES:
      return handleSetDashboardRules(state, action.payload);

    case TYPES.NO_RULES_DATA:
      return handleNoRulesData(state);

    default:
      return state;
  }
};

const handleGetLastRequest = (state, { data, requestStartOn }) => ({
  ...state,
  lastRequests: data,
  requestStartOn,
  requestsLoading: false,
  scrollLoader: false,
  errorRequest: false,
});

const handleSetTableData = (state, { data, search }) => {
  if (!Array.isArray(data)) {
    data = [data];
  }

  return {
    ...state,
    requestsLoading: false,
    scrollLoader: false,
    errorRequest: false,
    dataToShow: data,
    serviceSearched: search,
  };
};

const handleChangeHeaders = (state, headers) => ({
  ...state,
  headers: [...headers],
});

const handleSetColumnsToShow = (state, showColumns) => ({
  ...state,
  showColumns,
});

const handleLoadData = (state, { data, requestStartOn, serviceSearched }) => {
  const newDataToShow = state.dataToShow
    .concat(data)
    .filter((item, index, array) => {
      return array.findIndex((t) => t.id === item.id) === index;
    });

  return {
    ...state,
    requestsLoading: false,
    scrollLoader: false,
    errorRequest: false,
    dataToShow: newDataToShow,
    requestStartOn,
    serviceSearched,
  };
};

const handleSetServiceSearched = (state, serviceSearched) => ({
  ...state,
  serviceSearched,
});

const handleResetRequestCount = (state) => ({
  ...state,
  requestStartOn: 0,
});

const handleNoRequestData = (state, payload) => {
  if (payload?.statusError === 404) {
    return {
      ...state,
      requestsLoading: false,
      scrollLoader: false,
      errorRequest: false,
      requestStartOn: state.requestStartOn + 1,
    };
  }

  return {
    ...state,
    errorRequest:
      state.requestStartOn === 0 ? { errText: SIN_CONSULTAS } : false,
    requestsLoading: false,
    scrollLoader: false,
  };
};

const handleGetTotals = (state, payload) => {
  if (
    typeof payload?.data?.calls_by_status === "undefined" ||
    payload?.data?.calls_by_status === null
  ) {
    return {
      ...state,
      totals: { ...initialTotals },
      errorTotals: {
        errText: "Lo sentimos. No pudimos recuperar los totales",
      },
    };
  }

  if (payload?.data?.calls_by_status?.length === 0) {
    return {
      ...state,
      totals: { ...initialTotals },
      errorTotals: "No se realizaron consultas",
    };
  }
  const fraudService = calculateTotals(
    payload?.data?.calls_by_status,
    servicesNames.DECISION_MAKER.backName
  );
  const scoreService = calculateTotals(
    payload?.data?.calls_by_status,
    servicesNames.USER_SCORE.backName
  );

  const fraud200 = fraudService[200] || 0;
  const fraud400 = fraudService[400] || 0;
  const fraud500 = fraudService[500] || 0;

  const score200 = scoreService[200] || 0;
  const score400 = scoreService[400] || 0;
  const score500 = scoreService[500] || 0;

  const newTotals = {
    totals: {
      title: TOTALS_TITLE.total,
      total: fraud200 + fraud400 + fraud500 + score200 + score400 + score500,
      fraudTotal: fraud200 + fraud400 + fraud500,
      scoreTotal: score200 + score400 + score500,
    },
    totalsByService: {
      [servicesNames.DECISION_MAKER.frontName]: [
        {
          title: TOTALS_TITLE.correct,
          subtitle: "Response 200",
          total: fraud200,
        },
        {
          title: TOTALS_TITLE.invalid,
          subtitle: "Response 400",
          total: fraud400,
        },
        {
          title: TOTALS_TITLE.error,
          subtitle: "Response 500",
          total: fraud500,
        },
      ],
      [servicesNames.USER_SCORE.frontName]: [
        {
          title: TOTALS_TITLE.correct,
          subtitle: "Response 200",
          total: score200,
        },
        {
          title: TOTALS_TITLE.invalid,
          subtitle: "Response 400",
          total: score400,
        },
        {
          title: TOTALS_TITLE.error,
          subtitle: "Response 500",
          total: score500,
        },
      ],
    },
  };

  // debugger;
  return {
    ...state,
    errorTotals: false,
    totals: newTotals,
  };
};

const handleGetChartData = (state, chart) => ({
  ...state,
  chart,
  chartLoading: false,
  errorChart: false,
  noChartData: false,
});

const handleRequestLoading = (state) => ({
  ...state,
  requestsLoading: true,
  scrollLoader: false,
  errorRequest: false,
});

const handleChartLoading = (state) => ({
  ...state,
  chartLoading: true,
  errorChart: false,
  noChartData: false,
});

const handleErrorRequest = (state, { error, serviceSearched }) => ({
  ...state,
  dataToShow: [],
  requestsLoading: false,
  scrollLoader: false,
  errorRequest: error || { errText: "Sin dato" },
  serviceSearched,
});

const handleErrorTotals = (state, errorTotals) => ({
  ...state,
  errorTotals,
});

const handleErrorChart = (state, errorChart) => ({
  ...state,
  chartLoading: false,
  chart: {},
  errorChart,
  noChartData: false,
});

const handleNoChartData = (state) => ({
  ...state,
  chartLoading: false,
  chart: {},
  noChartData: {
    errText: "No se realizaron llamadas durante el período seleccionado",
  },
  errorChart: false,
});

const handleScrollLoader = (state, payload) => ({
  ...state,
  ...payload,
  scrollLoader: true,
  requestsLoading: false,
});

const handleResetData = (payload) => ({
  ...payload,
});

const handleRequestsFromLinks = (state, { answer, linkToken }) => ({
  ...state,
  requestsFromLink: answer,
  linkToken,
});

const handleChangeServicesToShow = (state, servicesToShow) => ({
  ...state,
  servicesToShow,
});

const handleGetPercentages = (state, { calls }) => {
  const dm = calls.find(
    (service) => service.service === servicesNames.DECISION_MAKER.backName
  );
  const score = calls.find(
    (service) => service.service === servicesNames.USER_SCORE.backName
  );

  return {
    ...state,
    servicesPercentages: state.servicesPercentages.map((service) =>
      service.id === dm?.service
        ? {
            ...service,
            totalUsers: dm?.count || 0,
            percentage: dm?.percentage || 0,
          }
        : {
            ...service,
            totalUsers: score?.count || 0,
            percentage: score?.percentage || 0,
          }
    ),
  };
};

const handleErrorPercentages = (state, errorPercentages) => ({
  ...state,
  errorPercentages,
});

const handleRulesLoading = (state) => ({
  ...state,
  rulesLoading: true,
  noRulesData: false,
});

const handleSetDashboardRules = (state, { rules, totals }) => ({
  ...state,
  rules,
  rulesTotals: totals,
  rulesLoading: false,
  noRulesData: false,
});

const handleNoRulesData = (state) => ({
  ...state,
  rules: [],
  rulesTotals: [],
  rulesLoading: false,
  noRulesData: [
    { title: "Total", total: 0 },
    { title: "Aceptados", total: 0, icon: "icon-check color--keppel" },
    { title: "Revisar", total: 0, icon: "icon-revisa color--com" },
    { title: "Rechazados", total: 0, icon: "icon-amenaza color--copperfield" },
  ],
});
