import { useContext } from "react";
import Calendar from "./Calendar";
import DatesContext from "../contexts/DatesContext";
import { DATE_TYPE } from "../constants";
import Chart from "./Chart";
import SDKContext from "../contexts/SDKContext";
import SelectSDK from "./SelectSDK";
import Loader from "./Loader";
import { useTranslate } from "@tolgee/react";

const CommandPanelSDK = () => {
  const { t } = useTranslate();
  const { sdkDates, handleDates, updateSDKChart, setUpdateSDKChart } =
    useContext(DatesContext);
  const { error, sdkToShow, sdkPercentages, getChartData, loading } =
    useContext(SDKContext);

  return (
    <section className="dashboard__panel-section">
      <h2 className="heading-2 chart--title__text">
        {t("command_panel_sdk_title")}
      </h2>
      <div style={{ display: "flex", gap: "32px", alignItems: "center" }}>
        <Calendar
          dates={sdkDates}
          setDates={handleDates}
          setUpdateChart={setUpdateSDKChart}
          dateType={DATE_TYPE.SDK}
        />
        <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
          <span className="text-1">{t("command_panel_sdk_conversion")}</span>{" "}
          <SelectSDK />
        </div>
      </div>
      <div className="layout__main__section__dashboard__component--container">
        <div className="layout__main__section__dashboard__component layout__main__section__dashboard__component--resumen border border-light rounded-2">
          {loading ? (
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Loader />
            </div>
          ) : (
            <Chart
              dates={sdkDates}
              setUpdateChart={setUpdateSDKChart}
              updateChart={updateSDKChart}
              sdkChart={true}
              chartLoading={false}
              errorChart={error}
              getChartData={getChartData}
              servicesPercentages={sdkPercentages}
              servicesToShow={sdkToShow}
            />
          )}
        </div>
      </div>
    </section>
  );
};
export default CommandPanelSDK;
