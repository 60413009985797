import { SDK_TYPE } from "../../constants";

const initialData = [
  {
    name: "Start",
    total: 10000,
    percentage: "100%",
    complete: 100,
  },
  {
    name: "Document",
    total: 7500,
    percentage: "75%",
    complete: 75,
  },
  {
    name: "Location",
    total: 6000,
    percentage: "60%",
    complete: 60,
  },
  {
    name: "Selfie",
    total: 5000,
    percentage: "50%",
    complete: 50,
  },
  {
    name: "Done",
    total: 2500,
    percentage: "25%",
    complete: 25,
  },
];

const initialPercentages = [
  { title: "Start", percentage: "0%", total: 0 },
  { title: "Document", percentage: "37%", total: 1850 },
  { title: "Location", percentage: "4%", total: 200 },
  { title: "Selfie", percentage: "9%", total: 550 },
  { title: "Done", percentage: "25%", total: 1250 },
];

const sdkPercentages = [
  {
    id: SDK_TYPE.WEB,
    data: initialPercentages,
  },
  {
    id: SDK_TYPE.ANDROID,
    data: initialPercentages,
  },
  {
    id: SDK_TYPE.MAGIC_LINK,
    data: initialPercentages,
  },
];

const sdkToShow = [
  { title: "Android", id: SDK_TYPE.ANDROID, show: false },
  { title: "Web", id: SDK_TYPE.WEB, show: true },
  { title: "Magic Link", id: SDK_TYPE.MAGIC_LINK, show: false },
];

export const initialState = {
  webData: initialData,
  androidData: initialData,
  magicLinkData: initialData,
  loading: false,
  sdkPercentages,
  sdkToShow,
  noData: false,
};
