import { useEffect, useState } from "react";
import TextInput from "./TextInput";
import {
  validateAddress,
  validateCURP,
  validateEmail,
  validatePhone,
} from "../helpers/formValidations";

const MagicLinkFormPreview = ({
  logo,
  primaryColor,
  secondaryColor,
  primaryTextColor,
  secondaryTextColor,
  inputs,
  identifyUser,
  text,
}) => {
  const { address, curp, email, phone_number } = inputs;
  const [form, setForm] = useState(() => {
    const form = {
      email: "",
      address: "",
      phone_number: "",
      curp: "",
    };

    if (!inputs.email) delete form.email;
    if (!inputs.phone_number) delete form.phone_number;
    if (!inputs.curp) delete form.curp;
    if (!inputs.address) delete form.address;

    return form;
  });
  const [isValidForm, setIsValidForm] = useState(() => false);

  useEffect(() => {
    setIsValidForm(() => {
      if (Object.keys(form).length === 0) return false;
      if (Object.keys(form).every((key) => form[key] === "")) return false;

      for (const key in form) {
        if (form[key] === "") return false;

        if (key === "email") if (!validateEmail(form[key])) return false;

        if (key === "address") if (!validateAddress(form[key])) return false;

        if (key === "phone_number") if (!validatePhone(form[key])) return false;

        if (key === "curp") if (!validateCURP(form[key])) return false;
      }
      return true;
    });
  }, [form]);

  return (
    <div className="linkPreview__component-container">
      <div className="formPreview__header">
        {logo && <img src={logo} alt="logo" className="linkPreview__logo" />}
        <p
          style={{ color: primaryTextColor }}
          className="initPreview__header__text apply-font-text"
        >
          Completa este formulario con tu información personal proporcionando
          información veraz. Estamos comprometidos con la confidencialidad y
          seguridad de tus datos personales
        </p>
      </div>
      <div className="formPreview__form">
        {email && (
          <TextInput
            form={form}
            inputName="email"
            inputLabel="ejemplo@mail.com"
            description="Email"
            setValue={setForm}
            validate={validateEmail}
            textStyles={{
              primaryColor: primaryTextColor,
              secondaryColor: secondaryTextColor,
              fontFamily: text,
            }}
            textClass="apply-font-text"
          />
        )}
        {!identifyUser && curp && (
          <TextInput
            form={form}
            inputName="curp"
            inputLabel="18 dígitos alfanuméricos"
            description="CURP"
            setValue={setForm}
            validate={validateCURP}
            textStyles={{
              primaryColor: primaryTextColor,
              secondaryColor: secondaryTextColor,
              fontFamily: text,
            }}
            textClass="apply-font-text"
          />
        )}
        {phone_number && (
          <TextInput
            form={form}
            inputName="phone_number"
            inputLabel="+52 1234567890"
            description="Teléfono"
            setValue={setForm}
            tooltipPosition={{ width: "150px" }}
            validate={validatePhone}
            textStyles={{
              primaryColor: primaryTextColor,
              secondaryColor: secondaryTextColor,
              fontFamily: text,
            }}
            textClass="apply-font-text"
          />
        )}
        {address && (
          <TextInput
            form={form}
            inputName="address"
            inputLabel="Av Paseo de la Reforma 1428"
            description="Dirección"
            setValue={setForm}
            validate={validateAddress}
            textStyles={{
              primaryColor: primaryTextColor,
              secondaryColor: secondaryTextColor,
              fontFamily: text,
            }}
            textClass="apply-font-text"
          />
        )}
      </div>
      <button
        className="initPreview__btn-container__btn apply-font-heading2"
        style={
          isValidForm
            ? {
                backgroundColor: primaryColor,
              }
            : {
                backgroundColor: secondaryColor,
              }
        }
      >
        Continuar
      </button>
    </div>
  );
};
export default MagicLinkFormPreview;
