import { useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { useTranslate } from "@tolgee/react";

const DataItem = ({ title, text }) => {
  return (
    <div className="reporteDataAccordion__sub-pep__dataItem">
      <span className="text-2 color--shuttle-gray">{title}</span>
      <span className="text-2 color--shuttle-gray">{text}</span>
    </div>
  );
};

const SanctionsSubDropdown = ({ detailsList }) => {
  const [expanded, setExpanded] = useState(false);
  const { t } = useTranslate();

  const handleChange = (panel) => (isExpanded) => {
    if (panel === "") setExpanded(false);
    setExpanded(isExpanded ? panel : false);
  };

  const generateAlias = (array1, array2) => {
    if (!array1 || !array2) return [];
    let maxLength = Math.max(array1.length, array2.length);
    let resultArray = [];

    for (let i = 0; i < maxLength; i++) {
      let element1 = array1[i] || "";
      let element2 = array2[i] || "";
      resultArray.push(element1 + " " + element2);
    }

    return resultArray;
  };

  return (
    <Accordion
      key="studies-sub"
      expanded={expanded}
      onChange={!expanded ? handleChange("studies-sub") : handleChange("")}
      className="reporteDataAccordion"
      style={{ minHeight: "none", paddingTop: "16px" }}
    >
      <AccordionSummary
        aria-controls="studies-sub-content"
        id="studies-sub-header"
        className="reporteDataAccordion__title--blue"
      >
        <div className="reporteDataAccordion__title__text--blue">
          <button>
            {!expanded ? (
              <div className="icon-down reporteDataAccordion__title__icon color--dodger-blue"></div>
            ) : (
              <div className="icon-expand-menos reporteDataAccordion__title__icon color--dodger-blue"></div>
            )}
          </button>
          <h3 className="text-2--bold color--dodger-blue">
            {t("pep_sub_drop_title")}
          </h3>
        </div>
      </AccordionSummary>
      <AccordionDetails
        style={{
          maxHeight: "335px",
          overflowY: "scroll",
        }}
        sx={{ transition: "height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;" }}
      >
        {detailsList?.map((el, i) => {
          const {
            date_of_birth,
            last_names,
            gender,
            given_names,
            place_of_birth,
            alias_names,
            entity_type,
            alias_given_names,
            source_id,
          } = el;
          const alias = generateAlias(alias_given_names, alias_names);
          return (
            <div
              key={`sanctions-${i}`}
              className={`
                    ${
                      i < detailsList.length - 1 &&
                      "reporteDataAccordion__sub-studies"
                    }
                  `}
            >
              {date_of_birth && date_of_birth.length > 0 && (
                <DataItem
                  title={t("dm_report_doc_4")}
                  text={date_of_birth.join(", ")}
                />
              )}
              {last_names && last_names.length > 0 && (
                <DataItem
                  title={t("pep_sub_drop_4")}
                  text={last_names.join(", ")}
                />
              )}
              {gender && gender !== "UNKNOWN" && (
                <DataItem title={t("dm_report_doc_5")} text={gender} />
              )}
              {source_id && source_id !== "UNKNOWN" && (
                <DataItem title={t("pep_sub_drop_7")} text={source_id} />
              )}
              {given_names && given_names.length > 0 && (
                <DataItem
                  title={t("pep_sub_drop_8")}
                  text={given_names.join(" ")}
                />
              )}
              {place_of_birth && place_of_birth.length > 0 && (
                <DataItem
                  title={t("pep_sub_drop_11")}
                  text={place_of_birth.join(" ")}
                />
              )}
              {entity_type && entity_type !== "UNKNOWN" && (
                <DataItem
                  title={t("sanctions_sub_drop_1")}
                  text={entity_type}
                />
              )}
              {alias.length > 0 && (
                <div className="reporteDataAccordion__sub-pep__container--sub">
                  <h4 className="color--shuttle-gray text-2">Alias:</h4>
                  <div className="reporteDataAccordion__sub-pep__container">
                    {alias.map((alias, index) => (
                      <p
                        className="text-2--bold color--shark reporteDataAccordion__sub-pep__link"
                        key={`alias-${i}-${index}`}
                      >
                        {alias}
                      </p>
                    ))}
                  </div>
                </div>
              )}
            </div>
          );
        })}
      </AccordionDetails>
    </Accordion>
  );
};
export default SanctionsSubDropdown;
