import Link from "./Link";
import LandingLink from "./LandingLink";
import { useContext } from "react";
import AuthContext from "../../contexts/AuthContext";

export function CheckLink() {
  const { user } = useContext(AuthContext);
  const is9090 = user.contract_type === '90-90';

  return (
    <>
      {is9090 ? (
        <LandingLink />
      ) : (
        <Link />
      )}
    </>
  );
}
