const PaidBadge = ({ paid }) => {
  return (
    <div
      className={`billingBadge${paid === "active" ? "--active" : "--inactive"}`}
    >
      <span className={`text-3 ${paid === "active" ? "badge--paid" : ""}`}>
        {paid === "active" ? "Pagado" : "Pendiente"}
      </span>
    </div>
  );
};
export default PaidBadge;
