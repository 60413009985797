import TruncatedText from "./TruncatedText";
import DropdownLinkOptions from "./DropdownLinkOptions";
import { formatUTCDate } from "../helpers/formatDate";
import { useTranslate } from "@tolgee/react";

const LinksTableRowTd = ({
  id,
  magic_link_url,
  title,
  created_on,
  times_used,
  is_active,
  handleShowOptions,
  dropdownPosition,
  show,
  token,
  setShowModal,
  setShowConfiguration,
}) => {
  const { t } = useTranslate();

  return (
    <tr className="layout__main__section__consultas__table__tbody__tr">
      <td
        className={`layout__main__section__consultas__table__tbody__td ${
          !is_active && "color--shuttle-gray"
        }`}
      >
        <TruncatedText text={id} />
      </td>
      <td
        className={`layout__main__section__consultas__table__tbody__td ${
          !is_active && "color--shuttle-gray"
        }`}
      >
        <TruncatedText text={magic_link_url} />
      </td>
      <td
        className={`layout__main__section__consultas__table__tbody__td ${
          !is_active && "color--shuttle-gray"
        }`}
      >
        <TruncatedText text={title} />
      </td>
      <td
        className={`layout__main__section__consultas__table__tbody__td ${
          !is_active && "color--shuttle-gray"
        }`}
      >
        <TruncatedText text={formatUTCDate(created_on, "DD/MM/YYYY")} />
      </td>
      <td
        className={`layout__main__section__consultas__table__tbody__td ${
          !is_active && "color--shuttle-gray"
        }`}
      >
        <TruncatedText text={times_used} />
      </td>
      <td
        className={`layout__main__section__consultas__table__tbody__td ${
          !is_active && "color--shuttle-gray"
        }`}
      >
        {is_active ? (
          <span className="text-2 linkPage__badge--active">
            {t("links_row_1")}
          </span>
        ) : (
          <span className="text-2 linkPage__badge--inactive">
            {t("links_row_2")}
          </span>
        )}
      </td>
      <td className="layout__main__section__consultas__table__tbody__td">
        <button
          onClick={() => handleShowOptions({ id: token, isOpen: show })}
          className="icon-opciones layout__main__section__consultas__table__tbody__td__icon"
        ></button>
        <DropdownLinkOptions
          showOptions={show}
          dropdownPosition={dropdownPosition}
          handleShowOptions={handleShowOptions}
          linkId={token}
          isActive={is_active}
          setShowModal={setShowModal}
          setShowConfiguration={setShowConfiguration}
        />
      </td>
    </tr>
  );
};
export default LinksTableRowTd;
