import { useCallback } from "react";
import icon from "../styles/images/icon-trully.svg";

const NameBadge = ({ name, color }) => {
  const getInitials = useCallback(
    (name) => {
      const split = name.split(" ");
      const first = split[0][0];
      const last = split[split.length - 1][0];
      return `${first}${last ? last : ""}`;
    },
    [name]
  );

  return (
    <>
      {name ? (
        <div
          className="changeStatus__iconName"
          style={{ backgroundColor: color }}
        >
          <span className="text-1" style={{ margin: 0 }}>
            {getInitials(name)}
          </span>
        </div>
      ) : (
        <img src={icon} alt="icon-trully" className="changeStatus__iconName" />
      )}
    </>
  );
};
export default NameBadge;
