import { useLocation } from "react-router-dom";
import TableRowTd from "./TableRowTd";
import TableTh from "./TableTh";
import { PRIVATE_ROUTES } from "./pages/routes";
import TeamTableRowTd from "./TeamTableRowTd";
import BillingTableRowTd from "./BillingTableRowTd";
import { useWindowSize } from "../hooks/useWindowSize";
import LinksTableRowTd from "./LinksTableRowTd";

const Table = ({
  headers,
  dataToShow,
  handleClick,
  headersBtnClick,
  handleSelection,
  deleteSelection,
  showColumns,
  inConsultas,
  inTeam,
  inBilling,
  inLinks,
  handleShowAccountsTypes,
  report,
  setShowModal,
  setShowConfiguration,
}) => {
  const location = useLocation();
  const { width } = useWindowSize();

  return (
    <table
      className={`layout__main__section__consultas__table texto-2 ${
        location.pathname === PRIVATE_ROUTES.DASHBOARD && "table-inDash"
      }`}
      style={width < 1700 && inConsultas && report ? { minWidth: "70vw" } : {}}
    >
      <thead className="layout__main__section__consultas__table__thead">
        <tr className="layout__main__section__consultas__table__thead--tr">
          {headers?.map((el, i) => (
            <TableTh
              key={`table-header-${i}`}
              {...el}
              headersBtnClick={headersBtnClick}
              inConsultas={inConsultas}
              showColumns={showColumns}
              inTeam={inTeam}
              inBilling={inBilling}
              report={report}
              inLinks={inLinks}
            />
          ))}
        </tr>
      </thead>
      <tbody className="layout__main__section__consultas__table__tbody">
        {dataToShow?.map((el, i) => {
          if (inConsultas)
            return (
              <TableRowTd key={`${el.id}`} {...el} handleClick={handleClick} />
            );

          if (inTeam)
            return (
              <TeamTableRowTd
                key={`${el.id}`}
                {...el}
                openModal={handleClick}
                handleShowAccountsTypes={handleShowAccountsTypes}
              />
            );

          if (inBilling)
            return (
              <BillingTableRowTd
                el={el}
                key={`${el.id}`}
                handleSelection={handleSelection}
                deleteSelection={deleteSelection}
              />
            );

          if (inLinks)
            return (
              <LinksTableRowTd
                key={`${el.token}-${i}`}
                {...el}
                handleShowOptions={handleClick}
                setShowModal={setShowModal}
                setShowConfiguration={setShowConfiguration}
              />
            );
        })}
      </tbody>
    </table>
  );
};
export default Table;
