import Button from "./Button";

const SendMailModal = ({ handleClick }) => {
  return (
    <section className="modal sendMailModal">
      <div className="modal--center-container">
        <div className="modal--center-container__container">
          <div className="icon-correo modal--center-container__container--icon color--shuttle-gray"></div>
          <p className="heading-3 modal--center-container__container--text sendMailModal--center-container__container--text">
            Se ha enviado un email a su cuenta de correo
          </p>
          <div className="modal--center-container__container__buttons">
            <Button
              handleClick={handleClick}
              styles={{ padding: "9px 12px" }}
              text="Aceptar"
            />
          </div>
        </div>
      </div>
    </section>
  );
};
export default SendMailModal;
