import Button from "./Button";

const NoDataToShow = ({ icon, title, text, btnData }) => {
  return (
    <div className="noDataToShow">
      <img className="noDataToShow__img" src={icon} alt="no hay dato" />
      <div className="noDataToShow__container">
        <h4 className="text-1--bold">{title}</h4>
        <p className="text-2">{text}</p>
      </div>
      {btnData && (
        <Button handleClick={btnData.handleClick} text={btnData.text} />
      )}
    </div>
  );
};
export default NoDataToShow;
