import moment from "moment";
import { useEffect, useState } from "react";

const SelectDate = ({ description, dates, setDates }) => {
  const [open, setOpen] = useState(false);
  const [months, setMonths] = useState([]);
  const [monthsDays, setMonthsDays] = useState([]);
  const [daysList, setDaysList] = useState([]);
  const [showDays, setShowDays] = useState(false);
  const [underline, setUnderline] = useState(null);
  const [monthSelected, setMonthSelected] = useState({ name: "", number: 0 });

  useEffect(() => {
    const thisMonth = moment().month();
    const months = moment.months().slice(0, thisMonth + 1);
    const totalMonth = months.length;
    const monthDays = Array.from({ length: totalMonth }, (_, i) =>
      moment().month(i).daysInMonth()
    );

    setMonths(months);
    setMonthsDays(monthDays);
  }, []);

  const handleEnter = (index) => {
    const month = months[index];
    const monthNumber = index;
    const totalDays = Number(monthsDays[index]);
    const daysArr = [];

    for (let i = 0; i < totalDays; i++) {
      daysArr.push(i + 1);
    }

    setDaysList(daysArr);
    setShowDays(true);
    setUnderline(index);
    setMonthSelected({
      name: month,
      number: monthNumber,
    });
  };

  const handleLeave = () => {
    setDaysList([]);
    setShowDays(false);
  };

  const openPicker = () => {
    if (open) {
      setOpen(false);
      setDaysList([]);
      setShowDays(false);
    } else {
      setOpen(true);
    }
  };

  const handleClick = ({ target }) => {
    const daySelected = Number(target.textContent);
    const year = Number(moment().local().format("YYYY"));

    if (description === "Desde") {
      setDates({
        single: {
          ...dates,
          from: {
            year,
            month: monthSelected.name,
            monthNumber: monthSelected.number,
            day: daySelected,
          },
        },
      });
    } else {
      const from = dates.from;

      if (from.month === "") {
        setDates({
          single: {
            from: {
              year,
              month: monthSelected.name,
              monthNumber: monthSelected.number,
              day: daySelected,
            },
            to: {
              year,
              month: monthSelected.name,
              monthNumber: monthSelected.number,
              day: daySelected,
            },
          },
        });
        return;
      }

      setDates({
        single: {
          ...dates,
          to: {
            year,
            month: monthSelected.name,
            monthNumber: monthSelected.number,
            day: daySelected,
          },
        },
      });
    }

    setDaysList([]);
    setShowDays(false);
    setOpen(false);
  };

  return (
    <div className="selectDate">
      <button
        className={`selectDate--btn ${open && "border-blue"}`}
        onClick={openPicker}
      >
        <span className="text-1">{description}</span>
        {open ? (
          <div className="icon-expand-menos"></div>
        ) : (
          <div className="icon-down"></div>
        )}
      </button>
      <div className="selectDate__container">
        {open && (
          <div className="selectDate__container--months">
            {months.map((month, i) => (
              <div
                key={`${description}-${i}`}
                className="selectDate__container--months__month"
              >
                <button
                  onMouseEnter={() => handleEnter(i)}
                  className={`text-1 ${underline === i && `underline-${i}`}`}
                >
                  {month}
                </button>
                <div className="icon-siguiente"></div>
              </div>
            ))}
          </div>
        )}
        {showDays && (
          <div className="selectDate__container__days">
            {daysList.length !== 0 && (
              <div
                onMouseLeave={handleLeave}
                className="selectDate__container--days"
              >
                {daysList.map((day, i) => (
                  <button
                    className="text-1 selectDate__container--days--btn"
                    key={`${description}-day${i}`}
                    onClick={handleClick}
                  >
                    {day}
                  </button>
                ))}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
export default SelectDate;
