import * as locales from "react-date-range/dist/locale";
import { useEffect, useState } from "react";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { DateRange } from "react-date-range";
import SelectDate from "./SelectDate";
import moment from "moment";
import { COLORS } from "../styles/utils/colors";
import { useTranslate } from "@tolgee/react";
import { useTimeTranslate } from "../hooks/useTimeTranslate";

const initialRange = [
  {
    startDate: moment().subtract(7, "days").toDate(),
    endDate: moment().toDate(),
    key: "selection",
  },
];

const Calendar = ({
  dates,
  setDates,
  setUpdateChart,
  inBilling,
  csvDates,
  dateType,
}) => {
  const [open, setOpen] = useState(false);
  const [totalDays, setTotalDays] = useState(0);
  const [dateRange, setDateRange] = useState(initialRange);
  const [border, setBorder] = useState({});
  const { t } = useTranslate();
  const translate = useTimeTranslate();

  useEffect(() => {
    const { from, to } = dates;
    const inicio = moment([from.year, from.monthNumber, from.day]);
    const fin = moment([to.year, to.monthNumber, to.day]);
    const totalDays = fin.diff(inicio, "days") + 1;
    setTotalDays(totalDays >= 0 ? totalDays : 0);

    const newRange = [
      {
        startDate: inicio.toDate(),
        endDate: fin.toDate(),
        key: "selection",
      },
    ];
    setDateRange(newRange);
  }, [dates]);

  const handleSelect = (ranges) => {
    setDateRange([ranges.selection]);

    const startDate = moment(ranges.selection.startDate);
    const endDate = moment(ranges.selection.endDate);

    setDates({ startDate, endDate, dateType });
  };

  const handleClick = () => {
    if (setUpdateChart) setUpdateChart(true);
    if (csvDates) setBorder(() => ({ border: `1px solid ${COLORS.KEPPEL}` }));
    setOpen(false);
  };

  return (
    <div
      className={`chart__calendar__container ${
        csvDates && "modal__csv__calendar"
      }`}
    >
      <button
        style={border}
        onClick={() => setOpen(!open)}
        className={`chart__calendar__container--btn ${open && "border-blue"} ${
          inBilling && "calendar-inBilling"
        } ${csvDates && "modal__csv__calendar__btn"}`}
      >
        <div className="chart__calendar__container--icon icon-calendario"></div>
        <span className={inBilling ? "text-5" : "text-2"}>
          <span>
            {dates.from.month.toUpperCase().substring(0, 3)} {dates.from.day},{" "}
            {dates.from.year}
          </span>
          <span> - </span>
          <span>
            {dates.to.month.toUpperCase().substring(0, 3)} {dates.to.day},{" "}
            {dates.to.year}
          </span>
        </span>
      </button>
      {open && (
        <>
          <div
            className={`chart__calendar__container--calendar ${
              inBilling && "calendar-inBilling--calendar"
            } ${csvDates && "modal__csv__calendar__picker"}`}
          >
            <div className="chart__calendar__container--calendar__buttons">
              <div className="chart__calendar__container--calendar__buttons__container">
                <SelectDate
                  description={translate("Desde")}
                  dates={dates}
                  setDates={setDates}
                />
                <SelectDate
                  description={translate("Hasta")}
                  dates={dates}
                  setDates={setDates}
                />
              </div>
              <h4 className="heading-3">
                {t("calendar_days", "{totalDays} días", { totalDays })}
              </h4>
            </div>
            <DateRange
              locale={locales["es"]}
              showMonthAndYearPickers={false}
              showMonthArrow={true}
              ranges={dateRange}
              onChange={handleSelect}
              moveRangeOnFirstSelection={false}
              showPreview={false}
            />
          </div>
          <button
            onClick={handleClick}
            className={`chart__calendar__container--calendar-btn ${
              inBilling && "calendar-inBilling--calendar-btn"
            } ${csvDates && "modal__csv__calendar__picker__btn"}`}
          >
            {t("save_btn")}
          </button>
        </>
      )}
    </div>
  );
};
export default Calendar;
