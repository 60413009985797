import { ROLES } from "../reducers/authReducer";
import { useContext } from "react";
import AuthContext from "../contexts/AuthContext";
import DropDownOptions from "./DropDownOptions";

const DropDownAccounts = ({
  showAccountTypes,
  account_type,
  dropdownPosition,
  userID,
  addUser,
  handleClick,
  handleTransferir,
}) => {
  const {
    user: { role },
  } = useContext(AuthContext);

  return (
    <DropDownOptions
      show={showAccountTypes}
      dropdownPosition={dropdownPosition}
      functions={{ handleClick, handleTransferir }}
      containerData={{ account_type, userID, addUser, role }}
      classNames={{
        container: "team--dropdown-container",
        transition: addUser
          ? "slideInAddUser"
          : role === ROLES.PROPIETARIO
          ? "slideInTeamCompleto"
          : "slideInTeam",
        dropdown: "team--dropdown",
        dropdownContent: "team--dropdown__content",
        dropdownContentElement: "team--dropdown__content--element",
      }}
      inTeam={true}
    />
  );
};
export default DropDownAccounts;
