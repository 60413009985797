import { TYPES } from "../actions/linkActions";
import { getLinkID } from "../contexts/LinkContext";

export function linkReducer(state, action) {
  switch (action.type) {
    case TYPES.GET_LINKS_LIST:
      return handleGetLinksList(state, action.payload);

    case TYPES.DATA_TO_SHOW:
      return handleDataToShow(state, action.payload);

    case TYPES.NEW_HEADERS_TO_SHOW:
      return handleNewHeadersToShow(state, action.payload);

    case TYPES.GET_LINKS_LOADER:
      return handleGetLinksLoader(state);

    case TYPES.SCROLL_LOADER:
      return handleScrollLoader(state);

    case TYPES.NO_LINKS_TO_SHOW:
      return handleNoLinksToShow(state);

    case TYPES.GET_LINKS_ERROR:
      return handleGetLinksError(state);

    case TYPES.SET_ACTIVE_LINK:
      return handleSetActiveLink(state, action.payload);

    case TYPES.UPDATE_LINK:
      return handleUpdateLink(state, action.payload);

    case TYPES.SET_MAGIC_LINK_URL:
      return handleSetMagicLinkURL(state, action.payload);

    default:
      return state;
  }
}

const handleGetLinksList = (state, { data, requestStartOn }) => {
  const newDataToShow = state.dataToShow
    .concat(data)
    .filter((item, index, array) => {
      return array.findIndex((t) => t.token === item.token) === index;
    });

  return {
    ...state,
    dataToShow: state.dataToShow.length > 0 ? newDataToShow : [...data],
    requestStartOn,
    linksLoading: false,
    scrollLoader: false,
    linksError: false,
    noLinksToShow: false,
  };
};

const handleDataToShow = (state, { data }) => ({
  ...state,
  dataToShow: data,
  linksLoading: false,
  scrollLoader: false,
  linksError: false,
  noLinksToShow: false,
});

const handleNewHeadersToShow = (state, { headers }) => ({
  ...state,
  headers,
});

const handleGetLinksLoader = (state) => ({
  ...state,
  linksLoading: true,
});

const handleScrollLoader = (state) => ({
  ...state,
  scrollLoader: true,
});

const handleNoLinksToShow = (state) => {
  if (state.dataToShow.length > 0) {
    return {
      ...state,
      scrollLoader: false,
      linksLoading: false,
    };
  }

  return {
    ...state,
    scrollLoader: false,
    linksLoading: false,
    noLinksToShow: true,
  };
};

const handleGetLinksError = (state) => ({
  ...state,
  scrollLoader: false,
  linksLoading: false,
  noLinksToShow: false,
});

const handleSetActiveLink = (state, { activeLink, isEdit }) => ({
  ...state,
  activeLink,
  isEdit,
});

const handleUpdateLink = (state, data) => {
  const newDataToShow = state.dataToShow.map((link) =>
    link.token === data.token
      ? { ...link, ...data, id: getLinkID(link?.magic_link_url) }
      : { ...link, id: getLinkID(link?.magic_link_url) }
  );

  return {
    ...state,
    activeLink: data,
    dataToShow: newDataToShow,
    isEdit: false,
  };
};

const handleSetMagicLinkURL = (state, magicLinkURL) => ({
  ...state,
  magicLinkURL,
});
