import { COLORS } from "../styles/utils/colors";
import TruncatedText from "./TruncatedText";

const Informer = ({ assigned_to }) => {
  return (
    <>
      {assigned_to ? (
        <div className="table__name">
          <TruncatedText
            text={assigned_to}
            nameBadge={{
              show: true,
              name: assigned_to,
              color: COLORS.BUTTERMILK,
            }}
          />
        </div>
      ) : (
        <div className="table__name">
          <TruncatedText text="Trully" nameBadge={{ show: true }} />
        </div>
      )}
    </>
  );
};
export default Informer;
