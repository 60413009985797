import { CSSTransition } from "react-transition-group";
import DropdownAccountsContainer from "./DropdownAccountsContainer";
import DropdownLinkOptionsContainer from "./DropdownLinkOptionsContainer";
import DropdownSDKContainer from "./DropdownSDKContainer";

const DropDownOptions = ({
  show,
  dropdownPosition,
  classNames,
  functions,
  containerData,
  inTeam,
  inLinks,
  inSDK,
}) => {
  const dropdownStyles = dropdownPosition
    ? {
        top: `${dropdownPosition.y}px`,
        left: `${dropdownPosition.x}px`,
      }
    : {};

  return (
    <div
      className={`slideContainer slideContainer--inputAuto ${classNames.container}`}
    >
      <CSSTransition
        in={show}
        timeout={{
          enter: 1000,
          exit: 300,
        }}
        classNames={classNames.transition}
        mountOnEnter
        unmountOnExit
      >
        <div
          className={`autoCompleteSelect slideInDown--floating ${classNames.dropdown}`}
          style={dropdownStyles}
        >
          <div
            className={`autoCompleteSelect__content ${classNames.dropdownContent}`}
          >
            {inTeam && (
              <DropdownAccountsContainer
                classNames={classNames}
                containerData={containerData}
                functions={functions}
              />
            )}
            {inLinks && (
              <DropdownLinkOptionsContainer
                classNames={classNames}
                containerData={containerData}
                functions={functions}
              />
            )}
            {inSDK && (
              <DropdownSDKContainer
                classNames={classNames}
                containerData={containerData}
                functions={functions}
              />
            )}
          </div>
        </div>
      </CSSTransition>
    </div>
  );
};
export default DropDownOptions;
