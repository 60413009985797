import { useEffect, useState } from "react";
import Button from "./Button";
import TextInput from "./TextInput";
import { validateString } from "../helpers/formValidations";
import { COLORS } from "../styles/utils/colors";
import { useTranslate } from '@tolgee/react';
import { T } from "@tolgee/react";


const GenerateLinkForm = ({ setShowModal, handleCreate }) => {
  const [form, setForm] = useState({ name: "" });
  const [disabled, setDisabled] = useState(true);
  const { t } = useTranslate();

  useEffect(() => {
    setDisabled(() => !validateString(form.name));
  }, [form.name]);

  return (
    <div className="generateLinkForm">
      <h3 className="heading-2"><T keyName="header_magic_link" /></h3>
      <TextInput
        form={form}
        inputName="name"
        inputLabel={t("input_magic_link_name")}
        description={t("input_name")}
        setValue={setForm}
        validate={validateString}
        validateErrorText={t("input_magic_link_name_error")}
        styles={{ width: "100%" }}
      />
      <div className="generateLinkForm__btn-container">
        <Button
          text={t("cancel_button")}
          styles={{
            padding: "12px",
            color: COLORS.SHARK,
            backgroundColor: COLORS.WHITE,
            border: `1px solid ${COLORS.SHARK}`,
          }}
          handleClick={() => setShowModal(false)}
        />
        <Button
          disabled={disabled}
          text={t("button_magic_link_create")}
          styles={{ padding: "12px" }}
          handleClick={() => handleCreate(form.name)}
        />
      </div>
    </div>
  );
};
export default GenerateLinkForm;
