import { useTranslate } from "@tolgee/react";
import Button from "./Button";
import ShowImage from "./ShowImage";

const SingleDescription = ({
  description,
  SubDropdown,
  showImage,
  setShowImage,
  detailsList,
}) => {
  const { t } = useTranslate();

  return (
    <>
      {description.map((el, i) => {
        if (!el || el.text === "-") return <></>;
        else if (
          (el.text === "selfie" || el.text === "document_image") &&
          el.image
        )
          return (
            <div
              key={`image-${el.text}`}
              className="reporteDataAccordion__image"
            >
              {!showImage && (
                <>
                  <div className="reporteDataAccordion__image__container">
                    <img src={el.image} alt={el.text} />
                  </div>
                  <Button
                    handleClick={() => setShowImage((prev) => !prev)}
                    text={t("show_img_btn")}
                  />
                </>
              )}
              {showImage && (
                <ShowImage
                  showImage={showImage}
                  image={el.image}
                  text={el.text}
                  setShowImage={setShowImage}
                />
              )}
            </div>
          );
        else if (el.text !== "selfie" && el.text !== "document_image")
          return (
            <div
              key={`reporte-data-accordion-${i}`}
              className="reporteDataAccordion__description"
            >
              <h4 className="text-2 color--shuttle-gray">{el.title}</h4>
              <p className="text-2">{el.text}</p>
            </div>
          );
      })}
      {SubDropdown && <SubDropdown detailsList={detailsList} />}
    </>
  );
};
export default SingleDescription;
