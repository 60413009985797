import moment from "moment";
import { useContext, useEffect, useRef, useState } from "react";
import TextDataResumen from "./TextDataResumen";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ReportContext from "../contexts/ReportContext";
import { url } from "../contexts/domain";
import { formatUTCDate } from "../helpers/formatDate";
import { createScrollFn } from "../helpers/createScrollFn";
import { useTranslate } from "@tolgee/react";
import LinearLoader from "./LinearLoader";

const translate = (text, isLangEs) => {
  if (isLangEs) return text;

  switch (text) {
    case "Tipo de institución":
      return "Company type";
    case "Fecha y hora":
      return "Date";
    case "Motivo":
      return "Reason";

    default:
      return text;
  }
};

const Title = ({ reportsCounted, handleClick, icon }) => {
  const { t } = useTranslate();

  return (
    <div className="networkReports__title-container">
      <button onClick={() => handleClick("panel-reportes-0")}>
        <div className={`${icon} reporteDataAccordion__title__icon`}></div>
      </button>
      <div className="networkReports__title">
        <div className="networkReports__title-container__flag">
          <div className="icon-flag-fill color--copperfield"></div>
        </div>
        <span className="text-1--bold color--copperfield">
          {t("dm_report_reports_title")}: {reportsCounted}
        </span>
      </div>
    </div>
  );
};

const Report = ({ report, fraudTypes, isLangEs }) => {
  const { t } = useTranslate();
  const { company_type, created_on, description, fraud_reason_id } = report;

  const formattedDate = formatUTCDate(
    created_on,
    "D [de] MMM YYYY, hh:mm:ss A"
  );

  const translateReasons = (text) => {
    switch (text) {
      case "identity_fraud": {
        return isLangEs ? "Suplantación de identidad" : "Identity fraud";
      }
      case "abuse": {
        return isLangEs ? "Comportamiento Sospechoso" : "Suspect Behavior";
      }
      case "first_party_fraud": {
        return isLangEs ? "Cuenta Mula" : "Mule Account";
      }
      case "first_payment_default": {
        return isLangEs ? "Morosidad" : "Defaulter";
      }
      case "chargeback": {
        return isLangEs ? "Contracargo" : "Charge back";
      }
      case "others": {
        return isLangEs ? "Otros" : "others";
      }
      default:
        return text;
    }
  };

  const getReasonType = (fraudTypes, isLangEs) => {
    if (!fraud_reason_id) return translateReasons(report.reason);

    const fraudType = fraudTypes.find((type) => type.id === fraud_reason_id);

    const reason = isLangEs
      ? fraudType.description_es
      : fraudType.description_en;

    return reason ?? fraudType.description_es;
  };

  return (
    <div className="networkReports__report">
      <div className="networkReports__report__separador"></div>

      <TextDataResumen
        description={translate("Tipo de institución")}
        value={company_type}
        report_network={true}
      />

      <TextDataResumen
        description={translate("Fecha y hora")}
        value={formattedDate}
        report_network={true}
      />
      <TextDataResumen
        description={translate("Motivo")}
        value={getReasonType(fraudTypes, isLangEs)}
        report_network={true}
      />
      <div className="networkReports__report__description">
        <span className="text-2--bold">
          {t("dm_report_reports_description")}
        </span>
        <p className="text-2">{description}</p>
      </div>
    </div>
  );
};

const NetworkReports = ({
  reportsList,
  fraudTypes,
  reportID,
  total_reports,
  identityReported,
  identity_id,
  isLangEs,
}) => {
  const [expanded, setExpanded] = useState("");
  const [open, setOpen] = useState(false);
  const { getNetworkReports, scrollLoader } = useContext(ReportContext);
  const [requestStartOn, setRequestStartOn] = useState(0);
  const containerRef = useRef(null);
  const prevScrollY = useRef(0);

  function handleScroll() {
    identityReported
      ? getNetworkReports({
          endpoint: `${url}/usage/request/identity/${identity_id}/reports?identity_reported=${identityReported}&page=${
            requestStartOn + 1
          }&offset=10`,
        })
      : getNetworkReports({
          requestStartOn: requestStartOn + 1,
          identity_id: reportID,
        });
    setRequestStartOn((prev) => prev + 1);
  }

  useEffect(() => {
    const container = containerRef.current;
    const scrollFn = createScrollFn({ container, prevScrollY, handleScroll });
    container.addEventListener("scroll", scrollFn);
    return () => container.removeEventListener("scroll", scrollFn);
  }, [requestStartOn]);

  const handleClick = (panel) => (isExpanded) => {
    if (panel === "") setExpanded(false);
    setOpen(!open);
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Accordion
      aria-controls={`panel-reports-0-content`}
      id={`panel-reports-0-header`}
      expanded={expanded === "panel-reports-0"}
      onChange={!expanded ? handleClick("panel-reports-0") : handleClick("")}
      className="reporteDataAccordion"
    >
      <AccordionSummary
        aria-controls="panel-reports-content"
        id="panel-reports-header"
        style={{ padding: 0 }}
      >
        <button>
          {!expanded ? (
            <div className="icon-down reporteDataAccordion__title__icon"></div>
          ) : (
            <div className="icon-expand-menos reporteDataAccordion__title__icon"></div>
          )}
        </button>
        <Title reportsCounted={total_reports} />
      </AccordionSummary>
      <AccordionDetails style={{ padding: 0, marginTop: "8px" }}>
        <div ref={containerRef} className="networkReports__reportsLists">
          {reportsList.map((report) => {
            const { created_on } = report;
            const idFromCreation = moment(created_on).valueOf();
            return (
              <Report
                key={idFromCreation}
                report={report}
                fraudTypes={fraudTypes}
                isLangEs={isLangEs}
              />
            );
          })}
          {scrollLoader && <LinearLoader />}
        </div>
      </AccordionDetails>
    </Accordion>
  );
};
export default NetworkReports;
