import React from "react";
import ReactDOM from "react-dom/client";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { disableReactDevTools } from "@fvilers/disable-react-devtools";
import { GoogleOAuthProvider } from "@react-oauth/google";

const root = ReactDOM.createRoot(document.getElementById("root"));

if (process.env.REACT_APP_TRULLY_ENV === "prod") disableReactDevTools();
const isFacekey = window.location.href.includes('facekey.asofom.mx');

root.render(
  <GoogleOAuthProvider clientId={isFacekey
    ? process.env.REACT_APP_GOOGLE_CLIENT_ID_ASOFOM
    : process.env.REACT_APP_GOOGLE_CLIENT_ID
  }
  >
    <App />
  </GoogleOAuthProvider>
);

reportWebVitals();
