import { useContext, useEffect, useState } from "react";
import DataContext from "../contexts/DataContext";
import {
  Bar,
  BarChart,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { COLORS } from "../styles/utils/colors";
import { servicesNames } from "../contexts/serviceName";

const ConsultasChart = ({ walkthroughData }) => {
  const { chart, servicesToShow } = useContext(DataContext);
  const fraud = servicesNames.DECISION_MAKER.frontName;
  const userScore = servicesNames.USER_SCORE.frontName;
  const [dataToShow, setDataToShow] = useState(() => ({
    [fraud]: true,
    [userScore]: true,
  }));

  useEffect(() => {
    setDataToShow((prev) => ({
      ...prev,
      [fraud]: servicesToShow.find((el) => el.title === fraud)?.show,
      [userScore]: servicesToShow.find((el) => el.title === userScore)?.show,
    }));
  }, [servicesToShow]);

  return (
    <ResponsiveContainer>
      <BarChart
        data={walkthroughData ? walkthroughData.dataChart : chart.dataChart}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
        barSize={20}
        barGap={2}
        style={{ fontFamily: "DM Sans" }}
      >
        <XAxis
          dataKey="nameX"
          scale="point"
          padding={{ left: 15, right: 15 }}
        />
        <YAxis type="number" />
        <Tooltip />
        <Legend />
        {dataToShow[fraud] && (
          <Bar dataKey="Fraud" fill={COLORS.DODGER_BLUE} stackId="a" />
        )}
        {dataToShow[userScore] && (
          <Bar dataKey="Score" fill={COLORS.KEPPEL} stackId="a" />
        )}
      </BarChart>
    </ResponsiveContainer>
  );
};
export default ConsultasChart;
