export const SNACKBAR_STATES_NAMES = {
  PERFIL_PASS_CHANGE: "PERFIL_PASS_CHANGE",
  DELETE_USER: "DELETE_USER",
  UPDATE_USER: "UPDATE_USER",
  ADD_USER: "ADD_USER",
  RESENT_INVITE: "RESENT_INVITE",
  CREATE_EXTERNAL_ID: "CREATE_EXTERNAL_ID",
  CREATE_REPORT: "CREATE_REPORT",
  SET_RULES: "SET_RULES",
  COPY_JSON: "COPY_JSON",
  SHOW_NOTIFICATION_REPORT: "SHOW_NOTIFICATION_REPORT",
  OPEN_REPORT: "OPEN_REPORT",
  UPDATE_STATUS_REPORT: "UPDATE_STATUS_REPORT",
  MAGIC_LINK: "MAGIC_LINK",
};

export const SNACKBAR_TEXTS = {
  ERROR_TITLE: "Ocurrió un problema",
  ERROR_TEXT: "Inténtalo en unos minutos",
  PASS_CHANGE_TEXT: "Contraseña actualizada correctamente",
  PASS_CHANGE_ERROR: "Contraseña actual incorrecta",
  DELETE_USER_TEXT: "Miembro eliminado correctamente",
  UPDATE_USER_TEXT: "Usuario actualizado correctamente",
  UPDATE_USER_ERROR: "Datos no válidos",
  ADD_USER_TEXT: "Se ha enviado la invitación al correo electrónico",
  ADD_USER_ERROR: "No se pudo enviar la invitación",
  RESENT_INVITE_TEXT: "La invitación ha sido reenviada",
  RESENT_INVITE_ERROR: "La invitación no pudo ser reenviada",
  CREATE_EXTERNAL_ID_TEXT: "ID de usuario creado exitosamente",
  CREATE_EXTERNAL_ID_ERROR: "No se pudo crear el id",
  CREATE_REPORT_TEXT: "El reporte se ha agregado exitosamente",
  SET_RULES_TEXT: "Reglas actualizadas correctamente",
  SET_RULES_ERROR: "No se pudo actualizar las reglas",
  COPY_JSON_TEXT: "Reporte copiado en el portapapeles",
  COPY_URL_TEXT: "Link copiado en el portapapeles",
  COPY_JSON_ERROR: "No se pudo copiar",
  NO_COPY_JSON: "Su navegador no permite copiar",
  SHOW_NOTIFICATION_REPORT_ERROR: "No pudimos obtener el reporte",
  UPDATE_STATUS_REPORT_TITLE: "Revisión completada",
  UPDATE_STATUS_REPORT_TEXT:
    "La información del solicitante fue enviada a la columna “Completados”",
  MAGIC_LINK_UPDATE: "Link actualizado exitosamente",
  QR_COPY: "La imagen fue copiada al portapapeles",
  MAGIC_LINK_EDIT_ERROR: "No se pudo obtener la información del link",
  MAGIC_LINK_CREATED: "Link creado exitosamente",
};

export const SNACKBAR_TEXTS_EN = {
  ERROR_TITLE: "A problem occurred",
  ERROR_TEXT: "Try again in a few minutes",
  PASS_CHANGE_TEXT: "Password updated successfully",
  PASS_CHANGE_ERROR: "Incorrect current password",
  DELETE_USER_TEXT: "Member deleted successfully",
  UPDATE_USER_TEXT: "User updated successfully",
  UPDATE_USER_ERROR: "Invalid data",
  ADD_USER_TEXT: "Invitation sent to email",
  ADD_USER_ERROR: "Failed to send invitation",
  RESENT_INVITE_TEXT: "The invite was sent successfully",
  RESENT_INVITE_ERROR: "The invite couldn't be sent",
  CREATE_EXTERNAL_ID_TEXT: "User ID created successfully",
  CREATE_EXTERNAL_ID_ERROR: "Failed to create ID",
  CREATE_REPORT_TEXT: "The report has been added successfully",
  SET_RULES_TEXT: "Rules updated successfully",
  SET_RULES_ERROR: "Failed to update rules",
  COPY_JSON_TEXT: "Report copied to clipboard",
  COPY_URL_TEXT: "Link copied to clipboard",
  COPY_JSON_ERROR: "Could not copy",
  NO_COPY_JSON: "Your browser does not allow copying",
  SHOW_NOTIFICATION_REPORT_ERROR: "We could not retrieve the report",
  UPDATE_STATUS_REPORT_TITLE: "Review completed",
  UPDATE_STATUS_REPORT_TEXT:
    "Applicant information was sent to the 'Completed' column",
  MAGIC_LINK_UPDATE: "Link updated successfully",
  QR_COPY: "The image was copied to the clipboard",
  MAGIC_LINK_EDIT_ERROR: "Could not retrieve the link information",
  MAGIC_LINK_CREATED: "Link created successfully",
};

export const initialState = [
  {
    name: SNACKBAR_STATES_NAMES.PERFIL_PASS_CHANGE,
  },
  {
    name: SNACKBAR_STATES_NAMES.DELETE_USER,
  },
  {
    name: SNACKBAR_STATES_NAMES.UPDATE_USER,
  },
  {
    name: SNACKBAR_STATES_NAMES.ADD_USER,
  },
  {
    name: SNACKBAR_STATES_NAMES.CREATE_EXTERNAL_ID,
  },
  {
    name: SNACKBAR_STATES_NAMES.SET_RULES,
  },
  {
    name: SNACKBAR_STATES_NAMES.COPY_JSON,
  },
  {
    name: SNACKBAR_STATES_NAMES.SHOW_NOTIFICATION_REPORT,
  },
  {
    name: SNACKBAR_STATES_NAMES.OPEN_REPORT,
  },
  {
    name: SNACKBAR_STATES_NAMES.UPDATE_STATUS_REPORT,
  },
  {
    mane: SNACKBAR_STATES_NAMES.MAGIC_LINK,
  },
];
