import { useContext, useEffect, useState } from "react";
import UserData from "./UserData";
import AuthContext from "../contexts/AuthContext";
import Modal from "./Modal";
import UserContext from "../contexts/UserContext";
import {
  validateEmail,
  validateName,
  validatePhone,
} from "../helpers/formValidations";
import SnackBarContext from "../contexts/SnackBarContext";
import SmallLoader from "./SmallLoader";
import {
  SNACKBAR_STATES_NAMES,
  SNACKBAR_TEXTS,
  SNACKBAR_TEXTS_EN,
} from "../states/contexts/SnackContext";
import Button from "./Button";
import { COLORS } from "../styles/utils/colors";
import { T, useTranslate, useTolgee } from "@tolgee/react";

const initialForm = {
  first_name: "-",
  last_name: "-",
  email: "-",
  phone_number: "-",
};

const PerfilDataComponent = () => {
  const { user } = useContext(AuthContext);
  const { changeUserData, updateUserLoading } = useContext(UserContext);
  const { setSnack } = useContext(SnackBarContext);
  const [showModal, setShowModal] = useState(false);
  const [form, setForm] = useState(initialForm);
  const [edit, setEdit] = useState(false);
  const tolgee = useTolgee(["language"]);
  const isLangEs = tolgee.getLanguage() === "es-MX";
  const { t } = useTranslate();

  useEffect(() => {
    setForm({
      first_name: user.first_name || initialForm.first_name,
      last_name: user.last_name || initialForm.last_name,
      email: user.email || initialForm.email,
      phone_number: user.phone_number || initialForm.phone_number,
    });
  }, [user.first_name, user.last_name, user.email, user.phone_number]);

  const handleChange = (value, name) => {
    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleBtn = () => {
    setShowModal(true);
  };

  const handleCancel = () => {
    setForm({
      first_name: user.first_name || initialForm.first_name,
      last_name: user.last_name || initialForm.last_name,
      email: user.email || initialForm.email,
      phone_number: user.phone_number || initialForm.phone_number,
    });
    setEdit(false);
  };

  const handleConfirm = () => {
    const validName =
      form.first_name !== "-" ? validateName(form.first_name) : true;
    const validLastName =
      form.last_name !== "-" ? validateName(form.last_name) : true;
    const validEmail = form.email !== "-" ? validateEmail(form.email) : true;
    const validPhone =
      form.phone_number !== "-" ? validatePhone(form.phone_number) : true;

    if (!validName || !validLastName || !validEmail || !validPhone) {
      const snackState = {
        show: true,
        error: {
          err: true,
          errText: isLangEs
            ? SNACKBAR_TEXTS.UPDATE_USER_ERROR
            : SNACKBAR_TEXTS_EN.UPDATE_USER_ERROR,
          errTextTitle: isLangEs
            ? SNACKBAR_TEXTS.ERROR_TITLE
            : SNACKBAR_TEXTS_EN.ERROR_TITLE,
        },
      };

      setSnack(SNACKBAR_STATES_NAMES.UPDATE_USER, snackState);
      setForm({
        first_name: user.first_name || initialForm.first_name,
        last_name: user.last_name || initialForm.last_name,
        email: user.email || initialForm.email,
        phone_number: user.phone_number || initialForm.phone_number,
      });
      setEdit(false);
      return;
    }

    const dataToSend = {
      first_name: form.first_name !== "-" ? form.first_name : undefined,
      last_name: form.last_name !== "-" ? form.last_name : undefined,
      phone_number:
        form.phone_number !== "-"
          ? form.phone_number.replace(" ", "")
          : undefined,
    };

    changeUserData(dataToSend);
    setForm({
      first_name: user.first_name || initialForm.first_name,
      last_name: user.last_name || initialForm.last_name,
      email: user.email || initialForm.email,
      phone_number: user.phone_number || initialForm.phone_number,
    });
    setEdit(false);
  };

  return (
    <>
      {showModal && <Modal password={true} setShowModal={setShowModal} />}
      <section className="perfil__container">
        <div className="perfil__container--data">
          <div className="perfil__title">
            <h3 className="heading-2">
              <T keyName="profile_header_title" />
            </h3>
            {!edit ? (
              <button
                className="ajustes__content__busqueda--IDS__form--btn"
                onClick={() => setEdit(true)}
              >
                <T keyName="btn_edit" />
              </button>
            ) : (
              <div className="perfil__container--data__buttons-container">
                {updateUserLoading && (
                  <div className="loader-container">
                    <SmallLoader />
                  </div>
                )}
                <Button
                  text={t("confirm_button")}
                  handleClick={handleConfirm}
                  styles={{ padding: "9px 12px" }}
                />
                <Button
                  text={t("cancel_button")}
                  handleClick={handleCancel}
                  styles={{
                    color: COLORS.SHARK,
                    backgroundColor: COLORS.WHITE,
                    border: `1px solid ${COLORS.CATSKILL_WHITE}`,
                    padding: "9px 12px",
                  }}
                />
              </div>
            )}
          </div>
          <div className="perfil__container--separator"></div>
          <div className="perfil__container--data-container">
            {!edit && (
              <UserData title={t("account_type_title")} data={user.role} />
            )}
            <UserData
              title={t("input_name")}
              data={form.first_name}
              edit={edit}
              form={form}
              inputName={"first_name"}
              handleChange={handleChange}
            />
            <UserData
              title={t("input_last_name")}
              data={form.last_name}
              edit={edit}
              form={form}
              inputName={"last_name"}
              handleChange={handleChange}
            />
            <UserData
              title={t("input_email_description")}
              data={form.email}
              edit={edit}
              form={form}
              inputName={"email"}
              handleChange={handleChange}
            />
            <UserData
              title={t("input_phone_description")}
              data={form.phone_number}
              edit={edit}
              form={form}
              inputName={"phone_number"}
              handleChange={handleChange}
            />
            <UserData
              title={t("input_pass_description")}
              data="*************"
              edit={edit}
              handleBtn={handleBtn}
            />
          </div>
        </div>
      </section>
    </>
  );
};
export default PerfilDataComponent;
