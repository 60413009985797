import { createContext, useEffect, useMemo, useState } from "react";
import { initialState } from "../states/contexts/SnackContext";

const SnackBarContext = createContext();

const SnackBarProvider = ({ children }) => {
  const [snacksStates, setSnacksStates] = useState(initialState);
  const [snackToShow, setSnackToShow] = useState({});

  useEffect(() => {
    const closeSnack = setTimeout(() => {
      setSnackToShow({
        ...snackToShow,
        show: false,
      });
    }, 5000);

    return () => {
      clearTimeout(closeSnack);
    };
  }, [snackToShow.name, snackToShow.show]);

  const setSnack = (stateName, newState) => {
    const snackName = snacksStates.find((state) => state.name === stateName);

    const requiredData = {
      show: true,
      error: {
        err: false,
        errTextTitle: "",
        errText: "",
      },
      text: "",
    };

    const snackToShow = {
      ...snackName,
      ...requiredData,
      ...newState,
    };

    setSnackToShow(snackToShow);
  };

  const changeSnackState = (stateName, newState) => {
    const newSnacksState = snacksStates.map((state) =>
      state.name === stateName ? { ...newState } : state
    );

    setSnacksStates(newSnacksState);
    setSnackToShow(newState);
  };

  const data = useMemo(
    () => ({
      setSnack,
      snackToShow,
      changeSnackState,
    }),
    [setSnack, snackToShow, changeSnackState]
  );

  return (
    <SnackBarContext.Provider value={data}>{children}</SnackBarContext.Provider>
  );
};

export { SnackBarProvider };
export default SnackBarContext;
