export class ImageExistsError extends Error {
  constructor(
    message = "Elimina esta imagen para continuar",
    title = "La imagen ya existe"
  ) {
    super(message);
    this.name = "ImageExistsError";
    this.title = title;
  }
}
