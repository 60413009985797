const SearchBar = ({ text, handleSubmit, setValue, value, styles }) => {
  return (
    <form
      style={styles ? { ...styles } : {}}
      className="searchBar"
      onSubmit={(e) => handleSubmit({ e, value })}
    >
      <button type="submit" className="icon-buscar searchBar__icon"></button>
      <input
        type="text"
        placeholder={text}
        className="searchBar__input"
        value={value}
        onChange={({ target: { value } }) => {
          setValue(value);
        }}
      />
    </form>
  );
};
export default SearchBar;
