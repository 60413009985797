import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Checkbox, FormControlLabel } from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { COLORS } from "../styles/utils/colors";

const InputCheckbox = ({
  label,
  value,
  checked,
  handleChecked,
  styles,
  typographyClass,
  disabled = false,
}) => {
  const theme = createTheme({
    typography: {
      fontFamily: "DM Sans",
      allVariants: {
        color: COLORS.SHARK,
      },
    },
    components: {
      MuiFormControlLabel: {
        styleOverrides: {
          label: {
            fontFamily: "DM Sans",
            color: COLORS.SHARK,
            "&.Mui-disabled": {
              color: COLORS.SHARK,
            },
          },
        },
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <FormControlLabel
        control={
          <Checkbox
            disabled={disabled}
            checked={checked}
            icon={<CheckBoxOutlineBlankIcon />}
            checkedIcon={<CheckBoxIcon />}
            onChange={handleChecked}
            inputProps={{ "aria-label": "secoondary checkbox" }}
            value={value}
            sx={
              styles
                ? {
                    ...styles,
                  }
                : { color: checked ? COLORS.DODGER_BLUE : COLORS.SHUTTLE_GRAY }
            }
            className={`inputCheckbox ${typographyClass} `}
          />
        }
        label={label}
      />
    </ThemeProvider>
  );
};

export default InputCheckbox;
