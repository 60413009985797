import React, { useContext } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import Notification from "./Notification";
import UserContext from "../contexts/UserContext";
import ReportContext from "../contexts/ReportContext";

const SnackBarNotification = () => {
  const { snackbarList, removeSnackbarFromList } = useContext(UserContext);
  const { findReportByIdentity } = useContext(ReportContext);

  const handleClick = ({ id, identity_id, timestamp, identity_reported }) => {
    removeSnackbarFromList(timestamp);
    findReportByIdentity({ id, identity_id, identity_reported });
  };

  return (
    <TransitionGroup>
      {snackbarList.map((notification, index) => {
        const { timestamp } = notification;
        return (
          <CSSTransition
            key={timestamp}
            timeout={500}
            classNames="slide"
            onExited={() => removeSnackbarFromList(timestamp)}
          >
            <Notification
              snack={true}
              notification={notification}
              handleClick={handleClick}
              index={index}
            />
          </CSSTransition>
        );
      })}
    </TransitionGroup>
  );
};
export default SnackBarNotification;
