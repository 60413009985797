import { useContext, useEffect } from "react";
import AuthContext from "../contexts/AuthContext";
import { ACCOUNT_TYPES, ROLES } from "../reducers/authReducer";
import moment from "moment";
import { MODAL_TO_SET } from "./pages/Team";
import UserContext from "../contexts/UserContext";
import DropDownAccounts from "./DropDownAccounts";
import { useWindowSize } from "../hooks/useWindowSize";
import TruncatedText from "./TruncatedText";
import Button from "./Button";
import { COLORS } from "../styles/utils/colors";
import { formatUTCDate } from "../helpers/formatDate";
import { useTolgee, useTranslate } from "@tolgee/react";

export const translateAccountType = (text, isLangEs) => {
  if (isLangEs) return text;

  switch (text) {
    case ROLES.ADMIN:
    case ROLES.OLD.ADMIN:
      return "Manager";

    case ROLES.ANALISTA:
    case ROLES.OLD.USER:
      return "Analyst";

    case ROLES.PROPIETARIO:
    case ROLES.OLD.OWNER:
      return "Owner";

    default:
      return text;
  }
};

const TeamTableRowTd = ({
  last_name,
  first_name,
  email,
  created_on,
  dashboard_role_id,
  id,
  openModal,
  confirmed,
  handleShowAccountsTypes,
  dropdownPosition,
  show,
}) => {
  const {
    user: { role, id: userLogID },
  } = useContext(AuthContext);
  const { t } = useTranslate();
  const tolgee = useTolgee(["language"]);
  const isLangEs = tolgee.getLanguage() === "es-MX";

  const { setUserToDelete, changeUserType, transferOwnership, resentInvite } =
    useContext(UserContext);
  const { width: windowSize } = useWindowSize();

  useEffect(() => {
    handleShowAccountsTypes({}, id, false);
  }, [windowSize]);

  const name = confirmed ? `${first_name} ${last_name}` : "-";
  const account_type = ACCOUNT_TYPES.find(
    (role) => role.id === dashboard_role_id
  ).role;

  let date = moment(created_on).isValid()
    ? formatUTCDate(created_on, "MMMM DD, YYYY")
    : "-";
  date = date.charAt(0).toUpperCase() + date.slice(1);

  const handleClick = (id) => {
    setUserToDelete(id);
    openModal({ name: MODAL_TO_SET.DELETE_USER });
  };

  const handleChangeUser = (userID, newRoleID) => {
    changeUserType(userID, newRoleID);
    handleShowAccountsTypes({}, userID, false);
  };

  const handleTransferir = (userID) => {
    transferOwnership(userID);
    handleShowAccountsTypes({}, userID, false);
  };

  const handleInvite = (userID) => {
    resentInvite(userID);
  };

  return (
    <tr className="layout__main__section__consultas__table__tbody__tr">
      <td className="layout__main__section__consultas__table__tbody__td">
        <TruncatedText text={name} />
      </td>
      <td className="layout__main__section__consultas__table__tbody__td">
        <TruncatedText text={email} />
      </td>
      <td className="layout__main__section__consultas__table__tbody__td">
        {confirmed ? (
          <TruncatedText text={date} />
        ) : (
          <div className="team--invite">{t("team_row_1")}</div>
        )}
      </td>
      <td className="layout__main__section__consultas__table__tbody__td">
        {account_type === ROLES.PROPIETARIO ||
        id === userLogID ||
        account_type === role ? (
          <span>{translateAccountType(account_type, isLangEs)}</span>
        ) : (
          <div className="team__accounts-container">
            <button
              className="team__accounts-container--account-btn"
              onClick={(e) =>
                show
                  ? handleShowAccountsTypes(e, id, false)
                  : handleShowAccountsTypes(e, id, true)
              }
            >
              {translateAccountType(account_type, isLangEs)}{" "}
              {show ? (
                <div className="icon-expand-menos"></div>
              ) : (
                <div className="icon-down"></div>
              )}
            </button>
            <DropDownAccounts
              showAccountTypes={show}
              account_type={account_type}
              dropdownPosition={dropdownPosition}
              userID={id}
              handleClick={handleChangeUser}
              handleTransferir={handleTransferir}
            />
          </div>
        )}
      </td>
      {(role === ROLES.PROPIETARIO ||
        (role === ROLES.ADMIN && account_type !== ROLES.ADMIN)) &&
        account_type !== ROLES.PROPIETARIO &&
        id !== userLogID && (
          <td className="layout__main__section__consultas__table__tbody__td">
            <Button
              text={t("btn_deactivate")}
              handleClick={() => handleClick(id)}
              styles={{
                color: COLORS.SHARK,
                backgroundColor: COLORS.WHITE,
                border: `1px solid ${COLORS.SHARK}`,
                padding: "4px 12px",
                boxShadow: "none",
              }}
            />
            {!confirmed && (
              <Button
                text={t("btn_resent_invite")}
                handleClick={() => handleInvite(id)}
                styles={{
                  color: COLORS.SHARK,
                  backgroundColor: COLORS.CATSKILL_WHITE,
                  border: `1px solid ${COLORS.SHARK}`,
                  padding: "4px 12px",
                  boxShadow: "none",
                  marginLeft: "16px",
                }}
              />
            )}
          </td>
        )}
    </tr>
  );
};
export default TeamTableRowTd;
