import { useContext, useState } from "react";
import UserContext from "../contexts/UserContext";
import SearchBar from "./SearchBar";
import { useTranslate } from '@tolgee/react';

const FormTeam = () => {
  const [nameToFind, setNameToFind] = useState("");
  const { searchBy } = useContext(UserContext);
  const { t } = useTranslate();
  return (
    <SearchBar
      value={nameToFind.toLowerCase()}
      setValue={setNameToFind}
      handleSubmit={searchBy}
      text={t("search_bar_message")}
    />
  );
};
export default FormTeam;
