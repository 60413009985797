import { CSSTransition } from "react-transition-group";
import Countries from "./Countries";
import Devices from "./Devices";

export const TRANSITION_TYPES = {
  COUNTRIES: "COUNTRIES",
  DEVICES: "DEVICES",
};

const DropDown = ({
  enter,
  arrElementos,
  handleClick,
  closeList,
  transitionClassNames,
  searchResults,
  searchText,
  highlightSearchText,
  type,
}) => {
  return (
    <>
      <div className="slideContainer slideContainer--inputAuto">
        <CSSTransition
          in={enter}
          timeout={{
            enter: 1000,
            exit: 300,
          }}
          classNames={transitionClassNames}
          mountOnEnter
          unmountOnExit
        >
          <>
            {type === TRANSITION_TYPES.COUNTRIES && (
              <Countries
                arrElementos={arrElementos}
                handleClick={handleClick}
                closeList={closeList}
              />
            )}

            {type === TRANSITION_TYPES.DEVICES && (
              <Devices
                searchResults={searchResults}
                searchText={searchText}
                highlightSearchText={highlightSearchText}
                handleClick={handleClick}
              />
            )}
          </>
        </CSSTransition>
      </div>
    </>
  );
};
export default DropDown;
