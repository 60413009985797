import { createContext, useContext, useMemo, useReducer } from "react";
import { reportReducer } from "../reducers/reportReducer";
import { TYPES } from "../actions/reportActions";
import { generateRequest } from "../helpers/helpHttp";
import AuthContext from "./AuthContext";
import { url } from "./domain";
import SnackBarContext from "./SnackBarContext";
import { initialState } from "../states/contexts/ReportContext";
import {
  SNACKBAR_STATES_NAMES,
  SNACKBAR_TEXTS,
  SNACKBAR_TEXTS_EN,
} from "../states/contexts/SnackContext";
import { useNavigate } from "react-router-dom";
import { PRIVATE_ROUTES } from "../components/pages/routes";
import UserContext from "./UserContext";
import DataContext from "./DataContext";
import { useTolgee } from "@tolgee/react";

const ReportContext = createContext();

const ReportProvider = ({ children }) => {
  const navigate = useNavigate();
  const {
    terminateSession,
    invalidSession,
    user: { first_name, last_name },
  } = useContext(AuthContext);
  const { dataToShow, serviceSearched, createTableData } =
    useContext(DataContext);
  const { markNotificationAsSeen } = useContext(UserContext);
  const { setSnack } = useContext(SnackBarContext);
  const [state, dispatch] = useReducer(reportReducer, initialState);
  const tolgee = useTolgee(["language"]);
  const isLangEs = tolgee.getLanguage() === "es-MX";

  const {
    showReport,
    report,
    reportToFlag,
    devices,
    loading,
    error,
    notification_identity_id,
    identity_reported,
    reportFromStatus,
    scrollLoader,
  } = state;

  const generateExternalID = async (
    reportID,
    userID,
    setExternalIDToTableData
  ) => {
    const createDatosRequest = (reportID, userID) => ({
      url: `${url}/usage/request/${reportID}?language=${
        isLangEs ? "es" : "en"
      }`,
      options: {
        headers: {
          "content-type": "application/json",
        },
        body: {
          external_id: userID,
        },
      },
      terminateSession,
      invalidSession,
    });

    const handleSuccess = (
      data,
      reportID,
      userID,
      setExternalIDToTableData
    ) => {
      const snackState = {
        text: isLangEs
          ? SNACKBAR_TEXTS.CREATE_EXTERNAL_ID_TEXT
          : SNACKBAR_TEXTS_EN.CREATE_EXTERNAL_ID_TEXT,
      };

      setSnack(SNACKBAR_STATES_NAMES.CREATE_EXTERNAL_ID, snackState);

      dispatch({
        type: TYPES.ADD_EXTERNAL_ID,
        payload: data.data.external_id,
      });

      setExternalIDToTableData(reportID, userID);
    };

    const handleError = () => {
      const snackState = {
        error: {
          err: true,
          errTextTitle: isLangEs
            ? SNACKBAR_TEXTS.ERROR_TITLE
            : SNACKBAR_TEXTS_EN.ERROR_TITLE,
          errText: isLangEs
            ? SNACKBAR_TEXTS.CREATE_EXTERNAL_ID_ERROR
            : SNACKBAR_TEXTS_EN.CREATE_EXTERNAL_ID_ERROR,
        },
      };
      setSnack(SNACKBAR_STATES_NAMES.CREATE_EXTERNAL_ID, snackState);
    };

    try {
      const datosRequest = createDatosRequest(reportID, userID);
      const data = await generateRequest.post(datosRequest);

      if (data.err) throw data;

      handleSuccess(data, reportID, userID, setExternalIDToTableData);
    } catch (error) {
      handleError();
    }
  };

  const closeReport = () => {
    dispatch({
      type: TYPES.CLOSE_REPORT,
    });
  };

  const dispatchReport = (data, types, network_reports) => {
    dispatch({
      type: TYPES.FIND_REPORT,
      payload: {
        data,
        types,
        showReport: true,
        error: false,
        loading: false,
        network_reports,
      },
    });
  };

  const findReport = async (form, is_external = false, is_identity = false) => {
    const hasMultipleIDs = (form) => {
      return (
        (form.idToSearch && form.idUser) ||
        (form.idToSearch && form.idTrully) ||
        (form.idUser && form.idTrully)
      );
    };

    const getIdFromForm = (form) => {
      return form.idToSearch || form.idUser || form.idTrully || "";
    };

    const handleError = (errText) => {
      const err = { err: true, errText };
      dispatch({ type: TYPES.NO_REPORT, payload: err });
    };

    const createRequestConfig = (id, is_external, is_identity) => {
      let endpoint = `${url}/usage/request/${id}?language=${
        isLangEs ? "es" : "en"
      }`;
      if (is_external)
        endpoint = `${url}/usage/request/external/${id}?language=${
          isLangEs ? "es" : "en"
        }`;
      if (is_identity)
        endpoint = `${url}/usage/request/identity/${id}?language=${
          isLangEs ? "es" : "en"
        }`;

      return {
        url: endpoint,
        terminateSession,
        invalidSession,
      };
    };

    const getNetworkReportsWrapper = async ({
      id,
      is_identity,
      request_id,
    }) => {
      const requestParams = {
        requestStartOn: 0,
        identity_id: is_identity ? id : request_id,
      };
      return await getNetworkReports(requestParams);
    };

    const handleFetchError = (error) => {
      const err = {
        ...error,
        err: false, // Corrección para no mostrar slider en búsqueda por id sin romper la lógica de los errores
        errText: isLangEs ? "No se encontró el reporte" : "No report found",
      };

      const snackState = {
        show: true,
        error: {
          err: true,
          errText: err.errText,
          errTextTitle: isLangEs
            ? SNACKBAR_TEXTS.ERROR_TITLE
            : SNACKBAR_TEXTS_EN.ERROR_TITLE,
        },
      };

      setSnack(SNACKBAR_STATES_NAMES.OPEN_REPORT, snackState);

      dispatch({ type: TYPES.NO_REPORT, payload: err });
    };

    if (hasMultipleIDs(form)) {
      handleError(
        isLangEs
          ? "No es posible buscar por más de un ID al mismo tiempo"
          : "It isn't possible to search for more than one ID"
      );
      return;
    }

    const id = getIdFromForm(form);
    if (!id) {
      handleError(isLangEs ? "Datos incompletos" : "Incomplete data");
      return;
    }

    try {
      openReport();
      const datosRequest = createRequestConfig(id, is_external, is_identity);

      const data = await generateRequest.get(datosRequest);

      if (data.err) throw data;

      const [fraudReasons, network_reports] = await Promise.all([
        generateRequest.get({
          ...datosRequest,
          url: `${url}/lookup/fraud-reason`,
        }),
        getNetworkReportsWrapper({
          id,
          is_identity,
          request_id: data.data.response.request_id,
        }),
      ]);

      if (fraudReasons.err || network_reports.err) {
        throw fraudReasons.err || network_reports.err;
      }

      dispatchReport(data.data, fraudReasons.data, network_reports);
    } catch (error) {
      handleFetchError(error);
    }
  };

  const findReportByIdentity = async ({
    id,
    identity_id,
    identity_reported,
  }) => {
    try {
      navigateToReport();
      const [data, fraudReasons, network_reports] = await fetchReportData(
        identity_id,
        identity_reported
      );

      if (data.err) throw data;
      if (fraudReasons.err) throw fraudReasons;
      if (network_reports.err) throw network_reports;

      dispatchReport(data.data, fraudReasons.data, network_reports);

      markNotificationAsSeen(id);
    } catch (err) {
      navigateToNoReport();
    }
  };

  const navigateToReport = () => {
    navigate(`${PRIVATE_ROUTES.BUSQUEDA}/con-reporte`);
  };

  const navigateToNoReport = () => {
    navigate(`${PRIVATE_ROUTES.BUSQUEDA}/sin-reporte`);
  };

  const fetchReportData = async (identity_id, identity_reported) => {
    const endpoint = `${url}/usage/request/identity/${identity_id}`;
    const endpoint2 = `${url}/lookup/fraud-reason`;

    const datosRequest = {
      url: endpoint,
      terminateSession,
      invalidSession,
    };

    const data = await generateRequest.get(datosRequest);
    const fraudReasons = await generateRequest.get({
      ...datosRequest,
      url: endpoint2,
    });
    const network_reports = await fetchNetworkReports(
      identity_id,
      identity_reported
    );

    return [data, fraudReasons, network_reports];
  };

  const fetchNetworkReports = async (identity_id, identity_reported) => {
    const requestParams = {
      requestStartOn: 0,
      ...(identity_reported
        ? {
            endpoint: `${url}/usage/request/identity/${identity_id}/reports?identity_reported=${identity_reported}`,
          }
        : { identity_id }),
    };

    return await getNetworkReports(requestParams);
  };

  const getNetworkReports = async ({
    requestStartOn,
    id,
    identity_id,
    endpoint,
  }) => {
    if (requestStartOn !== 0) dispatch({ type: TYPES.LOADING_SCROLL });

    const determineEndpoint = (endpoint, id, identity_id, requestStartOn) => {
      if (endpoint) return endpoint;
      if (id)
        return `${url}/usage/request/${id}/reports?page=${requestStartOn}&offset=10`;
      if (identity_id)
        return `${url}/usage/request/identity/${identity_id}/reports?page=${requestStartOn}&offset=10`;
      return "";
    };

    const createRequestConfig = (endpoint) => ({
      url: endpoint,
      terminateSession,
      invalidSession,
    });

    const handleNetworkReportsData = (network_reports_data, requestStartOn) => {
      const { network_reports, total_reports } = network_reports_data;
      if (network_reports.err) throw network_reports;

      if (requestStartOn === 0) {
        return { network_reports, total_reports };
      } else {
        dispatchNetworkReports(network_reports, total_reports);
      }
    };

    const dispatchNetworkReports = (network_reports, total_reports) => {
      let payload;
      if (Array.isArray(network_reports)) {
        payload = { network_reports, total_reports };
      } else {
        payload = { network_reports: [], total_reports };
      }

      dispatch({
        type: TYPES.ADD_NETWORK_REPORTS,
        payload,
      });
    };

    const handleNetworkReportsError = () => {
      return { network_reports: [], total_reports: 0 };
    };

    const endpointToUse = determineEndpoint(
      endpoint,
      id,
      identity_id,
      requestStartOn
    );

    const datosRequest = createRequestConfig(endpointToUse);

    try {
      const network_reports_data = await generateRequest.get(datosRequest);
      return handleNetworkReportsData(network_reports_data, requestStartOn);
    } catch (error) {
      return handleNetworkReportsError();
    }
  };

  const openReport = (loading = true) => {
    dispatch({
      type: TYPES.OPEN_REPORT,
      payload: {
        showReport: true,
        loading,
      },
    });
  };

  const sendReport = async (reportToSend, validation) => {
    const isReportEmpty = (report) => {
      const requiredFields = [
        "document_image",
        "rfc",
        "curp",
        "ip",
        "email",
        "image",
        "address",
        "phone_number",
        "lng",
        "lat",
        "device",
        "document_image_back",
      ];
      return requiredFields.every((field) => !report?.[field]);
    };

    const isLocationIncomplete = (report) => {
      return (report?.lng && !report?.lat) || (report?.lat && !report?.lng);
    };

    const isValidationInvalid = (validation) => {
      return validation.some((el) => !el.valid);
    };

    const handleError = (errText) => {
      const err = { err: true, errText };
      dispatch({ type: TYPES.NO_REPORT, payload: err });
    };

    const prepareFormData = (report) => {
      const appendImage = (formData, key, value) => {
        formData.append(key, value.file);
      };

      const appendPhoneNumber = (formData, key, value) => {
        if (!["+52", "+54"].includes(value.trim())) {
          formData.append(key, value.replace(/(\s|\+54|\+52)/g, ""));
        }
      };

      const sanitizeLocation = (value) => {
        return value.replace(/\s/g, "");
      };

      const appendCurp = (formData, key, value) => {
        formData.append(key, value.toUpperCase());
      };

      const formData = new FormData();
      const location = { lng: "", lat: "" };

      Object.keys(report).forEach((key) => {
        const value = report[key];
        if (!value) return;

        if (key.includes("image")) {
          appendImage(formData, key, value);
        } else if (key.includes("phone_number")) {
          appendPhoneNumber(formData, key, value);
        } else if (key.includes("lng")) {
          location.lng = sanitizeLocation(value);
        } else if (key.includes("lat")) {
          location.lat = sanitizeLocation(value);
        } else if (key.includes("curp")) {
          appendCurp(formData, key, value);
        } else {
          formData.append(key, value);
        }
      });

      if (location.lng && location.lat) {
        formData.append("location", JSON.stringify(location));
      }

      return formData;
    };

    const fetchReportData = async (formData) => {
      const endpoint = `${url}/product/decision-maker`;
      const endpoint2 = `${url}/lookup/fraud-reason`;

      const requestConfig = {
        url: endpoint,
        options: { body: formData },
        terminateSession,
        invalidSession,
      };

      const fraudRequestConfig = {
        url: endpoint2,
        terminateSession,
        invalidSession,
      };

      const data = await generateRequest.post(requestConfig);
      if (data.response_status === 400 || data.response_status === 500)
        throw data;

      const fraudReasons = await generateRequest.get(fraudRequestConfig);
      if (fraudReasons.err) throw fraudReasons;

      const network_reports = await getNetworkReports({
        requestStartOn: 0,
        endpoint: `${url}/usage/request/identity/${data.request_id}/reports?identity_reported=${data.request_id}`,
      });

      return [data, fraudReasons, network_reports];
    };

    const dispatchReport = (data, fraudReasons, network_reports) => {
      dispatch({
        type: TYPES.FIND_REPORT,
        payload: {
          data,
          types: fraudReasons.data,
          showReport: true,
          error: false,
          loading: false,
          network_reports,
        },
      });
    };

    const handleFetchError = (error) => {
      if (error.response_status === 400 || error.response_status === 500) {
        const data = { response_status: error.response_status };
        dispatch({
          type: TYPES.FIND_REPORT,
          payload: { data, showReport: true, error: false, loading: false },
        });
      } else {
        const err = { ...error, err: true, errText: error.message };
        dispatch({ type: TYPES.NO_REPORT, payload: err });
      }
    };

    if (isReportEmpty(reportToSend)) {
      handleError(
        isLangEs
          ? "Necesita completar al menos un campo"
          : "You need to complete at least one input"
      );
      return;
    }

    if (isLocationIncomplete(reportToSend)) {
      handleError(
        isLangEs
          ? "Debe agregar longitud y latitud"
          : "You must add longitude and latitude"
      );
      return;
    }

    if (isValidationInvalid(validation)) {
      handleError(
        isLangEs
          ? "Revisar los datos ingresados"
          : "Make sure you have entered valid data"
      );
      return;
    }

    const formData = prepareFormData(reportToSend);

    openReport();

    try {
      const [data, fraudReasons, network_reports] = await fetchReportData(
        formData
      );
      dispatchReport(data, fraudReasons, network_reports);
    } catch (error) {
      handleFetchError(error);
    }
  };

  const restartReport = () => {
    dispatch({
      type: TYPES.RESTART,
    });
  };

  const toggleFlaggingForm = (report, reportFromStatus = false) => {
    dispatch({
      type: TYPES.TOGGLE_FLAGGING_FORM,
      payload: {
        reportToFlag: report,
        reportFromStatus,
      },
    });
  };

  const setFlagToReport = (flag) => {
    dispatch({
      type: TYPES.UPDATE_REPORT_FLAG,
      payload: flag,
    });
  };

  const flagReport = async (report) => {
    try {
      const datosRequest = {
        url: `${url}/product/identity`,
        options: {
          headers: {
            "content-type": "application/json",
          },
          body: {
            ...report,
          },
        },
        terminateSession,
        invalidSession,
      };

      const data = await generateRequest.post(datosRequest);

      if (data.err) throw data;

      const snackState = {
        show: true,
        text: isLangEs
          ? SNACKBAR_TEXTS.CREATE_REPORT_TEXT
          : SNACKBAR_TEXTS_EN.CREATE_REPORT_TEXT,
      };
      setSnack(SNACKBAR_STATES_NAMES.CREATE_REPORT, snackState);

      setFlagToReport(report);
      toggleFlaggingForm(false);
      dispatch({
        type: TYPES.FLAG_REPORT,
        payload: report,
      });
    } catch (error) {
      const snackState = {
        show: true,
        error: {
          err: true,
          errText: isLangEs
            ? SNACKBAR_TEXTS.ERROR_TEXT
            : SNACKBAR_TEXTS_EN.ERROR_TEXT,
          errTextTitle: isLangEs
            ? SNACKBAR_TEXTS.ERROR_TITLE
            : SNACKBAR_TEXTS_EN.ERROR_TITLE,
        },
      };

      setSnack(SNACKBAR_STATES_NAMES.CREATE_REPORT, snackState);
    }
  };

  const getDevices = async () => {
    const endpoint = `${url}/lookup/devices`;

    const datosRequest = {
      url: endpoint,
      options: {
        headers: {
          "content-type": "application/json",
        },
      },
      terminateSession,
      invalidSession,
    };

    const data = await generateRequest.get(datosRequest);

    dispatch({
      type: TYPES.SET_DEVICES,
      payload: data.data,
    });
  };

  const getFilteredDevices = async (device) => {
    const endpoint = `${url}/lookup/devices?search=${device}`;

    const datosRequest = {
      url: endpoint,
      terminateSession,
      invalidSession,
    };

    const data = await generateRequest.get(datosRequest);
    if (data.data?.length) return data.data;
  };

  const addIdentityReported = (identity_reported, notification_identity_id) => {
    dispatch({
      type: TYPES.ADD_IDENTITY_REPORTED,
      payload: { identity_reported, notification_identity_id },
    });
  };

  const deleteIdentityReported = () => {
    dispatch({
      type: TYPES.DELETE_IDENTITY_REPORTED,
    });
  };

  const handleSnackError = () => {
    const snackState = {
      show: true,
      error: {
        err: true,
        errText: isLangEs
          ? SNACKBAR_TEXTS.ERROR_TEXT
          : SNACKBAR_TEXTS_EN.ERROR_TEXT,
        errTextTitle: isLangEs
          ? SNACKBAR_TEXTS.ERROR_TITLE
          : SNACKBAR_TEXTS_EN.ERROR_TITLE,
      },
    };

    setSnack(SNACKBAR_STATES_NAMES.UPDATE_STATUS_REPORT, snackState);
  };

  const updateReportStatus = async ({ reportID, newStatus, label }) => {
    const createDatosRequest = (reportID, newStatus) => ({
      url: `${url}/profile/assignment/${reportID}/request/evaluate`,
      options: {
        headers: {
          "content-type": "application/json",
        },
        body: {
          status: newStatus,
        },
      },
      terminateSession,
      invalidSession,
    });

    const handleSuccess = (data, reportID, label) => {
      const snackState = {
        show: true,
        text: isLangEs
          ? SNACKBAR_TEXTS.UPDATE_STATUS_REPORT_TEXT
          : SNACKBAR_TEXTS_EN.UPDATE_STATUS_REPORT_TEXT,
        title: isLangEs
          ? SNACKBAR_TEXTS.UPDATE_STATUS_REPORT_TITLE
          : SNACKBAR_TEXTS_EN.UPDATE_STATUS_REPORT_TITLE,
      };
      setSnack(SNACKBAR_STATES_NAMES.CREATE_REPORT, snackState);

      const assigned_to = `${first_name} ${last_name}`;

      dispatch({
        type: TYPES.UPDATE_REPORT_LABEL,
        payload: {
          label,
          assigned_to,
        },
      });

      const newDataToShow = dataToShow.map((report) =>
        report.id === reportID
          ? {
              ...report,
              label,
              assigned_to,
            }
          : report
      );
      createTableData({ data: newDataToShow, serviceSearched });
    };

    try {
      const datosRequest = createDatosRequest(reportID, newStatus);
      const data = await generateRequest.post(datosRequest);

      if (data.err) throw data;

      handleSuccess(data, reportID, label);
    } catch (error) {
      handleSnackError();
    }
  };

  const generateCSVReport = async ({ from, to }) => {
    const createDatosRequest = (from, to) => ({
      url: `${url}/usage/report`,
      options: {
        headers: {
          "content-type": "application/json",
        },
        body: {
          start_date: `${from.year}-${from.monthNumber + 1}-${from.day}`,
          end_date: `${to.year}-${to.monthNumber + 1}-${to.day}`,
        },
      },
      terminateSession,
      invalidSession,
    });

    try {
      const datosRequest = createDatosRequest(from, to);
      const data = await generateRequest.post(datosRequest);

      if (data.err) throw data;
    } catch (error) {
      handleSnackError();
    }
  };

  const data = useMemo(
    () => ({
      showReport,
      report,
      reportToFlag,
      devices,
      flagReport,
      closeReport,
      restartReport,
      toggleFlaggingForm,
      findReport,
      sendReport,
      getDevices,
      getFilteredDevices,
      openReport,
      generateExternalID,
      loading,
      error,
      findReportByIdentity,
      getNetworkReports,
      addIdentityReported,
      identity_reported,
      notification_identity_id,
      deleteIdentityReported,
      updateReportStatus,
      reportFromStatus,
      generateCSVReport,
      scrollLoader,
    }),
    [
      showReport,
      report,
      reportToFlag,
      devices,
      flagReport,
      closeReport,
      restartReport,
      toggleFlaggingForm,
      findReport,
      sendReport,
      getDevices,
      getFilteredDevices,
      openReport,
      generateExternalID,
      loading,
      error,
      findReportByIdentity,
      getNetworkReports,
      addIdentityReported,
      identity_reported,
      notification_identity_id,
      deleteIdentityReported,
      updateReportStatus,
      reportFromStatus,
      generateCSVReport,
      scrollLoader,
    ]
  );

  return (
    <ReportContext.Provider value={data}>{children}</ReportContext.Provider>
  );
};

export { ReportProvider };
export default ReportContext;
