import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { COLORS } from "../../styles/utils/colors";
import { useTolgee, useTranslate } from "@tolgee/react";
import { rulesGroupText } from "../../helpers/rulesGroupText";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  borderBottom: `1px solid ${COLORS.CATSKILL_WHITE}`,
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={
      <ArrowForwardIosSharpIcon
        sx={{ fontSize: "1.25rem", color: COLORS.SHARK }}
        color={COLORS.SHARK}
      />
    }
    {...props}
  />
))(({ theme }) => ({
  padding: "32px 0 16px",
  backgroundColor: COLORS.WHITE,
  flexDirection: "row",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
}));

export default function RulesAccordion({
  expandPanel,
  icon,
  forceExpand = false,
  rules = [],
  title,
  description,
}) {
  const [expanded, setExpanded] = useState(() =>
    forceExpand ? expandPanel : false
  );
  const tolgee = useTolgee(["language"]);
  const isLangEs = tolgee.getLanguage() === "es-MX";
  const [translateText] = useState(rulesGroupText(rules[0]?.group_rule_id));
  const { t } = useTranslate();

  const handleChange = (panel) => (isExpanded) => {
    if (panel === "") setExpanded(false);
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Accordion
      key={expandPanel}
      expanded={expanded === expandPanel}
      onChange={!expanded ? handleChange(expandPanel) : handleChange("")}
    >
      <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
        <div className="rulesAccordion__title-container">
          <img src={icon} alt={title} />
          <div className="rulesAccordion__title">
            <h2 className="text-1--bold">{title}</h2>
            <h3 className="text-2">{description}</h3>
          </div>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <>
          <div className="noCode__accordion__description">
            <p>{t(translateText.description)}</p>
            <ul className="noCode__accordion__description__list">
              {translateText.listItems.map((item) => (
                <li>{t(item)}</li>
              ))}
            </ul>
          </div>

          <table className="noCode__table">
            <thead>
              <tr>
                <th className="text-2--bold noCode__row--separator">{t('no_code_rules_message')}</th>
              </tr>
            </thead>
            <tbody>
              {rules.map((el, i) => {
                const {
                  id,
                  message_es,
                  message_en,
                } = el;
                const last = i === rules.length - 1;

                return (
                  <tr key={`not-hire-rule-${id}`}>
                    <td className={`noCode__row${last ? "" : "--separator"}`}>
                      {isLangEs ? message_es : message_en}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </>
      </AccordionDetails>
    </Accordion>
  );
}
