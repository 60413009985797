import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  ResponsiveContainer,
  LabelList,
} from "recharts";
import { COLORS } from "../styles/utils/colors";
import { useContext, useEffect, useState } from "react";
import SDKContext from "../contexts/SDKContext";
import { SDK_TYPE } from "../constants";
import ErrorComponent from "./ErrorComponent";
import { useTranslate } from "@tolgee/react";

const getMagicLinkData = ({
  sdkToShow,
  webData,
  androidData,
  magicLinkData,
}) => {
  const sdkId = sdkToShow.find((sdk) => sdk.show).id;

  if (sdkId === SDK_TYPE.WEB) return webData;

  if (sdkId === SDK_TYPE.ANDROID) return androidData;

  if (sdkId === SDK_TYPE.MAGIC_LINK) return magicLinkData;
};

const SDKChart = ({ sdkToShow }) => {
  const { webData, androidData, magicLinkData } = useContext(SDKContext);
  const [data, setData] = useState(() =>
    getMagicLinkData({ sdkToShow, webData, androidData, magicLinkData })
  );
  const { t } = useTranslate();

  useEffect(() => {
    setData(() =>
      getMagicLinkData({ sdkToShow, webData, androidData, magicLinkData })
    );
  }, [sdkToShow, webData, androidData, magicLinkData]);

  const renderCustomizedLabel = (props) => {
    const { x, y, width, value } = props;
    const percentage = data.find((d) => d.name === value)?.percentage;
    const total = data.find((d) => d.name === value)?.total;

    return (
      <text
        x={x + width / 2}
        y={y + 20}
        fill="#fff"
        textAnchor="middle"
        dominantBaseline="middle"
        className="text-3"
      >
        {percentage} ({Intl.NumberFormat("en-US").format(total)})
      </text>
    );
  };

  if (!data) {
    return (
      <section className="dashboard__panel-section">
        <ErrorComponent
          error={400}
          title={t("sdk_chart_error_400_title")}
          text={t("sdk_chart_error_400_text")}
          styles={{ maxWidth: "none" }}
        />
      </section>
    );
  }

  return (
    <ResponsiveContainer>
      <BarChart
        data={data}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
        barSize={150}
        style={{ fontFamily: "DM Sans" }}
      >
        <XAxis
          dataKey="name"
          scale="point"
          minTickGap={1}
          padding={{ left: 80, right: 80 }}
        />
        <YAxis
          label={{
            value: t("sdk_chart_percentage"),
            angle: -90,
            position: "insideLeft",
            fill: COLORS.LIGHT_GRAY,
            fontFamily: "DM Sans",
          }}
        />
        <Bar
          dataKey="complete"
          fill={COLORS.DODGER_BLUE}
          background={{ fill: COLORS.LIGHT_GRAY }}
        >
          <LabelList dataKey="name" content={renderCustomizedLabel} />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

export default SDKChart;
