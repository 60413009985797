import { useAutocomplete } from "@mui/base/useAutocomplete";
import {
  InputWrapper,
  Listbox,
  Root,
  StyledTag,
} from "../styles/AutoCompleteStyles";
import { SEARCH_TYPES } from "../states/contexts/DataContext";
import { useState } from "react";
import { useTolgee, useTranslate } from "@tolgee/react";

const AutoCompleteSelectMulti = ({
  id,
  options,
  optionSelected,
  handleChange,
  handleDelete,
}) => {
  const fixedOptions = [options[0]];
  const [value, setValue] = useState([...fixedOptions]);
  const { t } = useTranslate();
  const tolgee = useTolgee(["language"]);

  const {
    getRootProps,
    getInputProps,
    getTagProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    focused,
    setAnchorEl,
  } = useAutocomplete({
    id,
    multiple: true,
    options,
    openOnFocus: true,
    getOptionLabel: (option) => option.title || "",
    value,
    onChange: (event, newValue) => {
      if (newValue.length > 0) {
        const value = newValue[newValue.length - 1];
  
        setValue([...fixedOptions, value]);
        handleChange({ ...value, searchId: SEARCH_TYPES.SDK });
      }
    },
  });

  const translate = (text) => {
    const isLangEs = tolgee.getLanguage() === "es-MX";

    return isLangEs ? text : "SDK channel";
  };

  return (
    <Root>
      <div {...getRootProps()}>
        <InputWrapper
          ref={setAnchorEl}
          className={focused ? "focused" : ""}
          onSubmit={(e) => e.preventDefault()}
        >
          {value?.map((option, i) => (
            <StyledTag
              label={option?.title}
              {...getTagProps({ index: i })}
              onDelete={() => {
                handleDelete({ id: option?.id ? option.id : '', setValue });
              }}
            />
          ))}
          <input
            {...getInputProps()}
            value=""
            placeholder={optionSelected ? "" : translate("Tipo de Canal")}
            autoFocus
            style={{
              caretColor: "transparent",
              color: "transparent",
              backgroundColor: "transparent",
            }}
          />
        </InputWrapper>
      </div>
      {groupedOptions.length > 0 ? (
        <Listbox {...getListboxProps()}>
          <h3 className="text-1" style={{ padding: "8px 16px" }}>
            {t("btn_filtros_filters_title")}
          </h3>
          {groupedOptions.map((option, index) => {
            if (option.id === SEARCH_TYPES.SDK) return null;
            return (
              <li key={option.id} {...getOptionProps({ option, index })}>
                <span className="SearchBarWithFilters__li-title">
                  {option.title}
                </span>
              </li>
            );
          })}
        </Listbox>
      ) : null}
    </Root>
  );
};
export default AutoCompleteSelectMulti;
