import React, { useRef, useEffect } from "react";

const ImageFilterCanvas = ({ imageUrl, color, canvasWidth }) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    const image = new Image();

    image.src = imageUrl;
    image.onload = () => {
      const scale = canvasWidth / image.width;
      const scaledHeight = image.height * scale;

      canvas.width = canvasWidth;
      canvas.height = scaledHeight;
      ctx.drawImage(image, 0, 0, canvasWidth, scaledHeight);
      applyColorFilter(color);
    };
  }, [imageUrl, color]);

  const applyColorFilter = (hexColor) => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    const data = imageData.data;

    const rgbColor = hexToRgb(hexColor);

    if (!rgbColor) return "";

    for (let i = 0; i < data.length; i += 4) {
      data[i] = rgbColor.r;
      data[i + 1] = rgbColor.g;
      data[i + 2] = rgbColor.b;
    }

    ctx.putImageData(imageData, 0, 0);
  };

  const hexToRgb = (hex) => {
    const result =
      /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})?$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null;
  };

  return (
    <canvas ref={canvasRef} style={{ width: `${canvasWidth}px` }}></canvas>
  );
};

export default ImageFilterCanvas;
