import { TYPES } from "../actions/sdkActions";
import { generateSDKChartData } from "../helpers/generateSDKChartData";
import { getSDKPercentages } from "../helpers/getSDKPercentages";

export const sdkReducer = (state, action) => {
  switch (action.type) {
    case TYPES.GET_DATA:
      return handleGetData(state, action.payload);

    case TYPES.LOADING_DATA:
      return handleLoadingData(state);

    case TYPES.SET_SDK_TO_SHOW:
      return handleSetSdkToShow(state, action.payload);

    case TYPES.NO_DATA:
      return handleNoData(state);

    default:
      return state;
  }
};

const handleGetData = (state, data) => {
  const web = data.WEB ? generateSDKChartData(data.WEB) : null;
  const android = data.ANDROID ? generateSDKChartData(data.ANDROID) : null;
  const magicLink = data.MAGIC_LINK
    ? generateSDKChartData(data.MAGIC_LINK)
    : null;

  return {
    ...state,
    loading: false,
    webData: web?.sdkData,
    androidData: android?.sdkData,
    magicLinkData: magicLink?.sdkData,
    sdkPercentages: getSDKPercentages({
      state: state.sdkPercentages,
      web,
      android,
      magicLink,
    }),
  };
};

const handleLoadingData = (state) => ({
  ...state,
  loading: true,
});

const handleSetSdkToShow = (state, sdkToShow) => ({
  ...state,
  sdkToShow,
});

const handleNoData = (state) => ({
  ...state,
  loading: false,
  noData: true,
});
