import { useContext, useState } from "react";
import ReportContext from "../contexts/ReportContext";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import moment from "moment";
import { formatCurrency } from "../helpers/formatCurrency";
import { formatUTCDate } from "../helpers/formatDate";
import { useTolgee, useTranslate } from "@tolgee/react";

const ListedWeeksSubDropdown = () => {
  const {
    report: { listed_weeks_salary_summary, listed_weeks_salary_summary_gob },
  } = useContext(ReportContext);
  const { t } = useTranslate();
  const tolgee = useTolgee(["language"]);
  const isLangEs = tolgee.getLanguage() === "es-MX";

  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (isExpanded) => {
    if (panel === "") setExpanded(false);
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Accordion
      key="listed-weeks-sub"
      expanded={expanded}
      onChange={!expanded ? handleChange("listed-weeks-sub") : handleChange("")}
      className="reporteDataAccordion"
      style={{ minHeight: "none", paddingTop: "16px" }}
    >
      <AccordionSummary
        aria-controls="listed-weeks-sub-content"
        id="listed-weeks-sub-header"
        className="reporteDataAccordion__title--blue"
      >
        <div className="reporteDataAccordion__title__text--blue">
          <button>
            {!expanded ? (
              <div className="icon-down reporteDataAccordion__title__icon color--dodger-blue"></div>
            ) : (
              <div className="icon-expand-menos reporteDataAccordion__title__icon color--dodger-blue"></div>
            )}
          </button>
          <h3 className="text-2--bold color--dodger-blue">
            {t("listed_weeks_sub_drop_title")}
          </h3>
        </div>
      </AccordionSummary>
      <AccordionDetails
        style={{
          padding: "16px",
          maxHeight: "335px",
          overflowY: "scroll",
        }}
        sx={{ transition: "height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;" }}
      >
        {listed_weeks_salary_summary?.map((job, i) => {
          const {
            avg_base_salary,
            employer_name,
            discharge_date,
            registration_date,
            active_job,
          } = job;
          const init = formatUTCDate(registration_date, "DD/MM/YYYY");
          const end = active_job
            ? "N/A"
            : formatUTCDate(discharge_date, "DD/MM/YYYY");

          return (
            <div
              className="reporteDataAccordion__sub-description"
              key={`${employer_name}-${i}`}
            >
              <h3 className="text-2--bold">{employer_name}</h3>
              <div className="reporteDataAccordion__description">
                <h4 className="text-2 color--shuttle-gray">
                  {t("listed_weeks_sub_drop_1")}
                </h4>
                <span className="text-2">{`${init} - ${end}`}</span>
              </div>
              <div className="reporteDataAccordion__description">
                <h4 className="text-2 color--shuttle-gray">
                  {t("listed_weeks_sub_drop_2")}
                </h4>
                <span className="text-2">
                  {formatCurrency(avg_base_salary, "es-MX", "MXN")}
                </span>
              </div>
            </div>
          );
        })}

        {listed_weeks_salary_summary_gob?.map((job, i) => {
          const { start_date, end_date, institution, position } = job;
          const init = formatUTCDate(start_date, "DD/MM/YYYY");
          const end = moment().isSame(moment(end_date), "day")
            ? isLangEs
              ? "Vigente"
              : "Current"
            : formatUTCDate(end_date, "DD/MM/YY");

          return (
            <div
              className="reporteDataAccordion__sub-description"
              key={`${institution}-${i}`}
            >
              <h3 className="text-2--bold">{institution}</h3>
              <div className="reporteDataAccordion__description">
                <h4 className="text-2 color--shuttle-gray">
                  {t("listed_weeks_sub_drop_1")}
                </h4>
                <span className="text-2">{`${init} - ${end}`}</span>
              </div>
              <div className="reporteDataAccordion__description">
                <h4 className="text-2 color--shuttle-gray">
                  {t("listed_weeks_sub_drop_3")}
                </h4>
                <span className="text-2">{institution}</span>
              </div>
              <div className="reporteDataAccordion__description">
                <h4 className="text-2 color--shuttle-gray">
                  {t("listed_weeks_sub_drop_4")}
                </h4>
                <span className="text-2">{position}</span>
              </div>
            </div>
          );
        })}
      </AccordionDetails>
    </Accordion>
  );
};
export default ListedWeeksSubDropdown;
