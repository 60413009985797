import { useEffect, useState } from "react";
import ConsultasChart from "./ConsultasChart";
import Services from "./Services";
import Loader from "./Loader";
import { initialChart } from "../states/contexts/DataContext";
import ErrorComponent from "./ErrorComponent";
import SDKPercentages from "./SDKPercentages";
import SDKChart from "./SDKChart";
import { useTranslate } from "@tolgee/react";

const Chart = ({
  walkthrough,
  updateChart,
  dates,
  setUpdateChart,
  sdkChart,
  chartLoading,
  errorChart,
  noChartData,
  getChartData,
  servicesPercentages,
  servicesToShow,
}) => {
  const [percentages, setPercentages] = useState(() => servicesPercentages);
  const { t } = useTranslate();

  useEffect(() => {
    if (updateChart) {
      getChartData(dates);
      setUpdateChart(false);
    }
  }, [updateChart]);

  useEffect(() => {
    setPercentages(() =>
      servicesPercentages.filter(
        (percentage) =>
          servicesToShow.find((service) => service.id === percentage?.id)?.show
      )
    );
  }, [servicesToShow, servicesPercentages]);

  return (
    <section
      className={`${sdkChart ? "chart chart--sdk" : "chart"} ${
        sdkChart && !percentages[0]?.data && "chart--no-data"
      } ${(errorChart || noChartData) && "chart--error"}`}
    >
      {!sdkChart && (
        <h2 className="heading-2 chart--title__text">{t("no_sdk_chart")}</h2>
      )}

      <div className="chart__graph">
        {!walkthrough && chartLoading && !noChartData && !errorChart && (
          <Loader />
        )}
        {!walkthrough && errorChart && !noChartData && (
          <ErrorComponent
            error={500}
            title={t("error_500_title")}
            text={t("error_500_text")}
            styles={{ maxWidth: 520 }}
          />
        )}
        {!walkthrough && !errorChart && noChartData && (
          <ErrorComponent
            error={400}
            title={t("chart_error_400_title")}
            text={t("chart_error_400_text")}
            styles={{ maxWidth: 520 }}
          />
        )}
        {!walkthrough &&
          !chartLoading &&
          !errorChart &&
          !noChartData &&
          (sdkChart ? (
            <SDKChart sdkToShow={servicesToShow} />
          ) : (
            <ConsultasChart />
          ))}
        {walkthrough &&
          !chartLoading &&
          !errorChart &&
          !noChartData &&
          (sdkChart ? (
            <SDKChart sdkToShow={servicesToShow} />
          ) : (
            <ConsultasChart walkthroughData={initialChart} />
          ))}
      </div>
      {!errorChart && !noChartData && (
        <div className="chart__calendar">
          <div className="chart__calendar--data">
            {sdkChart ? (
              <SDKPercentages percentages={percentages[0]?.data} />
            ) : (
              <Services percentages={percentages} />
            )}
          </div>
        </div>
      )}
    </section>
  );
};
export default Chart;
