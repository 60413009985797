import { useTolgee } from "@tolgee/react";

export const useTimeTranslate = () => {
  const tolgee = useTolgee(["language"]);
  const isLangEs = tolgee.getLanguage() === "es-MX";

  const translate = {
    Desde: "From",
    Hasta: "To",
  };

  return (text) => {
    if (isLangEs) return text;

    return translate[text] ?? text;
  };
};
