import { useContext, useEffect, useState } from "react";
import SnackBarContext from "../contexts/SnackBarContext";
import { SNACKBAR_STATES_NAMES } from "../states/contexts/SnackContext";
import { useNavigate } from "react-router-dom";
import { PRIVATE_ROUTES } from "./pages/routes";

const SnackBar = () => {
  const { snackToShow, changeSnackState } = useContext(SnackBarContext);
  const { error, text, title } = snackToShow;
  const { err, errText, errTextTitle } = error;
  const navigate = useNavigate();
  const [dataToDelete, setDataToDelete] = useState({
    snackName: SNACKBAR_STATES_NAMES.SHOW_NOTIFICATION_REPORT,
    route: PRIVATE_ROUTES.BUSQUEDA,
  });

  const handleClose = () => {
    const newSnackState = {
      ...snackToShow,
      show: false,
    };

    changeSnackState(snackToShow, newSnackState);
  };

  useEffect(() => {
    return () => {
      if (snackToShow.name === dataToDelete.snackName)
        navigate(dataToDelete.route);
    };
  }, [snackToShow.name]);

  if (err) {
    return (
      <div className="snackBar snackBar--error">
        <div className="snackBar__icon snackBar__icon--error">
          <span className="icon-error"></span>
        </div>
        <div className="snackBar__text">
          <h4 className="snackBar__title">{errTextTitle}</h4>
          <p className="text-2">{errText}</p>
        </div>
        <button className="snackBar__btn" onClick={handleClose}>
          <span className="icon-close"></span>
        </button>
      </div>
    );
  }

  return (
    <div className="snackBar">
      <div className="snackBar__icon">
        <span className="icon-check"></span>
      </div>
      <div className="snackBar__text">
        {title ? (
          <>
            <h4 className="snackBar__title">{title}</h4>
            <p className="text-2">{text}</p>
          </>
        ) : (
          <h4 className="snackBar__title">{text}</h4>
        )}
      </div>
      <button className="snackBar__btn" onClick={handleClose}>
        <span className="icon-close"></span>
      </button>
    </div>
  );
};
export default SnackBar;
