import { useState } from "react";
import SelectRows from "./SelectRows";
import SelectInputs from "./SelectInputs";
import Button from "./Button";
import SelectServices from "./SelectServices";

const BtnFiltros = ({ filter, icon, title, styles, filters }) => {
  const [openForm, setOpenForm] = useState(false);
  return (
    <div className="button__relative-container">
      <Button
        text={title}
        iconSvg={icon}
        handleClick={() => setOpenForm(!openForm)}
        styles={styles}
      />
      {openForm && filter === "columns" && (
        <SelectRows setOpenForm={setOpenForm} />
      )}
      {openForm && filter === "inputs" && (
        <SelectInputs setOpenForm={setOpenForm} filters={filters} />
      )}
      {openForm && filter === "services" && (
        <SelectServices setOpenForm={setOpenForm} />
      )}
    </div>
  );
};
export default BtnFiltros;
