export const DATE_TYPE = {
  CONSULTAS: "consultas",
  RULES: "rules",
  SDK: "sdk",
};

export const SDK_TYPE = {
  WEB: "WEB",
  ANDROID: "ANDROID",
  IOS: "IOS",
  MAGIC_LINK: "MAGIC_LINK",
};
