export const TYPES = {
  CHANGE_PASS_PERFIL: "CHANGE_PASS_PERFIL",
  CHANGE_PASS_PERFIL_LOADING: "CHANGE_PASS_PERFIL_LOADING",
  CHANGE_PASS_PERFIL_ERROR: "CHANGE_PASS_PERFIL_ERROR",
  CREATE_TABLE_DATA: "CREATE_TABLE_DATA",
  CHANGE_HEADERS: "CHANGE_HEADERS",
  GET_USERS: "GET_USERS",
  GET_USERS_ERROR: "GET_USERS_ERROR",
  GET_USERS_LOADING: "GET_USERS_LOADING",
  NO_USERS: "NO_USERS",
  SCROLL_LOADER: "SCROLL_LOADER",
  SET_USER_TO_DELETE: "SET_USER_TO_DELETE",
  DELETE_USER: "DELETE_USER",
  DELETE_USER_LOADING: "DELETE_USER_LOADING",
  DELETE_USER_ERROR: "DELETE_USER_ERROR",
  UPDATE_USER_TYPE: "UPDATE_USER_TYPE",
  UPDATE_USER_TYPE_ERROR: "UPDATE_USER_TYPE_ERROR",
  SET_USER_TO_ADD: "SET_USER_TO_ADD",
  ADD_USER: "ADD_USER",
  ADD_USER_LOADING: "ADD_USER_LOADING",
  ADD_USER_ERROR: "ADD_USER_ERROR",
  UPDATE_USER_DATA: "UPDATE_USER_DATA",
  UPDATE_USER_DATA_ERROR: "UPDATE_USER_DATA_ERROR",
  UPDATE_USER_DATA_LOADING: "UPDATE_USER_DATA_LOADING",
  GET_API_KEY: "GET_API_KEY",
  GET_RULES: "GET_RULES",
  LOADING_RULES: "LOADING_RULES",
  RULES_ERROR: "RULES_ERROR",
  SET_RULE: "SET_RULE",
  SET_FILTERS: "SET_FILTERS",
};
