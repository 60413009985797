import React, { useState } from "react";

const RadioGroup = ({ name, options, onChange, showText }) => {
  const [selectedOption, setSelectedOption] = useState(() => {
    const checked = options.find((el) => el.checked);
    return checked ? checked.id : "";
  });

  const handleChange = ({ target: { value } }) => {
    setSelectedOption(value);
    onChange(value);
  };

  return (
    <div className="radioGroup">
      {options.map((option) => (
        <div key={option.id} className="radioGroup__option">
          <div className="radioGroup__input">
            <input
              type="radio"
              id={option.id}
              name={name}
              value={option.id}
              checked={selectedOption === option.id}
              onChange={handleChange}
            />
            <label
              className="text-1--bold"
              htmlFor={option.id}
              style={option.styles ? { ...option.styles } : {}}
            >
              {option.label}
            </label>
          </div>
          {showText && <p className="text-2 radioGroup__text">{option.text}</p>}
        </div>
      ))}
    </div>
  );
};

export default RadioGroup;
