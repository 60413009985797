import { COLORS } from "./utils/colors";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import { autocompleteClasses } from "@mui/material/Autocomplete";

export const Root = styled("div")(
  () => `
      color: ${COLORS.SHARK};
      font-size: 0.875rem;
      font-family: DM Sans;
      position: relative;
      width: 100%;
      max-width: 300px;
    `
);

export const InputWrapper = styled("form")(
  () => `
      width: 100%;
      border: 1px solid ${COLORS.CATSKILL_WHITE};
      background-color: ${COLORS.WHITE};
      border-radius: 50px;
      padding: 4px 12px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    
      &:hover {
        border-color: ${COLORS.SHARK};
      }
    
      &.focused {
        border-color: ${COLORS.CATSKILL_WHITE};
      }
    
      & input {
        background-color: ${COLORS.WHITE};
        color: ${COLORS.SHARK};
        height: 30px;
        box-sizing: border-box;
        padding: 4px 8px;
        width: 0;
        min-width: 30px;
        flex-grow: 1;
        border: 0;
        margin: 0;
        outline: 0;
      }
    `
);

export function Tag(props) {
  const { label, onDelete, ...other } = props;
  return (
    <div {...other}>
      <span>{label}</span>
      <CloseIcon onClick={onDelete} />
    </div>
  );
}

export const StyledTag = styled(Tag)(
  () => `
      display: flex;
      align-items: center;
      min-height: 24px;
      font-size: 0.875rem;
      line-height: 18px;
      font-weight: 400;
      background-color: ${COLORS.CATSKILL_WHITE};
      border-radius: 4px;
      box-sizing: content-box;
      padding: 2px 6px;
      outline: 0;
      overflow: hidden;
      margin-left: 4px;
    
      & span {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        margin-right: 4px;
      }
    
      & svg {
        font-size: 1rem;
        cursor: pointer;
      }
    `
);

export const Listbox = styled("ul")(
  () => `
      width: 100%;
      min-width: 345px;
      margin: 2px 0 0;
      padding: 0;
      position: absolute;
      list-style: none;
      background-color: ${COLORS.WHITE};
      overflow: auto;
      max-height: 250px;
      border-radius: 4px;
      border: 1px solid ${COLORS.CATSKILL_WHITE};
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
      z-index: 10;
      top: 45px;
    
      & li {
        color: ${COLORS.SHARK};
        padding: 8px 16px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 8px;  
        font-family: DM Sans;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 24px; 
      }
    
      & li[aria-selected='true'] {
        background-color: ${COLORS.CATSKILL_WHITE};
        font-weight: 700;
      }
    
      & li.${autocompleteClasses.focused} {
        background-color: ${COLORS.CATSKILL_WHITE}75;
        cursor: pointer;
      }
    `
);
