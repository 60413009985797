import { useEffect, useRef, useState } from "react";
import LinkConfigBtnContainer from "./LinkConfigBtnContainer";
import LinkConfigHeader from "./LinkConfigHeader";
import LinkPreview from "./LinkPreview";
import Button from "./Button";
import { COLORS } from "../styles/utils/colors";
import ColorPicker from "./ColorPicker";
import FontPickerComponent from "./FontPickerComponent";
import { T, useTranslate } from "@tolgee/react";

const ConfigBrand = ({ closeTab, linkData, setLinkData, initialForm }) => {
  const [form, setForm] = useState(initialForm);
  const [disabled, setDisabled] = useState(true);
  const inputRef = useRef(null);
  const [logo, setLogo] = useState(null);
  const [isValidSize, setIsValidSize] = useState(true);
  const [colorPickersToShow, setColorPickersToShow] = useState([
    { name: "primary", show: false },
    { name: "secondary", show: false },
    { name: "textPrimary", show: false },
    { name: "textSecondary", show: false },
  ]);
  const { t } = useTranslate();

  useEffect(() => {
    const {
      logo,
      colors: { primary, secondary, textPrimary, textSecondary },
      font: { heading1, heading2, text },
    } = form;

    const {
      logo: initialLogo,
      colors: {
        primary: initialPrimary,
        secondary: initialSecondary,
        textPrimary: initialTextPrimary,
        textSecondary: initialTextSecondary,
      },
      font: {
        heading1: initialHeading1,
        heading2: initialHeading2,
        text: initialText,
      },
    } = initialForm;

    if (
      logo !== initialLogo ||
      primary !== initialPrimary ||
      secondary !== initialSecondary ||
      textPrimary !== initialTextPrimary ||
      textSecondary !== initialTextSecondary ||
      heading1 !== initialHeading1 ||
      heading2 !== initialHeading2 ||
      text !== initialText
    )
      setDisabled(() => false);
    else setDisabled(() => true);
  }, [form]);

  const saveConfig = () => {
    setLinkData((prev) => ({
      ...prev,
      brand: {
        logo: form.logo,
        colors: {
          primary: form.colors.primary,
          secondary: form.colors.secondary,
          textPrimary: form.colors.textPrimary,
          textSecondary: form.colors.textSecondary,
        },
        font: {
          heading1: form.font.heading1,
          heading2: form.font.heading2,
          text: form.font.text,
        },
      },
    }));

    closeTab();
  };

  const handleFileChange = (e) => {
    setLogo(null);
    const file = e.target.files[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = (e) => {
      const img = new Image();
      img.onload = () => {
        const width = img.width;
        const height = img.height;
        if (width <= 650 && height <= 500) {
          setIsValidSize(true);
          const url = URL.createObjectURL(file);
          setLogo(url);
          setForm((prev) => ({ ...prev, logo: file, logoPreview: url }));
        } else {
          setIsValidSize(false);
        }
      };
      img.src = e.target.result;
    };
    reader.readAsDataURL(file);
  };

  const handleImageLoad = ({ target: { width, height } }) =>
    setIsValidSize(() => width <= 650 && height <= 500);

  const setColor = ({ color, name }) =>
    setForm((prev) => ({
      ...prev,
      colors: {
        ...prev.colors,
        [name]: color,
      },
    }));

  const setFont = ({ font, name }) =>
    setForm((prev) => ({
      ...prev,
      font: {
        ...prev.font,
        [name]: font,
      },
    }));

  const handlePickerShowing = (name, state) => {
    setColorPickersToShow((prev) =>
      prev.map((picker) =>
        picker.name === name
          ? { ...picker, show: state }
          : { ...picker, show: false }
      )
    );
  };

  const handleFontPickerShowing = ({ target }) => {
    const ul = target.offsetParent.lastElementChild;
    ul.style.bottom = "45px";
  };

  return (
    <section className="linkPage__configuration">
      <div className="linkPage__configuration__menu">
        <div className="configBrand">
          <div className="configBrand__sub-title">
            <LinkConfigHeader
              title={t("config_brand_header")}
              closeTab={closeTab}
            />
            <p className="text-2">
              <T keyName="config_brand_message" />
            </p>
          </div>
          <div className="configBrand__logo">
            <h4 className="text-1--bold">
              <T keyName="config_brand_logo_header" />
            </h4>
            <div className="configBrand__logo__container">
              <div className="configBrand__logo__container__img">
                {logo && (
                  <img
                    src={logo}
                    alt={t("img_preview_message")}
                    onLoad={handleImageLoad}
                    style={{ display: "none" }}
                  />
                )}
                {logo && isValidSize ? (
                  <img
                    src={logo}
                    alt={t("img_preview_message")}
                    className="configBrand__logo__container__img__icon"
                  />
                ) : (
                  <div className="icon-add configBrand__logo__container__img__icon"></div>
                )}
              </div>
              <div className="configBrand__logo__container__btn-container">
                {logo ? (
                  <Button
                    handleClick={() => {
                      setForm((prev) => ({
                        ...prev,
                        logo: null,
                        logoPreview: null,
                      }));
                      setLogo(null);
                      inputRef.current.value = "";
                    }}
                    text={t("config_brand_delete_img")}
                    styles={{
                      color: COLORS.COPPERFIELD,
                      backgroundColor: COLORS.WHITE,
                      border: `1px solid ${COLORS.COPPERFIELD}`,
                      padding: "8px",
                      boxShadow: "none",
                    }}
                    btnTextClass="text-2"
                  />
                ) : (
                  <Button
                    handleClick={() => inputRef.current.click()}
                    text={t("config_brand_upload_img")}
                    styles={{
                      color: COLORS.SHARK,
                      backgroundColor: COLORS.WHITE,
                      border: `1px solid ${COLORS.SHUTTLE_GRAY}`,
                      padding: "8px",
                      boxShadow: "none",
                    }}
                    btnTextClass="text-2"
                  />
                )}
                <input
                  type="file"
                  accept="image/*"
                  ref={inputRef}
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                />
                <span
                  className={`text-3 ${
                    isValidSize ? "color--shuttle-gray" : "color--copperfield"
                  } `}
                >
                  <T keyName="config_brand_extensions_msg" />
                </span>
              </div>
            </div>
          </div>
          <div className="configBrand__buttons">
            <h4 className="text-1--bold">
              <T keyName="config_brand_colors_header" />
            </h4>
            <div className="configBrand__buttons__color-container">
              <ColorPicker
                colorType="primary"
                initialColor={initialForm.colors.primary}
                title={t("config_brand_primary_color")}
                setColor={setColor}
                colorPickersToShow={colorPickersToShow}
                handlePickerShowing={handlePickerShowing}
              />
              <ColorPicker
                colorType="secondary"
                initialColor={initialForm.colors.secondary}
                title={t("config_brand_secondary_color")}
                setColor={setColor}
                colorPickersToShow={colorPickersToShow}
                handlePickerShowing={handlePickerShowing}
              />
            </div>
          </div>
          <div className="configBrand__text">
            <h4 className="text-1--bold">
              <T keyName="config_brand_text_header" />
            </h4>
            <div className="configBrand__text__text-container">
              <ColorPicker
                colorType="textPrimary"
                initialColor={initialForm.colors.textPrimary}
                title={t("config_brand_primary_color")}
                setColor={setColor}
                colorPickersToShow={colorPickersToShow}
                handlePickerShowing={handlePickerShowing}
              />
              <ColorPicker
                colorType="textSecondary"
                initialColor={initialForm.colors.textSecondary}
                title={t("config_brand_secondary_color")}
                setColor={setColor}
                colorPickersToShow={colorPickersToShow}
                handlePickerShowing={handlePickerShowing}
              />
            </div>
            <FontPickerComponent
              setFont={setFont}
              title={t("config_brand_header_1")}
              initialFont={initialForm.font.heading1}
              fontType="heading1"
            />
            <FontPickerComponent
              setFont={setFont}
              title={t("config_brand_header_2")}
              initialFont={initialForm.font.heading2}
              fontType="heading2"
              handleFontPickerShowing={handleFontPickerShowing}
            />
            <FontPickerComponent
              setFont={setFont}
              title={t("config_brand_p")}
              initialFont={initialForm.font.text}
              fontType="text"
              handleFontPickerShowing={handleFontPickerShowing}
            />
          </div>
        </div>
        <LinkConfigBtnContainer
          cancelBtnConfig={{ text: t("cancel_button"), handleClick: closeTab }}
          saveBtnConfig={{
            text: t("save_changes_button"),
            handleClick: saveConfig,
          }}
          enableSave={!disabled}
        />
      </div>
      <LinkPreview linkData={linkData} brandPreview={form} />
    </section>
  );
};
export default ConfigBrand;
