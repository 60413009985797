import { useContext, useEffect, useState } from "react";
import Button from "../Button";
import Modal from "../Modal";
import LinkContext from "../../contexts/LinkContext";
import { changeHeadersHelper } from "../../helpers/changeHeadersHelper";
import TableContainer from "../TableContainer";
import LinkConfiguration from "../LinkConfiguration";
import { showDropdown } from "../../helpers/showDropdown";
import { useTranslate } from "@tolgee/react";

const Link = () => {
  const [showGenerateLinkModal, setShowGenerateLinkModal] = useState(false);
  const [showShareLinkModal, setShowShareLinkModal] = useState(false);
  const [showConfiguration, setShowConfiguration] = useState(false);
  const [sdkName, setSdkName] = useState("");
  const { t } = useTranslate();
  const {
    headers,
    dataToShow,
    linksLoading,
    linksError,
    requestStartOn,
    scrollLoader,
    sortBy,
    getLinksList,
    createTableData,
    setActiveLink,
    activeLink,
    noLinksToShow,
  } = useContext(LinkContext);

  useEffect(() => {
    if (dataToShow.length === 0) getLinksList(0);
  }, []);

  const headersBtnClick = (title, dataType, dataKey, sortedBy) => {
    const newOrder = sortedBy === "asc" ? "desc" : "asc";
    const newHeaders = changeHeadersHelper(headers, title, newOrder);
    sortBy(newOrder, dataType, dataKey, newHeaders);
  };

  const handleScroll = () => {
    const noLoader = true;
    getLinksList(requestStartOn + 1, noLoader);
  };

  const handleCreate = (name) => {
    setSdkName(name);
    setShowGenerateLinkModal(() => false);
    setShowConfiguration(() => true);
  };

  const closeConfig = () => {
    setShowConfiguration(() => false);
    setActiveLink(null);
  };

  const handleShowOptions = ({ id, isOpen }) => {
    if (!isOpen) {
      const linkToHandle = dataToShow.find((link) => link.token === id);
      linkToHandle.id = linkToHandle.token;
      const finalDownIndex = dataToShow.findIndex(
        (link) => link.token === linkToHandle.id
      );

      const dropdownPosition = {
        y: finalDownIndex >= 14 ? -180 : 30,
        x: -175,
      };

      showDropdown({
        createNewData: true,
        tableData: dataToShow,
        anchorElement: linkToHandle,
        handleShow: (data) => createTableData({ data }),
        dropdownPosition,
      });
    } else {
      showDropdown({
        createNewData: true,
        tableData: dataToShow,
        handleShow: (data) => createTableData({ data }),
      });
    }
  };

  if (showGenerateLinkModal)
    return (
      <Modal
        generateLink={true}
        setShowModal={setShowGenerateLinkModal}
        handleCreate={handleCreate}
      />
    );

  if (showShareLinkModal)
    return <Modal shareLink={true} setShowModal={setShowShareLinkModal} />;

  if (showConfiguration)
    return (
      <LinkConfiguration
        sdkName={sdkName || activeLink.title}
        closeConfig={closeConfig}
        linkDataToUpdate={activeLink}
        setShowShareLinkModal={setShowShareLinkModal}
      />
    );

  return (
    <section className="linkPage">
      <header className="linkPage__header">
        <h2 className="heading-2">Magic Link</h2>
        <Button
          handleClick={() => setShowGenerateLinkModal(() => true)}
          text={t("button_magic_link_create")}
        />
      </header>
      <TableContainer
        headers={headers}
        dataToShow={dataToShow}
        loading={linksLoading}
        error={linksError}
        requestStartOn={requestStartOn}
        scrollLoader={scrollLoader}
        headersBtnClick={headersBtnClick}
        handleScroll={handleScroll}
        handleClick={handleShowOptions}
        inLinks={true}
        setShowModal={setShowShareLinkModal}
        setShowConfiguration={setShowConfiguration}
        noLinksToShow={noLinksToShow}
        setShowGenerateLinkModal={setShowGenerateLinkModal}
      />
    </section>
  );
};
export default Link;
