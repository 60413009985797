import { useState } from "react";

export const useFormValidation = (dataToValidate) => {
  const [validateForm, setValidateForm] = useState(dataToValidate);

  const handleFormValidation = (inputToValidate) => {
    const newValidations = validateForm.map((input) =>
      input.name === inputToValidate.name
        ? inputToValidate
        : { ...input, valid: true }
    );
    setValidateForm(newValidations);
  };

  return {
    validateForm,
    setValidateForm,
    handleFormValidation,
  };
};
