import { useContext } from "react";
import {
  SNACKBAR_STATES_NAMES,
  SNACKBAR_TEXTS,
  SNACKBAR_TEXTS_EN,
} from "../states/contexts/SnackContext";
import SnackBarContext from "../contexts/SnackBarContext";
import { useTolgee, useTranslate } from "@tolgee/react";

const CopyToClipboard = ({ text, snackData }) => {
  const { setSnack } = useContext(SnackBarContext);
  const { t } = useTranslate();
  const tolgee = useTolgee(["language"]);
  const isLangEs = tolgee.getLanguage() === "es-MX";

  const handleCopy = () => {
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          const snackState = {
            show: true,
            text: snackData.text,
          };
          setSnack(SNACKBAR_STATES_NAMES.COPY_JSON, snackState);
        })
        .catch((error) => {
          const snackState = {
            show: true,
            error: {
              err: true,
              errText: isLangEs
                ? SNACKBAR_TEXTS.COPY_JSON_ERROR
                : SNACKBAR_TEXTS_EN.COPY_JSON_ERROR,
              errTextTitle: isLangEs
                ? SNACKBAR_TEXTS.ERROR_TITLE
                : SNACKBAR_TEXTS_EN.ERROR_TITLE,
            },
          };

          setSnack(SNACKBAR_STATES_NAMES.COPY_JSON, snackState);
        });
    } else {
      const snackState = {
        show: true,
        error: {
          err: true,
          errText: isLangEs
            ? SNACKBAR_TEXTS.NO_COPY_JSON
            : SNACKBAR_TEXTS_EN.NO_COPY_JSON,
          errTextTitle: isLangEs
            ? SNACKBAR_TEXTS.ERROR_TITLE
            : SNACKBAR_TEXTS_EN.ERROR_TITLE,
        },
      };

      setSnack(snackData.state, snackState);
    }
  };
  return (
    <button className="json-report__btn" onClick={handleCopy}>
      <div className="icon-copy color--dodger-blue"></div>
      <span className="text-1--bold color--dodger-blue">{t("btn_copy")}</span>
    </button>
  );
};
export default CopyToClipboard;
