const Countries = ({ arrElementos, handleClick, closeList }) => {
  return (
    <div className="autoCompleteSelect  slideInDown--floating">
      <div className="autoCompleteSelect__title">
        <div className="icon-buscar autoCompleteSelect__title--icon"></div>
        <button onClick={closeList}>
          <div className="icon-close autoCompleteSelect__title--icon color--dodger-blue"></div>
        </button>
      </div>
      <div className="autoCompleteSelect__content">
        {arrElementos.map((el, i) => (
          <button
            key={`${el.name}-${i}`}
            className={`autoCompleteSelect__content--element ${
              el.selected && "border border-blue"
            } `}
            onClick={() => {
              handleClick(el.preFix);
              closeList();
            }}
          >
            <img
              src={el.flag}
              alt={`Bandera de ${el.name}`}
              className="autoCompleteSelect__content--flag"
            />
            <p className="autoCompleteSelect__content--text text-1">
              {el.name}
            </p>
            <div className="autoCompleteSelect__title--icon-check">
              {el.selected && (
                <div className="icon-check-plano autoCompleteSelect__title--icon-check color--dodger-blue"></div>
              )}
            </div>
          </button>
        ))}
      </div>
    </div>
  );
};
export default Countries;
