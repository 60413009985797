import { useEffect, useRef } from "react";
import Loader from "./Loader";
import Table from "./Table";
import { SIN_CONSULTAS } from "../reducers/dataReducer";
import { NO_INVOICE } from "../reducers/billingReducer";
import { USER_ERRORS } from "../contexts/UserContext";
import ErrorComponent from "./ErrorComponent";
import LinearLoader from "./LinearLoader";
import NoDataToShow from "./NoDataToShow";
import linkImg from "../styles/images/link.svg";
import { createScrollFn } from "../helpers/createScrollFn";
import { useTranslate } from '@tolgee/react';

const TableContainer = ({
  headers,
  dataToShow,
  loading,
  error,
  requestStartOn,
  serviceSearched,
  scrollLoader,
  handleScroll,
  handleClick,
  headersBtnClick,
  showColumns,
  userSearched,
  noUsers,
  handleSelection,
  deleteSelection,
  inConsultas,
  inTeam,
  inBilling,
  handleShowAccountsTypes,
  report,
  walkthrough,
  inLinks,
  setShowModal,
  setShowConfiguration,
  noLinksToShow,
  setShowGenerateLinkModal,
}) => {
  const containerRef = useRef(null);
  const prevScrollY = useRef(0);
  const { t } = useTranslate();

  useEffect(() => {
    const container = containerRef.current;
    const scrollFn = createScrollFn({ container, prevScrollY, handleScroll });
    container.addEventListener("scroll", scrollFn);
    return () => container.removeEventListener("scroll", scrollFn);
  }, [requestStartOn, serviceSearched, userSearched]);

  return (
    <div
      className={`layout__main__section__consultas__table-container ${
        (loading || dataToShow?.length === 0) && "loaderStyles"
      } ${inTeam && "team--loader team__table"} ${
        inLinks && error && "loaderStyles"
      } `}
      ref={containerRef}
    >
      {!walkthrough &&
        (loading || dataToShow?.length === 0) &&
        !error &&
        !noLinksToShow && <Loader />}

      {!walkthrough &&
        !loading &&
        inConsultas &&
        dataToShow?.length === 0 &&
        error?.errText === SIN_CONSULTAS && (
          <ErrorComponent
            error={400}
            title={t("table_container_404_title")}
            text={t("table_container_404_message")}
            styles={{ maxWidth: 520 }}
          />
        )}

      {!loading && inTeam && noUsers && (
        <ErrorComponent
          error={500}
          title={t("table_container_500_members_error")}
          text={t("error_500_text")}
          styles={{ maxWidth: 520 }}
        />
      )}

      {!loading && inTeam && error?.errText === USER_ERRORS.UNAUTHORIZED && (
        <ErrorComponent
          error={500}
          title={t("table_container_unauthorized_error")}
          text={t("table_container_unauthorized_error_message")}
          styles={{ maxWidth: "auto" }}
        />
      )}

      {!loading && inBilling && error?.errText === NO_INVOICE && (
        <ErrorComponent
          error={500}
          title={t("table_container_billing_500_error")}
          text={t("error_500_text")}
          styles={{ maxWidth: 520 }}
        />
      )}

      {!walkthrough &&
        !loading &&
        error?.errText &&
        error?.errText !== SIN_CONSULTAS &&
        error?.errText !== NO_INVOICE &&
        error?.errText !== USER_ERRORS.UNAUTHORIZED && (
          <ErrorComponent
            error={500}
            title={t("error_500_title")}
            text={t("error_500_text")}
            styles={{ maxWidth: 520 }}
          />
        )}

      {!walkthrough && !loading && inLinks && noLinksToShow && (
        <NoDataToShow
          icon={linkImg}
          title={t("table_container_magic_link_404_error")}
          text={t("table_container_magic_link_404_text")}
          btnData={{
            handleClick: setShowGenerateLinkModal,
            text: t("button_magic_link_create"),
          }}
        />
      )}

      {!walkthrough && !loading && !error && dataToShow?.length !== 0 && (
        <Table
          headers={headers}
          dataToShow={dataToShow}
          handleClick={handleClick}
          headersBtnClick={headersBtnClick}
          showColumns={showColumns}
          inConsultas={inConsultas}
          inTeam={inTeam}
          inBilling={inBilling}
          inLinks={inLinks}
          handleSelection={handleSelection}
          deleteSelection={deleteSelection}
          handleShowAccountsTypes={handleShowAccountsTypes}
          report={report}
          setShowModal={setShowModal}
          setShowConfiguration={setShowConfiguration}
        />
      )}

      {walkthrough && (
        <Table
          headers={headers}
          dataToShow={dataToShow}
          handleClick={handleClick}
          headersBtnClick={headersBtnClick}
          showColumns={showColumns}
          inConsultas={inConsultas}
          inTeam={inTeam}
          inBilling={inBilling}
          handleSelection={handleSelection}
          deleteSelection={deleteSelection}
          handleShowAccountsTypes={handleShowAccountsTypes}
          report={report}
        />
      )}

      {scrollLoader && <LinearLoader />}
    </div>
  );
};
export default TableContainer;
