import { forwardRef } from "react";
import { FLAGS_NAMES } from "../states/contexts/ReportContext";
import { COLORS } from "../styles/utils/colors";
import Badge from "./Badge";
import { formatUTCDate } from "../helpers/formatDate";

const ReportTableDetails = forwardRef(
  (
    {
      id,
      created_on,
      label,
      response,
      response_status,
      reportSelectedID,
      handleClick,
    },
    ref
  ) => {
    if (response_status === 400 || response_status === 500) return <></>;

    const getLabel = () => {
      return label || response.label;
    };

    const isPotentialThreat = () => {
      const label = getLabel();

      return (
        label === FLAGS_NAMES.FRAUD || label === FLAGS_NAMES.POTENTIAL_THREAT
      );
    };

    const isCorrect = () => {
      const label = getLabel();

      return label === FLAGS_NAMES.NOT_FRAUD || label === FLAGS_NAMES.NO_THREAT;
    };

    return (
      <button
        className="reportTableDetails"
        style={
          reportSelectedID === id
            ? {
                border: `1px solid ${COLORS.DODGER_BLUE}`,
                position: "sticky",
                top: "0",
                background: "white",
              }
            : {}
        }
        onClick={() => handleClick(id)}
        ref={ref}
      >
        <span>{id}</span>
        <span>{formatUTCDate(created_on)}</span>
        {isPotentialThreat() ? (
          <Badge
            icon="icon-amenaza"
            color={COLORS.COPPERFIELD}
            bgColor={COLORS.PEACH_SCHNAPPS}
            text="Amenaza"
            styles={{
              container: {
                minWidth: "5.903125rem",
                justifyContent: "flex-start",
                gap: "6px",
              },
              text: {
                margin: 0,
              },
            }}
          />
        ) : isCorrect() ? (
          <Badge
            icon="icon-check"
            color={COLORS.KEPPEL}
            bgColor={COLORS.ICEBERG}
            text="¡Correcto!"
            styles={{
              container: {
                justifyContent: "flex-start",
              },
              text: {
                margin: 0,
              },
            }}
          />
        ) : (
          <Badge
            icon="icon-revisa"
            color={COLORS.COM}
            bgColor={COLORS.BUTTERMILK}
            text="Revisar"
            styles={{
              container: {
                minWidth: "5.903125rem",
                justifyContent: "flex-start",
                gap: "6px",
              },
              text: {
                margin: 0,
              },
            }}
          />
        )}
      </button>
    );
  }
);
export default ReportTableDetails;
