const LinkConfigHeader = ({ title, closeTab }) => {
  return (
    <header className="linkPage__configuration__menu__header">
      <button
        onClick={closeTab}
        className="icon-anterior linkPage__configuration__menu__header__btn"
      ></button>
      <h2 className="heading-2">{title}</h2>
    </header>
  );
};
export default LinkConfigHeader;
