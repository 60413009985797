import React, { createContext, useContext, useMemo, useReducer } from "react";
import { generateRequest } from "../helpers/helpHttp";
import { SIN_CONSULTAS, dataReducer } from "../reducers/dataReducer";
import { TYPES } from "../actions/dataActions";
import AuthContext from "./AuthContext";
import { url } from "./domain";
import { createChartDataTotals } from "../helpers/createChartData";
import DatesContext from "./DatesContext";
import { changeHeadersHelper } from "../helpers/changeHeadersHelper";
import { sorting } from "../helpers/sorting";
import { SEARCH_TYPES, defaultState } from "../states/contexts/DataContext";
import { useNavigate } from "react-router-dom";
import { PRIVATE_ROUTES } from "../components/pages/routes";
import { getRulesTotals } from "../helpers/getRulesTotals";
import { SDK_TYPE } from "../constants";
import { useTolgee } from "@tolgee/react";

const DataContext = createContext();

const DataProvider = ({ children }) => {
  const { user, updateUser, terminateSession, invalidSession } =
    useContext(AuthContext);
  const { dates } = useContext(DatesContext);
  const [state, dispatch] = useReducer(dataReducer, defaultState);
  const navigate = useNavigate();
  const tolgee = useTolgee(["language"]);
  const isLangEs = tolgee.getLanguage() === "es-MX";

  const {
    lastRequests,
    dataToShow,
    headers,
    showColumns,
    totals,
    chart,
    requestStartOn,
    serviceSearched,
    requestsLoading,
    chartLoading,
    errorRequest,
    errorTotals,
    errorChart,
    noChartData,
    scrollLoader,
    requestsFromLink,
    linkToken,
    servicesToShow,
    servicesPercentages,
    errorPercentages,
    rules,
    rulesTotals,
    rulesLoading,
    noRulesData,
  } = state;

  const columnsToShow = (columnsToShow) => {
    const newState = {
      date: columnsToShow.date,
      time: columnsToShow.time,
      productName: columnsToShow.productName,
      id: columnsToShow.id,
      externalId: columnsToShow.externalId,
      informador: columnsToShow.informador,
      estatus: columnsToShow.estatus,
    };

    const newMeta = {
      metadata: {
        history: {
          "history.date": newState.date,
          "history.time": newState.time,
          "history.productName": newState.productName,
          "history.id": newState.id,
          "history.externalId": newState.externalId,
          "history.informador": newState.informador,
          "history.estatus": newState.estatus,
        },
      },
    };

    const requestData = {
      url: `${url}/profile`,
      options: {
        headers: { "content-type": "application/json" },
        body: newMeta,
      },
      terminateSession,
      invalidSession,
    };

    generateRequest.patch(requestData);

    const newUser = {
      ...user,
      metadata: {
        ...user.metadata,
        history: { ...newState },
      },
    };

    updateUser(newUser);

    dispatch({
      type: TYPES.SET_COLUMNS_TO_SHOW,
      payload: columnsToShow,
    });
  };

  const changeHeaders = (title, sortedBy) => {
    const newHeaders = changeHeadersHelper(headers, title, sortedBy);

    dispatch({
      type: TYPES.CHANGE_HEADERS,
      payload: newHeaders,
    });
  };

  const createTableData = (dataObj) => {
    const data = dataObj.data;
    const serviceSearched = dataObj.serviceSearched || "";

    setLoader(TYPES.REQUEST_LOADING);
    if (data.length === 0) {
      dispatch({
        type: TYPES.NO_REQUEST_DATA,
      });
      return;
    }

    dispatch({
      type: TYPES.SET_TABLE_DATA,
      payload: {
        data,
        serviceSearched,
      },
    });
  };

  const setLoader = (type, payload) => {
    return dispatch({
      type: type,
      payload,
    });
  }

  const setExternalIDToTableData = (reportID, external_id) => {
    const report = dataToShow.find((report) => report.id === reportID);

    if (!report) return;

    dispatch({
      type: TYPES.SET_TABLE_DATA,
      payload: {
        data: dataToShow.map((data) =>
          data.id === reportID ? { ...report, external_id } : data
        ),
        serviceSearched,
      },
    });
  };

  const getLastRequests = async ({ requestStartOn, noLoader }) => {
    if (!noLoader) setLoader(TYPES.REQUEST_LOADING);
    else setLoader(TYPES.SCROLL_LOADER, requestStartOn);

    try {
      const requestData = {
        url: `${url}/usage/request?page=${requestStartOn}&offset=25`,
        options: { body: {} },
        terminateSession,
        invalidSession,
      };

      const data = await generateRequest.post(requestData);

      if (data.err || data instanceof Error) throw data;

      if (data.data.length === 0) {
        dispatch({
          type: TYPES.NO_REQUEST_DATA,
        });
        return;
      }

      if (requestStartOn === 0)
        dispatch({
          type: TYPES.GET_LAST_REQUEST,
          payload: {
            data: data.data,
            requestStartOn,
          },
        });
      else {
        const payload = {
          data: data.data,
          requestStartOn,
          serviceSearched: "",
        };
        dispatch({ type: TYPES.LOAD_DATA, payload });
      }
    } catch (error) {
      if (error.status === 404) {
        dispatch({
          type: TYPES.LOAD_DATA,
          payload: {
            data: [],
            requestStartOn,
          },
        });
        return;
      }

      const err = {
        ...error,
        errText: isLangEs
          ? "Lo sentimos. No se pudo cargar el historial"
          : "We're sorry. The history could not be loaded",
      };

      dispatch({
        type: TYPES.ERROR_REQUEST,
        payload: err,
      });
    }
  };

  const restartRequestsCount = () => {
    dispatch({ type: TYPES.RESET_REQUEST_COUNT });
  };

  const getTotals = async (services) => {
    const { from, to } = dates;

    const startYear = from.year;
    const startMonth = from.monthNumber + 1;
    const startDay = from.day;

    const endYear = to.year;
    const endMonth = to.monthNumber + 1;
    const endDay = to.day;

    try {
      const requestData = {
        url: `${url}/usage/command-panel/summary?services=${services.toString()}&start_date=${startYear}-${startMonth}-${startDay}&end_date=${endYear}-${endMonth}-${endDay}`,
        terminateSession,
        invalidSession,
      };

      const data = await generateRequest.get(requestData);

      if (data.err) throw data;

      dispatch({
        type: TYPES.GET_TOTALS,
        payload: data,
      });
    } catch (error) {
      const err = {
        ...error,
        errText: isLangEs
          ? "Lo sentimos. No se pudieron cargar los totales de búsqueda"
          : "We're sorry. Search totals couldn't be loaded",
      };

      dispatch({
        type: TYPES.ERROR_TOTALS,
        payload: err,
      });
    }
  };

  const sortBy = (order, type, key) => {
    const newDataToShow = sorting(dataToShow, order, type, key);
    createTableData({ data: newDataToShow });
  };

  const searchBy = async ({
    value,
    serviceSearched,
    noLoader,
    requestStartOn = 0,
  }) => {
    if (noLoader) setLoader(TYPES.SCROLL_LOADER);
    else setLoader(TYPES.REQUEST_LOADING, requestStartOn);

    try {
      let data;

      switch (serviceSearched) {
        case SEARCH_TYPES.ID: {
          if (Number.isNaN(Number(value))) {
            throw new Error("ID inválido");
          }

          const endpoint = `${url}/usage/request/${value}`;

          const requestData = {
            url: endpoint,
            terminateSession,
            invalidSession,
          };

          data = await generateRequest.get(requestData);
          break;
        }

        case SEARCH_TYPES.USER_ID: {
          const endpoint = `${url}/usage/request/external?external_id=${value}&page=${requestStartOn}&offset=40`;

          const requestData = {
            url: endpoint,
            terminateSession,
            invalidSession,
          };

          data = await generateRequest.get(requestData);

          break;
        }

        case SEARCH_TYPES.TRULLY_ID: {
          const endpoint = `${url}/usage/request/identity/${value}?page=${requestStartOn}&offset=40`;

          const requestData = {
            url: endpoint,
            terminateSession,
            invalidSession,
          };

          data = await generateRequest.get(requestData);

          break;
        }

        case SEARCH_TYPES.LINK: {
          data = await getRequestsForLinks({
            token: value,
            showLoader: !noLoader,
            isSearch: true,
          });
          break;
        }

        case SDK_TYPE.ANDROID:
        case SDK_TYPE.WEB:
        case SDK_TYPE.MAGIC_LINK: {
          const endpoint = `${url}/usage/request/channel?channels=${value}&page=${requestStartOn}&offset=40`;

          const requestData = {
            url: endpoint,
            terminateSession,
            invalidSession,
          };

          data = await generateRequest.get(requestData);

          break;
        }

        default: {
          const endpoint = `${url}/usage/request?page=0&offset=40`;

          const requestData = {
            url: endpoint,
            options: { body: {} },
            terminateSession,
            invalidSession,
          };

          data = await generateRequest.post(requestData);

          break;
        }
      }

      if (data.err || data.data.length === 0) throw data;

      if (!noLoader || serviceSearched !== value) {
        dispatch({
          type: TYPES.SET_TABLE_DATA,
          payload: {
            data: data.data,
            search: value,
          },
        });
      } else {
        const payload = {
          data: data.data,
          requestStartOn,
          serviceSearched: value,
        };
        dispatch({ type: TYPES.LOAD_DATA, payload });
      }
    } catch (error) {
      const selectedOption = JSON.parse(
        sessionStorage.getItem("selectedOption")
      )?.id;

      if (selectedOption && noLoader) {
        const payload = {
          data: [],
          requestStartOn,
          serviceSearched: value,
        };
        dispatch({ type: TYPES.LOAD_DATA, payload });
        return;
      }

      const err = {
        ...error,
        errText: SIN_CONSULTAS,
      };

      dispatch({
        type: TYPES.ERROR_REQUEST,
        payload: { error: err, serviceSearched: value },
      });
    }
  };

  const getPercentages = async (services) => {
    const { from, to } = dates;

    const startYear = from.year;
    const startMonth = from.monthNumber + 1;
    const startDay = from.day;

    const endYear = to.year;
    const endMonth = to.monthNumber + 1;
    const endDay = to.day;

    try {
      const requestData = {
        url: `${url}/usage/command-panel/calls?services=${services.toString()}&start_date=${startYear}-${startMonth}-${startDay}&end_date=${endYear}-${endMonth}-${endDay}`,
        terminateSession,
        invalidSession,
      };

      const data = await generateRequest.get(requestData);

      if (data.err) throw data;

      dispatch({
        type: TYPES.GET_PERCENTAGES,
        payload: data.data,
      });
    } catch (error) {
      const err = {
        ...error,
        errText: isLangEs
          ? "Lo sentimos. No se pudieron cargar los porcentajes"
          : "We're sorry. Percentages couldn't be loaded",
      };

      dispatch({
        type: TYPES.ERROR_PERCENTAGES,
        payload: err,
      });
    }
  };

  const getChartData = async (dates) => {
    try {
      const { from, to } = dates;

      const startYear = from.year;
      const startMonth = from.monthNumber + 1;
      const startDay = from.day;

      const endYear = to.year;
      const endMonth = to.monthNumber + 1;
      const endDay = to.day;

      setLoader(TYPES.CHART_LOADING);
      const services = servicesToShow
        .filter((service) => service.show)
        .map((service) => service.id);
      getTotals(services);
      getPercentages(services);

      const requestData = {
        url: `${url}/usage/command-panel/graph?services=${services.toString()}&start_date=${startYear}-${startMonth}-${startDay}&end_date=${endYear}-${endMonth}-${endDay}&period=day`,
        terminateSession,
        invalidSession,
      };

      const data = await generateRequest.get(requestData);

      if (data.err) throw data;

      const chartData = createChartDataTotals(data);

      if (chartData.dataChart.length === 0)
        dispatch({
          type: TYPES.NO_CHART_DATA,
        });
      else
        dispatch({
          type: TYPES.GET_CHART_DATA,
          payload: chartData,
        });
    } catch (error) {
      const err = {
        ...error,
        errText: isLangEs
          ? "Lo sentimos. No se pudo cargar el gráfico"
          : "We're sorry. Chart data could not be loaded",
      };

      dispatch({
        type: TYPES.ERROR_CHART,
        payload: err,
      });
    }
  };

  const resetData = () => {
    dispatch({
      type: TYPES.RESET_DATA,
      payload: defaultState,
    });
  };

  const getRequestsForLinks = async ({
    token,
    showLoader,
    inLinks,
    requestStartOn = 0,
    isSearch,
  }) => {
    if (inLinks) navigate(PRIVATE_ROUTES.CONSULTAS);

    if (showLoader) setLoader(TYPES.REQUEST_LOADING);
    else setLoader(TYPES.SCROLL_LOADER, requestStartOn);

    try {
      const requestData = {
        url: `${url}/usage/request/${
          token || linkToken
        }?page=${requestStartOn}&offset=40`,
        terminateSession,
        invalidSession,
      };

      const data = await generateRequest.get(requestData);

      if (isSearch) return data;

      if (data.err) throw data;

      if (data.data.length === 0) {
        dispatch({
          type: TYPES.NO_REQUEST_DATA,
          payload: requestStartOn,
        });
        return;
      }

      if (requestStartOn === 0) {
        dispatch({
          type: TYPES.GET_LAST_REQUEST,
          payload: {
            data: data.data,
            requestStartOn,
          },
        });
        createTableData({ data: data.data });
      } else {
        const payload = {
          data: data.data,
          requestStartOn,
          serviceSearched: "",
        };
        dispatch({ type: TYPES.LOAD_DATA, payload });
      }
    } catch (error) {
      if (error.status === 404) {
        dispatch({
          type: TYPES.NO_REQUEST_DATA,
          payload: { statusError: error.status },
        });
        return;
      }

      const err = {
        ...error,
        errText: isLangEs
          ? "Lo sentimos. No se pudo cargar el historial"
          : "We're sorry. Links history could not be loaded",
      };

      dispatch({
        type: TYPES.ERROR_REQUEST,
        payload: err,
      });
    }
  };

  const isRequestsFromLink = ({ answer, token = "" }) => {
    if (!answer) {
      getLastRequests({ requestStartOn: 0 });
    }

    dispatch({
      type: TYPES.REQUESTS_FROM_LINKS,
      payload: {
        answer,
        linkToken: token,
      },
    });
  };

  const changeServicesToShow = (newList) => {
    dispatch({
      type: TYPES.CHANGE_SERVICES_TO_SHOW,
      payload: newList,
    });
  };

  const setServiceSearched = (serviceSearched) => {
    dispatch({ type: TYPES.SET_SERVICE_SEARCHED, payload: serviceSearched });
  };

  const getDashboardRules = async (dates) => {
    dispatch({
      type: TYPES.RULES_LOADING,
    });

    try {
      const { from, to } = dates;

      const startYear = from.year;
      const startMonth = from.monthNumber + 1;
      const startDay = from.day;

      const endYear = to.year;
      const endMonth = to.monthNumber + 1;
      const endDay = to.day;

      const requestData = {
        url: `${url}/usage/rules?start_date=${startYear}-${startMonth}-${startDay}&end_date=${endYear}-${endMonth}-${endDay}`,
        terminateSession,
        invalidSession,
      };

      const data = await generateRequest.get(requestData);

      const requestData2 = {
        url: `${url}/usage/command-panel/labels?start_date=${startYear}-${startMonth}-${startDay}&end_date=${endYear}-${endMonth}-${endDay}`,
        terminateSession,
        invalidSession,
      };

      const data2 = await generateRequest.get(requestData2);

      if (data.err) throw data;

      if (data2.err) throw data2;

      if (data.rules.length === 0) {
        dispatch({
          type: TYPES.NO_RULES_DATA,
        });

        return;
      }

      dispatch({
        type: TYPES.SET_DASHBOARD_RULES,
        payload: {
          rules: data.rules,
          totals: getRulesTotals(data2.data),
        },
      });
    } catch (error) {
      dispatch({
        type: TYPES.NO_RULES_DATA,
      });
    }
  };

  const data = useMemo(
    () => ({
      lastRequests,
      getLastRequests,
      requestStartOn,
      restartRequestsCount,
      createTableData,
      setExternalIDToTableData,
      dataToShow,
      showColumns,
      columnsToShow,
      headers,
      changeHeaders,
      serviceSearched,
      totals,
      getTotals,
      getChartData,
      chart,
      sortBy,
      searchBy,
      resetData,
      requestsLoading,
      chartLoading,
      errorRequest,
      errorTotals,
      errorChart,
      noChartData,
      scrollLoader,
      isRequestsFromLink,
      requestsFromLink,
      getRequestsForLinks,
      servicesToShow,
      changeServicesToShow,
      servicesPercentages,
      errorPercentages,
      setServiceSearched,
      getDashboardRules,
      rules,
      rulesTotals,
      rulesLoading,
      noRulesData,
    }),
    [
      lastRequests,
      getLastRequests,
      requestStartOn,
      restartRequestsCount,
      createTableData,
      setExternalIDToTableData,
      dataToShow,
      showColumns,
      columnsToShow,
      headers,
      changeHeaders,
      serviceSearched,
      totals,
      getTotals,
      getChartData,
      chart,
      sortBy,
      searchBy,
      resetData,
      requestsLoading,
      chartLoading,
      errorRequest,
      errorTotals,
      errorChart,
      noChartData,
      scrollLoader,
      isRequestsFromLink,
      requestsFromLink,
      getRequestsForLinks,
      servicesToShow,
      changeServicesToShow,
      servicesPercentages,
      errorPercentages,
      setServiceSearched,
      getDashboardRules,
      rules,
      rulesTotals,
      rulesLoading,
      noRulesData,
    ]
  );

  return <DataContext.Provider value={data}>{children}</DataContext.Provider>;
};

export { DataProvider };
export default DataContext;
