const getDropTotal = (startTotal, total) => startTotal - total;

const getDropPercentage = (startTotal, dropTotal) => {
  if (dropTotal === 0) return "0%";

  return `${((dropTotal / startTotal) * 100).toFixed(2)}%`;
};

const generateArrElement = ({ name, total, percentage, startTotal }) => {
  const sdkElement = {
    name: name,
    total,
    percentage: `${percentage}%`,
    complete: percentage,
  };

  const dropTotal = getDropTotal(startTotal, total);

  const percentageElement = {
    title: name,
    total: percentage === 100 ? 0 : dropTotal,
    percentage:
      percentage === 100 ? "0%" : getDropPercentage(startTotal, dropTotal),
  };

  return { sdkElement, percentageElement };
};

const getOrder = (title) => {
  const order = {
    Start: 1,
    Document: 2,
    Location: 3,
    Selfie: 4,
    Done: 5,
  };

  return order[title] || 6;
};

const sortArr = (arr) =>
  arr.sort((a, b) => {
    const A = a.title || a.name;
    const B = b.title || b.name;

    return getOrder(A) - getOrder(B);
  });

export const generateSDKChartData = (dataObj) => {
  const sdkData = [];
  const percentageData = [];

  const startTotal = dataObj["form_start"].count;

  for (const key in dataObj) {
    const el = dataObj[key];

    const name =
      key === "form_start"
        ? "Start"
        : key === "form_document"
        ? "Document"
        : key === "form_location"
        ? "Location"
        : key === "form_selfie"
        ? "Selfie"
        : "Done";

    const { sdkElement, percentageElement } = generateArrElement({
      name,
      total: el.count,
      percentage: el.percentage,
      startTotal,
    });

    sdkData.push(sdkElement);
    percentageData.push(percentageElement);
  }

  return {
    sdkData: sortArr(sdkData),
    percentageData: sortArr(percentageData),
  };
};
