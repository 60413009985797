import { useLocation } from "react-router-dom";
import { PRIVATE_ROUTES } from "./pages/routes";
import ReporteDataAccordion from "./ReporteDataAccordion";
import ListedWeeksSubDropdown from "./ListedWeeksSubDropdown";
import StudiesSubDropdown from "./StudiesSubDropdown";
import EmailSubDropdown from "./EmailSubDropdown";
import DocumentSubDropdown from "./DocumentSubDropdown";
import SanctionsSubDropdown from "./SanctionsSubDropdown";
import PepSubDropdown from "./PepSubDropdown";
import { formatCurrency } from "../helpers/formatCurrency";
import { formatUTCDate } from "../helpers/formatDate";
import { useTolgee, useTranslate } from "@tolgee/react";

const ResumenTab = ({ report }) => {
  const location = useLocation();
  const consultas = location.pathname === PRIVATE_ROUTES.CONSULTAS;
  const { t } = useTranslate();
  const tolgee = useTolgee(["language"]);
  const isLangEs = tolgee.getLanguage() === "es-MX";

  const formatPhone = (phone) => {
    const numberArr = phone.split("+52");
    const prefix = phone.substring(0, 3);
    return `${prefix} ${numberArr[1]}`;
  };

  const translateYesNo = (data) => {
    const yes = isLangEs ? "Sí" : "Yes";

    return data ? yes : "No";
  };

  const translate = (text) => {
    if (text === "RFC not in SAT blacklist")
      return isLangEs
        ? "El RFC no se encuentra en la lista negra del SAT"
        : text;
    if (isLangEs) return text;

    switch (text) {
      case "La dirección ingresada no es válida":
        return "The address entered is not valid";
      case "masculino":
      case "Masculino":
        return "Masculine";
      case "femenino":
      case "Femenino":
        return "Feminine";
      case "semanas":
        return "weeks";
      case "días":
        return "days";
      case "Móvil":
        return "Wireless";
      case "Fija":
        return "Land line";
      case "No se encontraron emails asociados":
        return "There are no associated email accounts";
      case "Tipo de PEP":
        return "PEP type";
      case "Nombre":
        return "Name";
      default:
        return text;
    }
  };

  const hasWarnings = (obj) => {
    if (!obj) return false;
    return Object.keys(obj).some((key) => Boolean(obj[key]) === true);
  };

  return (
    <div
      className={`${consultas ? "inConsultas" : "inBusqueda"} ${
        report.warnings && "h-90"
      } `}
    >
      {(typeof report.real_address_geospatial !== "undefined" ||
        typeof report.address_validation !== "undefined" ||
        typeof report.inside_of_prison !== "undefined" ||
        typeof report.outside_of_territory !== "undefined") && (
        <ReporteDataAccordion
          key="report-panel1"
          expandPanel="panel1"
          accordionTitle={t("dm_report_title_geo")}
          description={[
            {
              title: t("dm_report_geo_1"),
              text: `${
                typeof report.real_address_geospatial === "undefined"
                  ? "-"
                  : typeof report.real_address_geospatial !== "undefined"
                  ? report.real_address_geospatial
                  : translate("La dirección ingresada no es válida")
              }`,
            },
            {
              title: t("dm_report_geo_2"),
              text: `${
                typeof report.inside_of_prison === "undefined"
                  ? "-"
                  : translateYesNo(report.inside_of_prison)
              }`,
            },
            {
              title: t("dm_report_geo_3"),
              text: `${
                typeof report.near_to_prison === "undefined"
                  ? "-"
                  : translateYesNo(report.near_to_prison)
              }`,
            },
            {
              title: t("dm_report_geo_4"),
              text: `${
                typeof report.outside_of_territory === "undefined"
                  ? "-"
                  : translateYesNo(report.outside_of_territory)
              }`,
            },
          ]}
          showBadge={report?.geospatial_warnings?.length > 0}
          warningsToShow={report?.geospatial_warnings}
        />
      )}
      {typeof report.real_address_geospatial === "undefined" &&
        typeof report.address_validation === "undefined" &&
        typeof report.inside_of_prison === "undefined" &&
        typeof report.outside_of_territory === "undefined" &&
        hasWarnings(report?.geospatial_warnings) && (
          <ReporteDataAccordion
            key="report-panel1"
            expandPanel="panel1"
            accordionTitle={t("dm_report_title_geo")}
            description={[]}
            showBadge={report?.geospatial_warnings?.length > 0}
            warningsToShow={report?.geospatial_warnings}
          />
        )}
      {typeof report.document_type !== "undefined" &&
        report.document_type !== "Passport" && (
          <ReporteDataAccordion
            key="report-panel2"
            expandPanel="panel2"
            accordionTitle={t("dm_report_title_doc")}
            description={[
              {
                title: t("dm_report_doc_1"),
                text: `${
                  typeof report.document_type === "undefined"
                    ? "-"
                    : report.document_type
                }`,
              },
              {
                title: t("dm_report_doc_2"),
                text: `${
                  typeof report.document_name === "undefined"
                    ? "-"
                    : report.document_name
                }`,
              },
              {
                title: t("dm_report_doc_3"),
                text: `${
                  typeof report.document_valid_thru === "undefined"
                    ? "-"
                    : report.document_valid_thru
                }`,
              },
              {
                title: t("dm_report_doc_4"),
                text: `${
                  typeof report.document_DoB === "undefined"
                    ? "-"
                    : report.document_DoB
                }`,
              },
              {
                title: t("dm_report_doc_5"),
                text: `${
                  typeof report.document_gender === "undefined"
                    ? "-"
                    : report.document_gender
                }`,
              },
              {
                title: t("dm_report_doc_6"),
                text: `${
                  typeof report.document_nationality === "undefined"
                    ? "-"
                    : report.document_nationality
                    ? "MEX"
                    : "-"
                }`,
              },
              {
                title: t("dm_report_doc_7"),
                text: `${
                  typeof report.document_SoB === "undefined"
                    ? "-"
                    : report.document_SoB
                }`,
              },
              {
                title: t("dm_report_doc_8"),
                text: `${
                  typeof report.curp_valid_ine === "undefined"
                    ? "-"
                    : translateYesNo(report.curp_valid_ine)
                }`,
              },
              {
                title: t("dm_report_doc_9"),
                text: `${
                  typeof report.valid_rfc === "undefined"
                    ? "-"
                    : translateYesNo(report.valid_rfc)
                }`,
              },
              {
                title: t("dm_report_doc_10"),
                text: `${
                  typeof report.document_electoral_key === "undefined"
                    ? "-"
                    : translateYesNo(report.document_electoral_key)
                }`,
              },
              {
                title: t("dm_report_doc_12"),
                text: `${
                  typeof report.real_address_ine === "undefined"
                    ? "-"
                    : report.real_address_ine
                }`,
              },
              report?.json?.response?.raw_data?.document_ocr?.document_ocr
                ?.information?.address?.text && [
                {
                  title: t("dm_report_doc_11"),
                  text: `${
                    typeof report.json.response.raw_data.document_ocr
                      .document_ocr.information.address.text === "undefined"
                      ? "-"
                      : report.json.response.raw_data.document_ocr.document_ocr
                          .information.address.text
                  }`,
                },
              ],
              {
                text: "document_image",
                image: report?.document_image,
              },
            ]}
            SubDropdown={
              typeof report.document_type !== "undefined" && DocumentSubDropdown
            }
            showBadge={report?.document_warnings?.length > 0}
            warningsToShow={report?.document_warnings}
          />
        )}
      {typeof report.document_type !== "undefined" &&
        report.document_type === "Passport" && (
          <ReporteDataAccordion
            key="report-panel2"
            expandPanel="panel2"
            accordionTitle="Documento de identificación"
            description={[
              {
                title: t("dm_report_doc_1"),
                text: `${
                  typeof report.document_type === "undefined"
                    ? "-"
                    : report.document_type
                }`,
              },
              {
                title: t("dm_report_doc_2"),
                text: `${
                  typeof report.document_name === "undefined"
                    ? "-"
                    : report.document_name
                }`,
              },
              {
                title: t("dm_report_doc_3"),
                text: `${
                  typeof report.document_expiration_date === "undefined"
                    ? "-"
                    : report.document_expiration_date
                }`,
              },
              {
                title: t("dm_report_doc_4"),
                text: `${
                  typeof report.document_DoB === "undefined"
                    ? "-"
                    : report.document_DoB
                }`,
              },
              {
                title: t("dm_report_doc_5"),
                text: `${
                  typeof report.document_gender === "undefined"
                    ? "-"
                    : report.document_gender
                }`,
              },
              {
                title: t("dm_report_doc_6"),
                text: `${
                  typeof report.document_nationality === "undefined"
                    ? "-"
                    : report.document_nationality
                    ? "MEX"
                    : "-"
                }`,
              },
              {
                title: t("dm_report_doc_7"),
                text: `${
                  typeof report.document_SoB === "undefined"
                    ? "-"
                    : report.document_SoB
                }`,
              },
              {
                title: t("dm_report_doc_8"),
                text: `${
                  typeof report.curp_valid_passport === "undefined"
                    ? "-"
                    : translateYesNo(report.curp_valid_passport)
                }`,
              },
              {
                title: t("dm_report_doc_9"),
                text: `${
                  typeof report.valid_rfc === "undefined"
                    ? "-"
                    : translateYesNo(report.valid_rfc)
                }`,
              },
              {
                title: t("dm_report_doc_10"),
                text: `${
                  typeof report.document_electoral_key === "undefined"
                    ? "-"
                    : translateYesNo(report.document_electoral_key)
                }`,
              },
              {
                title: t("dm_report_doc_11"),
                text: `${
                  typeof report.real_address_ine === "undefined"
                    ? "-"
                    : report.real_address_ine
                }`,
              },
              {
                text: "document_image",
                image: report?.document_image,
              },
            ]}
            SubDropdown={
              typeof report.document_type !== "undefined" && DocumentSubDropdown
            }
            showBadge={report?.document_warnings?.length > 0}
            warningsToShow={report?.document_warnings}
          />
        )}
      {typeof report.document_type === "undefined" &&
        report?.document_image && (
          <ReporteDataAccordion
            key="report-panel2"
            expandPanel="panel2"
            accordionTitle="Documento de identificación"
            description={[
              {
                text: "document_image",
                image: report?.document_image,
              },
            ]}
            showBadge={report?.document_warnings?.length > 0}
            warningsToShow={report.document_warnings}
          />
        )}
      {typeof report.document_type === "undefined" &&
        !report?.document_image &&
        hasWarnings(report.document_warnings) && (
          <ReporteDataAccordion
            key="report-panel2"
            expandPanel="panel2"
            accordionTitle="Documento de identificación"
            description={[]}
            showBadge={report?.document_warnings?.length > 0}
            warningsToShow={report.document_warnings}
          />
        )}
      {(report.selfie_data || report?.selfie_warnings.length > 0) && (
        <ReporteDataAccordion
          key="report-panel0"
          expandPanel="panel0"
          accordionTitle={t("dm_report_title_selfie")}
          description={[
            {
              title: t("dm_report_selfie_1"),
              text: `${
                typeof report.selfie_first_seen === "undefined"
                  ? "-"
                  : formatUTCDate(
                      report.selfie_first_seen,
                      "DD/MM/YYYY, h:mm:ss A"
                    )
              }`,
            },
            {
              title: t("dm_report_selfie_2"),
              text: `${
                typeof report.times_seen_last_month === "undefined"
                  ? "-"
                  : report.times_seen_last_month
              }`,
            },
            {
              title: t("dm_report_selfie_3"),
              text: `${
                typeof report.selfie_gender === "undefined"
                  ? "-"
                  : translate(report.selfie_gender)
              }`,
            },
            {
              title: t("dm_report_selfie_4"),
              text: `${
                typeof report.selfie_min_age === "undefined"
                  ? "-"
                  : report.selfie_min_age
              }`,
            },
            {
              title: t("dm_report_selfie_5"),
              text: `${
                typeof report.selfie_max_age === "undefined"
                  ? "-"
                  : report.selfie_max_age
              }`,
            },
            {
              text: "selfie",
              image: report?.selfie,
            },
          ]}
          showBadge={report?.selfie_warnings?.length > 0}
          warningsToShow={report?.selfie_warnings}
        />
      )}
      {!report.selfie_data && report?.selfie && (
        <ReporteDataAccordion
          key="report-panel0"
          expandPanel="panel0"
          accordionTitle={t("dm_report_title_selfie")}
          description={[
            {
              text: "selfie",
              image: report?.selfie,
            },
          ]}
          showBadge={report?.selfie_warnings?.length > 0}
          warningsToShow={report?.selfie_warnings}
        />
      )}
      {!report.selfie_data &&
        !report?.selfie &&
        hasWarnings(report?.selfie_warning) && (
          <ReporteDataAccordion
            key="report-panel0"
            expandPanel="panel0"
            accordionTitle={t("dm_report_title_selfie")}
            description={[]}
            showBadge={report?.selfie_warnings?.length > 0}
            warningsToShow={report?.selfie_warnings}
          />
        )}
      {(typeof report.valid_rfc !== "undefined" ||
        typeof report.blacklisted_rfc !== "undefined") && (
        <ReporteDataAccordion
          key="report-panel5"
          expandPanel="panel5"
          accordionTitle="RFC"
          description={[
            {
              title: t("dm_report_doc_9"),
              text: `${
                typeof report.valid_rfc === "undefined"
                  ? "-"
                  : translateYesNo(report.valid_rfc)
              }`,
            },
            {
              title: t("dm_report_rfc_1"),
              text: `${
                typeof report.blacklisted_rfc === "undefined"
                  ? "-"
                  : translate(report.blacklisted_rfc)
              }`,
            },
            {
              title: "RFC",
              text: `${
                typeof report.rfc_number === "undefined"
                  ? "-"
                  : report.rfc_number
              }`,
            },
          ]}
          showBadge={report?.rfc_warnings?.length > 0}
          warningsToShow={report?.rfc_warnings}
        />
      )}
      {typeof report.valid_rfc === "undefined" &&
        typeof report.blacklisted_rfc === "undefined" &&
        hasWarnings(report?.rfc_warnings) && (
          <ReporteDataAccordion
            key="report-panel5"
            expandPanel="panel5"
            accordionTitle="RFC"
            description={[]}
            showBadge={report?.rfc_warnings?.length > 0}
            warningsToShow={report?.rfc_warnings}
          />
        )}
      {(typeof report.curp_valid !== "undefined" ||
        typeof report.curp_valid_ine !== "undefined" ||
        typeof report.curp_valid_passport !== "undefined") && (
        <ReporteDataAccordion
          key="report-panel6"
          expandPanel="panel6"
          accordionTitle="CURP"
          description={[
            {
              title: "CURP",
              text: `${
                typeof report.curp_number === "undefined"
                  ? "-"
                  : report.curp_number
              }`,
            },
            {
              title: t("dm_report_curp_1"),
              text: `${
                typeof report.curp_valid === "undefined"
                  ? "-"
                  : typeof report.curp_valid === "string"
                  ? "No"
                  : translate(report.curp_valid)
              }`,
            },
            {
              title: t("dm_report_curp_2"),
              text: `${
                typeof report.curp_age === "undefined" ? "-" : report.curp_age
              }`,
            },
            {
              title: t("dm_report_doc_4"),
              text: `${
                typeof report.document_DoB === "undefined"
                  ? "-"
                  : report.document_DoB
              }`,
            },
            {
              title: t("dm_report_doc_5"),
              text: `${
                typeof report.document_gender === "undefined"
                  ? "-"
                  : translate(report.document_gender)
              }`,
            },
            {
              title: t("dm_report_curp_3"),
              text: `${
                typeof report.curp_name === "undefined" ? "-" : report.curp_name
              }`,
            },
            {
              title: t("dm_report_doc_7"),
              text: `${
                typeof report.document_SoB === "undefined"
                  ? "-"
                  : report.document_SoB
              }`,
            },
          ]}
          showBadge={report?.curp_warnings?.length > 0}
          warningsToShow={report?.curp_warnings}
        />
      )}
      {typeof report.curp_valid === "undefined" &&
        typeof report.curp_valid_ine === "undefined" &&
        typeof report.curp_valid_passport !== "undefined" &&
        hasWarnings(report?.curp_warning) && (
          <ReporteDataAccordion
            key="report-panel6"
            expandPanel="panel6"
            accordionTitle="CURP"
            description={[]}
            showBadge={report?.curp_warnings?.length > 0}
            warningsToShow={report?.curp_warnings}
          />
        )}
      {typeof report.listed_weeks !== "undefined" && (
        <ReporteDataAccordion
          key="report-panel8"
          expandPanel="panel8"
          accordionTitle={t("dm_report_title_listed_weeks")}
          description={[
            {
              title: t("dm_report_title_listed_weeks"),
              text: `${
                typeof report.listed_weeks === "undefined"
                  ? "-"
                  : `${report.listed_weeks} ${translate("semanas")}`
              }`,
            },
            {
              title: t("dm_report_listed_weeks_1"),
              text: `${
                typeof report.listed_weeks_jobs === "undefined"
                  ? "-"
                  : report.listed_weeks_jobs
              }`,
            },
            {
              title: t("dm_report_listed_weeks_2"),
              text: `${
                typeof report.listed_weeks_gob === "undefined"
                  ? "-"
                  : report.listed_weeks_gob
              }`,
            },
            {
              title: t("dm_report_listed_weeks_3"),
              text: `${
                typeof report.listed_weeks_avg_days === "undefined"
                  ? "-"
                  : `${report.listed_weeks_avg_days} ${translate("días")}`
              }`,
            },
            {
              title: t("dm_report_listed_weeks_4"),
              text: `${
                typeof report.listed_weeks_longest_period === "undefined"
                  ? "-"
                  : `${report.listed_weeks_longest_period} ${translate("días")}`
              }`,
            },
            {
              title: t("dm_report_listed_weeks_5"),
              text: `${
                typeof report.listed_weeks_shortest_period === "undefined"
                  ? "-"
                  : `${report.listed_weeks_shortest_period} ${translate(
                      "días"
                    )}`
              }`,
            },
            {
              title: t("dm_report_listed_weeks_6"),
              text: `${
                typeof report.listed_weeks_max_salary === "undefined"
                  ? "-"
                  : formatCurrency(
                      report.listed_weeks_max_salary,
                      "es-MX",
                      "MXN"
                    )
              }`,
            },
            {
              title: t("dm_report_listed_weeks_7"),
              text: `${
                typeof report.listed_weeks_min_salary === "undefined"
                  ? "-"
                  : formatCurrency(
                      report.listed_weeks_min_salary,
                      "es-MX",
                      "MXN"
                    )
              }`,
            },
          ]}
          SubDropdown={ListedWeeksSubDropdown}
        />
      )}
      {(typeof report.valid_email !== "undefined" ||
        typeof report.suspect_email !== "undefined") && (
        <ReporteDataAccordion
          key="report-panel3"
          expandPanel="panel3"
          accordionTitle={t("dm_report_title_email")}
          description={[
            {
              title: t("dm_report_email_1"),
              text: `${
                typeof report.valid_email === "undefined"
                  ? "-"
                  : translateYesNo(report.valid_email)
              }`,
            },
            {
              title: t("dm_report_email_2"),
              text: `${
                typeof report.email_disposable === "undefined"
                  ? "-"
                  : translateYesNo(report.email_disposable)
              }`,
            },
            {
              title: t("dm_report_email_3"),
              text: `${
                typeof report.email_valid_dns === "undefined"
                  ? "-"
                  : translateYesNo(report.email_valid_dns)
              }`,
            },
            {
              title: t("dm_report_email_4"),
              text: `${
                typeof report.email_honeypot === "undefined"
                  ? "-"
                  : translateYesNo(report.email_honeypot)
              }`,
            },
            {
              title: t("dm_report_email_5"),
              text: `${
                typeof report.suspect_email === "undefined"
                  ? "-"
                  : report.suspect_email
              }`,
            },
            {
              title: t("dm_report_title_email"),
              text: `${
                typeof report.email === "undefined" ? "-" : report.email
              }`,
            },
            {
              title: t("dm_report_email_6"),
              text: `${
                !report.email_names ? "-" : report.email_names.toString()
              }`,
            },
            {
              title: t("dm_report_email_7"),
              text: `${
                !report.email_phone ? "-" : report.email_phone.toString()
              }`,
            },
          ]}
          SubDropdown={report.email_social_media_accounts && EmailSubDropdown}
          showBadge={report?.email_warnings?.length > 0}
          warningsToShow={report?.email_warnings}
        />
      )}
      {typeof report.valid_email === "undefined" &&
        typeof report.suspect_email === "undefined" &&
        hasWarnings(report?.email_warnings) && (
          <ReporteDataAccordion
            key="report-panel3"
            expandPanel="panel3"
            accordionTitle={t("dm_report_title_email")}
            description={[]}
            SubDropdown={report.email_social_media_accounts && EmailSubDropdown}
            showBadge={report?.email_warnings?.length > 0}
            warningsToShow={report?.email_warnings}
          />
        )}
      {(typeof report.valid_phone !== "undefined" ||
        typeof report.risky_phone !== "undefined" ||
        typeof report.valid_whatsapp_phone !== "undefined") && (
        <ReporteDataAccordion
          key="report-panel4"
          expandPanel="panel4"
          accordionTitle={t("dm_report_title_phone")}
          description={[
            {
              title: t("dm_report_phone_1"),
              text: `${
                typeof report.valid_phone === "undefined" &&
                typeof report.valid_whatsapp_phone !== "undefined"
                  ? "-"
                  : translateYesNo(
                      report.valid_phone || report.valid_whatsapp_phone
                    )
              }`,
            },
            {
              title: t("dm_report_phone_2"),
              text: `${
                typeof report.risky_phone === "undefined"
                  ? "-"
                  : translateYesNo(report.risky_phone)
              }`,
            },
            {
              title: t("dm_report_phone_3"),
              text: `${
                typeof report.phone_on_whatsapp === "undefined"
                  ? "-"
                  : translateYesNo(report.phone_on_whatsapp)
              }`,
            },
            {
              title: t("dm_report_phone_4"),
              text: `${
                typeof report.phone_city === "undefined"
                  ? "-"
                  : report.phone_city
              }`,
            },
            {
              title: t("dm_report_phone_5"),
              text: `${
                typeof report.phone_country === "undefined"
                  ? "-"
                  : report.phone_country
              }`,
            },
            {
              title: t("dm_report_phone_6"),
              text: `${
                typeof report.phone_carrier === "undefined"
                  ? "-"
                  : report.phone_carrier
              }`,
            },
            {
              title: t("dm_report_phone_7"),
              text: `${
                typeof report.phone_line_type === "undefined"
                  ? "-"
                  : report.phone_line_type === "Wireless"
                  ? translate("Móvil")
                  : translate("Fija")
              }`,
            },
            {
              title: t("dm_report_title_phone"),
              text: `${
                typeof report.phone_number === "undefined"
                  ? "-"
                  : formatPhone(report.phone_number)
              }`,
            },
            {
              title: t("dm_report_phone_8"),
              text: `${
                !report.phone_associated_emails
                  ? translate("No se encontraron emails asociados")
                  : report.phone_associated_emails.toString()
              }`,
            },
          ]}
          showBadge={report?.phone_warnings.length > 0}
          warningsToShow={report?.phone_warnings}
        />
      )}
      {typeof report.valid_phone === "undefined" &&
        typeof report.risky_phone === "undefined" &&
        typeof report.valid_whatsapp_phone === "undefined" &&
        hasWarnings(report?.phone_warnings) && (
          <ReporteDataAccordion
            key="report-panel4"
            expandPanel="panel4"
            accordionTitle={t("dm_report_title_phone")}
            description={[]}
            showBadge={report?.phone_warnings.length > 0}
            warningsToShow={report?.phone_warnings}
          />
        )}
      {(typeof report.ip_blacklisted !== "undefined" ||
        typeof report.ip_country_code !== "undefined") && (
        <ReporteDataAccordion
          key="report-panel7"
          expandPanel="panel7"
          accordionTitle="IPv4"
          description={[
            {
              title: t("dm_report_ip_1"),
              text: `${
                typeof report.ip_blacklisted === "undefined"
                  ? "-"
                  : translateYesNo(report.ip_blacklisted)
              }`,
            },
            {
              title: t("dm_report_ip_2"),
              text: `${
                typeof report.ip_country_code === "undefined"
                  ? "-"
                  : report.ip_country_code
              }`,
            },
            {
              title: translate("Móvil"),
              text: `${
                typeof report.ip_mobile === "undefined"
                  ? "-"
                  : translateYesNo(report.ip_mobile)
              }`,
            },
            {
              title: "Proxy",
              text: `${
                typeof report.ip_proxy === "undefined"
                  ? "-"
                  : translateYesNo(report.ip_proxy)
              }`,
            },
            {
              title: t("dm_report_ip_3"),
              text: `${
                typeof report.ip_tor === "undefined"
                  ? "-"
                  : translateYesNo(report.ip_tor)
              }`,
            },
            {
              title: t("input_lat_description"),
              text: `${
                typeof report.ip_lat === "undefined" ? "-" : report.ip_lat
              }`,
            },
            {
              title: t("input_lng_description"),
              text: `${
                typeof report.ip_lng === "undefined" ? "-" : report.ip_lng
              }`,
            },
          ]}
          showBadge={report?.ip_warnings?.length > 0}
          warningsToShow={report?.ip_warnings}
        />
      )}
      {typeof report.ip_blacklisted === "undefined" &&
        typeof report.ip_country_code === "undefined" &&
        hasWarnings(report?.ip_warnings) && (
          <ReporteDataAccordion
            key="report-panel7"
            expandPanel="panel7"
            accordionTitle="IPv4"
            description={[]}
            showBadge={report?.ip_warnings?.length > 0}
            warningsToShow={report?.ip_warnings}
          />
        )}
      {typeof report.studies !== "undefined" &&
        typeof report.studies_details !== "undefined" &&
        report.studies !== 0 && (
          <ReporteDataAccordion
            key="report-panel9"
            expandPanel="panel9"
            accordionTitle={t("dm_report_title_education")}
            description={[
              {
                title: t("dm_report_education_1"),
                text: report.studies,
              },
            ]}
            SubDropdown={StudiesSubDropdown}
          />
        )}
      {typeof report.pep !== "undefined" && report.pep.length > 0 && (
        <ReporteDataAccordion
          key="report-panel10"
          expandPanel="panel10"
          accordionTitle="PEP"
          multipleDescriptions={{
            valuesToShow: {
              nombre: {
                keyOfText: "name",
                title: translate("Nombre"),
              },
              tipoDePep: {
                keyOfText: "pep_type",
                title: translate("Tipo de PEP"),
              },
            },
            list: report.pep,
          }}
          SubDropdown={PepSubDropdown}
          showBadge={report?.pep_warnings?.length > 0}
          warningsToShow={report?.pep_warnings}
        />
      )}
      {typeof report.pep === "undefined" &&
        hasWarnings(report?.pep_warnings) && (
          <ReporteDataAccordion
            key="report-panel10"
            expandPanel="panel10"
            accordionTitle="PEP"
            multipleDescriptions={{
              valuesToShow: {},
              list: [],
            }}
            SubDropdown={PepSubDropdown}
            showBadge={report?.pep_warnings?.length > 0}
            warningsToShow={report?.pep_warnings}
          />
        )}
      {typeof report.sanctions !== "undefined" &&
        report.sanctions.length > 0 && (
          <ReporteDataAccordion
            key="report-panel11"
            expandPanel="panel11"
            accordionTitle={t("dm_report_title_sanctions")}
            multipleDescriptions={{
              valuesToShow: {
                nombre: {
                  keyOfText: "name",
                  title: t("pep_sub_drop_8"),
                },
                entidad: {
                  keyOfText: "source_type",
                  title: t("pep_sub_drop_7"),
                },
                sanctions: {
                  keyOfText: "sanction_details",
                  title: t("dm_report_sanctions_1"),
                },
              },
              list: report.sanctions,
            }}
            SubDropdown={SanctionsSubDropdown}
            showBadge={report?.sanctions_warnings?.length > 0}
            warningsToShow={report?.sanctions_warnings}
          />
        )}
      {typeof report.sanctions === "undefined" &&
        hasWarnings(report?.sanctions_warnings) && (
          <ReporteDataAccordion
            key="report-panel11"
            expandPanel="panel11"
            accordionTitle={t("dm_report_title_sanctions")}
            multipleDescriptions={{
              valuesToShow: {},
              list: [],
            }}
            SubDropdown={SanctionsSubDropdown}
            showBadge={report?.sanctions_warnings?.length > 0}
            warningsToShow={report?.sanctions_warnings}
          />
        )}
    </div>
  );
};
export default ResumenTab;
