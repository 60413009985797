import Radio from "@mui/joy/Radio";

const InputRadio = ({
  color,
  value,
  name,
  checked,
  handleCheck,
  handleClick,
  id,
}) => {
  if (color === "blue") {
    return (
      <Radio
        variant="solid"
        name={name}
        value={value}
        checked={checked}
        onChange={(e) => (!handleClick ? handleCheck(e) : () => {})}
        onClick={(e) =>
          e.target.checked && handleClick ? handleClick(e) : () => {}
        }
        className="blue"
        id={id ? id.toString() : ""}
      />
    );
  }

  return (
    <Radio
      variant="solid"
      name={name}
      value={value}
      checked={checked}
      onChange={handleCheck}
      className="gray"
      id={id ? id.toString() : ""}
    />
  );
};
export default InputRadio;
