import React, { useEffect, useState } from "react";
import { SEARCH_TYPES } from "../states/contexts/DataContext";
import AutoCompleteSelect from "./AutoCompleteSelect";
import { SDK_TYPE } from "../constants";
import AutoCompleteSelectMulti from "./AutoCompleteSelectMulti";

const getTitle = (service, title) => {
  return service === SDK_TYPE.ANDROID ||
    service === SDK_TYPE.MAGIC_LINK ||
    service === SDK_TYPE.WEB
    ? SEARCH_TYPES.SDK
    : title;
};

export default function SearchBarWithFilters({
  handleFormSubmit,
  serviceSearched,
  setServiceSearched,
}) {
  const [selectedOption, setSelectedOption] = useState(null);
  const [inputText, setInputText] = useState("");
  const [activeSearch, setActiveSearch] = useState(false);
  const [secondOptionSelect, setSecondOptionSelect] = useState(false);
  const [secondOption, setSecondOption] = useState(null);

  useEffect(() => {
    setActiveSearch(
      () => serviceSearched !== "" || serviceSearched !== undefined
    );
  }, [serviceSearched]);

  useEffect(() => {
    const selectOption = sessionStorage.getItem("selectedOption");
    const inputText = sessionStorage.getItem("searchedValue");

    if (selectOption) {
      const option = JSON.parse(selectOption);
      const title = getTitle(option.id, option.title);
      setServiceSearched(() => option.id);
      setSelectedOption(() => ({
        ...option,
        title,
      }));
    }

    if (inputText) setInputText(() => inputText);

    const removeSessionItems = () => {
      sessionStorage.removeItem("selectedOption");
      sessionStorage.removeItem("searchedValue");
    };

    window.addEventListener("beforeunload", removeSessionItems);

    return () => {
      window.removeEventListener("beforeunload", removeSessionItems);
    };
  }, []);

  const handleDelete = (anchorEl, id) => {
    inputText && activeSearch && handleFormSubmit({ value: "" });
    anchorEl?.children[id].blur();
    sessionStorage.removeItem("selectedOption");
    sessionStorage.removeItem("searchedValue");
    setActiveSearch(() => false);
    setSelectedOption(null);
    setInputText("");
  };

  const handleSubmit = ({ e, anchorEl, valueProp, id }) => {
    e.preventDefault();
    if (!valueProp) {
      handleFormSubmit({ value: "" });
      return;
    }

    anchorEl?.children[id].blur();
    sessionStorage.setItem("selectedOption", JSON.stringify(valueProp));
    sessionStorage.setItem("searchedValue", inputText);
    handleFormSubmit({ searchType: valueProp?.id, value: inputText });
  };

  const handleChange = (newValue) => {
    if (newValue?.id === selectedOption?.id) return;

    if (newValue?.id === SEARCH_TYPES.SDK) {
      setInputText(() => "");
      setSelectedOption(() => null);
      setSecondOptionSelect(() => true);
      return;
    }

    setSelectedOption(newValue);
    setInputText("");
  };

  const handleInputChange = (newInputValue) => {
    if (newInputValue === selectedOption?.title) return;
    setInputText(newInputValue);
  };

  const handleSecondChange = (value) => {
    setSecondOption(() => value);
    setInputText(() => value.id);

    sessionStorage.setItem("selectedOption", JSON.stringify(value));
    sessionStorage.setItem("searchedValue", value.id);
    handleFormSubmit({ searchType: value.id, value: value.id });
  };

  const handleSecondDelete = ({ id, setValue }) => {
    sessionStorage.removeItem("selectedOption");
    sessionStorage.removeItem("searchedValue");

    if (id === SEARCH_TYPES.SDK) {
      handleFormSubmit({ value: "" });
      setSecondOptionSelect(() => false);
      setInputText(() => "");
      return;
    }

    setValue((prev) => prev.filter((el) => el.id !== id));
    setSelectedOption(() => null);
  };

  return (
    <>
      {secondOptionSelect ? (
        <AutoCompleteSelectMulti
          id="second-option"
          options={secondFilters}
          setOptionSelected={setSecondOption}
          optionSelected={secondOption}
          handleChange={handleSecondChange}
          handleDelete={handleSecondDelete}
        />
      ) : (
        <AutoCompleteSelect
          id="search-bar"
          filters={filters}
          value={selectedOption}
          selectedOption={selectedOption}
          placeholder={selectedOption ? "" : "Buscar"}
          inputText={inputText}
          handleChange={handleChange}
          handleInputChange={handleInputChange}
          handleSubmit={handleSubmit}
          handleDelete={handleDelete}
        />
      )}
    </>
  );
}

const filters = [
  {
    id: SEARCH_TYPES.ID,
    title: "ID",
    description: "Búsqueda por ID",
  },
  {
    id: SEARCH_TYPES.USER_ID,
    title: "User ID",
    description: "Búsqueda por ID de un usuario",
  },
  {
    id: SEARCH_TYPES.TRULLY_ID,
    title: "Trully ID",
    description: "Búsqueda por ID de la Red Trully",
  },
  {
    id: SEARCH_TYPES.LINK,
    title: "Magic Link ID",
    description: "Búsqueda por ID de Magic Link",
  },
  {
    id: SEARCH_TYPES.SDK,
    title: "SDK",
    description: "Tipo de Canal",
  },
];

const secondFilters = [
  {
    id: SEARCH_TYPES.SDK,
    title: "SDK",
    description: "Tipo de Canal",
  },
  { id: SDK_TYPE.ANDROID, title: "Android", description: "" },
  { id: SDK_TYPE.WEB, title: "Web", description: "" },
  { id: SDK_TYPE.MAGIC_LINK, title: "Magic Link", description: "" },
];
