export const COLORS = {
  SHARK: "#181c21",
  DODGER_BLUE: "#475fff",
  WHITE: "#ffffff",
  GULF_BLUE: "#001063",
  AZURE: "#0067dd",
  DANUBE: "#88bbff",
  KEPPEL: "#008800",
  COPPERFIELD: "#d51f11",
  ICEBERG: "#e7f6f3",
  PEACH_SCHNAPPS: "#fff0e7",
  COM: "#ecba09",
  BUTTERMILK: "#fff8d0",
  SHUTTLE_GRAY: "#5f6877",
  CATSKILL_WHITE: "#e5ebf3",
  ZIRCON: "#fbfcff",
  MAUVE: "#d6a0ff",
  LAVENDER: "#cbbaff",
  LIGHT_GRAY: "#c1c0c9",
};
