import { useTranslate } from "@tolgee/react";
import { FLAGS_NAMES } from "../states/contexts/ReportContext";
import { COLORS } from "../styles/utils/colors";
import RadioGroup from "./RadioGroup";

const SelectStatus = ({ status, setNewStatus }) => {
  const { t } = useTranslate();

  const handleRadioChange = (selectedOption) => {
    const newStatus =
      selectedOption === "aceptar"
        ? FLAGS_NAMES.NO_THREAT
        : FLAGS_NAMES.POTENTIAL_THREAT;
    setNewStatus(newStatus);
  };

  const accept =
    (status === FLAGS_NAMES.NOT_FRAUD || status === FLAGS_NAMES.NO_THREAT) &&
    status !== FLAGS_NAMES.POTENTIAL_THREAT &&
    status !== FLAGS_NAMES.FRAUD &&
    status !== FLAGS_NAMES.REVIEW;

  const reject =
    (status === FLAGS_NAMES.POTENTIAL_THREAT || status === FLAGS_NAMES.FRAUD) &&
    status !== FLAGS_NAMES.NOT_FRAUD &&
    status !== FLAGS_NAMES.NO_THREAT &&
    status !== FLAGS_NAMES.REVIEW;

  return (
    <div className="selectStatus">
      <RadioGroup
        name="opciones"
        options={[
          {
            id: "aceptar",
            styles: { color: COLORS.KEPPEL },
            label: t("accept_button"),
            text: t("select_status_accept_text"),
            checked: accept,
          },
          {
            id: "rechazar",
            styles: { color: COLORS.COPPERFIELD },
            label: t("select_status_potential_threat"),
            text: t("select_status_potential_threat_text"),
            checked: reject,
          },
        ]}
        onChange={handleRadioChange}
        showText={true}
      />
    </div>
  );
};
export default SelectStatus;
