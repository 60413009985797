import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";
import IconInfo from "./IconInfo";
import { useEffect, useRef, useState } from "react";
import Slide from "@mui/material/Slide";
import { COLORS } from "../styles/utils/colors";
import { ThemeProvider, createTheme } from "@mui/material";
import { useTranslate } from "@tolgee/react";

const InputAddress = ({
  form,
  inputName,
  inputLabel,
  description,
  setValue,
  infoText,
  validate,
  validateErrorText,
  iconStyles,
  handleFormValidation,
  styles,
  disabled = false,
  textClass,
  textStyles,
  inputStylesMUI,
  keyProp,
}) => {
  const [labelVisible, setLabelVisible] = useState(true);
  const [isValid, setIsValid] = useState(undefined);
  const containerRef = useRef(null);
  const inputRef = useRef(null);
  const { t } = useTranslate();
  const [errorText, setErrorText] = useState(validateErrorText);

  useEffect(() => {
    if (handleFormValidation) {
      const input = {
        name: inputName,
        valid: typeof isValid === "undefined" ? true : isValid,
      };
      handleFormValidation(input);
    }
  }, [isValid]);

  useEffect(() => {
    if (form[inputName] === "" || typeof form[inputName] === "undefined")
      setIsValid(undefined);

    if (typeof form[inputName] === "undefined") setLabelVisible(true);

    if (inputRef.current?.value !== "") setLabelVisible(false);
  }, [form[inputName]]);

  const handleInputFocus = (show) => {
    setLabelVisible(show);
  };

  const handleChange = ({ target: { name, value } }) => {
    const valid = validate(value);

    if (typeof valid === "undefined") setIsValid(undefined);
    else if (valid) setIsValid(true);
    else {
      setIsValid(false);
      setErrorText(generateErrorText());
    }
    setValue({
      ...form,
      [name]: value,
    });
  };

  const generateErrorText = () => {
    if (inputName === "lng") return t("input_lng_error");
    else if (inputName === "lat") return t("input_lat_error");
    else return validateErrorText;
  };

  const theme = createTheme({
    typography: {
      fontFamily: textStyles?.fontFamily || "DM Sans",
      allVariants: {
        color: textStyles?.secondaryColor || COLORS.SHUTTLE_GRAY,
      },
    },
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            width: inputStylesMUI?.width || "100%",
            maxWidth: inputStylesMUI?.maxWidth || "350px",
            fontFamily: textStyles?.fontFamily || "DM Sans",
            // Estilo para el texto ingresado en el input
            "& .MuiInputBase-input": {
              color: textStyles?.primaryColor || COLORS.SHARK,
            },
            // Estilo para el label
            "& label": {
              color: textStyles?.secondaryColor || COLORS.SHUTTLE_GRAY,
            },
            // Estilo para el label cuando está enfocado
            "& label.Mui-focused": {
              color: textStyles?.primaryColor || COLORS.SHARK,
            },
            // Estilo para el placeholder
            "& .MuiInputBase-input::placeholder": {
              color: textStyles?.secondaryColor || COLORS.SHUTTLE_GRAY,
              opacity: 1,
            },
          },
        },
      },
      MuiFormControlLabel: {
        styleOverrides: {
          root: {
            width: inputStylesMUI?.width || "100%",
            maxWidth: inputStylesMUI?.maxWidth || "350px",
          },
          label: {
            width: inputStylesMUI?.width || "100%",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            fontFamily: textStyles?.fontFamily || "DM Sans",
            color: textStyles?.secondaryColor || COLORS.SHUTTLE_GRAY,
          },
        },
      },
    },
  });

  return (
    <div className="textInputComponent" key={keyProp}>
      <div className="textInputComponent--title">
        <span
          style={{ color: textStyles?.primaryColor || COLORS.SHUTTLE_GRAY }}
          className={`${
            textClass === "apply-font-text"
              ? textClass
              : "text-2 color--shuttle-gray"
          }`}
        >
          {description}
        </span>
        {infoText && <IconInfo text={infoText} styles={iconStyles} />}
      </div>
      <ThemeProvider theme={theme}>
        <FormControlLabel
          control={
            <TextField
              disabled={disabled}
              type="text"
              name={inputName}
              value={form[inputName] ? form[inputName] : ""}
              onChange={handleChange}
              onFocus={() => {
                handleInputFocus(false);
              }}
              onBlur={({ target: { value } }) => {
                if (value === "") handleInputFocus(true);
              }}
              label={labelVisible ? inputLabel : ""}
              InputLabelProps={{ shrink: false }}
              className={`custom-input ${
                typeof isValid === "undefined"
                  ? ""
                  : isValid
                  ? "valid-input"
                  : "invalid-input"
              }`}
              style={styles}
              inputRef={inputRef}
            />
          }
          className="textInputComponent--label custom-label"
        />
      </ThemeProvider>
      <div ref={containerRef} className="slideContainer">
        <Slide
          direction="down"
          in={isValid === false}
          mountOnEnter
          unmountOnExit
          timeout={500}
          container={containerRef.current}
        >
          <p className="inputTextErrMsg">{errorText}</p>
        </Slide>
      </div>
    </div>
  );
};
export default InputAddress;
