import { useContext, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import DataContext from "../../contexts/DataContext";
import Reporte from "../Reporte";
import TableContainer from "../TableContainer";
import ReportContext from "../../contexts/ReportContext";
import AuthContext from "../../contexts/AuthContext";
import ModalCSV from "../ModalCSV";
import ConsultasDetails from "./ConsultasDetails";
import ConsultasHeader from "../ConsultasHeader";
const Consultas = ({ walkthroughDash }) => {
  const {
    closeReport,
    showReport,
    findReport,
    toggleFlaggingForm,
    restartReport,
  } = useContext(ReportContext);
  const location = useLocation();
  const {
    user: {
      metadata: { history },
    },
  } = useContext(AuthContext);
  const {
    requestsLoading,
    errorRequest,
    getLastRequests,
    requestStartOn,
    serviceSearched,
    searchBy,
    dataToShow,
    scrollLoader,
    lastRequests,
    createTableData,
    headers,
    changeHeaders,
    showColumns,
    columnsToShow,
    sortBy,
    requestsFromLink,
    isRequestsFromLink,
    getRequestsForLinks,
  } = useContext(DataContext);
  const [openModal, setOpenModal] = useState(false);
  const scrollCalled = useRef(false);
  const requestStartOnRef = useRef(requestStartOn);

  useEffect(() => {
    columnsToShow(history);
  }, []);

  useEffect(() => {
    requestStartOnRef.current = requestStartOn;
  }, [requestStartOn]);

  useEffect(() => {
    if (!requestsFromLink) getLastRequests({ requestStartOn: 0 });

    return () => {
      isRequestsFromLink({ answer: false });
      restartReport();
    };
  }, [location.pathname]);

  useEffect(() => {
    if (requestsFromLink) return;

    if (lastRequests.length !== 0) createTableData({ data: lastRequests });
    closeReport();
  }, [location.pathname, lastRequests]);

  const handleScroll = () => {
    if (scrollCalled.current) return;
    scrollCalled.current = true;

    const handleAsyncOperation = async () => {
      try {
        if (requestsFromLink) {
          await getRequestsForLinks({
            showLoader: false,
            requestStartOn: requestStartOnRef.current + 1, 
          });
        } else if (serviceSearched === "" || typeof serviceSearched === "undefined") {
          await getLastRequests({
            requestStartOn: requestStartOnRef.current + 1, 
            noLoader: true,
          });
        } else {
          const searchedValue = sessionStorage.getItem("searchedValue");
          const selectedOption = JSON.parse(
            sessionStorage.getItem("selectedOption")
          )?.id;

          await searchBy({
            value: searchedValue,
            serviceSearched: selectedOption,
            noLoader: true,
            requestStartOn: requestStartOnRef.current + 1, 
          });
        }
      } catch (error) {
      } finally {
        scrollCalled.current = false;
      }
    };

    handleAsyncOperation();
  };

  const handleClick = (id) => {
    const report = dataToShow.find((el) => el.id === id);
    toggleFlaggingForm(null);
    findReport({ idToSearch: report.id });
  };

  const headersBtnClick = (title, dataType, dataKey, sortedBy) => {
    const newOrder = sortedBy === "asc" ? "desc" : "asc";
    changeHeaders(title, newOrder);
    sortBy(newOrder, dataType, dataKey);
  };

  return (
    <>
      {!showReport && (
        <>
          {openModal && <ModalCSV setOpenModal={setOpenModal} />}
          <section
            className={`layout__main__section__consultas inConsultas border border-light rounded-2 `}
          >
            <ConsultasHeader showButtons={true} setOpenModal={setOpenModal} />
            <TableContainer
              headers={headers}
              dataToShow={dataToShow}
              loading={requestsLoading}
              error={errorRequest}
              requestStartOn={requestStartOn}
              serviceSearched={serviceSearched}
              scrollLoader={scrollLoader}
              handleScroll={handleScroll}
              inConsultas={true}
              handleClick={handleClick}
              headersBtnClick={headersBtnClick}
              showColumns={showColumns}
              walkthrough={false}
            />
          </section>
        </>
      )}
      {showReport && (
        <section className="consultas__reporte__container">
          <section className="consultas__reporte__details-container border border-light rounded-2">
            <ConsultasDetails
              dataToShow={dataToShow}
              dataHeader={{ showReport, closeReport, showButtons: false }}
              handleClick={handleClick}
              scrollLoader={scrollLoader}
              handleScroll={handleScroll}
              requestStartOn={requestStartOn}
            />
          </section>
          <section className="consultas__reporte border border-light rounded-2">
            <Reporte />
          </section>
        </section>
      )}
    </>
  );
};
export default Consultas;
