export const saveMetadata = ({
  userData = null,
  metadata = null,
  notifications = null,
}) => {
  const saveToSessionStorage = (data) => {
    sessionStorage.setItem("metadata", JSON.stringify(data));
  };

  const extractMetadataFromUserData = (userData) => {
    const {
      getUserData: { metadata: storedMetadata },
      notifications,
    } = userData;

    return {
      metadata: {
        filters: {
          document_image: storedMetadata?.filters["filter.document_image"],
          rfc: storedMetadata?.filters["filter.rfc"],
          curp: storedMetadata?.filters["filter.curp"],
          ip: storedMetadata?.filters["filter.ip"],
          email: storedMetadata?.filters["filter.email"],
          image: storedMetadata?.filters["filter.image"],
          address: storedMetadata?.filters["filter.address"],
          phone_number: storedMetadata?.filters["filter.phone_number"],
          location: storedMetadata?.filters["filter.location"],
          document_image_back: storedMetadata?.filters["filter.document_image_back"],
        },
        history: {
          date: storedMetadata?.history["history.date"],
          productName: storedMetadata?.history["history.productName"],
          id: storedMetadata?.history["history.id"],
          externalId: storedMetadata?.history["history.externalId"],
          informador: storedMetadata?.history["history.informador"],
          estatus: storedMetadata?.history["history.estatus"],
        },
        walkthrough: {
          dash: storedMetadata?.walkthrough["dashboard.dashboard"],
          busqueda: {
            form: storedMetadata?.walkthrough["busqueda.form"],
            report: storedMetadata?.walkthrough["busqueda.report"],
          },
          consultas: storedMetadata?.walkthrough["consultas.consultas"],
        },
      },
      notifications,
    };
  };

  const updateSessionStorageMetadata = (newMetadata) => {
    const savedMetadata = JSON.parse(sessionStorage.getItem("metadata"));
    const updatedMetadata = {
      ...savedMetadata,
      ...newMetadata,
    };
    saveToSessionStorage(updatedMetadata);
  };

  if (userData) {
    const extractedData = extractMetadataFromUserData(userData);
    saveToSessionStorage(extractedData);
    return;
  }

  if (metadata) {
    updateSessionStorageMetadata({ metadata });
    return;
  }

  if (notifications) {
    updateSessionStorageMetadata({ notifications });
  }
};
