const initialHeaders = [
  {
    id: "link-id",
    title: "Link ID",
    dataType: "string",
    dataKey: "magic_link_id",
    sortedBy: "desc",
  },
  {
    id: "link-url",
    title: "Link URL",
    dataType: "string",
    dataKey: "magic_link_url",
    sortedBy: "desc",
  },
  {
    id: "link-title",
    title: "Título",
    dataType: "string",
    dataKey: "title",
    sortedBy: "desc",
  },
  {
    id: "link-date",
    title: "Fecha de creación",
    dataType: "date",
    dataKey: "created_on",
    sortedBy: "desc",
  },
  {
    id: "link-response",
    title: "Respuestas",
    dataType: "number",
    dataKey: "times_used",
    sortedBy: "desc",
  },
  {
    id: "link-status",
    title: "Estatus",
    dataType: "string",
    dataKey: "is_active",
    sortedBy: "desc",
  },
  {
    id: "link-btn",
    title: "",
    dataType: "",
    dataKey: "",
    sortedBy: "",
  },
];

export const initialState = {
  dataToShow: [],
  headers: initialHeaders,
  linksLoading: false,
  linksError: false,
  scrollLoader: false,
  requestStartOn: 0,
  activeLink: null,
  noLinksToShow: false,
  isEdit: false,
  magicLinkURL: null,
};
