import { FLAGS_NAMES } from "../states/contexts/ReportContext";
import Badge from "./Badge";
import ExternalIDInput from "./ExternaIDInput";
import { COLORS } from "../styles/utils/colors";
import { useTranslate } from "@tolgee/react";

const PerfilData = ({ status, firstSeen, fraudType, lastSeen, timesSeen }) => {
  const { t } = useTranslate();

  return (
    <div className="perfilData">
      <div className="perfilData__container">
        <h3 className="text-2--bold">{t("perfil_data_status")}</h3>
        {status === FLAGS_NAMES.FRAUD ||
        status === FLAGS_NAMES.POTENTIAL_THREAT ? (
          <Badge
            icon="icon-amenaza"
            color={COLORS.COPPERFIELD}
            bgColor={COLORS.PEACH_SCHNAPPS}
            text="Amenaza"
            styles={{
              container: { width: "fit-content" },
            }}
          />
        ) : status === FLAGS_NAMES.NO_THREAT ||
          status === FLAGS_NAMES.NOT_FRAUD ? (
          <Badge
            icon="icon-check"
            color={COLORS.KEPPEL}
            bgColor={COLORS.ICEBERG}
            text="¡Correcto!"
            styles={{
              container: { width: "fit-content" },
            }}
          />
        ) : (
          <Badge
            icon="icon-revisa"
            color={COLORS.COM}
            bgColor={COLORS.BUTTERMILK}
            text="Revisar"
            styles={{
              container: { width: "fit-content" },
            }}
          />
        )}
      </div>
      <div className="perfilData__container">
        <h3 className="text-2--bold">{t("perfil_data_fraud_type")}</h3>
        <p className="text-1">{fraudType}</p>
      </div>
      <div className="perfilData__container">
        <h3 className="text-2--bold">User ID</h3>
        <ExternalIDInput />
      </div>
    </div>
  );
};
export default PerfilData;
