import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";
import IconInfo from "./IconInfo";
import { useEffect, useRef, useState } from "react";
import Slide from "@mui/material/Slide";
import {
  IconButton,
  InputAdornment,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import { COLORS } from "../styles/utils/colors";

const InputPass = ({
  form,
  inputName,
  inputLabel,
  description,
  setValue,
  infoText,
  validate,
  validateErrorText,
  iconStyles,
  handleFormValidation,
  styles,
  textStyles,
  textClass,
  inputStylesMUI,
}) => {
  const [labelVisible, setLabelVisible] = useState(true);
  const [isValid, setIsValid] = useState(undefined);
  const [showPass, setShowPass] = useState(false);
  const containerRef = useRef(null);

  useEffect(() => {
    if (handleFormValidation) {
      const input = {
        name: inputName,
        valid: typeof isValid === "undefined" ? true : isValid,
      };
      handleFormValidation(input);
    }
  }, [isValid]);

  useEffect(() => {
    if (form[inputName] === "" || typeof form[inputName] === "undefined")
      setIsValid(undefined);
  }, [form[inputName]]);

  const handleInputFocus = (show) => {
    setLabelVisible(show);
  };

  const handleChange = ({ target: { name, value } }) => {
    const pass1Key = form.password1 ? "password1" : "new_password1";
    const pass1 = form[pass1Key];
    const pass2Key = form.password2 ? "password2" : "new_password2";
    const pass2 = form[pass2Key];

    let valid;

    if (name === pass2Key) valid = validate(pass1, value);
    else valid = validate(value);

    let resetPass2;

    if (
      name === pass1Key &&
      form[pass2Key] !== value &&
      form[pass2Key] !== ""
    ) {
      resetPass2 = { ...form, [pass2Key]: "" };
    }

    if (typeof valid === "undefined") setIsValid(undefined);
    else if (valid) setIsValid(true);
    else setIsValid(false);

    if (resetPass2)
      setValue({
        ...resetPass2,
        [name]: value,
      });
    else
      setValue({
        ...form,
        [name]: value,
      });
  };

  const theme = createTheme({
    typography: {
      fontFamily: textStyles?.fontFamily || "DM Sans",
      allVariants: {
        color: textStyles?.secondaryColor || COLORS.SHUTTLE_GRAY,
      },
    },
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            width: inputStylesMUI?.width || "100%",
            maxWidth: inputStylesMUI?.maxWidth || "350px",
            fontFamily: textStyles?.fontFamily || "DM Sans",
            // Estilo para el texto ingresado en el input
            "& .MuiInputBase-input": {
              color: textStyles?.primaryColor || COLORS.SHARK,
            },
            // Estilo para el label
            "& label": {
              color: textStyles?.secondaryColor || COLORS.SHUTTLE_GRAY,
            },
            // Estilo para el label cuando está enfocado
            "& label.Mui-focused": {
              color: textStyles?.primaryColor || COLORS.SHARK,
            },
            // Estilo para el placeholder
            "& .MuiInputBase-input::placeholder": {
              color: textStyles?.secondaryColor || COLORS.SHUTTLE_GRAY,
              opacity: 1,
            },
          },
        },
      },
      MuiFormControlLabel: {
        styleOverrides: {
          root: {
            width: inputStylesMUI?.width || "100%",
            maxWidth: inputStylesMUI?.maxWidth || "350px",
          },
          label: {
            width: inputStylesMUI?.width || "100%",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            fontFamily: textStyles?.fontFamily || "DM Sans",
            color: textStyles?.secondaryColor || COLORS.SHUTTLE_GRAY,
          },
        },
      },
    },
  });

  return (
    <div className="textInputComponent">
      <div className="textInputComponent--title">
        <span
          style={{ color: textStyles?.primaryColor || COLORS.SHUTTLE_GRAY }}
          className={`${
            textClass === "apply-font-text"
              ? textClass
              : "text-2 color--shuttle-gray"
          }`}
        >
          {description}
        </span>
        {infoText && <IconInfo text={infoText} styles={iconStyles} />}
      </div>
      <ThemeProvider theme={theme}>
        <FormControlLabel
          control={
            <TextField
              type={showPass ? "text" : "password"}
              name={inputName}
              value={form[inputName] || ""}
              onChange={handleChange}
              onFocus={() => {
                handleInputFocus(false);
              }}
              onBlur={({ target: { value, name } }) => {
                if (value === "") handleInputFocus(true);
              }}
              label={labelVisible ? inputLabel : ""}
              InputLabelProps={{ shrink: false }}
              className={`custom-input ${
                typeof isValid === "undefined"
                  ? ""
                  : isValid
                  ? "valid-input"
                  : "invalid-input"
              }`}
              style={styles}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPass(!showPass)}
                      onMouseDown={(e) => e.preventDefault()}
                    >
                      {showPass ? (
                        <div className="icon-mostrar"></div>
                      ) : (
                        <div className="icon-ocultar"></div>
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          }
          className="textInputComponent--label custom-label"
        />
      </ThemeProvider>
      <div ref={containerRef} className="slideContainer">
        <Slide
          direction="down"
          in={isValid === false}
          mountOnEnter
          unmountOnExit
          timeout={500}
          container={containerRef.current}
        >
          <p className="inputTextErrMsg">{validateErrorText}</p>
        </Slide>
      </div>
    </div>
  );
};
export default InputPass;
