import { useContext } from "react";
import ReportContext from "../contexts/ReportContext";
import ReportarForm from "./ReportarForm";
import ReportarFlagged from "./ReportarFlagged";
import { useTranslate } from "@tolgee/react";

const Reportar = ({ reportToFlag }) => {
  const { toggleFlaggingForm } = useContext(ReportContext);
  const flagged = reportToFlag.flaggedFor.wasFlagged;
  const { t } = useTranslate();

  return (
    <article style={{ padding: "16px" }}>
      <header className="reporte__container__header">
        <div className="reporte__container__header--title">
          <button
            className="icon-anterior"
            onClick={() => {
              toggleFlaggingForm(null);
            }}
          ></button>
          {!flagged && (
            <h1 className="heading-2">{t("report_flag_report_title")}</h1>
          )}
          {flagged && (
            <h1 className="heading-2">
              {t("report_header_title")}: ID-
              {reportToFlag.id || reportToFlag.request_id}
            </h1>
          )}
        </div>
      </header>
      {reportToFlag && !flagged && <ReportarForm />}
      {flagged && <ReportarFlagged flag={reportToFlag.flaggedFor} />}
    </article>
  );
};
export default Reportar;
