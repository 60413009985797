import { useContext, useEffect } from "react";
import ReportContext from "../../contexts/ReportContext";
import CommandPanelConsultas from "../CommandPanelConsultas";
import CommandPanelRules from "../CommandPanelRules";
import CommandPanelSDK from "../CommandPanelSDK";

export const stepsOptions = {
  disableInteraction: true,
  showBullets: false,
  exitOnOverlayClick: false,
  exitOnEsc: true,
  tooltipClass: "customTooltip",
  highlightClass: "customHighlight",
  nextLabel: "Continuar",
  prevLabel: "Anterior",
  skipLabel: "Saltar",
  doneLabel: "Terminar",
};

const Dashboard = () => {
  const { report, closeReport } = useContext(ReportContext);

  useEffect(() => {
    if (report) closeReport();
  }, []);

  return (
    <div className="dashboard__main walkthrough__chart">
      <CommandPanelConsultas />
      <CommandPanelRules />
      <CommandPanelSDK />
    </div>
  );
};
export default Dashboard;
