import { useEffect, useRef } from "react";
import { Slide } from "@mui/material";

const ErrorSlideTimeOut = ({ error, restartFn, styles }) => {
  const containerRef = useRef(null);

  useEffect(() => {
    if (error.err) {
      const showMsg = setTimeout(() => {
        restartFn();
      }, 5000);

      return () => {
        clearTimeout(showMsg);
      };
    }
  }, [error.err]);

  return (
    <div
      ref={containerRef}
      style={styles}
      className="errorSlide errorSlide__busqueda"
    >
      <Slide
        direction="down"
        in={error.err}
        mountOnEnter
        unmountOnExit
        timeout={500}
        container={containerRef.current}
      >
        <p className="inputTextErrMsg">{error.errText}</p>
      </Slide>
    </div>
  );
};
export default ErrorSlideTimeOut;
