import { useEffect, useState } from "react";

export const useNotifications = (duration = 5000) => {
  const [snackbarList, setSnackbarList] = useState([]);

  const addSnackbarToList = (notification) => {
    setSnackbarList((prevList) => {
      let newList = [{ ...notification, timestamp: Date.now() }, ...prevList];
      newList = newList.length > 3 ? newList.slice(0, -1) : newList;
      return newList;
    });
  };

  const removeSnackbarFromList = (timestamp) => {
    setSnackbarList((prevList) =>
      prevList.filter((item) => item.timestamp !== timestamp)
    );
  };

  useEffect(() => {
    if (snackbarList.length > 0) {
      const oldestSnackbar = snackbarList[snackbarList.length - 1];
      const timer = setTimeout(() => {
        removeSnackbarFromList(oldestSnackbar.timestamp);
      }, duration);

      return () => clearTimeout(timer);
    }
  }, [snackbarList, duration]);

  return {
    snackbarList,
    addSnackbarToList,
    removeSnackbarFromList,
  };
};
